import React, { useEffect, useState } from 'react';
import { AutoComplete } from "primereact/autocomplete";
import styled from 'styled-components';
import { translatedUnit } from "../shared/utils/unit.utils";
import { ProductUnit } from "../__generated__/types";

interface ProductAutoCompleteProps {
  value?: Product | undefined;
  products: Product[];
  onChange: (product: Product) => void;
  tabIndex?: number;
}

type Product = {
  id: string;
  name: string;
  extra: string | null;
  unit: ProductUnit | null;
  amount: number | null;
};

export const Item = styled.div`
  div:last-child {
    color: #EE7A31;
    font-size: 0.9rem;
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  .p-button,
    .p-button:enabled:active,
    .p-button:enabled:hover,
    .p-button:enabled:focus,
    .p-button:enabled:visited {
    background: transparent;
    color: #748F56;
    border-left: 0;
    border-color: #a6a6a6;
  }

  input {
    border-right: 0;
  }
`;

const ProductAutoComplete = (props: ProductAutoCompleteProps) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (props.value) {
      setSelectedProduct(props.value);
    }
  }, [props.value]);

  const searchProduct = (event: any) => {
    setTimeout(() => {
      let _filteredProducts;
      if (!event.query.trim().length) {
        _filteredProducts = [...props.products];
      } else {
        _filteredProducts = props.products.filter((product) => {
          return product.name.toLowerCase().includes(event.query.toLowerCase());
        });
      }

      setFilteredProducts(_filteredProducts);
    }, 250);
  };

  const itemTemplate = (item: Product) => {
    const {amount, unit} = item;
    const unitText = `${amount} ${translatedUnit(unit)}`;

    return <Item>
      <div>{item.name} ({unitText})</div>
      <div>{item.extra}</div>
    </Item>;
  };

  return <StyledAutoComplete
    autoFocus
    value={selectedProduct}
    suggestions={filteredProducts}
    completeMethod={searchProduct}
    field="name"
    dropdown
    tabIndex={props.tabIndex}
    itemTemplate={itemTemplate}
    onChange={(e) => {
      setSelectedProduct(e.value);
    }}
    onSelect={(e) => {
      if (e.value) {
        props.onChange(e.value);
      }
    }}
  />;
};

export default ProductAutoComplete;
