import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {MenuItem} from "primereact/menuitem/menuitem";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";

interface UserMenuProps {
  items: MenuItem[];
  redirectUrl: string;
  switchSites?: boolean;
}

const UserMenu = (props: UserMenuProps) => {
  const {loginWithRedirect, logout, user} = useAuth0();
  const navigate = useNavigate();

//  const menu = useRef<Menu>(null);

  return <div className="grid align-items-center h-full">
    {/*{user &&<div className="col">*/}
    {/*  <i className="pi pi-user text-3xl"/>*/}
    {/*</div>}*/}
    <div className="col flex justify-content-end flex-column">
      {user && <div className={"text-right"}>Welkom {user?.given_name}</div>}
      <div className="flex flex-wrap">
        {!user &&
          <Button
            label='Aanmelden'
            className='p-button-outlined p-button p-1'
            onClick={() =>{
              if(props.switchSites) {
                window.location.href = props.redirectUrl
              } else {
                loginWithRedirect({redirectUri: props.redirectUrl})
              }
            }}
          />
        }
        {user && <>
          {/*<Menu model={[...props.items, {*/}
          {/*  label: 'Afmelden',*/}
          {/*  icon: 'pi pi-sign-out',*/}
          {/*  command: () => {*/}
          {/*    logout({returnTo: window.location.origin});*/}
          {/*  }*/}
          {/*}]} popup ref={menu} id="popup_menu"/>*/}

          {/*<Button className={'p-button p-button-link p-0'} onClick={() => navigate('/account')}>Mijn*/}
          {/*  account</Button>*/}
          {/*<div className={'border-1 border-300 mx-1 p-0'}/>*/}
          <Button className={'p-button p-button-link p-0'}
                  onClick={(event) => logout({returnTo: window.location.origin})}>Afmelden</Button>
        </>}
      </div>
    </div>
  </div>;
};

export default UserMenu;
