import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { getAuthConfig } from "./config";

const Auth0ProviderWithHistory = ({children}: any) => {
  const navigate = useNavigate();

  const authConfig: any = getAuthConfig();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: authConfig.domain,
    clientId: authConfig.clientId,
    ...(authConfig.audience ? {audience: authConfig.audience} : null),
    redirectUri: window.location.origin,
    scope: 'read:roles openid profile create:users',
    audience: `https://${authConfig.domain}/api/v2/`,
    onRedirectCallback,
    // advancedOptions: {
    //   defaultScope: 'create:users'
    // }
  };

  return (
    <Auth0Provider
      {...providerConfig}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
