import gql from "graphql-tag";

export const UpdateClientGroupMutation = gql`
mutation UpdateClientGroup(
  $clientGroupId: ID!,
  $data: ClientGroupUpdateInput!,
) {
  updateClientGroup(
    where: {id: $clientGroupId}
    data: $data
  ) {
    id
    productPrices {
      id
    }
  }
}

`;
export const ClientGroupsQuery = gql`
query ClientGroups($farmId: ID!) {
  clientGroups(orderBy: order_ASC, where: {farm: {id: $farmId}}) {
    id
    name
    code
    profitMargin
    order
    default
  }
}
`;


