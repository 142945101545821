import {compact} from "lodash";

export enum FarmConfigCodes {
  selfHarvest = "selfHarvest",
  baskets = "baskets",
  clientGroups_flexCode = "clientGroup.flexCode",
  baskets_flexCodes = "basket.flexCodes"
}

export const isEnabled = (config: {
  id: string,
  code: string,
  value: any
}[] | null | undefined, code: FarmConfigCodes) => {
  let value = getFarmConfig(config, code)?.value;
  return value && value.value;
};

export const getFarmConfig = (config: {
  id: string,
  code: string,
  value: any
}[] | null | undefined, code: FarmConfigCodes) => {
  return compact(config).find(configItem => configItem.code === code.toString());
};
