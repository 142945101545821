import React from 'react';
import moment, {Moment} from "moment";
import {asDayFullDdMmYyyy, iso8601_DayFullDdMmm, momentFromIso8601} from 'shared/utils/date.utils';
import {compact, isEmpty} from "lodash";
import {
  ActiveSubscriptions_clientOrders_productOrders_deliveries,
  DeliveryUpdate,
  DeliveryUpdateVariables,
  ProductOrderDeliveryUpdateInput
} from '__generated__/types';
import EditableDescriptionItem from "../../../../../../../../shared/components/DescriptionList/EditableDescriptionItem";
import BasketCompositionInfoForDelivery from "../BasketCompositionInfoForDelivery/BasketCompositionInfoForDelivery";
import DescriptionItem from "../../../../../../../../shared/components/DescriptionList/DescriptionItem";
import {Message} from "primereact/message";
import CancelDeliveryButton from './components/CancelDeliveryButton';
import {DeliveryUpdateMutation} from "../../../../../../../../shared/queries/productOrder.gql";
import {useMutation} from "@apollo/client";
import ChangeDeliveryLocationButton from "../../../ChangeDeliveryLocationButton/ChangeDeliveryLocationButton";
import {SubscriptionClientOrders_productOrders} from "../../../../../SubscriptionTable/SubscriptionTable";

interface DeliveryWeekDetailProps {
  clientOrderId: string;
  deliveryWeek: Moment;
  productOrder: SubscriptionClientOrders_productOrders | null | undefined;
}

const DeliveryWeekDetail = (props: DeliveryWeekDetailProps) => {
  const [updateProductOrderDelivery] = useMutation<DeliveryUpdate>(DeliveryUpdateMutation);
  let delivery: ActiveSubscriptions_clientOrders_productOrders_deliveries | undefined = compact(props.productOrder?.deliveries).find(delivery =>
    momentFromIso8601(delivery.plannedDeliveryDate).isSame(props.deliveryWeek, 'isoWeek'));


  let isDelivered = delivery && momentFromIso8601(delivery.deliveryDate).isSame(momentFromIso8601(delivery.plannedDeliveryDate), 'isoWeek');
  let isOverdue = delivery && !delivery.deliveryDate && momentFromIso8601(delivery.plannedDeliveryDate).isBefore(moment(), 'isoWeek');

  return <div className="m-2 p-2 border-1 border-green-100">
    <div className="text-l font-medium">Week: {asDayFullDdMmYyyy(props.deliveryWeek)}</div>
    {delivery && <div className="p-2">
      <div className="font-medium text-2xl mb-3 color-orange uppercase">Pakketgegevens</div>
      {delivery &&
        <ul className="list-none p-0 m-3">
          <DescriptionItem label="Status">
            {!delivery.cancelled && <div className="flex justify-content-between w-full">
              <Message
                severity={isOverdue
                  ? "error"
                  : isDelivered
                    ? isEmpty(delivery.undeliverableProductIds)
                      ? "success"
                      : "warn"
                    : !delivery.deliveryDate ? "info" : undefined}
                text={
                  isDelivered
                    ? 'Afgeleverd' : !delivery.deliveryDate ? 'Te leveren' : 'Afgeleverd, andere datum'
                }
              />
              <CancelDeliveryButton
                delivery={delivery}
                productOrder={props.productOrder}
              />
            </div>}
            {delivery.cancelled &&
              <div>
                <Message severity={"warn"} text="Geannuleerd"/>
                <CancelDeliveryButton delivery={delivery} productOrder={props.productOrder}/>
              </div>
            }
          </DescriptionItem>
          <EditableDescriptionItem<ActiveSubscriptions_clientOrders_productOrders_deliveries>
            fields={[
              {
                value: delivery.plannedDeliveryDate,
                name: "Geplande datum",
                field: "plannedDeliveryDate",
                type: "date",
                placeholder: "",
              },
            ]}
            onChange={(updates: { [Property in keyof ActiveSubscriptions_clientOrders_productOrders_deliveries]?: string | null }) => {
              if (delivery?.id) {
                let variables: DeliveryUpdateVariables = {
                  data: {
                    ...updates as ProductOrderDeliveryUpdateInput,
                  },
                  id: delivery?.id,
                };

                if (delivery.basketComposition) {
                  variables = {
                    ...variables,
                    data: {
                      ...variables.data,
                      basketComposition: {
                        disconnect: true
                      }
                    }
                  }
                }

                updateProductOrderDelivery({
                  variables,
                  refetchQueries: "active",
                })
              }
            }}
            label={'Geplande datum'}>
            {iso8601_DayFullDdMmm(delivery?.plannedDeliveryDate)}
          </EditableDescriptionItem>

          <DescriptionItem
            label={'Locatie'}>
            <ChangeDeliveryLocationButton
              value={delivery.deliveryLocation}
              forDelivery={delivery.id}
              clientOrderId={props.clientOrderId}
            />
          </DescriptionItem>

          <BasketCompositionInfoForDelivery delivery={delivery} productOrder={props.productOrder}/>

        </ul>
      }

      {!delivery &&
        <div>Geen pakket</div>
      }
    </div>}
  </div>;
};

export default DeliveryWeekDetail;
