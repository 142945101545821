import { PDFViewer } from "@react-pdf/renderer";
import { Dialog } from "primereact/dialog";
import React from "react";
import ClientOrderInvoicePdf from "../../../../shared/pdf/ClientOrderInvoicePdf";
import {ClientOrderInvoicePdfData} from "../../../../shared/utils/invoice.utils";

interface PdfDialogProps {
  pdfData: ClientOrderInvoicePdfData,
  farm: {code: string, name: string},
  onHide: () => void;
}

const PdfDialog = (props: PdfDialogProps) => {
  return (
    <Dialog
      header={props.pdfData.filename}
      visible={true}
      onHide={() => props.onHide()}
      closeOnEscape
      closable>
      <PDFViewer style={{height: '90vh', width: '50vw'}}>
        <ClientOrderInvoicePdf data={props.pdfData} farm={props.farm}/>
      </PDFViewer>
    </Dialog>
  );
};
export default PdfDialog
