import React from 'react';
import PricelistTable from "./pricelist-table";
import {compact} from 'lodash';
import {PricelistQuery} from "./Pricelist.gql";
import {useQuery} from "@apollo/client";
import {PricelistData} from "../../../__generated__/types";
import SalesPageHeading from "../SalesPageHeading";
import {useUserObject} from "../../../shared/context/UserContext";

const Pricelist = () => {
  const {activeFarm} = useUserObject();
  const {data} = useQuery<PricelistData>(PricelistQuery, {variables: {farmId: activeFarm?.id||''}});

  debugger;
  return <div>
    <SalesPageHeading/>
    <PricelistTable
      clientGroups={compact(data?.clientGroups)}
      value={compact(data?.products)}
      onAddNewDiscount={() => console.warn()}
    />
  </div>;
};

export default () => <Pricelist/>;
