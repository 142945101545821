import React from 'react';
import {
  ClientOrderUpdate,
  ClientOrderUpdateVariables
} from "../../../../../../../../__generated__/types";
import {compact, filter} from "lodash";
import {formatCurrency} from "../../../../../../../../shared/utils/currency.utils";
import {vat} from "../../../../../../../../shared/utils/vat.utils";
import {useMutation} from "@apollo/client";
import {ClientOrderUpdateMutation} from "../../../../../../../../shared/queries/clientOrder.gql";
import {Button} from 'primereact/button';
import {
  SubscriptionClientOrders,
  SubscriptionClientOrders_productOrders
} from "../../../../../SubscriptionTable/SubscriptionTable";

interface CreditProductOrdersViewProps {
  clientOrder: { id: string };
  productOrders: SubscriptionClientOrders_productOrders[];
}

const CreditProductOrdersView = (props: CreditProductOrdersViewProps) => {
  const [updateClientOrder] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation);

  let productOrdersWithCancelledDeliveries = props.productOrders
    .filter(productOrder => compact(productOrder.deliveries).findIndex(del => del.cancelled) > -1)

  return <div className="m-2 border-1 border-100 p-2">
    <div className="text-xl font-medium">Krediteer geannuleerde leveringen</div>
    <div className="text-sm text-color-secondary">Volgende bedragen zullen per product worden gekrediteerd:</div>
    <div className="grid m-2 w-fit">
      {productOrdersWithCancelledDeliveries.map(po => {
        let allDeliveries = compact(po.deliveries);
        let cancelledDeliveries = filter(allDeliveries, ['cancelled', true]);
        return <>
          <div className="col-6 text-right white-space-nowrap">{po.product?.name}</div>
          <div className="col-6 font-bold">
            {formatCurrency(-(cancelledDeliveries.length / allDeliveries.length) * po.priceExcl * (1 + vat(po.product?.vat)))}
          </div>
        </>;
      })}

    </div>

    <Button
      label={"Ga verder"}
      onClick={() => {
        let variables: ClientOrderUpdateVariables = {
          where: {
            id: props.clientOrder.id
          },
          data: {
            productOrders: {
              create: productOrdersWithCancelledDeliveries.map(productOrder => {
                let allDeliveries = compact(productOrder.deliveries);
                let cancelledDeliveries = filter(allDeliveries, ['cancelled', true]);

                return {
                  priceExcl: -(cancelledDeliveries.length / allDeliveries.length) * productOrder.priceExcl,
                  quantity: 1,
                  vat: productOrder.product?.vat,
                  productDescription: `Stopzetting abonnement: ${productOrder.product?.name}`,
                }
              })
            }
          }
        }
        updateClientOrder({variables, refetchQueries: "active"});
      }}
    />
  </div>;
};

export default CreditProductOrdersView;
