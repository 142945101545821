import React, {useState} from 'react';
import {Products_products, UpdateProduct, UpdateProductVariables} from "../../../../../__generated__/types";
import {first} from "lodash";
import {InputNumber} from 'primereact/inputnumber';
import {UpdateProductMutation} from "../../../queries.gql";
import {useMutation} from "@apollo/client";
import {Button} from "primereact/button";

interface UpdateStockActionProps {
  product: Products_products;
}

const UpdateStockAction = (props: UpdateStockActionProps) => {
  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation);
  let latestStock = first(props.product.latestStock);
  const [value, setValue] = useState<number>(0);
  return <div className="p-inputgroup w-6rem">
    <InputNumber
      placeholder={"+/- XX"}
      value={value}
      inputMode={"url"}
      onChange={(e) => {
        setValue(e.value || 0)
      }}
    />

    <Button icon="pi pi-check"
            disabled={value === 0}
            onClick={() => {
              let updatedStock = latestStock?.value || 0;
              if (value && value !== 0) {
                updatedStock = updatedStock + value;

                const variables: UpdateProductVariables = {
                  id: props.product.id,
                  data: {
                    stock: {
                      create: [{
                        value: updatedStock,
                        delta: value
                      }]
                    }
                  }
                };
                // setValue(0);
                updateProduct({variables, refetchQueries: "active"});
              }
            }}
    />
  </div>;
};

export default UpdateStockAction;
