import React, {useRef} from 'react';
import {OverlayPanel} from "primereact/overlaypanel";
import {useUserObject} from "../context/UserContext";
import FarmSelector from "./FarmSelector";
import {Permission} from "../../__generated__/types";
import {compact} from "lodash";

interface FarmSwitchProps {
  createPermission: Permission;
}

const FarmSwitch = (props: FarmSwitchProps) => {
  const {user, activeFarm, setActiveFarm} = useUserObject();
  const overlayPanelRef = useRef<OverlayPanel>(null);

  let hasMultipleFarms = compact(user?.farms).length > 0;

  return <div className="pr-2 mr-2 border-right-1 border-50">
    <div onClick={(e) => {
      if (hasMultipleFarms) {
        overlayPanelRef.current?.toggle(e);
      }
    }}>
      {activeFarm?.logo && <img className="h-3rem" src={activeFarm.logo} alt={activeFarm.name}/>}
      {!activeFarm &&
        <img className="h-3rem" src={'https://www.groentegeweld.be/logo.png'} alt={'Groentegeweld logo'}/>}
    </div>
    {hasMultipleFarms && <OverlayPanel ref={overlayPanelRef}>
      <FarmSelector createPermission={props.createPermission}/>
    </OverlayPanel>}
  </div>;
};

export default FarmSwitch;
