import React from 'react';
import {ClientOrder_clientOrder} from "../../../../__generated__/types";
import CreateInvoiceAction from '../CreateInvoiceAction';
import InvoiceTable from '../InvoiceTable';

interface SubscriptionInvoicingProps {
  clientOrder: ClientOrder_clientOrder;
}

const SubscriptionInvoicing = (props: SubscriptionInvoicingProps) => {
  return <div>
    {!props.clientOrder.invoice &&
      <CreateInvoiceAction buttonClassName={'p-button-rounded p-button-success'} clientOrderId={props.clientOrder.id}/>}
    {props.clientOrder.invoice && <>
      <InvoiceTable clientOrder={props.clientOrder}/>
      <a className={'text-right'} target="_blank"
         href={`https://shop.groentegeweld.be/clientsBilling?client=${props.clientOrder.client.id}`}>Bekijk het
        facturatieoverzicht</a>
    </>}
  </div>;
};

export default SubscriptionInvoicing;
