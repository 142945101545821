import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button} from "primereact/button";
import {compact, isEmpty} from "lodash";
import {
  Clients_clients,
  GetClientOrdersToProcess_clientOrders,
  GetClientOrdersToProcess_clientOrders_client,
  SalesData_clientOrders, Sequences,
  SequencesVariables,
  SequenceType
} from "../../../../../__generated__/types";
import {useSales} from "../../../sales-context";
import {useLazyQuery} from "@apollo/client";
import {SequencesQuery} from "../../../../../shared/queries/sequence.gql";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface ClientHeaderRowProps {
  client: GetClientOrdersToProcess_clientOrders_client;
  allClientOrders: SalesData_clientOrders[];
  selectedClientOrders: GetClientOrdersToProcess_clientOrders[];
  onSelectAllClientOrders: (client: Clients_clients | null) => void;
  onInvoiceCreated: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 68px 1fr max-content;
  grid-gap: 16px;
`;

const ClientHeaderRow = (props: ClientHeaderRowProps) => {
  const {createClientOrderInvoice} = useSales();
  const {activeFarm} = useUserObject();
  // const [selectAll, setSelectAll] = useState<boolean>();
  //
  // useEffect(() => {
  //   setSelectAll(props.selectedClientOrders.length === props.allClientOrders.length);
  // }, [props.selectedClientOrders, props.allClientOrders]);

  const variables: SequencesVariables= {
    farmId: activeFarm?.id ||'',
    where: {},
  };
  let [getSequences, {data}] = useLazyQuery<Sequences>(SequencesQuery, {variables});
  const [creatingInvoice, setCreatingInvoice] = useState<boolean>(false);
  useEffect(() => {
    if (data?.sequences) {
      const sequence = compact(data.sequences)
        .find(sequence =>
          sequence.sequenceType === SequenceType.INVOICE_CLIENT
          && sequence.season === null
        );

      if (!sequence) {
        setCreatingInvoice(false);
        props.onInvoiceCreated();
      } else {
        createClientOrderInvoice(props.client, props.selectedClientOrders, sequence);
        props.onInvoiceCreated();
      }
    }
  }, [data]);

  return <Container>
    <div style={{textAlign: 'right'}}>
      {/*<Checkbox*/}
      {/*  checked={selectAll}*/}
      {/*  onChange={() => {*/}
      {/*    const updatedSelectAll = !selectAll;*/}
      {/*    setSelectAll(updatedSelectAll);*/}
      {/*    props.onSelectAllClientOrders(updatedSelectAll ? props.client : null);*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
    <div>
      {props.client.companyName ? props.client.companyName : `${props.client.name || ''} ${props.client.firstName || ''}`}
    </div>
    <div>
      <Button
        className={'p-button-text'}
        loading={creatingInvoice}
        disabled={creatingInvoice || isEmpty(props.selectedClientOrders)}
        label={'Factureer geselecteerde bestellingen'}
        onClick={() => {
          setCreatingInvoice(true);
          getSequences({fetchPolicy: "no-cache"});
        }}
      />
    </div>
  </Container>;
};

export default ClientHeaderRow;
