import React, { useState } from 'react';
import HarvestList from "./HarvestList/HarvestList";
import ShopOrderList from "./ShopOrderList/ShopOrderList";
import { TabMenu } from "primereact/tabmenu";
import styled from "styled-components";
import ClientListing from "./ClientListing/ClientListing";

interface VeldwinkelProps {

}

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: scroll;
  margin: 0 32px;
  grid-gap: 16px;
`;

const SalesMenu = styled.div`
`;

const SalesPageContainer = styled.div`
  padding: 8px;
`;


const Veldwinkel = (props: VeldwinkelProps) => {
  const [page, setPage] = useState<string>('clients');

  const model = [
    {
      value: "orders",
      icon: 'pi pi-fw pi-book',
      label: 'Te verwerken',
      page: () => <HarvestList/>,
    },
    {
      value: "overview",
      icon: 'pi pi-fw pi-list',
      label: 'Overzicht',
      page: () => <ShopOrderList/>,
    },
    {
      value: "clients",
      icon: 'pi pi-fw pi-users',
      label: 'Klanten',
      page: () => <ClientListing/>,
    },
  ];
  return <Container>
    <h1>Veldwinkel</h1>
    <SalesMenu>
      <TabMenu model={model.map(item => ({...item, command: () => setPage(item.value)}))}/>
    </SalesMenu>
    <SalesPageContainer>
      {model.find(item => item.value === page)?.page()}
    </SalesPageContainer>
  </Container>;
};

export default Veldwinkel;
