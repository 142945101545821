import React, {useState} from 'react';
import {deliveryLocations} from "../../../../../shared/utils/deliveryLocation.utils";
import {Button} from "primereact/button";
import {DeliveryLocation} from "../../../../../__generated__/types";
import {without} from "lodash";
import classNames from "classnames";
import DeliveryLocationCircle from "../DeliveryLocationCircle";

interface DeliveryLocationSelectionProps {
  value: DeliveryLocation[];
  onChange: (deliveryLocation: DeliveryLocation[]) => void;
  hideSelectAll?: boolean;
  expandable: boolean;
  label: string;
}

const DeliveryLocationSelection = (props: DeliveryLocationSelectionProps) => {
  const expandable = props.expandable;
  const [expanded, setExpanded] = useState<boolean>(!props.expandable);

  const {value} = props;

  return <div className="my-1">
    {expandable && !expanded && props.value.length === 0 && <Button
      className="p-button-link p-0 text-color-secondary font-normal underline"
      label="Selecteer per dorp"
      onClick={() => setExpanded(true)}
    />}

    {expanded &&
      <div>
        <div className="flex align-items-center justify-content-between">
          <div>{props.label}</div>
          {props.expandable && <Button
            className="p-button-link p-0 text-color-secondary"
            icon="pi pi-times"
            onClick={() => setExpanded(false)}
          />}
        </div>
        <div className="flex my-1">
          {!props.hideSelectAll && <div
            className={classNames(
              "cursor-pointer w-2rem h-1-5rem mr-1 text-sm font-bold border-round-xl border-1 flex align-items-center justify-content-center border-800",
              {[`surface-800 text-white`]: props.value.length == 0},
              {[`bg-white text-800`]: props.value.length > 0},
            )}
            onClick={() => props.onChange([])}
          >
            Alle
          </div>}

          {deliveryLocations.map(deliveryLocation => {
              let isSelected = value.includes(deliveryLocation.value);
              return <DeliveryLocationCircle
                key={deliveryLocation.value}
                deliveryLocation={deliveryLocation.value}
                isSelected={isSelected}
                onClick={() => {
                  let updatedDeliveryLocations = isSelected
                    ? without(props.value, deliveryLocation.value)
                    : [...props.value, deliveryLocation.value];

                  if (updatedDeliveryLocations.length === deliveryLocations.length) {
                    updatedDeliveryLocations = [];
                  }
                  props.onChange(updatedDeliveryLocations)
                }
                }
              />;
            }
          )
          }
        </div>
      </div>
    }

    {props.value.length > 0 && (!expanded) &&
      <div className="flex align-items-center justify-content-start">
        <div className="pr-2">
          <Button
            className="p-button-link p-0 text-color-secondary w-1-5rem"
            icon="pi pi-pencil"
            onClick={() => setExpanded(true)}
          />
        </div>
        <div className="flex flex-wrap">
          {props.value.map(deliveryLocation => {
            return <DeliveryLocationCircle isSelected deliveryLocation={deliveryLocation}/>;
          })}
        </div>
      </div>
    }
  </div>
    ;
};

export default DeliveryLocationSelection;
