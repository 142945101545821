import styled from "styled-components";

const SFPage = styled.div`
  font-family: Montserrat,sans-serif;
  font-weight: 400;

  @media only screen and (max-width: 1000px) {
    table {
      font-size: 80%;
    }
  }

  .p-inputtext:enabled:focus {
    outline: initial none 0;
    outline-offset: 0;
    box-shadow: none;
  }

  a {
    color: #748F56;

    span {
      font-size: 1em;
      color: #748F56;
    }
  }

  .pi-green {
    color: #748F56;
  }

  h1 {
    font-weight: 800;
    text-transform: uppercase;
    color: #748F56;
    margin-top: 2vh;

    span {
      color: #EE7A31;
      font-size: inherit;
    }
  }

  h2 {
    color: #EE7A31;

  }

  //li, p {
  //  font-size: 1rem;
  //}

  //p {
  //  span {
  //    color: #EE7A31;
  //    font-weight: 500;
  //  }
  //}

  .error {
    color: var(--error);
  }

  .p-radiobutton.error {
    .p-radiobutton-box {
      border-color: var(--error);
      border-width: 2px;
      background-color: var(--error-10);
    }
  }

  .p-selectbutton.error {
    .p-button {
      border: 1px solid var(--error);
      border-collapse: collapse;
      background-color: var(--error-10);
    }
  }

  .p-button.p-button-icon {
    margin: 0;
    padding: 0;
    background: transparent;
    color: var(--surface-500);
    width: 20px;
    height: 20px;
  }
`;

export default SFPage;
