import React from 'react';
import {momentFromDdMmYyyy} from "../../../../shared/utils/date.utils";
import {Calendar} from "primereact/calendar";
import {Moment} from "moment";
import { Button } from 'primereact/button';

interface WeekSelectorProps {
 value: Moment;
 onChange: (value: Moment) => void;
}

const WeekSelector = (props: WeekSelectorProps) => {
  return <div className={'p-inputgroup'}>
    <Button
      className="p-0 p-button-outlined"
      icon="pi pi-arrow-left"
      onClick={() => {
        props.onChange(props.value.clone().startOf('isoWeek').isoWeekday(5).subtract(1, 'week'));
      }}
    />
    <Calendar
      inputClassName="border-orange text-center p-1"
      value={(props.value).toDate()}
      locale={'nl'}
      disabledDays={[0,1,2,3,4,6]}
      dateFormat={"dd-mm-yy"}
      showWeek
      onChange={(e) => {
        props.onChange(momentFromDdMmYyyy(e.target.value));
      }}
    />
    <Button
      className="p-0 p-button-outlined"
      icon="pi pi-arrow-right"
      onClick={() => {
        props.onChange(props.value.clone().startOf('isoWeek').isoWeekday(5).add(1, 'week'));
      }}
    />
  </div>;
};

export default WeekSelector;
