import React, { useEffect, useState } from 'react';
import { AllProducts, AllProducts_products, ShopOrders, ShopOrders_shopOrders, ShopOrders_shopOrders_shopOrderItems } from "../../../__generated__/types";
import { asDayFullDdMmYyyy, momentFromIso8601 } from "../../../shared/utils/date.utils";
import moment, { Moment } from "moment";
import { chain, compact, floor, keyBy, keys, sum } from "lodash";
import HarvestDay from "./HarvestDay";
import { useQuery } from "@apollo/client";
import { AllProductsQuery, ShopOrdersQuery } from "../Veldwinkel.gql";
import styled from "styled-components";
import IndividualShopOrder from "./IndividualShopOrder";

export interface HarvestItem {
  product: AllProducts_products,
  amount: number,
}

export interface HarvestDayData {
  day: Moment;
  harvestItems: {
    [key: string]: HarvestItem
  }
}

interface HarvestDays {
  [dayKey: string]: HarvestDayData;
}

const HarvestDays = styled.div`
  border-right: 1px solid var(--orange);
  padding-right: 16px;
  display: grid;
  grid-gap: 16px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;
`;

const ShopOrdersList = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const HarvestList = () => {
  const {data: shopOrderData} = useQuery<ShopOrders>(ShopOrdersQuery);
  const {data: productsData} = useQuery<AllProducts>(AllProductsQuery);

  const [harvestDays, setHarvestDays] = useState<HarvestDays>({});
  const [activeHarvestDay, setActiveHarvestDay] = useState<string>();
  const allProducts = compact(productsData?.products);

  useEffect(() => {
    if (productsData) {
      const products = keyBy(compact(productsData?.products), 'id');
      const allHarvestDays = chain(shopOrderData?.shopOrders)
        .compact()
        .filter(shopOrder => momentFromIso8601(shopOrder.deliveryDate).isSameOrAfter(moment(), 'day'))
        .groupBy((shopOrder) => momentFromIso8601(shopOrder.deliveryDate).startOf('day').valueOf())
        .mapValues((shopOrders: ShopOrders_shopOrders[], key: string): HarvestDayData => ({
          day: moment(key, 'x'),
          harvestItems:
            chain(shopOrders)
              .map('shopOrderItems')
              .compact()
              .flatten()
              .groupBy('product.id')
              .mapValues((shopOrderItems: ShopOrders_shopOrders_shopOrderItems[], productId: string) => ({
                product: products[productId],
                amount: sum(shopOrderItems.map(shopOrderItem => {
                  const {amount, productPromos} = products[shopOrderItem.product.id];
                  const activePlusOnePromo = compact(productPromos)
                    .find(promo => promo.id === shopOrderItem.currentPromo?.id);

                  let freeAmount = 0;
                  let shopAmount = 0;
                  if (amount) {
                    shopAmount = amount * shopOrderItem.amount;
                    if (activePlusOnePromo && activePlusOnePromo.secondValue) {
                      freeAmount = floor(shopAmount / activePlusOnePromo.value) * activePlusOnePromo.secondValue;
                    }
                  }
                  return shopAmount + freeAmount;
                }))
              }))
              .value()
        })).value();
      setHarvestDays(allHarvestDays);

      setActiveHarvestDay(chain(allHarvestDays)
        .keys()
        .sort((a, b) => a > b ? 1 : -1)
        .first()
        .value());
    }

  }, [shopOrderData, productsData]);

  return <div>
    <h2>Bestellingen</h2>
    <Container>
      <HarvestDays>
        {keys(harvestDays)
          .sort((a, b) => a > b ? 1 : -1)
          .map(harvestDay => <HarvestDay onSelected={() => setActiveHarvestDay(harvestDay)}
                                         active={harvestDay === activeHarvestDay}
                                         harvestDay={harvestDays[harvestDay]}/>)}
      </HarvestDays>
      {activeHarvestDay && <div>
        <h3>Individuele bestellingen voor {asDayFullDdMmYyyy(moment(activeHarvestDay, 'x'))}</h3>
        <ShopOrdersList>
          {compact(shopOrderData?.shopOrders)
            .filter(shopOrder => momentFromIso8601(shopOrder.deliveryDate).isSame(moment(activeHarvestDay, 'x'), 'day'))
            .map(shopOrder => {
              return <IndividualShopOrder products={allProducts} shopOrder={shopOrder}/>;
            })}
        </ShopOrdersList>
      </div>}
    </Container>
  </div>;
};

export default HarvestList;
