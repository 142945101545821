import Logo_groentegeweld from "./logo.png";
import Logo_mosterdzaadje from "./logo-mosterdzaadje.jpeg";
import {Text} from "@react-pdf/renderer";
import React from "react";

const pdfFarmConfig: {
  [key: string]: {
    logo: any,
    logoStyles: { [key: string]: any },
    addressLines: string[],
    contactLines: string[],
    financialLines: string[],
    termsAndConditions: {
      intro: string,
      numberedParagraphs: string[],
      pakketten?: {
        title: string,
        intro?: string,
        numberedParagraphs: string[],
      },
      zelfoogst?: {
        title: string,
        intro?: string,
        numberedParagraphs: string[],
      }

    },
  }
} = {
  groentegeweld: {
    logo: Logo_groentegeweld,
    logoStyles: {width: 200},
    addressLines: [
      "GROENTEGEWELD",
      "WEELDESTRAAT 168",
      "2381 WEELDE",
    ],
    contactLines: [
      "info@groentegeweld.be",
      "www.groentegeweld.be",
      "facebook.be/groentegeweld",
      "+32 494 66 43 46 | +32 489 95 51 13",
    ],
    financialLines: [
      "BTW BE0746.361.154",
      "IBAN BE46 5230 8118 5136",
      "BIC: TRIOBEBB",
    ],
    termsAndConditions: {
      intro: "De basis van Community Supported Agriculture is dat er goede afspraken gemaakt worden. Deze afspraken vormen de basis om de boer, de boerderij en de aandeelhouders respectvol te verbinden.",
      numberedParagraphs: [
        "Je hebt als deelnemer toegang tot CSA Groentegeweld (uitgezonderd op zondagnamiddag). Het oogstaandeel omvat immers ook het ervaren van de omgeving, het beleven van de landbouw en het gebruik van de boerderij als gemeenschappelijke tuin.",
        "Groentegeweld is lid van vzw CSA-netwerk Vlaanderen. Elk natuurlijk persoon of rechtspersoon die een oogstaandeel koopt bij een CSA-bedrijf, wordt deelnemer van een lokale afdeling van de vzw. Het CSA-bedrijf biedt actief mogelijkheden aan zijn oogstaandeelhouders tot de beleving van en de verbinding met de seizoenen, het land en de voedselproductie als instrument voor een hernieuwd bewustzijn van de individuele connectie met de natuurlijke omgeving.",
        "Als lokale afdeling organiseert Groentegeweld collectieve meehelpdagen en andere activiteiten die bijdragen tot de cohesie van de deelnemers en de boer binnen het CSA-bedrijf en de ontwikkeling van vaardigheden en kennis. Het kan ook gebeuren dat de boer een oproep doet om een acute werkpiek op te vangen.   Al deze voornoemde momenten zijn vrijblijvend. De deelnemers kunnen hier desgewenst en vrijwillig aan participeren. "
      ],
      pakketten: {
        title: "Pakketten",
        intro: "",
        numberedParagraphs: [
          "Flexibel pakket: Je bepaalt zelf de samenstelling van je pakket. Dit is op basis van een aantal porties (afhankelijk van je type pakket) die je kan verdelen over je pakketten. Porties die op het einde van je abonnement nog over zijn, vervallen.",
          "Afhankelijk van het jaar en het weer, ziekten en plagen kunnen sommige teelten overvloedige oogst geven of juist volledig mislukken. Zowel het oogstrisico als de overvloed maken deel uit van het oogstaandeelhouderschap en worden gedeeld tussen de boeren en oogstaandeelhouders.",
          "Het afhalen van de pakketten kan op verschillende plaatsen. Je kiest een afhaalpunt voor het hele seizoen. De exacte afhaaluren worden steeds via de nieuwsbrieven meegedeeld. Kom je je pakket in Weelde ophalen met de auto dan vragen we om de auto aan de straat te parkeren, let hierbij op de opritten van de nabijgelegen huizen! Met de fiets mag je tot op het veld rijden.",
          "Als oogstaandeelhouder krijg je één of twee blauwe bakken in bruikleen. Na het laatste pakket van het seizoen verwachten we beide bakken uiterlijk 1 week later op het afhaalpunt van jouw keuze.",
          "Het wijzigen van een oogstaandeel (van groot naar klein of van klein naar groot) kan niet, tenzij na afspraak met Dirk en Willem. Zo garanderen we het beste grondgebruik en het minste voedselverspilling.",
          "Als oogstaandeelhouder kan je tijdens de evaluatievergadering de boekhouding inkijken, inzage krijgen rond de bio controle enz..",
          "Een handje toesteken mag altijd. Er worden ook meewerkdagen georganiseerd, deelname hieraan is welkom.",
          "Het oogstaandeel wordt voor het volledige gezin/huishouden afgesloten voor een volledig pakketseizoen. Een oogstaandeel kan niet worden terugbetaald of vroeger stopgezet.",
          "Een oogstaandeel wordt betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of Willem.",
          "Het oogstaandeel wordt voor een vastgestelde periode afgesloten en betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of Willem.",
          "De prijs wordt jaarlijks geïndexeerd.",
          "Deze prijs kan geëvalueerd worden op de evaluatievergadering met de deelnemers.",
        ],
      },
      zelfoogst: {
        title: "Zelfoogst",
        numberedParagraphs: [
          "Inschrijven kan enkel met het hele gezin, je kan dus niet voor één persoon inschrijven wanneer je met twee of meerdere thuis bent.",
          "Wanneer je komt oogsten bepaal je zelf (van zonsopgang tot zonsondergang 7 dagen op 7). Enkel zondagnamiddag is het veld ‘gesloten’. Zo heeft Willem en zijn gezin wat meer privacy in het weekend.",
          "Met de fiets mag je tot op het veld rijden. Kom je met de auto dan vragen we om de auto aan de straat te parkeren, let hierbij op de opritten van de nabijgelegen huizen!",
          "Wat, hoeveel en wanneer je oogst is niet bij alle gewassen precies bepaald. Het gaat om wat je als gezin/persoon vers gebruikt. Je volgt de oogstrichtlijnen zo goed als mogelijk op. Deze richtlijnen ontvang je via mail, via kleur- en naambordjes op het veld of via de informatie in de schuur.",
          "Voor het oogsten heb je enkel verpakking nodig, een mand of bakjes zijn handig. Mesjes liggen in onze oogstbak. Gelieve ze ook netjes terug te leggen.",
          "Oogstresten mag je ter plaatste (verspreid op het bed als mulchlaag) laten liggen, biologische groenteafval mag op de composthoop.",
          "Tijdens het oogsten loop je enkel op de graspaden of de hakselhoutpaden. Op de teeltbedden lopen, mag niet.",
          "De deelnemers blijven aansprakelijk voor zichzelf en hun kinderen.",
          "Het doorverkopen, doorgeven, of systematisch fout oogsten van producten wordt als een ernstige overtreding gezien.",
          "Hamsteren (invriezen, inmaken enz,) kan enkel bij een groene aanduiding.",
          "Afhankelijk van het jaar en het weer, ziekten en plagen kunnen sommige teelten overvloedige oogst geven of juist volledig mislukken. Zowel het oogstrisico als de overvloed maken deel uit van het oogstaandeelhouderschap en worden gedeeld tussen de boeren en oogstaandeelhouders.",
          "De deelnemers/aandeelhouders in de oogst kunnen tijdens de evaluatievergadering de boekhouding inkijken, inzage krijgen rond de bio controle enz..",
          "Het oogstaandeel wordt voor het volledige gezin/huishouden afgesloten voor een heel jaar. Een oogstaandeel kan niet worden terugbetaald of vroeger stopgezet.",
          "Een oogstaandeel wordt betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of Willem.",
          "De prijs wordt jaarlijks geïndexeerd.",
          "Deze prijs kan geëvalueerd worden op de evaluatievergadering met de deelnemers.",
          "De boeren kunnen bij overtredingen of vermoeden van het schenden van vertrouwen beslissen om het oogstaandeelhouderschap niet te verlengen of zelfs stop te zetten.",
        ],
      },
    },
  },
  mosterdzaadje: {
    logo: Logo_mosterdzaadje,
    logoStyles: {width: 50, marginLeft: 20},
    addressLines: [
      "CSA HET MOSTERDZAADJE",
      "BINNENWOUWERS 1",
      "2490 BALEN",
    ],
    contactLines: [
      "info@mosteedzaadje.be",
      "www.mosterdzaadje.be",
      "facebook.com/hetmosterdzaadje",
      "+32 472 40 89 43",
    ],
    financialLines: [
      "BTW BE0536.864.019",
      "IBAN BE97 7360 3387 0949",
      "BIC: KREDBEBB",
    ],
    termsAndConditions: {
      intro: "De basis van Community Supported Agriculture is dat er goede afspraken gemaakt worden. Deze afspraken vormen de basis om de boer, de boerderij en de aandeelhouders respectvol te verbinden.",
      numberedParagraphs: [
        "Je hebt als deelnemer toegang tot CSA Groentegeweld (uitgezonderd op zondagnamiddag). Het oogstaandeel omvat immers ook het ervaren van de omgeving, het beleven van de landbouw en het gebruik van de boerderij als gemeenschappelijke tuin.",
        "Groentegeweld is lid van vzw CSA-netwerk Vlaanderen. Elk natuurlijk persoon of rechtspersoon die een oogstaandeel koopt bij een CSA-bedrijf, wordt deelnemer van een lokale afdeling van de vzw. Het CSA-bedrijf biedt actief mogelijkheden aan zijn oogstaandeelhouders tot de beleving van en de verbinding met de seizoenen, het land en de voedselproductie als instrument voor een hernieuwd bewustzijn van de individuele connectie met de natuurlijke omgeving.",
        "Als lokale afdeling organiseert Groentegeweld collectieve meehelpdagen en andere activiteiten die bijdragen tot de cohesie van de deelnemers en de boer binnen het CSA-bedrijf en de ontwikkeling van vaardigheden en kennis. Het kan ook gebeuren dat de boer een oproep doet om een acute werkpiek op te vangen.   Al deze voornoemde momenten zijn vrijblijvend. De deelnemers kunnen hier desgewenst en vrijwillig aan participeren. "
      ],
      pakketten: {
        title: "Pakketten",
        intro: "",
        numberedParagraphs: [
          "Flexibel pakket: Je bepaalt zelf de samenstelling van je pakket. Dit is op basis van een aantal porties (afhankelijk van je type pakket) die je kan verdelen over je pakketten. Porties die op het einde van je abonnement nog over zijn, vervallen.",
          "Afhankelijk van het jaar en het weer, ziekten en plagen kunnen sommige teelten overvloedige oogst geven of juist volledig mislukken. Zowel het oogstrisico als de overvloed maken deel uit van het oogstaandeelhouderschap en worden gedeeld tussen de boeren en oogstaandeelhouders.",
          "Het afhalen van de pakketten kan op verschillende plaatsen. Je kiest een afhaalpunt voor het hele seizoen. De exacte afhaaluren worden steeds via de nieuwsbrieven meegedeeld. Kom je je pakket in Weelde ophalen met de auto dan vragen we om de auto aan de straat te parkeren, let hierbij op de opritten van de nabijgelegen huizen! Met de fiets mag je tot op het veld rijden.",
          "Als oogstaandeelhouder krijg je één of twee blauwe bakken in bruikleen. Na het laatste pakket van het seizoen verwachten we beide bakken uiterlijk 1 week later op het afhaalpunt van jouw keuze.",
          "Het wijzigen van een oogstaandeel (van groot naar klein of van klein naar groot) kan niet, tenzij na afspraak met Dirk en Willem. Zo garanderen we het beste grondgebruik en het minste voedselverspilling.",
          "Als oogstaandeelhouder kan je tijdens de evaluatievergadering de boekhouding inkijken, inzage krijgen rond de bio controle enz..",
          "Een handje toesteken mag altijd. Er worden ook meewerkdagen georganiseerd, deelname hieraan is welkom.",
          "Het oogstaandeel wordt voor het volledige gezin/huishouden afgesloten voor een volledig pakketseizoen. Een oogstaandeel kan niet worden terugbetaald of vroeger stopgezet.",
          "Een oogstaandeel wordt betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of Willem.",
          "Het oogstaandeel wordt voor een vastgestelde periode afgesloten en betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of Willem.",
          "De prijs wordt jaarlijks geïndexeerd.",
          "Deze prijs kan geëvalueerd worden op de evaluatievergadering met de deelnemers.",
        ],
      },
      zelfoogst: {
        title: "Zelfoogst",
        numberedParagraphs: [
          "Inschrijven kan enkel met het hele gezin, je kan dus niet voor één persoon inschrijven wanneer je met twee of meerdere thuis bent.",
          "Wanneer je komt oogsten bepaal je zelf (van zonsopgang tot zonsondergang 7 dagen op 7). Enkel zondagnamiddag is het veld ‘gesloten’. Zo heeft Willem en zijn gezin wat meer privacy in het weekend.",
          "Met de fiets mag je tot op het veld rijden. Kom je met de auto dan vragen we om de auto aan de straat te parkeren, let hierbij op de opritten van de nabijgelegen huizen!",
          "Wat, hoeveel en wanneer je oogst is niet bij alle gewassen precies bepaald. Het gaat om wat je als gezin/persoon vers gebruikt. Je volgt de oogstrichtlijnen zo goed als mogelijk op. Deze richtlijnen ontvang je via mail, via kleur- en naambordjes op het veld of via de informatie in de schuur.",
          "Voor het oogsten heb je enkel verpakking nodig, een mand of bakjes zijn handig. Mesjes liggen in onze oogstbak. Gelieve ze ook netjes terug te leggen.",
          "Oogstresten mag je ter plaatste (verspreid op het bed als mulchlaag) laten liggen, biologische groenteafval mag op de composthoop.",
          "Tijdens het oogsten loop je enkel op de graspaden of de hakselhoutpaden. Op de teeltbedden lopen, mag niet.",
          "De deelnemers blijven aansprakelijk voor zichzelf en hun kinderen.",
          "Het doorverkopen, doorgeven, of systematisch fout oogsten van producten wordt als een ernstige overtreding gezien.",
          "Hamsteren (invriezen, inmaken enz,) kan enkel bij een groene aanduiding.",
          "Afhankelijk van het jaar en het weer, ziekten en plagen kunnen sommige teelten overvloedige oogst geven of juist volledig mislukken. Zowel het oogstrisico als de overvloed maken deel uit van het oogstaandeelhouderschap en worden gedeeld tussen de boeren en oogstaandeelhouders.",
          "De deelnemers/aandeelhouders in de oogst kunnen tijdens de evaluatievergadering de boekhouding inkijken, inzage krijgen rond de bio controle enz..",
          "Het oogstaandeel wordt voor het volledige gezin/huishouden afgesloten voor een heel jaar. Een oogstaandeel kan niet worden terugbetaald of vroeger stopgezet.",
          "Een oogstaandeel wordt betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of Willem.",
          "De prijs wordt jaarlijks geïndexeerd.",
          "Deze prijs kan geëvalueerd worden op de evaluatievergadering met de deelnemers.",
          "De boeren kunnen bij overtredingen of vermoeden van het schenden van vertrouwen beslissen om het oogstaandeelhouderschap niet te verlengen of zelfs stop te zetten.",
        ],
      },
    },
  },
}

export const getPdfFarmConfig = (farmCode: string) => pdfFarmConfig[farmCode]
