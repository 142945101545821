import React from 'react';
import {useQuery} from "@apollo/client";
import {ClientQuery} from "../../../../shared/queries/client.gql";
import {Client} from '__generated__/types';
import styled from 'styled-components';
import Persona from './Persona';
import {SubscriptionClientOrders_productOrders} from "../SubscriptionTable/SubscriptionTable";

interface ClientInformationProps {
  clientId: string;
  productOrders: SubscriptionClientOrders_productOrders[];
}

const ClientInformation = (props: ClientInformationProps) => {
  const {data, loading} = useQuery<Client>(ClientQuery, {variables: {id: props.clientId}});
  let client = data?.client;

  if (loading) {
    return <div>loading</div>;
  }

  return <div>
    {client && <Persona client={client} productOrders={props.productOrders}/>}
  </div>;
};

export default ClientInformation;
