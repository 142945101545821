import React from 'react';
import {Button} from "primereact/button";
import {asDdMmm} from "../../../../../../shared/utils/date.utils";
import {Moment} from "moment";

interface SetHeaderProps {
  deliveryWeek: Moment;
  expanded?: boolean;
  onExpand?: () => void;
}

const SetHeader = (props: SetHeaderProps) => {
  const {deliveryWeek, expanded, onExpand} = props;
  return <div className="flex justify-content-between align-items-center">
    <Button
      className={"p-button-icon p-button-text p-0 text-600 text-xl"}
      icon={`pi pi-chevron${props.expanded ? '-down' : '-right'}`}
      onClick={props.onExpand}
    />
    <div className="font-normal text-xl ml-3 flex-grow-1">
      <span>Week {deliveryWeek.format('W')}: {asDdMmm(deliveryWeek.clone())}</span>
    </div>
  </div>;
};

export default SetHeader;
