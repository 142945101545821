import React from 'react';
import {ReactComponent as Vegetables01} from './vegetables/vegetables-01.svg';
import {ReactComponent as Vegetables02} from './vegetables/vegetables-02.svg';
import {ReactComponent as Vegetables03} from './vegetables/vegetables-03.svg';
import {ReactComponent as Vegetables04} from './vegetables/vegetables-04.svg';
import {ReactComponent as Vegetables05} from './vegetables/vegetables-05.svg';
import {ReactComponent as Vegetables06} from './vegetables/vegetables-06.svg';
import {ReactComponent as Vegetables07} from './vegetables/vegetables-07.svg';
import {ReactComponent as Vegetables08} from './vegetables/vegetables-08.svg';
import {ReactComponent as Vegetables9} from './vegetables/vegetables-09.svg';
import {ReactComponent as Vegetables10} from './vegetables/vegetables-10.svg';
import {ReactComponent as Vegetables11} from './vegetables/vegetables-11.svg';
import {ReactComponent as Vegetables12} from './vegetables/vegetables-12.svg';
import {ReactComponent as Vegetables13} from './vegetables/vegetables-13.svg';
import {ReactComponent as Vegetables14} from './vegetables/vegetables-14.svg';
import {ReactComponent as Vegetables15} from './vegetables/vegetables-15.svg';
import {ReactComponent as Vegetables16} from './vegetables/vegetables-16.svg';
import {ReactComponent as Vegetables17} from './vegetables/vegetables-17.svg';
import {ReactComponent as Vegetables18} from './vegetables/vegetables-18.svg';
import {ReactComponent as Vegetables19} from './vegetables/vegetables-19.svg';
import {ReactComponent as Vegetables20} from './vegetables/vegetables-20.svg';
import {ReactComponent as Vegetables21} from './vegetables/vegetables-21.svg';
import {ReactComponent as Vegetables22} from './vegetables/vegetables-22.svg';
import {ReactComponent as Vegetables23} from './vegetables/vegetables-23.svg';
import {ReactComponent as Vegetables24} from './vegetables/vegetables-24.svg';
import {ReactComponent as Vegetables25} from './vegetables/vegetables-25.svg';
import {ReactComponent as Vegetables26} from './vegetables/vegetables-26.svg';
import {ReactComponent as Vegetables27} from './vegetables/vegetables-27.svg';
import {ReactComponent as Vegetables28} from './vegetables/vegetables-28.svg';
import {ReactComponent as Vegetables29} from './vegetables/vegetables-29.svg';
import {ReactComponent as Vegetables30} from './vegetables/vegetables-30.svg';
import {ReactComponent as Vegetables31} from './vegetables/vegetables-31.svg';
import {ReactComponent as Vegetables32} from './vegetables/vegetables-32.svg';
import {ReactComponent as Vegetables33} from './vegetables/vegetables-33.svg';
import {ReactComponent as Vegetables34} from './vegetables/vegetables-34.svg';
import {ReactComponent as Vegetables35} from './vegetables/vegetables-35.svg';
import {ReactComponent as Vegetables36} from './vegetables/vegetables-36.svg';
import {ReactComponent as Vegetables37} from './vegetables/vegetables-37.svg';
import {ReactComponent as Vegetables38} from './vegetables/vegetables-38.svg';
import {ReactComponent as Vegetables39} from './vegetables/vegetables-39.svg';
import {ReactComponent as Vegetables40} from './vegetables/vegetables-40.svg';
import {ReactComponent as Vegetables41} from './vegetables/vegetables-41.svg';
import {ReactComponent as Vegetables42} from './vegetables/vegetables-42.svg';
import {ReactComponent as Vegetables43} from './vegetables/vegetables-43.svg';
import {ReactComponent as Vegetables44} from './vegetables/vegetables-44.svg';
import {ReactComponent as Vegetables45} from './vegetables/vegetables-45.svg';
import {ReactComponent as Vegetables46} from './vegetables/vegetables-46.svg';
import {ReactComponent as Vegetables47} from './vegetables/vegetables-47.svg';
import {ReactComponent as Vegetables48} from './vegetables/vegetables-48.svg';
import {ReactComponent as Vegetables49} from './vegetables/vegetables-49.svg';
import {ReactComponent as Vegetables50} from './vegetables/vegetables-50.svg';
import {ReactComponent as Vegetables51} from './vegetables/vegetables-51.svg';
import {ReactComponent as Vegetables52} from './vegetables/vegetables-52.svg';
import {ReactComponent as Vegetables53} from './vegetables/vegetables-53.svg';
import {ReactComponent as Vegetables54} from './vegetables/vegetables-54.svg';
import {ReactComponent as Vegetables55} from './vegetables/vegetables-55.svg';
import {ReactComponent as Vegetables56} from './vegetables/vegetables-56.svg';
import {ReactComponent as Vegetables57} from './vegetables/vegetables-57.svg';
import {ReactComponent as Vegetables58} from './vegetables/vegetables-58.svg';
import {ReactComponent as Vegetables59} from './vegetables/vegetables-59.svg';
import {ReactComponent as Vegetables60} from './vegetables/vegetables-60.svg';
import {ReactComponent as Vegetables61} from './vegetables/vegetables-61.svg';
import {ReactComponent as Vegetables62} from './vegetables/vegetables-62.svg';
import {ReactComponent as Vegetables63} from './vegetables/vegetables-63.svg';
import {ReactComponent as Vegetables64} from './vegetables/vegetables-64.svg';
import {ReactComponent as Vegetables65} from './vegetables/vegetables-65.svg';
import {Props} from "../types/types";


interface CategoryIconProps {
  icon: string;
}

const CategoryIcon = ({icon, ...rest}: Props<CategoryIconProps>) => {
  if (icon === 'vegetables-01.svg') {
    return <Vegetables01 {...rest}></Vegetables01>;
  } else if (icon === 'vegetables-02.svg') {
    return <Vegetables02 {...rest}></Vegetables02>;
  } else if (icon === 'vegetables-03.svg') {
    return <Vegetables03 {...rest}></Vegetables03>;
  } else if (icon === 'vegetables-04.svg') {
    return <Vegetables04 {...rest}></Vegetables04>;
  } else if (icon === 'vegetables-05.svg') {
    return <Vegetables05 {...rest}></Vegetables05>;
  } else if (icon === 'vegetables-06.svg') {
    return <Vegetables06 {...rest}></Vegetables06>;
  } else if (icon === 'vegetables-07.svg') {
    return <Vegetables07 {...rest}></Vegetables07>;
  } else if (icon === 'vegetables-08.svg') {
    return <Vegetables08 {...rest}></Vegetables08>;
  } else if (icon === 'vegetables-09.svg') {
    return <Vegetables9 {...rest}></Vegetables9>;
  } else if (icon === 'vegetables-10.svg') {
    return <Vegetables10 {...rest}></Vegetables10>;
  } else if (icon === 'vegetables-11.svg') {
    return <Vegetables11 {...rest}></Vegetables11>;
  } else if (icon === 'vegetables-12.svg') {
    return <Vegetables12 {...rest}></Vegetables12>;
  } else if (icon === 'vegetables-13.svg') {
    return <Vegetables13 {...rest}></Vegetables13>;
  } else if (icon === 'vegetables-14.svg') {
    return <Vegetables14 {...rest}></Vegetables14>;
  } else if (icon === 'vegetables-15.svg') {
    return <Vegetables15 {...rest}></Vegetables15>;
  } else if (icon === 'vegetables-16.svg') {
    return <Vegetables16 {...rest}></Vegetables16>;
  } else if (icon === 'vegetables-17.svg') {
    return <Vegetables17 {...rest}></Vegetables17>;
  } else if (icon === 'vegetables-18.svg') {
    return <Vegetables18 {...rest}></Vegetables18>;
  } else if (icon === 'vegetables-19.svg') {
    return <Vegetables19 {...rest}></Vegetables19>;
  } else if (icon === 'vegetables-20.svg') {
    return <Vegetables20 {...rest}></Vegetables20>;
  } else if (icon === 'vegetables-21.svg') {
    return <Vegetables21 {...rest}></Vegetables21>;
  } else if (icon === 'vegetables-22.svg') {
    return <Vegetables22 {...rest}></Vegetables22>;
  } else if (icon === 'vegetables-23.svg') {
    return <Vegetables23 {...rest}></Vegetables23>;
  } else if (icon === 'vegetables-24.svg') {
    return <Vegetables24 {...rest}></Vegetables24>;
  } else if (icon === 'vegetables-25.svg') {
    return <Vegetables25 {...rest}></Vegetables25>;
  } else if (icon === 'vegetables-26.svg') {
    return <Vegetables26 {...rest}></Vegetables26>;
  } else if (icon === 'vegetables-27.svg') {
    return <Vegetables27 {...rest}></Vegetables27>;
  } else if (icon === 'vegetables-28.svg') {
    return <Vegetables28 {...rest}></Vegetables28>;
  } else if (icon === 'vegetables-29.svg') {
    return <Vegetables29 {...rest}></Vegetables29>;
  } else if (icon === 'vegetables-30.svg') {
    return <Vegetables30 {...rest}></Vegetables30>;
  } else if (icon === 'vegetables-31.svg') {
    return <Vegetables31 {...rest}></Vegetables31>;
  } else if (icon === 'vegetables-32.svg') {
    return <Vegetables32 {...rest}></Vegetables32>;
  } else if (icon === 'vegetables-33.svg') {
    return <Vegetables33 {...rest}></Vegetables33>;
  } else if (icon === 'vegetables-34.svg') {
    return <Vegetables34 {...rest}></Vegetables34>;
  } else if (icon === 'vegetables-35.svg') {
    return <Vegetables35 {...rest}></Vegetables35>;
  } else if (icon === 'vegetables-36.svg') {
    return <Vegetables36 {...rest}></Vegetables36>;
  } else if (icon === 'vegetables-37.svg') {
    return <Vegetables37 {...rest}></Vegetables37>;
  } else if (icon === 'vegetables-38.svg') {
    return <Vegetables38 {...rest}></Vegetables38>;
  } else if (icon === 'vegetables-39.svg') {
    return <Vegetables39 {...rest}></Vegetables39>;
  } else if (icon === 'vegetables-40.svg') {
    return <Vegetables40 {...rest}></Vegetables40>;
  } else if (icon === 'vegetables-41.svg') {
    return <Vegetables41 {...rest}></Vegetables41>;
  } else if (icon === 'vegetables-42.svg') {
    return <Vegetables42 {...rest}></Vegetables42>;
  } else if (icon === 'vegetables-43.svg') {
    return <Vegetables43 {...rest}></Vegetables43>;
  } else if (icon === 'vegetables-44.svg') {
    return <Vegetables44 {...rest}></Vegetables44>;
  } else if (icon === 'vegetables-45.svg') {
    return <Vegetables45 {...rest}></Vegetables45>;
  } else if (icon === 'vegetables-46.svg') {
    return <Vegetables46 {...rest}></Vegetables46>;
  } else if (icon === 'vegetables-47.svg') {
    return <Vegetables47 {...rest}></Vegetables47>;
  } else if (icon === 'vegetables-48.svg') {
    return <Vegetables48 {...rest}></Vegetables48>;
  } else if (icon === 'vegetables-49.svg') {
    return <Vegetables49 {...rest}></Vegetables49>;
  } else if (icon === 'vegetables-50.svg') {
    return <Vegetables50 {...rest}></Vegetables50>;
  } else if (icon === 'vegetables-51.svg') {
    return <Vegetables51 {...rest}></Vegetables51>;
  } else if (icon === 'vegetables-52.svg') {
    return <Vegetables52 {...rest}></Vegetables52>;
  } else if (icon === 'vegetables-53.svg') {
    return <Vegetables53 {...rest}></Vegetables53>;
  } else if (icon === 'vegetables-54.svg') {
    return <Vegetables54 {...rest}></Vegetables54>;
  } else if (icon === 'vegetables-55.svg') {
    return <Vegetables55 {...rest}></Vegetables55>;
  } else if (icon === 'vegetables-56.svg') {
    return <Vegetables56 {...rest}></Vegetables56>;
  } else if (icon === 'vegetables-57.svg') {
    return <Vegetables57 {...rest}></Vegetables57>;
  } else if (icon === 'vegetables-58.svg') {
    return <Vegetables58 {...rest}></Vegetables58>;
  } else if (icon === 'vegetables-59.svg') {
    return <Vegetables59 {...rest}></Vegetables59>;
  } else if (icon === 'vegetables-60.svg') {
    return <Vegetables60 {...rest}></Vegetables60>;
  } else if (icon === 'vegetables-61.svg') {
    return <Vegetables61 {...rest}></Vegetables61>;
  } else if (icon === 'vegetables-62.svg') {
    return <Vegetables62 {...rest}></Vegetables62>;
  } else if (icon === 'vegetables-63.svg') {
    return <Vegetables63 {...rest}></Vegetables63>;
  } else if (icon === 'vegetables-64.svg') {
    return <Vegetables64 {...rest}></Vegetables64>;
  } else if (icon === 'vegetables-65.svg') {
    return <Vegetables65 {...rest}></Vegetables65>;
  }
  return null;
};

export default CategoryIcon;
