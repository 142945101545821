import { PDFViewer } from "@react-pdf/renderer";
import { Dialog } from "primereact/dialog";
import React, { FunctionComponent } from "react";
import MembershipInvoicePdf, { PdfData } from "./membershipInvoicePdf";

interface PdfDialogProps {
  pdfData?: PdfData,
  onHide: () => void;
  children?: JSX.Element,
}

const PdfDialog: FunctionComponent<PdfDialogProps> = (props: PdfDialogProps) => {
  return (
    <Dialog
      header={props.pdfData && props.pdfData.filename}
      visible={true}
      onHide={() => props.onHide()}
      closeOnEscape
      closable>
      <PDFViewer style={{height: '90vh', width: '70vw'}}>
        {props.children
          ? props.children
          : props.pdfData && <MembershipInvoicePdf data={props.pdfData}/>}
      </PDFViewer>
    </Dialog>
  );
};
export default PdfDialog
