import React, {useEffect, useState} from 'react';
import {ClientOrderQuery, ClientOrderUpdateMutation} from "../../../shared/queries/clientOrder.gql";
import {iso8601_DayFullDdMmm, momentFromIso8601} from "../../../shared/utils/date.utils";
import {useMutation, useQuery} from "@apollo/client";
import {
  BasketCompositionType, BasketCompositionUpdateOneWithoutDeliveriesInput,
  ClientOrder,
  ClientOrder_clientOrder_productOrders_deliveries,
  ClientOrderUpdate,
  ClientOrderUpdateVariables
} from '__generated__/types';
import {chain, compact, orderBy} from "lodash";
import {isBasketProductOrder} from "../../../utils/productOrder.utils";
import {Button} from 'primereact/button';
import DeliveriesOverview from "./DeliveriesOverview";
import {productCategoryIcon} from 'shared/utils/productCategory.utils';
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {findFlexBasketProductOrder} from "../../../shared/utils/productOrder.utils";

interface MoveDeliveryViewProps {
  deliveryId?: string;
  clientOrderId: string;
}

const MoveDeliveryView = (props: MoveDeliveryViewProps) => {
  const navigate = useNavigate();

  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});
  const [updateClientOrder] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation, {refetchQueries: "active"});

  const [newDeliveryDate, setNewDeliveryDate] = useState<string | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const [sourceDeliveryId, setSourceDeliveryId] = useState<string>();

  useEffect(() => {
    if (props.deliveryId) {
      setSourceDeliveryId(props.deliveryId);
    }
  }, [props.deliveryId]);

  const deliveryToMove = chain(data?.clientOrder?.productOrders)
    .map('deliveries')
    .compact()
    .flatten()
    .find((d: ClientOrder_clientOrder_productOrders_deliveries) => d.id === sourceDeliveryId)
    .value()

  let flexBasketProductOrder = findFlexBasketProductOrder(data?.clientOrder?.productOrders);

  if (flexBasketProductOrder) {
    let productOrders = data?.clientOrder?.productOrders;
    let deliveryUpdates = deliveryToMove
      ? chain(productOrders)
        .map(productOrder => compact(productOrder.deliveries).map(delivery => ({...delivery, productOrder})))
        .compact()
        .flatten()
        .filter(d => momentFromIso8601(d.plannedDeliveryDate).isSame(momentFromIso8601(deliveryToMove.plannedDeliveryDate), 'isoWeek'))
        .map(extraDelivery => {
          let nextUpcomingDelivery = flexBasketProductOrder && chain(flexBasketProductOrder.deliveries)
            .compact()
            .orderBy('plannedDeliveryDate', 'asc')
            .find(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isAfter(extraDelivery.plannedDeliveryDate))
            .value();

          const updatedPlannedDeliveryDate = ['eieren', 'pakketten'].includes(extraDelivery.productOrder.product?.category?.code || '')
            ? newDeliveryDate
            : (nextUpcomingDelivery?.plannedDeliveryDate || extraDelivery.plannedDeliveryDate);

          return {
            ...extraDelivery,
            updatedPlannedDeliveryDate,
          }
        })
        .value()
      : [];


    let moveableExtras = orderBy(deliveryUpdates, 'updatedPlannedDeliveryDate', 'asc')
      .filter(deliveryUpdate => deliveryUpdate.productOrder.product?.category?.code !== 'pakketten');

    let onSave = () => {
      setUpdating(true);
      let basketCompositionUpdate: BasketCompositionUpdateOneWithoutDeliveriesInput | null = null;
      if(deliveryToMove.basketComposition?.type === BasketCompositionType.CUSTOMER_BASED) {
        basketCompositionUpdate = {
          update: {
            items: {
              deleteMany: [{id_not: null}]
            }
          },
          delete: true,
        };
      } else if(deliveryToMove.basketComposition?.type === BasketCompositionType.FIXED) {
        basketCompositionUpdate = {
          disconnect: true,
        };
      }

      const variables: ClientOrderUpdateVariables = {
        where: {id: props.clientOrderId},
        data: {
          productOrders: {
            update: deliveryUpdates.map(deliveryUpdate => {
              let basketProductOrder = isBasketProductOrder(deliveryUpdate.productOrder);
              return ({
                where: {id: deliveryUpdate.productOrder.id},
                data: {
                  deliveries: {
                    update: [{
                      where: {id: deliveryUpdate.id},
                      data: {
                        plannedDeliveryDate: deliveryUpdate.updatedPlannedDeliveryDate,
                        basketComposition: basketProductOrder ? basketCompositionUpdate : null,
                      }
                    }]
                  }
                }
              });
            })
          }
        }
      };

      updateClientOrder({variables}).then(() => {
          setUpdating(false);
          navigate('/mijnpakket');
        }
      );
    };
    return <div className='m-2 surface-0'>
      <div>
        <div className="text-xl md:text-2xl font-medium border-bottom-1 border-300 pb-1 mb-1">Verplaats een pakket</div>
        <div className="p-3 text-700 font-italic text-sm">
          Een weekje op vakantie of geen tijd om te koken? Verplaats hier een pakket naar een andere beschikbare week.
        </div>

        <div className={classNames(
          "mx-2 my-1 text-md font-medium flex flex-row align-items-start p-2 border-round-xl",
          {"border-orange-500 border-2 bg-orange-100": sourceDeliveryId === undefined},
          {"border-green-500 border-1 bg-green-100": sourceDeliveryId !== undefined}
        )}>
          <div
            className={classNames(
              "text-xl font-bold overflow-hidden mr-1 border-circle border-2 border-800 text-800 w-2rem h-2rem min-w-2rem min-h-2rem flex align-items-center justify-content-center",
              {"color-orange border-orange font-bold": sourceDeliveryId === undefined}
            )}>
            1
          </div>
          <div>
            <div className={classNames({"color-orange font-bold": sourceDeliveryId === undefined})}>Selecteer het pakket
              dat je wil verplaatsen
            </div>
            <div className="my-1 text-base md:text-lg lg:text-xl flex align-items-center">
              <div className="w-3rem p-0 ml-3 font-medium">Van:</div>
              {!sourceDeliveryId &&
                <div className="col p-0 text-sm text-700 font-italic">(Maak je keuze uit de <span className="text-green-500">groene</span> vakjes.)</div>}
              {sourceDeliveryId &&
                <div className="p-0 font-bold text-lg md:text-xl lg:text-2xl mr-3">
                  {deliveryToMove && iso8601_DayFullDdMmm(deliveryToMove.plannedDeliveryDate)}
                </div>
              }
              {sourceDeliveryId && <Button
                size="small"
                icon="pi pi-pencil"
                label="Wijzigen"
                className="p-button-link font-light p-0 text-900"
                onClick={() => {
                  setSourceDeliveryId(undefined);
                  setNewDeliveryDate(undefined);
                }}
              />
              }
            </div>
          </div>
        </div>

        <div className={classNames(
          "mx-2 my-1 text-md font-medium flex flex-row align-items-start p-2 border-round-xl",
          {"border-orange-500 border-1 bg-orange-100": sourceDeliveryId !== undefined && newDeliveryDate === undefined},
          {"border-green-500 border-1 bg-green-100": newDeliveryDate !== undefined}
        )}>
          <div
            className={classNames(
              "text-xl font-bold overflow-hidden mr-1 border-circle border-2 border-800 text-800 w-2rem h-2rem min-w-2rem min-h-2rem flex align-items-center justify-content-center",
              {"color-orange border-orange font-bold": sourceDeliveryId !== undefined && newDeliveryDate === undefined}
            )}>
            2
          </div>
          <div>
            <div
              className={classNames({"color-orange font-bold": sourceDeliveryId !== undefined && newDeliveryDate === undefined})}>
              Selecteer een nieuwe leverweek voor je gekozen pakket.
            </div>
            <div className="my-1 text-base md:text-lg lg:text-xl flex align-items-center">
              <div className="w-3rem p-0 ml-3 font-medium">Naar:</div>
              {!newDeliveryDate &&
                <div className="col p-0 text-sm text-700 font-italic">(Maak je keuze uit de <span className="text-yellow-500">gele</span> vakjes.)</div>}
              {newDeliveryDate &&
                <div className="p-0 font-bold text-lg md:text-xl lg:text-2xl mr-3">
                  {deliveryToMove && iso8601_DayFullDdMmm(newDeliveryDate)}
                </div>
              }
              {newDeliveryDate && <Button
                size="small"
                icon="pi pi-pencil"
                label="Wijzigen"
                className="p-button-link font-light p-0 text-900"
                onClick={() => {
                  setNewDeliveryDate(undefined);
                }}
              />
              }
            </div>
          </div>
        </div>
      </div>

      {
        !newDeliveryDate && <DeliveriesOverview
          type={sourceDeliveryId === undefined ? 'source' : 'destination'}
          clientOrderId={props.clientOrderId}
          toDeliveryDate={newDeliveryDate}
          onDeliverySelected={setSourceDeliveryId}
          onChangeToDeliveryDate={(toDeliveryDate) => {
            setNewDeliveryDate(toDeliveryDate);
          }}
        />
      }


      {moveableExtras.length > 0 && <div className="mt-4 py-3 border-top-1 border-300">
        <div className="text-xl font-medium">Extra's</div>
        <div className="mb-2 pl-0 p-2 md:p-3">Deze extra's worden automatisch mee verplaatst:</div>
        {moveableExtras
          .map(extraDelivery => {
            return <div className="mt-1 flex justify-content-between align-items-center">
              <div className="flex justify-content-start align-items-center">
                <img
                  className="w-2rem h-2rem"
                  alt={extraDelivery.productOrder.product?.name}
                  src={`../extras/${productCategoryIcon(extraDelivery.productOrder.product?.category)}`}
                />
                <div className="white-space-nowrap">{extraDelivery.productOrder.product?.name}</div>
              </div>
              <div>
                {extraDelivery.plannedDeliveryDate &&
                  <div
                    className="white-space-nowrap font-bold">Van {iso8601_DayFullDdMmm(extraDelivery.plannedDeliveryDate)}</div>
                }
                {extraDelivery.updatedPlannedDeliveryDate &&
                  <div
                    className="white-space-nowrap font-bold">naar {iso8601_DayFullDdMmm(extraDelivery.updatedPlannedDeliveryDate)}</div>
                }

              </div>
            </div>;
          })
        }
      </div>
      }


      <div className="border-top-1 border-300 py-2">
        {newDeliveryDate &&
          <div>Wil je de verplaatsing van dit pakket doorvoeren? Klik dan op <strong>'Bevestigen'</strong>. <br/>Of ga
            terug door op 'Annuleren' te klikken.</div>}
        <div className="my-3 flex justify-content-between py-2">
          <Button
            label={'Annuleren'}
            className={'p-button-link p-0'}
            size={"small"}
            onClick={() => {
              navigate('/');
            }}
          />
          <Button
            label="Bevestigen"
            className="p-button-outlined"
            loading={updating}
            disabled={!newDeliveryDate || updating}
            onClick={onSave}
          />
        </div>
      </div>
    </div>;
  }
  return <div>
    <div className="text-lg">Geen flex pakket gevonden</div>
  </div>;
};

export default MoveDeliveryView;
