import React, {useEffect, useState} from 'react';
import {
  ClientGroups, FarmConfigurationsByCode,
  Product_product,
  ProductPrices,
  ProductPrices_productPrices,
  UpdateProduct,
  UpdateProductVariables
} from "../../../../../../__generated__/types";
import {useMutation, useQuery} from "@apollo/client";
import {ProductPricesQuery} from "../../../../../../shared/queries/product.gql";
import {asIso8601} from "../../../../../../shared/utils/date.utils";
import moment from "moment";
import {formatCurrency} from "../../../../../../shared/utils/currency.utils";
import {InputNumber} from "primereact/inputnumber";
import {UpdateProductMutation} from "../../../../queries.gql";
import {Button} from "primereact/button";
import {compact, first} from "lodash";
import {ClientGroupsQuery} from "../../../../../../shared/queries/clientGroup.gql";
import {vat} from "../../../../../../shared/utils/vat.utils";
import {useUserObject} from "../../../../../../shared/context/UserContext";
import {FarmConfigurationsByCodeQuery} from "../../../../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../../../../shared/utils/farmConfig.utils";

interface BasePriceProps {
  productPrice: ProductPrices_productPrices | undefined;
  product: Product_product;
  legacyPrice?: number | null;
  onChange: (productPriceId: string, value: number) => void;
  ignoreWithBasePriceUpdate: string[];
  basePriceDidUpdate: () => void;
}

const BasePrice = (props: BasePriceProps) => {
  const  {activeFarm} = useUserObject();
  const baseProductPrice = props.productPrice;

  const {
    data: productPricesData,
  } = useQuery<ProductPrices>(ProductPricesQuery, {variables: {productId: props.product.id}});
  const {data: clientGroupsData,} = useQuery<ClientGroups>(ClientGroupsQuery, {variables: {farmId: activeFarm?.id}});

  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  const [updatedValue, setUpdatedValue] = useState<number>(0);

  useEffect(() => {
    setUpdatedValue(baseProductPrice?.value || 0);
  }, [baseProductPrice]);

  // const [createProductPrice] = useMutation<CreateProductPrice>(CreateProductPriceMutation, {
  //   update(cache, {data}) {
  //     cache.modify({
  //       fields: {
  //         productPrices: (previous) => {
  //           return data ? [...previous, data.createProductPrice] : previous;
  //         }
  //       }
  //     })
  //   }
  // });

  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          products: (previous) => {
            return data ? [...previous, data.updateProduct] : previous;
          }
        }
      })
    }
  });

  return <>
    <div><strong>Basisprijs</strong></div>
    <div><strong>{formatCurrency(baseProductPrice?.value)}</strong></div>
    <div>
      <InputNumber
        value={baseProductPrice?.value}
        mode="currency"
        currency="EUR"
        locale={'nl-NL'}
        maxFractionDigits={2}
        minFractionDigits={2}
        onChange={(e: any) => {
          setUpdatedValue(e.value);
          props.productPrice?.id && props.onChange(props.productPrice.id, e.value);
        }}/>
    </div>
    <div>
      <InputNumber
        value={baseProductPrice?.value ? baseProductPrice.value * (1 + vat(props.product.vat)) : undefined}
        mode="currency"
        currency="EUR"
        locale={'nl-NL'}
        maxFractionDigits={2}
        minFractionDigits={2}
        onChange={(e: any) => {
          setUpdatedValue(e.value / (1 + vat(props.product.vat)));
          props.productPrice?.id && props.onChange(props.productPrice.id, e.value);
        }}/>
    </div>
    <div>
      {updatedValue && <Button
        className={'p-button-text'}
        label={'Wijzig basisprijs (+ klantgroepprijzen)'}
        onClick={() => {
          const allProductPricesToUpdate = compact(productPricesData?.productPrices)
            .filter(pp => pp.clientGroup?.code !== flexClientGroupCode)
            .filter(productPrice =>
              productPrice.endDate === null
              && (productPrice.base || (productPrice.clientGroup?.id && props.ignoreWithBasePriceUpdate.indexOf(productPrice.clientGroup.id) < 0))
            );

          let variables: UpdateProductVariables = {
            id: props.product.id,
            data: {
              productPrices: {
                update: [
                  //update the current productPrices to expire, inclusive the base productPrice
                  ...allProductPricesToUpdate.map(productPrice => (
                    {
                      where: {id: productPrice.id},
                      data: {
                        endDate: asIso8601(moment()),
                      }
                    }
                  )),
                ],
                create: [
                  // create new base price
                  {
                    value: updatedValue,
                    base: true,
                    startDate: asIso8601(moment()),
                  },
                  // create all new productPrices for clientGroups
                  ...compact(clientGroupsData?.clientGroups)
                    .filter(clientGroup => clientGroup?.code && ["standaard", "restaurants", "detailhandel", "kinderopvang", "boeren"].includes(clientGroup.code))
                    .filter(clientGroup =>
                      props.ignoreWithBasePriceUpdate.indexOf(clientGroup.id) < 0
                    ).map(clientGroup => {
                      return (
                        {
                          value: updatedValue * (clientGroup?.profitMargin || 0),
                          startDate: asIso8601(moment()),
                          clientGroup: {connect: {id: clientGroup.id}}
                        });
                    })
                ]
              },
            }
          };

          updateProduct({variables}).then(() => props.basePriceDidUpdate());
        }}
      />}

      {/*{baseProductPrice === undefined &&*/}
      {/*  <Button*/}
      {/*    label={'Stel basisprijs in'}*/}
      {/*    className={'p-button p-button-text'}*/}
      {/*    onClick={(e) => {*/}
      {/*      e.preventDefault();*/}
      {/*      if (props.legacyPrice) {*/}
      {/*        const variables: CreateProductPriceVariables = {*/}
      {/*          data: {*/}
      {/*            base: true,*/}
      {/*            product: {connect: {id: props.product.id}},*/}
      {/*            value: props.legacyPrice,*/}
      {/*            startDate: asIso8601(moment()),*/}
      {/*          }*/}
      {/*        };*/}
      {/*        createProductPrice({variables});*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*}*/}
    </div>
  </>;
};

export default BasePrice;
