import {compact} from "lodash";

export const clientName = (client: {
  companyName?: string | null,
  name?: string | null,
  firstName?: string | null,

} | null | undefined) => {
  if (!client) {
    return '';
  }

  if (client.companyName) {
    return client.companyName
  }
  return compact([client.firstName, client.name]).join(' ');
};
