import React from 'react';
import DashboardItemBasketsSummary from '../Dashboard/DashboardItemBasketsSummary';
import DashboardItemSelfHarvestSummary from "../Dashboard/DashboardItemSelfHarvestSummary";
import DashboardItemRevenue from "../Dashboard/DashboardItemRevenue";
import DashboardItemExpiringSubscriptions from '../Dashboard/DashboardItemExpiringSubscriptions';
import DashboardItemAmbassador from "../Dashboard/DashboardItemAmbassador";

interface SubscriptionDashboardProps {
}

const SubscriptionDashboard = (props: SubscriptionDashboardProps) => {
  return <div className="grid m-2">
    {/*<div className="col-2"><DashboardItemExpiringSubscriptions/></div>*/}
    <div className="col-2"><DashboardItemSelfHarvestSummary/></div>
    <div className="col-2"><DashboardItemBasketsSummary/></div>
    <div className="col-4"><DashboardItemRevenue/></div>
    <div className="col-4"><DashboardItemAmbassador/></div>
  </div>;
};

export default SubscriptionDashboard;
