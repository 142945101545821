import React, {useState} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {useClientUpdateMutation} from "../../../mutations/client.mutations";
import ClientsDropdown from "./ClientsDropdown";
import {BankTransactions_bankTransactions, UpdateClientVariables} from "../../../__generated__/types";

interface AddIbanNumberToClientButtonProps {
  ibanNumber?: string | null | undefined;
  bankTransactions: BankTransactions_bankTransactions[];
}

const AddIbanNumberToClientButton = (props: AddIbanNumberToClientButtonProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  let [updateClient] = useClientUpdateMutation();

  return <div>
    <Dialog visible={showDialog} onHide={() => {
      setShowDialog(false)
    }}>
      <ClientsDropdown onChange={(client) => {
        if (client && props.ibanNumber) {
          const variables: UpdateClientVariables = {
            id: client.id,
            data: {
              ibanNumbers: {
                set: [...(client.ibanNumbers || []), props.ibanNumber],
              },
              bankTransactions: {
                connect: props.bankTransactions
                  .filter(b => b.ibanNumber === props.ibanNumber)
                  .map(b => ({id: b.id})),
              }
            }
          }
          updateClient({variables})
            .then(() => setShowDialog(false));
        }

      }}/>
    </Dialog>
    <Button className="p-button-link p-0" label="Klant koppelen" onClick={() => {
      setShowDialog(true);
    }}/>
  </div>;
};

export default AddIbanNumberToClientButton;
