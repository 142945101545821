import React from 'react';
import {ProductUnit} from "../../../../__generated__/types";
import RoundImage from "../../../../shared/components/RoundImage";
import {amountWithUnit} from "../../../../shared/utils/unit.utils";
import classNames from "classnames";
import {productIsAvailableAsAlternativeUnitFor} from "../../../../shared/utils/product.utils";
import {Button} from "primereact/button";
import {responsiveSizeClasses} from "../../../../shared/constants";

interface ProductPackagingViewProps {
  productDescription?: string | null | undefined;
  product?: {
    image?: string | null | undefined;
    name?: string | null | undefined;
    unit: ProductUnit | null;
    amount: number | null;
    availableAsAlternativeUnitFor: string[] | null;
    avgWeight: number | null;
    alternativeUnit: ProductUnit | null;
  } | null,
  quantity: number;
  portions?: number;
  isDelivered: boolean;
  isUndeliverable: boolean;
  onDelivered: (isDelivered: boolean) => void;
  onToggleUndeliverable?: (isUndeliverable: boolean) => void;
  clientGroupId: string;
}

const ProductPackagingView = (props: ProductPackagingViewProps) => {
  const {product} = props;

  let unit = product?.unit;
  let quantity = props.quantity * (props.product?.amount || 1);
  if (product?.avgWeight && productIsAvailableAsAlternativeUnitFor(product, props.clientGroupId)) {
    unit = product.alternativeUnit
    quantity = quantity / product.avgWeight;
  }

  return <div
    onClick={() => {
      props.onDelivered(!props.isDelivered);
    }}
    className={classNames(
      "my-1 cursor-pointer border-1 border-400 p-1 flex justify-content-start align-items-center text-md sm:text-lg md:text-xl",
      {"border-2 border-green-500 bg-green-200": props.isDelivered},
      {"border-2 border-red-500 bg-red-200": props.isUndeliverable},
    )}>
    <div className={responsiveSizeClasses["md"]}>
      <RoundImage size={"md"} src={product?.image}/>
    </div>

    <div className="white-space-nowrap font-bold text-800 min-w-6rem w-6rem text-right pr-1">
      {amountWithUnit(quantity, unit || ProductUnit.PIECE)}
    </div>
    <div className="px-1 font-medium flex-grow-1">{product?.name} {props.productDescription}</div>

    <div className="">
      <Button
        className='text-3xl'
        icon={'pi pi-times-circle'}
        rounded
        text
        severity={"danger"}
        iconPos={'right'}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          return props.onToggleUndeliverable && props.onToggleUndeliverable(!props.isUndeliverable);
        }
        }
      />
    </div>
  </div>;
};

export default ProductPackagingView;
