import React, {useState} from 'react';
import {compact, first, orderBy, without} from "lodash";
import {
    ClientGroups,
    ClientGroups_clientGroups,
    FarmConfigurationsByCode,
    Product,
    ProductPrices
} from "../../../../../../__generated__/types";
import styled from "styled-components";
import {ClientGroupsQuery} from "../../../../../../shared/queries/clientGroup.gql";
import {useQuery} from "@apollo/client";
import {ProductPricesQuery, ProductQuery} from "../../../../../../shared/queries/product.gql";
import ClientGroupPrice from "./ClientGroupPrice";
import BasePrice from "./BasePrice";
import {useUserObject} from "../../../../../../shared/context/UserContext";
import {FarmConfigCodes} from "../../../../../../shared/utils/farmConfig.utils";
import {FarmConfigurationsByCodeQuery} from "../../../../../FarmManagement/farm.gql";
import PointsPrice from "./PointsPrice";

interface ProductPriceOverviewProps {
    productId?: string;
}

const Container = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: max-content max-content 100px 100px max-content;
  grid-gap: 4px 8px;
  align-items: center;
`;

const ProductPriceOverview = (props: ProductPriceOverviewProps) => {
    const {activeFarm} = useUserObject();
    const {data: clientGroupData} = useQuery<ClientGroups>(ClientGroupsQuery, {variables: {farmId: activeFarm?.id}});
    const {data: productData} = useQuery<Product>(ProductQuery, {variables: {id: props.productId || ''}});
    const {
        data: productPricesData,
        refetch
    } = useQuery<ProductPrices>(ProductPricesQuery, {variables: {productId: props.productId || ''}});

    const [updatedBasePrice, setUpdatedBasePrice] = useState<number>();
    const [clientGroupsIgnoreWithBasePriceUpdate, setClientGroupsIgnoreWithBasePriceUpdate] = useState<string[]>([]);

    const productPrices = compact(productPricesData?.productPrices);
    const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {
        variables: {
            farmId: activeFarm?.id || '',
            code: FarmConfigCodes.clientGroups_flexCode
        }
    });

    let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

    let product = productData?.product;
    return <div>
        <h1>Prijzen</h1>
        {productData?.product && flexClientGroupCode && product && <PointsPrice
            productPrices={productPrices}
            product={product}
            flexClientGroupCode={flexClientGroupCode}
            />}

        <Container>
            <div>Klantengroep</div>
            <div></div>
            <div>Excl. btw</div>
            <div>Incl. btw</div>
            <div></div>
            {productData?.product &&
                <BasePrice
                    basePriceDidUpdate={() => refetch()}
                    productPrice={productPrices.find(productPrice => productPrice.base && productPrice.endDate == null)}
                    product={productData.product}
                    ignoreWithBasePriceUpdate={clientGroupsIgnoreWithBasePriceUpdate}
                    onChange={(productPriceId, updatedValue: number) => {
                        setUpdatedBasePrice(updatedValue);
                    }}
                />}

            {orderBy(compact(clientGroupData?.clientGroups), 'order')
                .filter(c => c.code !== flexClientGroupCode)
                .map((clientGroup: ClientGroups_clientGroups) => {
                    if (productData?.product) {
                        return <ClientGroupPrice
                            disabled={!!updatedBasePrice}
                            onBasePriceUpdated={() => refetch()}
                            suggestedUpdate={updatedBasePrice ? updatedBasePrice * clientGroup.profitMargin : undefined}
                            onSelectionChange={(selected) => {
                                if (selected) {
                                    setClientGroupsIgnoreWithBasePriceUpdate(value => without(value, clientGroup.id));
                                } else {
                                    setClientGroupsIgnoreWithBasePriceUpdate(value => ([...value, clientGroup.id]));
                                }
                            }}
                            ignoreWithBasePriceUpdate={clientGroupsIgnoreWithBasePriceUpdate.indexOf(clientGroup.id) > -1}
                            productPrices={productPrices.filter(productPrice => productPrice.endDate === null)}
                            product={productData.product}
                            clientGroup={clientGroup}
                            onChange={() => {
                                refetch();
                            }}
                        />;
                    }
                    return null;
                })}
        </Container>
    </div>;
};

export default ProductPriceOverview;
