import React, {useEffect, useState} from 'react';
import CategoryIcon from "../../icons/CategoryIcon";
import {Tag} from "primereact/tag";
import placeholder from "../../placeholder.svg";
import {amountWithUnit, formatAsPoints} from "../../utils/unit.utils";
import {Button} from "primereact/button";
import {GetProducts_clientGroup, GetProducts_products} from "../../../__generated__/types";
import {
  findProductPriceForClientGroup,
  findProductPriceForClientGroupCode,
  portionsBasedOnProductOrderAmount,
  priceInclBasedOnProductOrderAmount,
  productIsAvailableAsAlternativeUnitFor
} from "../../utils/product.utils";
import ProductQuantityInput from "../ProductQuantityInput";
import {first} from "lodash";
import classNames from "classnames";
import {formatCurrency} from "../../utils/currency.utils";

interface ProductItemTemplateGridProps {
  showIn: "currency" | "points";
  product: GetProducts_products;
  clientGroup: GetProducts_clientGroup;
  //disable?: boolean;
  remainingPortions?: number;
  onAdd: (productId: string, quantity: number, portions: number) => void;
}

const getSeverity = (product: GetProducts_products) => {
  let stock = first(product.latestStock);
  if (stock) {
    if (stock.value < 1) {
      return 'danger';
    } else if (stock.value < 10) {
      return 'warning';
    }
    return 'success';
  }
  return 'warning';
};

const getSeverityValue = (product: GetProducts_products, clientGroup: GetProducts_clientGroup) => {
  let stock = first(product.latestStock);
  if (stock) {
    if (stock.value < 1) {
      return 'Uitverkocht';
    } else if (stock.value < 10) {
      const productPrice = findProductPriceForClientGroupCode(product, clientGroup.code)
      const unit = productIsAvailableAsAlternativeUnitFor(product, productPrice?.clientGroup?.id) ? product.alternativeUnit : product.unit;
      return `${amountWithUnit(stock.value, unit)} beschikbaar`;
    }
    return 'Beschikbaar';
  }
  return 'Voorraad onbekend';
};

let stock = (product: GetProducts_products, clientGroup: GetProducts_clientGroup) =>
  <Tag value={getSeverityValue(product, clientGroup)} severity={getSeverity(product)} className="opacity-70"/>;

let category = (product: { category?: { image: string | null | undefined } | null | undefined }) =>
  <div
    className="flex align-items-center gap-2">
    {!product.category?.image && <i className="pi pi-image"></i>}
    {product.category?.image && <CategoryIcon className="w-1-5rem h-1-5rem" icon={product.category.image}/>}
  </div>;

let productImg = (product: { name: string, image?: string | null | undefined }) =>
  <img className="w-5 lg:w-7 xl:w-8 border-round"
       src={product.image || placeholder}
       alt={product.name}/>;

let productDescription = (product: GetProducts_products, clientGroup: GetProducts_clientGroup) =>
  <div className="text-center">
    <div className="text-l md:text-xl font-bold">{product.name}</div>
    {/*<div className="text-xs md:text-sm lg:text-base text-500">{product.category?.name}</div>*/}
  </div>;

let productUnit = (product: GetProducts_products,
                   clientGroupId: string | undefined | null,
                   selectedQuantity: number,
                   showIn: "currency" | "points",
                   onChange: (quantity: number | null) => void) => {
  let productPrice = findProductPriceForClientGroup(product, clientGroupId);
  let selectedPortions = portionsBasedOnProductOrderAmount(product, productPrice, selectedQuantity, clientGroupId);
  let selectedPrice = priceInclBasedOnProductOrderAmount(product, productPrice, selectedQuantity, clientGroupId);
  let currentStock = first(product.latestStock);

  return <>{productPrice && <div className="flex flex-column">
    <ProductQuantityInput
      onChange={onChange}
      clientGroupId={clientGroupId}
      product={product}
      inputClassName={'w-3rem'}
      value={selectedQuantity}
      hideSaveButton
      disabled={currentStock && currentStock.value < 1}
    />
    <div className="text-800 text-sm md:text-base xl:text-lg font-semibold pl-1 white-space-nowrap">
      = {showIn === "points"
      ? formatAsPoints(selectedPortions)
      : formatCurrency(selectedPrice)
    }
    </div>
  </div>
  }</>;
};

const ProductItemTemplateGrid = (props: ProductItemTemplateGridProps) => {
  const {product, clientGroup} = props;
  const clientGroupId = clientGroup.id;

  const [selectedQuantity, setSelectedQuantity] = useState<number>(0);

  useEffect(() => {
    let availableAsAlternativeUnit = productIsAvailableAsAlternativeUnitFor(product, clientGroupId);
    if (product.amount) {
      if (product.avgWeight && availableAsAlternativeUnit) {
        setSelectedQuantity(product.avgWeight / product.amount);
      } else {
        setSelectedQuantity((product.showMinOrderQuantityByDefault && product.minOrderQuantity) ? product.minOrderQuantity : product.amount);
      }
    }
  }, [props.product, clientGroupId]);

  let currentStock = first(product.latestStock);

  let selectedPortions = portionsBasedOnProductOrderAmount(product, findProductPriceForClientGroup(product, clientGroupId), selectedQuantity);

  return <div className="relative col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 p-2 flex">
    <div
      className="p-2 border-1 surface-border surface-card border-round w-full flex flex-column justify-content-between">
      <div className="flex flex-wrap align-items-center justify-content-between gap-1 md:gap-2">
        {category(product)}
        {stock(product, props.clientGroup)}
      </div>

      <div className="flex flex-column align-items-center gap-3 py-3 md:py-5">
        {productImg(product)}
        {productDescription(product, props.clientGroup)}
      </div>
      <div className={
        classNames(
          "flex align-items-center justify-content-between flex-row",
          {"opacity-40": currentStock && currentStock.value < 1}
        )
      }>
        {productUnit(product, clientGroupId, selectedQuantity, props.showIn, (quantity: number | null) => {
          setSelectedQuantity(quantity || 0);
        })}
      </div>
    </div>
    <div className="absolute bottom-0 right-0 pr-3 pb-3">
      {<Button
        type="button"
        size={"small"}
        icon="pi pi-shopping-cart"
        className="p-button-rounded w-2rem h-2rem"
        disabled={(currentStock && currentStock.value < 1) || (!!props.remainingPortions && props.remainingPortions < selectedPortions)}
        onClick={() => {
          props.onAdd(product.id, selectedQuantity, selectedPortions);
        }}
      />}
    </div>

  </div>;
};

export default ProductItemTemplateGrid;
