import React, {Fragment} from 'react';
import {BasketCompositions_basketCompositions} from "../../../../../__generated__/types";
import {Moment} from "moment/moment";
import CounterValues from "./ProductOrdersCounter/CounterValues";
import {
  filterBasketProductOrders,
  filterProductOrdersByDeliveryLocations
} from "../../../../../shared/utils/productOrder.utils";
import {deliveryLocations} from "../../../../../shared/utils/deliveryLocation.utils";
import {useProductOrdersForBasketCompositionQuery} from "../../../../../mutations/productOrder.queries";
import {compact} from "lodash";

interface DeliveriesInfoProps {
  basketComposition: BasketCompositions_basketCompositions;
  deliveryWeek: Moment;
}

const DeliveriesInfo = (props: DeliveriesInfoProps) => {
  let rows = deliveryLocations;
  let {basketComposition, deliveryWeek} = props;

  const {data} = useProductOrdersForBasketCompositionQuery(props.basketComposition.id, deliveryWeek);

  let productOrders = compact(data?.productOrders);
  return <div className={"grid mr-2 p-2 text-sm"}>
    <div className="col-4 py-0 border-right-1 border-bottom-1 border-400"></div>
    <div className="col-3 py-0 text-center font-bold border-bottom-1 border-400">Klein</div>
    <div className="col-3 py-0 text-center font-bold border-bottom-1 border-400">Groot</div>
    <div className="col-2 py-0 border-bottom-1 border-400"></div>

    {rows.map(deliveryLocation => <Fragment key={`deliveriesInfo${deliveryLocation.value}`}>
      <div className="col-4 py-0 font-bold border-right-1 border-400 white-space-nowrap">{deliveryLocation.city}</div>
      {(["medium", "large"] as ('medium' | 'large')[]).map((size) => {
        return <CounterValues
          key={`counterValues_${size}`}
          status={basketComposition.status}
          basketComposition={basketComposition}
          productOrders={filterProductOrdersByDeliveryLocations(productOrders, [deliveryLocation.value], [size])}
        />;
      })}
      <div
        className="col-2 py-0 text-center">{filterProductOrdersByDeliveryLocations(productOrders, [deliveryLocation.value]).length}</div>
    </Fragment>)}

    <div className="col-4 py-0 border-right-1 border-400"></div>
    <div className="col-3 py-0 text-center">{filterBasketProductOrders(productOrders, ["medium"]).length}</div>
    <div className="col-3 py-0 text-center">{filterBasketProductOrders(productOrders, ["large"]).length}</div>
    <div className="col-2 py-0 text-center">{filterBasketProductOrders(productOrders).length}</div>

  </div>;
};

export default DeliveriesInfo;
