import React, {ReactNode, useEffect, useState} from 'react';

import {chain, compact} from 'lodash';
import {
  ClientByAuth0UserId,
  ClientByAuth0UserId_clientsByUserId,
  ClientByAuth0UserIdVariables,
  CreateUser,
  CreateUserVariables,
  Permission
} from "./__generated__/types";
import {useLazyQuery, useMutation} from "@apollo/client";
import {ClientByAuth0UserIdQuery} from "./shared/queries/client.gql";
import {TODAY} from "./shared/config";
import {momentFromDdMmYyyy, momentFromIso8601} from "./shared/utils/date.utils";
import {CreateUserMutation} from "./shared/queries/user.gql";
import {findBasketProductOrder, findSelfHarvestProductOrder} from "./shared/utils/productOrder.utils";

interface ClientContextValue {
  client: ClientByAuth0UserId_clientsByUserId | null;
  initialize: (auth0userId: string, auth0Email: string) => void;
}

const DEFAULT_CONTEXT = {
  initialize: (auth0userId: string) => {
  },
  client: null,
};
const ClientContext = React.createContext<ClientContextValue>(DEFAULT_CONTEXT);

export type Props = {
  children: ReactNode;
  clientContextValue?: ClientContextValue;
};

function ClientProvider(props: Props) {
  const {children, clientContextValue} = props;
  const [value, setValue] = useState<ClientContextValue>(clientContextValue || DEFAULT_CONTEXT);

  const [clientsByAuth0userId, {data, loading}] = useLazyQuery<ClientByAuth0UserId>(ClientByAuth0UserIdQuery);
  const [createUser] = useMutation<CreateUser>(CreateUserMutation, {
    refetchQueries: 'active'
  });

  useEffect(() => {
    let clients = compact(data?.clientsByUserId);
    console.log('d', data);

    if (clients && clients.length === 1) {
      let client = clients[0];
      setValue(value => ({
        ...value,
        client: client
      }))

      let allProductOrders = chain(client.clientOrders)
        .map('productOrders')
        .flatten()
        .compact()
        .value();

      if (!data?.user
        && (client.email && client.name && client.firstName)) {

        let hasBasket = findBasketProductOrder(allProductOrders) !== undefined;
        let hasSelfHarvest = findSelfHarvestProductOrder(allProductOrders) !== undefined;

        let permissions = [];
        if (hasBasket) {
          permissions.push(Permission.CLIENT_BASKET);
        }
        if (hasSelfHarvest) {
          permissions.push(Permission.CLIENT_SELF_HARVEST);
        }

        const variables: CreateUserVariables = {
          data: {
            auth0userId: client.auth0userId,
            email: client.email.toLowerCase(),
            name: client.name,
            firstName: client.firstName,
            permissions: {set: permissions},
            farms: {
              connect: [{
                id: "clbuwjlqc8rvq0775qw2nhw6l"
              }]
            }
          }
        };
        createUser({variables});
      }
    }
  }, [data]);

  const initialize = (auth0userId: string, auth0Email: string) => {
    const variables: ClientByAuth0UserIdVariables = {auth0userId: auth0userId, auth0Email: auth0Email, today: TODAY};
    //const variables: ClientByAuth0UserIdVariables = {auth0userId: 'auth0|644e1bdaee985430fe8bc1ce', auth0Email: 'biekeloots@hotmail.com', today: TODAY};
    clientsByAuth0userId({variables});
  };

  // const addToShoppingCart = (productId: string, amount: number) => {
  //   const newVar: UserContextValue = {
  //     ...value,
  //     shoppingCart: {
  //       ...value.shoppingCart,
  //       items: {
  //         ...value.shoppingCart.items,
  //         [productId]: {
  //           productId,
  //           amount: get(value,`shoppingCart.items[${productId}].amount`, 0) + amount
  //         }
  //       }
  //     }
  //   };
  //   setValue(newVar);
  // };

  return (
    <ClientContext.Provider value={{
      ...value,
      initialize,
    }}>
      {children}
    </ClientContext.Provider>
  );
}

const useClientObject = () => React.useContext(ClientContext);

export {ClientProvider, useClientObject};
