import React from 'react';

interface CreateFarmItemProps {

}

const CreateFarmItem = (props: CreateFarmItemProps) => {
  return <div className="w-9rem m-2 border-1 border-200 flex flex-column align-items-center justify-content-center p-4">
    <div className="w-6rem h-6rem bg-gray-600 flex align-items-center justify-content-center border-circle">
      <i className="pi pi-plus text-ray-900"></i>
    </div>
    <div className="mt-2 text-center">Maak een nieuwe boerderij</div>
  </div>;
};

export default CreateFarmItem;
