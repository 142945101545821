import React from 'react';
import {Products_products} from "../../../../../__generated__/types";
import {first} from "lodash";
import {amountWithUnit} from "../../../../../shared/utils/unit.utils";
import classNames from "classnames";
import {availabilityFor, product_hasAvailability} from "../../../../../shared/utils/product.utils";

interface StockCellProps {
  product: Products_products;
}

const StockCell = (props: StockCellProps) => {
  let latestStock = first(props.product.latestStock);
  let productHasAvailability = product_hasAvailability(props.product);
  if(props.product.id === "clv0vx2v009gb07754b5ofbl5") {
    console.log(props.product);
    console.log(latestStock);
    console.log(productHasAvailability);

  }
  return <div
    className={classNames({"bg-red-100 p-2 text-red-800 font-bold": productHasAvailability && (!latestStock || (latestStock?.value !== undefined && latestStock.value <= 0))})}
  >
    {amountWithUnit(latestStock?.value, props.product.unit)}
  </div>;
};

export default StockCell;
