import {CreateClientOrderInvoice, UpdateClientOrderInvoice} from "../__generated__/types";
import {useMutation} from "@apollo/client";
import {CreateClientOrderInvoiceMutation, UpdateClientOrderInvoiceMutation} from "../shared/queries/clientOrder.gql";

export const useCreateClientOrderInvoice = () => {
  return useMutation<CreateClientOrderInvoice>(CreateClientOrderInvoiceMutation, {
    refetchQueries: 'active'
  });
};

export const useUpdateClientOrderInvoice = () => {
  return useMutation<UpdateClientOrderInvoice>(UpdateClientOrderInvoiceMutation, {
    refetchQueries: 'active'
  });
};
