import React from 'react';
import {useQuery} from "@apollo/client";
import {ClientOrderQuery} from "../../../shared/queries/clientOrder.gql";
import {ClientOrder, FarmConfigurationsByCode} from "../../../__generated__/types";
import {compact, first} from "lodash";
import {basketItemsForDelivery} from 'shared/utils/basketComposition.utils';
import {findBasketProductOrder} from "../../../shared/utils/productOrder.utils";
import {FarmConfigurationsByCodeQuery} from "../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../shared/utils/farmConfig.utils";

interface ClientOrderHistoryProps {
  clientOrderId: string;
}

const ClientOrderHistory = (props: ClientOrderHistoryProps) => {
  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});
  // HARDCODED FARM ID
  // let {activeFarm} = useUserObject();
  let {activeFarm} = {activeFarm: {id: 'clbuwjlqc8rvq0775qw2nhw6l'}};
  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  let basketProductOrder = findBasketProductOrder(data?.clientOrder?.productOrders);
  return <div className="p-2 border-bottom-2 border-500">
    <div className="text-lg font-medium">{basketProductOrder?.product?.name}</div>
    {compact(basketProductOrder?.deliveries).map(delivery => <div className="m-3 p-3 border-1 border-round-lg border-300">

      {/*{basketProductOrder?.product && basketItemsForDelivery(delivery.basketComposition?.items, basketProductOrder.product, delivery.deliveryLocation)*/}
      {/*  .map(basketItem => <div className="flex justify-content-between">*/}
      {/*    {basketItem.product.name}*/}
      {/*  </div>)}*/}

    </div>)}
  </div>;
};

export default ClientOrderHistory;
