import React from 'react';
import {useQuery} from "@apollo/client";
import {ClientGroups} from "../../../__generated__/types";
import {compact, keyBy} from "lodash";
import SalesPageHeading from "../SalesPageHeading";
import InvoiceTable from '../OrderManagement/components/InvoiceTable/InvoiceTable';
import {ClientGroupsQuery} from "../../../shared/queries/clientGroup.gql";
import {useUserObject} from "../../../shared/context/UserContext";

const InvoiceManagement = () => {
  const  {activeFarm} = useUserObject();
  const {data: clientGroupsData} = useQuery<ClientGroups>(ClientGroupsQuery, {variables: {farmId: activeFarm?.id}});

  return <div>
    <SalesPageHeading/>
    <InvoiceTable
      clientGroups={keyBy(compact(clientGroupsData?.clientGroups), 'id')}
    />
  </div>;
};

export default InvoiceManagement;
