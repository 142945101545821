import {
  BasketType,
  ClientType,
  GoogleShopStatus,
  InvoiceType,
  MembershipType,
  PaymentCreateWithoutInvoiceInput
} from "../../__generated__/types";
import moment, {Moment} from "moment";
import {formatCurrency} from "../utils/currency.utils";
import {camelCase, ceil, min, range, sortBy, sum, times} from "lodash";
import {priceForMember, targetOrTrialPriceFor} from "../utils/membership.utils";
import {
  asDayFullDdMmYyyy,
  asIso8601,
  asMonthYyyy,
  calculateAgeAtStartOfSeason,
  momentFromIso8601
} from "../utils/date.utils";
import {MembershipInvoicePdfDataProductLine, PdfData} from "./membershipInvoicePdf";
import {notEmpty} from "../utils/stream.utils";
import {
  endOfSeason,
  lastPlannedBasketDelivery,
  lastTrialBasketDelivery,
  nextBasketDeliveryOfSeason,
  remainingBasketsInSeason,
  remainingWeeksInSelfHarvestSeason,
  startOfSeason
} from "../utils/season.util";
import {CLIENT_SETTINGS} from "../clientConfig";

export interface GoogleShopOrders {
  status: GoogleShopStatus;
  deliveryDate: any;
  amount: number;
  product: Memberships_memberships_googleShopOrders_product;
}

export interface Memberships_memberships_googleShopOrders_product {
  price: number;
  name: string;
}

export interface Invoice {
  type: InvoiceType;
  data: any | null;
}

export interface Member {
  dateOfBirth: any;
  child: boolean;
}

export interface MembershipForPdf {
  type: MembershipType;
  name: string;
  firstName: string;
  dateOfBirth: any | null;
  street: string | null;
  streetNumber: string | null;
  city: string | null;
  cityPostalCode: string | null;
  price: number | null;
  season: string;
  invoices: Invoice[] | null;
  basketType: BasketType | null;
  //deliveryLocation: DeliveryLocation | null;
  biWeekly: boolean;
  vat: string | null;
  homeDelivery: boolean;
  startDate: any | null;
  trial: boolean;
  //googleShopOrders: GoogleShopOrders[] | null;
  members: Member[] | null;
}


/**
 * @deprecated use calculatePayments in invoice utils
 */
export const calculatePayments = (nrOfPayments: number, totalInclRaw: number, prePayment: number, discount: number, season: string): PaymentCreateWithoutInvoiceInput[] => {

  const firstPaymentDate = moment().add('1', 'M');
  const totalInclWithoutDiscountRaw = totalInclRaw - discount;
  if (nrOfPayments === 1) {
    return [{
      amount: totalInclWithoutDiscountRaw,
      dueDate: asIso8601(firstPaymentDate),
    }];
  } else {
    const lastAllowedPaymentDate = endOfSeason(season).clone().subtract('8', 'month');
    const daysBetweenPayments = ceil(lastAllowedPaymentDate.diff(firstPaymentDate, 'day') / nrOfPayments);
    if (prePayment > 0) {
      const payments = [{
        amount: prePayment,
        dueDate: asIso8601(firstPaymentDate),
      }];
      const remainingAmount = totalInclWithoutDiscountRaw - prePayment;
      return [
        ...payments,
        ...range(1, nrOfPayments).map(t => {
          return {
            amount: remainingAmount / (nrOfPayments - 1),
            dueDate: asIso8601(firstPaymentDate.clone().add(daysBetweenPayments * t, 'days')),
          }
        })
      ];
    } else {
      return [
        ...times(nrOfPayments).map(t => {
          return {
            amount: totalInclRaw / nrOfPayments,
            dueDate: asIso8601(firstPaymentDate.clone().add(daysBetweenPayments * t, 'days')),
          }
        })
      ];
    }
  }
};

function createInvoiceDescription({basketType, biWeekly, type, vat}: MembershipForPdf) {
  let description: string;
  if (type === MembershipType.BASKETS) {
    if (vat) {
      description = 'Sponsoring';
    } else {
      description = `Oogstaandeel ${basketType === BasketType.STANDARD ? "klein pakket" : "groot pakket"}${biWeekly ? '\n(tweewekelijks)' : ''}`;
    }
  } else {
    description = `Oogstaandeel zelfoogst`;
  }
  return description;
}

export const createMembershipInvoiceData2 = (membership: MembershipForPdf,
                                             invoiceNumber: string,
                                             today: Moment,
                                             proForma: boolean,
                                             productLines: { description: string, priceIncl: number, vatPercentage: number }[],
                                             payments?: PaymentCreateWithoutInvoiceInput[],
): PdfData => {
  let totalExcl = 0;
  let totalIncl = 0;
  let totalVat6 = 0;
  let totalVat21 = 0;

  let recipientName = membership.vat ? membership.name : `${membership.firstName} ${membership.name}`;
  let invoiceType;
  if (proForma) {
    invoiceType = membership.type === MembershipType.SELF_HARVEST ? InvoiceType.PRO_FORMA_SELF_HARVEST : InvoiceType.PRO_FORMA_BASKET;
  } else {
    if (membership.trial) {
      invoiceType = membership.type === MembershipType.SELF_HARVEST ? InvoiceType.SELF_HARVEST_TRIAL : InvoiceType.BASKET_TRIAL;
    } else {
      invoiceType = membership.type === MembershipType.SELF_HARVEST ? InvoiceType.SELF_HARVEST : InvoiceType.BASKET;
    }
  }

  const enrichedProductLines = productLines.map(productLine => {
    const priceExcl = productLine.priceIncl / (1 + productLine.vatPercentage);
    const vat = priceExcl * productLine.vatPercentage;

    totalExcl += priceExcl;
    totalIncl += productLine.priceIncl;
    if (productLine.vatPercentage === 0.06) {
      totalVat6 += vat;
    } else if (productLine.vatPercentage === 0.21) {
      totalVat21 += vat;
    }

    return ({
      ...productLine,
      priceIncl: formatCurrency(productLine.priceIncl),
      priceExcl: formatCurrency(priceExcl),
      priceExclRaw: priceExcl,
      vatPercentage: productLine.vatPercentage,
      vat,
    });
  });

  return {
    type: invoiceType,
    number: invoiceNumber,
    filename: `${invoiceNumber}-${camelCase(recipientName)}.pdf`,
    data: {
      recipient: {
        name: recipientName,
        businessName: membership.vat && membership.firstName,
        addressLines: [`${membership.street} ${membership.streetNumber}`, `${membership.cityPostalCode} ${membership.city}`],
      },
      meta: [
        {
          label: "Factuurnummer",
          value: invoiceNumber,
        },
        membership.vat ? {label: "Btw nummer klant", value: `BTW ${membership.vat}`} : undefined,
        {
          label: "Factuurdatum",
          value: today.format("DD-MM-YYYY"),
        },
      ].filter(notEmpty),
      productLines: enrichedProductLines,
      totalExclRaw: totalExcl,
      totalExcl: formatCurrency(totalExcl),
      vat6: formatCurrency(totalVat6),
      vat21: formatCurrency(totalVat21),
      totalIncl: formatCurrency(totalIncl),
      totalInclRaw: totalIncl,
      payments: (payments || []).map(payment => {
        return {
          dueDate: momentFromIso8601(payment.dueDate).format("DD-MM-YYYY"),
          amount: formatCurrency(payment.amount),
        };
      }),
    }
  };
};


export const createMembershipInvoiceData = (membership: MembershipForPdf,
                                            invoiceNumber: string,
                                            manualDiscount: number,
                                            manualDiscountDescription: string | undefined,
                                            today: Moment,
                                            proForma: boolean,
                                            payments?: PaymentCreateWithoutInvoiceInput[],
): PdfData => {
  let nrOfChildren = (membership.members || []).filter(m => m.child).length;
  let productLines: MembershipInvoicePdfDataProductLine[];
  let totalExcl = 0;
  let discount = 0;
  let description = createInvoiceDescription(membership);

  if (membership.trial) {
    description += ` proefmaand`;
  }

  const trialInvoice = (membership.invoices || []).find(i => i.type === InvoiceType.SELF_HARVEST_TRIAL || i.type === InvoiceType.BASKET_TRIAL);
  if (membership.type === MembershipType.BASKETS) {
    let period: string;
    const memebershipStartDate = momentFromIso8601(membership.startDate);
    const firstDay = nextBasketDeliveryOfSeason(membership.season, memebershipStartDate.clone());
    let lastDay;
    if (membership.trial) {
      lastDay = firstDay && lastTrialBasketDelivery(firstDay);
      period = `${asDayFullDdMmYyyy(firstDay)} - ${asDayFullDdMmYyyy(lastDay)}`;
    } else {
      lastDay = firstDay && lastPlannedBasketDelivery(membership.season);
      period = `${asMonthYyyy(firstDay)} - ${asMonthYyyy(lastDay)}`;

    }
    const percentage = (remainingBasketsInSeason(membership.season, memebershipStartDate.clone())) / 32;

    const remainingPrice = (membership.price || 0) * (membership.trial ? 1 : percentage);
    const price = membership.biWeekly ? remainingPrice / 2 : remainingPrice;
    const priceExclRaw = price / (membership.vat ? 1.21 : 1.06);

    totalExcl = priceExclRaw;
    productLines = [{
      description,
      period: period,
      priceIncl: formatCurrency(price),
      priceExcl: formatCurrency(priceExclRaw),
      priceExclRaw: priceExclRaw,
      discountExcl: formatCurrency(0),
      discountExclRaw: 0,
    }];
    if (membership.homeDelivery) {
      const homeDeliveryCost = membership.trial ? 5 * 4 : 5 * 34;
      const homeDeliveryCostExclRaw = homeDeliveryCost / (membership.vat ? 1.21 : 1.06);
      totalExcl += homeDeliveryCostExclRaw;
      productLines = [...productLines, {
        description: 'Thuislevering',
        period: '',
        priceIncl: formatCurrency(homeDeliveryCost),
        priceExcl: formatCurrency(homeDeliveryCostExclRaw),
        priceExclRaw: homeDeliveryCostExclRaw,
        discountExcl: formatCurrency(0),
        discountExclRaw: 0,
      }];
    }
  } else {

    productLines = [
      ...sortBy([...(membership.members || []), {
        firstName: membership.firstName,
        name: membership.name,
        dateOfBirth: membership.dateOfBirth,
        child: false,
      }], 'child').map(member => {
        const age = calculateAgeAtStartOfSeason(member.dateOfBirth, startOfSeason(membership.season));
        const number = remainingWeeksInSelfHarvestSeason(membership.season, momentFromIso8601(membership.startDate)) + (trialInvoice ? 4 : 0);
        const percentage = membership.trial ? 1 : (min([number / 52, 1]) || 1);

        const price = priceForMember((membership.price || 0), age, percentage);
        const priceExclRaw = price / (membership.vat ? 1.21 : 1.06);
        totalExcl += priceExclRaw;
        const familyDiscount = member.child && nrOfChildren > 2;
        let period;

        const firstDay = startOfSeason(membership.season);
        let lastDay;

        if (membership.trial) {
          lastDay = firstDay && firstDay.clone().add('3', 'week');
          period = `${asMonthYyyy(firstDay)} - ${asMonthYyyy(lastDay)}`;
        } else {
          lastDay = firstDay && endOfSeason(membership.season);
          period = `${asMonthYyyy(firstDay)} - ${asMonthYyyy(lastDay)}`;
        }

        return {
          description: member.child ? `${description} (kind)` : description,
          age: age >= 18 ? "18+" : `${age}`,
          period: period,
          priceIncl: formatCurrency(price),
          priceExcl: formatCurrency(priceExclRaw),
          priceExclRaw: priceExclRaw,
          discountExcl: familyDiscount ? formatCurrency(priceExclRaw * 0.25) : formatCurrency(0),
          discountExclRaw: familyDiscount ? priceExclRaw * 0.25 : 0,
        };
      }),
    ];
    if (nrOfChildren > 2) {
      const discountExclRaw = sum(productLines.filter(pl => pl.discountExclRaw && pl.discountExclRaw > 0).map(pl => pl.discountExclRaw));
      discount += discountExclRaw;
      productLines = [...productLines, {
        description: 'Korting: gezinskorting, 25% per kind',
        period: '',
        priceExcl: `-${formatCurrency(sum(productLines.filter(pl => pl.discountExclRaw && pl.discountExclRaw > 0).map(pl => pl.discountExclRaw)))}`,
        priceExclRaw: -discountExclRaw,
        priceIncl: `-${formatCurrency(discountExclRaw * (membership.vat ? 1.21 : 1.06))}`,
        age: '',
        discountExcl: '0',
        discountExclRaw: 0,
      }];
    }

  }
  if (manualDiscount) {
    const manualDiscountExcl = manualDiscount / (membership.vat ? 1.21 : 1.06);
    discount += manualDiscountExcl;
    productLines = [...productLines, {
      description: 'Korting: ' + manualDiscountDescription,
      period: '',
      priceExcl: `-${formatCurrency(manualDiscountExcl)}`,
      priceExclRaw: -(manualDiscountExcl),
      priceIncl: `-${formatCurrency(manualDiscount)}`,
      age: '',
      discountExcl: '0',
      discountExclRaw: 0,
    }];

  }
  if (trialInvoice && !proForma) {
    let priceForTrial;
    if (membership.type === MembershipType.BASKETS) {
      priceForTrial = targetOrTrialPriceFor(CLIENT_SETTINGS[0], true, membership.type, membership.basketType);
      priceForTrial = priceForTrial / (membership.vat ? 1.21 : 1.06);
    } else {
      priceForTrial = trialInvoice.data.totalExclRaw;
    }

    discount += priceForTrial;
    productLines.push({
      period: "",
      description: 'Korting: proefmaand',
      priceExcl: `-${formatCurrency(priceForTrial)}`,
      //priceExclRaw: priceForTrial,
      priceExclRaw: -priceForTrial,
      priceIncl: `-${formatCurrency(priceForTrial * (membership.vat ? 1.21 : 1.06))}`,
      age: "",
      discountExcl: formatCurrency(0),
      discountExclRaw: 0,
    });
  }

  let recipientName = membership.vat ? membership.name : `${membership.firstName} ${membership.name}`;
  let invoiceType;
  if (proForma) {
    invoiceType = membership.type === MembershipType.SELF_HARVEST ? InvoiceType.PRO_FORMA_SELF_HARVEST : InvoiceType.PRO_FORMA_BASKET;
  } else {
    if (membership.trial) {
      invoiceType = membership.type === MembershipType.SELF_HARVEST ? InvoiceType.SELF_HARVEST_TRIAL : InvoiceType.BASKET_TRIAL;
    } else {
      invoiceType = membership.type === MembershipType.SELF_HARVEST ? InvoiceType.SELF_HARVEST : InvoiceType.BASKET;
    }
  }


  const invoiceTotal = totalExcl - discount;
  return {
    type: invoiceType,
    number: invoiceNumber,
    filename: `${invoiceNumber}-${camelCase(recipientName)}.pdf`,
    data: {
      //type: membership.type,
      recipient: {
        name: recipientName,
        businessName: membership.vat && membership.firstName,
        addressLines: [`${membership.street} ${membership.streetNumber}`, `${membership.cityPostalCode} ${membership.city}`],
      },
      meta: [
        {
          label: "Factuurnummer",
          value: invoiceNumber,
        },
        membership.vat ? {label: "Btw nummer klant", value: `BTW ${membership.vat}`} : undefined,
        {
          label: "Factuurdatum",
          value: today.format("DD-MM-YYYY"),
        },
      ].filter(notEmpty),
      productLines: productLines,
      discount: formatCurrency(discount),
      totalExcl: formatCurrency(invoiceTotal),
      totalExclRaw: invoiceTotal,
      vatPercentage: membership.vat ? 0.21 : 0.06,
      vat: formatCurrency((invoiceTotal) * (membership.vat ? 0.21 : 0.06)),
      totalIncl: formatCurrency((invoiceTotal) * (membership.vat ? 1.21 : 1.06)),
      totalInclRaw: (invoiceTotal) * (membership.vat ? 1.21 : 1.06),
      payments: (payments || []).map(payment => {
        return {
          dueDate: momentFromIso8601(payment.dueDate).format("DD-MM-YYYY"),
          amount: formatCurrency(payment.amount),
        };
      }),
    }
  };
};

export const createGoogleShopInvoiceData = (membership: MembershipForPdf, invoiceNumber: string): PdfData => {
  let recipientName = membership.vat ? membership.name : `${membership.firstName} ${membership.name}`;

  const totalInclRaw = 0
  // const totalInclRaw = chain(membership.googleShopOrders || [])
  //   .filter(order => order.status === GoogleShopStatus.DELIVERED || order.status === GoogleShopStatus.SCHEDULED_FOR_DELIVERY)
  //   .map(order => order.product.price * order.amount)
  //   .sum()
  //   .value();

  const productLines: MembershipInvoicePdfDataProductLine[] = [];
  // const productLines = chain(membership.googleShopOrders || [])
  //   .filter(order => order.status === GoogleShopStatus.DELIVERED || order.status === GoogleShopStatus.SCHEDULED_FOR_DELIVERY)
  //   .map(order => {
  //     const priceInclRaw = order.product.price * order.amount;
  //     const priceExclRaw = priceInclRaw / (membership.vat ? 1.21 : 1.06);
  //     return ({
  //       description: order.product.name,
  //       period: asDayDdMmYyyy(momentFromIso8601(order.deliveryDate)) || "",
  //       priceExcl: formatCurrency(priceExclRaw),
  //       priceIncl: formatCurrency(priceInclRaw),
  //       priceInclRaw: formatCurrency(priceInclRaw),
  //       priceExclRaw: priceExclRaw
  //     });
  //   })
  //   .value();

  const totalExclRaw = (totalInclRaw) / (membership.vat ? 1.21 : 1.06);
  const payments = calculatePayments(1, totalInclRaw, 0, 0, membership.season
  );
  return {
    type: InvoiceType.GOOGLE_SHOP,
    number: invoiceNumber,
    filename: `${invoiceNumber}-${camelCase(recipientName)}.pdf`,
    data: {
      recipient: {
        name: recipientName,
        businessName: membership.vat && membership.firstName,
        addressLines: [`${membership.street} ${membership.streetNumber}`, `${membership.cityPostalCode} ${membership.city}`],
      },
      meta: [
        {
          label: "Factuurnummer",
          value: invoiceNumber,
        },
        membership.vat ? {label: "Btw nummer klant", value: `BTW ${membership.vat}`} : undefined,
        {
          label: "Factuurdatum",
          value: moment().format("DD-MM-YYYY"),
        },
      ].filter(notEmpty),
      productLines: productLines,
      totalExcl: formatCurrency(totalExclRaw),
      totalExclRaw: totalExclRaw,
      vatPercentage: membership.vat ? 0.21 : 0.06,
      vat: formatCurrency((totalExclRaw) * (membership.vat ? 0.21 : 0.06)),
      totalIncl: formatCurrency(totalInclRaw),
      totalInclRaw: totalInclRaw,
      payments: (payments || []).map(payment => {
        return {
          dueDate: momentFromIso8601(payment.dueDate).format("DD-MM-YYYY"),
          amount: formatCurrency(payment.amount),
        };
      }),
    }
  };
};

export const clientToRecipient = (client: {
  name: string | null
  companyName?: string | null
  firstName?: string | null
  street: string | null
  streetNumber: string | null
  cityPostalCode: string | null
  city: string | null
  vatNumber: string | null
  type?: ClientType | null
}) => {
  return {
    businessName: ((client.type !== ClientType.BUSINESS && client.vatNumber === null)
      ? (`${client.firstName} ${client.name}`)
      : (client.companyName || client.name)) ||'',
    addressLines: [`${client.street} ${client.streetNumber}`, `${client.cityPostalCode} ${client.city}`],
  }
};
