import gql from "graphql-tag";

export const BankTransactionFieldsFragment = gql`
fragment BankTransactionFields on BankTransaction {
  id
    date
    amount
    ibanNumber
    bic
    name
    address
    sct
    description
    bankAccount { id }
    bankAccountBalance
    client { id}
}
`;

export const BankTransactionsQuery = gql`
${BankTransactionFieldsFragment}
query BankTransactions {
  bankTransactions {
    ...BankTransactionFields
  }
}
`;

export const BankTransactionsCreateMutation = gql`
${BankTransactionFieldsFragment}
mutation BankTransactionsCreate($ibanNumber: String!, $transactionCreates: [BankTransactionCreateWithoutBankAccountInput!]!) {
  updateBankAccount(
    where: {
      ibanNumber: $ibanNumber
    }
    data: {
      transactions: {
        create: $transactionCreates
      }
    }
  ) {
   id
   transactions {
      ...BankTransactionFields
      }
  }
}
`;
