import React from 'react';
import {chain, compact, groupBy} from "lodash";
import {
  allDaysInMonth,
  asDayFullDdMmYyyy,
  asIso8601,
  asYyyyW,
  iso8601_YyyyW,
  momentFromIso8601,
  momentFromMmYyyy,
  momentFromYyyyW,
  YyyyW_MmYyyy
} from "../../../shared/utils/date.utils";
import classNames from "classnames";
import {deliveryWeeksForSubscription} from "../../../shared/utils/subscription.utils";
import {useQuery} from "@apollo/client";
import {ClientOrder} from "../../../__generated__/types";
import {ClientOrderQuery} from "../../../shared/queries/clientOrder.gql";
import {TODAY} from "../../../shared/config";
import {findFlexBasketProductOrder} from "../../../shared/utils/productOrder.utils";

interface DeliveriesOverviewProps {
  type: 'source' | 'destination';
  clientOrderId: string;
  toDeliveryDate?: string;
  onChangeToDeliveryDate: (toDeliveryDate: string | undefined) => void;
  onDeliverySelected: (deliveryId: string) => void;
}

const DeliveriesOverview = (props: DeliveriesOverviewProps) => {
  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});

  let flexBasketProductOrder = findFlexBasketProductOrder(data?.clientOrder?.productOrders);
  let actualDeliveries = compact(flexBasketProductOrder?.deliveries);
  let actualDeliveryWeeks = actualDeliveries.map(delivery => iso8601_YyyyW(delivery.plannedDeliveryDate));
  let deliveryWeeksBySubscriptionCode = deliveryWeeksForSubscription(flexBasketProductOrder?.product?.code,
    momentFromIso8601(data?.clientOrder?.deliveryDate));

  let monthsToView = groupBy(deliveryWeeksBySubscriptionCode
      .possibleWeeks
      .filter(week => momentFromYyyyW(week).isAfter(TODAY, 'isoWeek')),
    possibleWeek => YyyyW_MmYyyy(possibleWeek)
  );

  let isoWeekDayForDelivery = momentFromIso8601(data?.clientOrder?.deliveryDate).isoWeekday();

  let allWeeks = chain(monthsToView)
    .mapValues((value, key) => {
      let month = momentFromMmYyyy(key);
      return allDaysInMonth(month, isoWeekDayForDelivery)
        .filter(day => momentFromYyyyW(day).isAfter(TODAY, 'isoWeek'))
        .map(day => {
          let delivery = actualDeliveries.find(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(day, 'isoWeek'));
          let weekYear = asYyyyW(day);
          return ({
            day,
            isPossible: day.isAfter(TODAY, 'day') && weekYear && deliveryWeeksBySubscriptionCode.possibleWeeks.includes(weekYear) && !actualDeliveryWeeks.includes(weekYear),
            isDayOff: weekYear && !deliveryWeeksBySubscriptionCode.possibleWeeks.includes(weekYear),
            isPlanned: !!delivery,
            isPassed: day.isSameOrBefore(TODAY, 'd'),
            delivery,
            x: asDayFullDdMmYyyy(day)
          });
        });
    }).value();
  const {onChangeToDeliveryDate, onDeliverySelected, toDeliveryDate} = props;

  return <div>
    <div className="text-lg font-medium mt-3 mb-2">Pakketoverzicht</div>

    <div className={"flex flex-column p-2 m-3 border-1 border-300 text-sm"}>
      <div className="font-medium">Legende:</div>
      <div className={"flex align-items-center justify-content-start"}>
        <div
          className={"w-1rem h-1rem m-1 flex flex-shrink-0 flex-column align-items-center justify-content-center bg-yellow-100 border-2 border-yellow-500 cursor-pointer"}/>
        <div>= Beschikbaar</div>
      </div>
      <div className={"flex align-items-center justify-content-start"}>
        <div
          className={"w-1rem h-1rem m-1 flex flex-shrink-0 flex-column align-items-center justify-content-center bg-green-100 border-1 opacity-60 border-green cursor-pointer"}/>
        <div>= Reeds een ingepland pakket</div>
      </div>
      <div className={"flex align-items-center justify-content-start"}>
        <div
          className={"w-1rem h-1rem m-1 flex flex-shrink-0 flex-column align-items-center justify-content-center bg-green border-1 border-green opacity-60 bg-gray-striped cursor-pointer"}/>
        <div>= Geen pakketweek</div>
      </div>
    </div>

    <div className="flex justify-content-center p-0 flex-wrap">
      {chain(allWeeks)
        .keys()
        .map(month => {
          return allWeeks[month].map(week => {
              let day = momentFromYyyyW(week.day).isoWeekday(isoWeekDayForDelivery);
              return <div
                key={`week-${week.day.milliseconds()}`}
                onClick={() => {
                  let updatedDeliveryDate = asIso8601(week.day);
                  if (props.type === 'source' && week.delivery) {
                    onDeliverySelected(week.delivery.id);
                  } else if (props.type === 'destination') {
                    if (toDeliveryDate && toDeliveryDate === updatedDeliveryDate) {
                      onChangeToDeliveryDate(undefined);
                    } else if (week.isPossible) {
                      onChangeToDeliveryDate(updatedDeliveryDate);
                    }
                  }
                }}
                className={
                  classNames(
                    "w-5rem h-2rem m-1 p-1 flex flex-column",
                    {"bg-green-100 border-1 opacity-60 border-green": week.isPassed},
                    {"bg-green border-1 border-green": !week.isPlanned && !week.isPossible},
                    {"bg-green-100 border-1 border-green": !week.isPassed && week.isPlanned},
                    {"opacity-80": props.type === 'source' && !week.isPassed && week.isPlanned},
                    {"opacity-40": props.type === 'destination' && !week.isPassed && week.isPlanned},
                    {
                      "cursor-pointer": !week.isPassed &&
                        (
                          (week.isPlanned && props.type === 'source')
                          || week.isPossible
                          || toDeliveryDate && week.day.isSame(momentFromIso8601(toDeliveryDate), 'day')
                        )
                    },
                    {"hover:bg-green-200 border-2": !week.isPassed && (week.isPlanned && props.type === 'source')},
                    {"opacity-60 bg-gray-striped": week.isDayOff},
                    {"bg-yellow-100 border-2 border-yellow-500": week.isPossible},
                    {"opacity-40": props.type === 'source' && week.isPossible},
                    {"bg-indigo-100 border-2 border-indigo-500": toDeliveryDate && week.day.isSame(momentFromIso8601(toDeliveryDate), 'day')},
                  )
                }>
                <div className='text-lg font-bold'>
                  <span>{day.format('DD')}</span>
                  <span className='text-md font-medium text-900 pl-1'>{day.format('MMM')}</span>
                </div>
              </div>;
            }
          )
        })
        .flatten()
        .value()}
    </div>
    <div className=""></div>

    {/*<div className="grid grid-nogutter">*/}
    {/*  {keys(allWeeks).map(month => {*/}
    {/*    return <>*/}
    {/*      <div*/}
    {/*        className={'col-4 align-self-center text-right p-0 text-xs sm:text-sm md:text-base pr-1'}>{asMonthYyyy(momentFromMmYyyy(month))}</div>*/}
    {/*      <div className={'col-8 flex justify-content-start p-0 flex-wrap'}>{allWeeks[month].map(week => {*/}
    {/*          let day = momentFromYyyyW(week.day).isoWeekday(isoWeekDayForDelivery);*/}
    {/*          return <div*/}
    {/*            onClick={() => {*/}
    {/*              let updatedDeliveryDate = asIso8601(week.day);*/}
    {/*              if (toDeliveryDate && toDeliveryDate === updatedDeliveryDate) {*/}
    {/*                onChangeToDeliveryDate(undefined);*/}
    {/*              } else if (week.isPossible) {*/}
    {/*                onChangeToDeliveryDate(updatedDeliveryDate);*/}
    {/*              }*/}
    {/*            }}*/}
    {/*            className={*/}
    {/*              classNames(*/}
    {/*                "w-2rem h-2rem m-1 sm:w-3rem sm:h-3rem sm:m-2 p-1 py-2 flex flex-shrink-0 flex-column align-items-center justify-content-center",*/}
    {/*                {"bg-green border-1 border-green": !week.isPlanned && !week.isPossible},*/}
    {/*                {"bg-green-100 border-1 opacity-60 border-green": week.isPlanned},*/}
    {/*                {"opacity-60 bg-gray-striped": week.isDayOff},*/}
    {/*                {"bg-yellow-100 border-2 border-yellow-500 cursor-pointer": week.isPossible},*/}
    {/*                {"bg-indigo-100 border-2 border-indigo-500 cursor-pointer": toDeliveryDate && week.day.isSame(momentFromIso8601(toDeliveryDate), 'day')},*/}
    {/*              )*/}
    {/*            }>*/}
    {/*            <div className='text-sm sm:text-base font-medium'>{day.format('DD')}</div>*/}
    {/*            <div className='text-xs sm:taxt-sm font-light text-800'>{day.format('MMM')}</div>*/}
    {/*          </div>;*/}
    {/*        }*/}
    {/*      )}*/}
    {/*      </div>*/}
    {/*    </>;*/}
    {/*  })}*/}
    {/*</div>*/}
  </div>;
};

export default DeliveriesOverview;
