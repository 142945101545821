import {useQuery} from "@apollo/client";
import {ProductOrdersForBasketComposition} from "../__generated__/types";
import {asIso8601} from "../shared/utils/date.utils";
import {ProductOrdersForBasketCompositionQuery} from "../shared/queries/productOrder.gql";
import {Moment} from "moment";

export const useProductOrdersForBasketCompositionQuery = (basketCompositionId: string, deliveryWeek: Moment) => {
  let plannedDeliveryStartDate = asIso8601(deliveryWeek.clone().startOf('isoWeek'));
  let plannedDeliveryEndDate = asIso8601(deliveryWeek.clone().endOf('isoWeek'));
  return useQuery<ProductOrdersForBasketComposition>(ProductOrdersForBasketCompositionQuery, {
    variables: {
      basketCompositionId,
      plannedDeliveryStartDate,
      plannedDeliveryEndDate
    }
  });
};
