import React, {useState} from 'react';
import {Button} from "primereact/button";
import CreateInvoiceDialog from "./CreateInvoiceDialog/CreateInvoiceDialog";

interface CreateInvoiceActionProps {
  clientOrderId: string;
  buttonClassName?: string;
}

const CreateInvoiceAction = (props: CreateInvoiceActionProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return <div>
    {showDialog && <CreateInvoiceDialog onHide={() => setShowDialog(false)} clientOrderId={props.clientOrderId}/>}

    <Button
      className={`${props.buttonClassName ? props.buttonClassName : 'p-button-link'}`}
      tooltip="Factuur maken"
      icon="pi pi-euro"
      onClick={() => {
        setShowDialog(true);
      }}
    />
  </div>;
};

export default CreateInvoiceAction;
