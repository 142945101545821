import React, {useState} from 'react';
import {PricelistData_clientGroups, PricelistData_products, ProductType} from "../../../../__generated__/types";
import {Button} from "primereact/button";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import {amountWithUnit} from "../../../../shared/utils/unit.utils";
import {compact, first} from "lodash";
import {formatDoubleDigitStrict} from "../../../../shared/utils/currency.utils";
import {vat} from "../../../../shared/utils/vat.utils";
import {Dialog} from "primereact/dialog";
import {RadioButton} from "primereact/radiobutton";
import Logo from "./logo.png";
import {asDdMmYyyy, asYyyyMmDd} from "../../../../shared/utils/date.utils";
import moment from "moment";


interface ExportPricelistButtonProps {
  products: PricelistData_products[];
  clientGroups: PricelistData_clientGroups[] | null;
}

const ExportPricelistButton = (props: ExportPricelistButtonProps) => {
  const {products} = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedClientGroups, setSelectedClientGroups] = useState<PricelistData_clientGroups[]>([]);

  const exportPdf = () => {
    const doc = new jsPDF({
      unit: "cm",
    });

    let clientGroup = first(selectedClientGroups);
    let showInclVat = clientGroup?.code === 'standaard';
    doc.addImage(Logo, "PNG", 0, 0, 5, 1072 / (2935 / 5),undefined,'FAST');
    doc.setFontSize(10);
    doc.text("Datum: "+asDdMmYyyy(moment()), 0.5, 2.5);
    doc.setFontSize(20);
    doc.text(`Prijstlijst ${clientGroup?.name || ''}`, 0.5, 4);

    doc.setFontSize(12);
    autoTable(doc, {
      startY: 5,
      headStyles: {
        fillColor: [116, 143, 86],
      },
      head: [[
        'Naam', 'Eenheid',
        {content: `Prijs ${showInclVat ? 'incl.' : 'excl.'} btw`, colSpan: 2}
      ]],
      body: products
        .filter(product => product.type === ProductType.SIMPLE)
        .map(product => {
          let clientGroupProductPrice = compact(product.productPrices)
            .find(productPrice => productPrice.clientGroup?.id === clientGroup?.id);

          let price = clientGroupProductPrice && clientGroupProductPrice.value * (1 + (showInclVat ? vat(product.vat) : 0));

          return ([
            {content: product.name + (product.extra ? ` (${product.extra})` : '')},
            product.amount && amountWithUnit(product.amount, product.unit),
            {content: formatDoubleDigitStrict(price), styles: {halign: "right"}},
            'EUR'
          ]);
        })
    });
    doc.save('Groentegeweld productenlijst '+asYyyyMmDd(moment())+' '+ clientGroup?.name.toLowerCase()+'.pdf');
  };

  return <div className="inline-block flex align-items-center">
    <Dialog
      header="Exporteer prijslijst"
      visible={showDialog}
      onHide={() => setShowDialog(false)}
      closeOnEscape
      closable>

      <div className="mb-3">
        {compact(props.clientGroups).map(clientGroup =>
          <div className="py-1" key={clientGroup.id}>
            <RadioButton
              radioGroup={"clientGroups"}
              checked={selectedClientGroups.findIndex(selected => selected.id === clientGroup.id) > -1}
              onChange={(event) => {
                if (event.checked) {
                  setSelectedClientGroups([clientGroup]);
                }
              }}/>
            <span className="pl-2">{clientGroup.name}</span>
          </div>)
        }
      </div>
      <Button
        disabled={selectedClientGroups.length === 0}
        type="button"
        label="Exporteer prijslijst"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        data-pr-tooltip="PDF"/>
    </Dialog>

    <Button
      className="p-button-link"
      type="button"
      label="Exporteer prijslijst"
      icon="pi pi-file-pdf"
      onClick={() => setShowDialog(true)}
      data-pr-tooltip="PDF"/>
  </div>;
};

export default ExportPricelistButton;
