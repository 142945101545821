import React, {useEffect, useState} from 'react';
import {InputNumber} from "primereact/inputnumber";
import {
    findProductPriceForClientGroupCode,
    findProductPriceForDefaultClientGroup,
    suggestedPointsFor
} from "../../../../../../shared/utils/product.utils";
import {useDebounce} from "primereact/hooks";
import {
    Product_product,
    ProductPriceCreateWithoutProductInput, ProductPrices_productPrices,
    ProductPriceUpdateWithWhereUniqueWithoutProductInput, UpdateProduct, UpdateProductVariables
} from "../../../../../../__generated__/types";
import {asIso8601} from "../../../../../../shared/utils/date.utils";
import {TODAY} from "../../../../../../shared/config";
import {compact} from "lodash";
import {useMutation} from "@apollo/client";
import {UpdateProductMutation} from "../../../../queries.gql";
import {Button} from "primereact/button";

interface PointsPriceProps {
    productPrices: ProductPrices_productPrices[];
    flexClientGroupCode: string;
    product: Product_product;
}

const PointsPrice = (props: PointsPriceProps) => {
    const {productPrices, flexClientGroupCode, product} = props;

    const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {refetchQueries: "active"});

    let flexProductPrice = findProductPriceForClientGroupCode({productPrices}, flexClientGroupCode);

    let defaultClientGroupPrice = findProductPriceForDefaultClientGroup({productPrices});

    let suggestedPoints = suggestedPointsFor(defaultClientGroupPrice?.value, product.vat);

    const [inputValue, setInputValue] = useState<number>(0);
    //const [inputValue, debouncedValue, setInputValue] = useDebounce(flexProductPrice?.value, 1);

    useEffect(() => {
        setInputValue(flexProductPrice?.value || 0)
    }, [flexProductPrice?.value]);

    const handleInputChange = (value: number) => {
        if (product.id) {
            let updateOldProductPrice: ProductPriceUpdateWithWhereUniqueWithoutProductInput | null = null;
            if (flexProductPrice) {
                updateOldProductPrice = {
                    where: {id: flexProductPrice.id},
                    data: {
                        endDate: asIso8601(TODAY),
                    }
                };
            }

            // let defaultClientGroup = findDefaultClientGroup(clientGroupData?.clientGroups);
            let createProductPrice: ProductPriceCreateWithoutProductInput | undefined = undefined;

            createProductPrice = {
                value: value || 0,
                base: false,
                startDate: asIso8601(TODAY),
                clientGroup: {connect: {code: flexClientGroupCode}}
            };
            let variables: UpdateProductVariables = {
                id: props.product.id,
                data: {
                    productPrices: {
                        update: compact([updateOldProductPrice]),
                        create: compact([createProductPrice])
                    },
                }
            };
            updateProduct({variables, refetchQueries: "active"});
        }
    };

    return <div>
        <div>punten</div>
        <div>{suggestedPoints} pt.</div>
        <div className="p-inputgroup">
            <InputNumber
                value={inputValue}
                mode="decimal"
                locale={'nl-NL'}
                step={0.5}
                maxFractionDigits={1}
                minFractionDigits={0}
                onChange={(e: any) => {
                    setInputValue(e.value)
                }}
            />
            <Button
                icon="pi pi-check"
                onClick={() => {

                handleInputChange(inputValue)
            }}>

            </Button>
        </div>
    </div>;
};

export default PointsPrice;

