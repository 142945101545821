import React, {ChangeEvent, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from 'primereact/column';
import {iso8601_DdMmYyyy} from "../../../shared/utils/date.utils";
import {formatCurrency} from "../../../shared/utils/currency.utils";
import {ClientsQuery} from "../../../shared/queries/client.gql";
import {useQuery} from "@apollo/client";
import {
  BankTransactions,
  BankTransactions_bankTransactions,
  Clients,
  Clients_clients, ClientsVariables
} from "../../../__generated__/types";
import {compact, find} from "lodash";
import {BankTransactionsQuery} from "../../../shared/queries/bankTransaction.gql";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {FilterMatchMode} from "primereact/api";
import AddIbanNumberToClientButton from './AddIbanNumberToClientButton';
import {useUserObject} from "../../../shared/context/UserContext";

const NewTransactionsList = () => {
  const {data: bankTransactionsData} = useQuery<BankTransactions>(BankTransactionsQuery);
  const {activeFarm} = useUserObject();
  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data: clientsData} = useQuery<Clients>(ClientsQuery, {variables});

  const clients: Clients_clients[] = compact(clientsData?.clients);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>();
  const [filters, setFilters] = useState<{ [key: string]: { value: null | any, matchMode: FilterMatchMode } }>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  });

  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = () => {
    return <div className="p-0 m-0 surface-0 border-0">
      <Toolbar
        className="p-toolbar p-component p-0 m-0 surface-0 border-0"
        end={() => {
        }}
        start={<div>
          <span className="p-input-icon-left">
              <i className="pi pi-search"/>
              <InputText type="search" value={globalFilterValue || ''} onChange={onGlobalFilterChange}
                         placeholder="Search..."/>
          </span>
        </div>
        }
      />
    </div>;
  };

  let allBankTransactions = compact(bankTransactionsData?.bankTransactions);
  return <DataTable<BankTransactions_bankTransactions[]>
    dataKey="id"
    globalFilter={globalFilterValue}
    globalFilterFields={["date", "name", "description", "ibanNumber"]}
    scrollable
    sortMode={'multiple'}
    multiSortMeta={[
      {field: 'date', order: -1},
    ]}
    header={header}
    scrollHeight={'70vh'}
    sortOrder={1}
    value={allBankTransactions}
    paginator rows={100} rowsPerPageOptions={[25, 50, 100, 250, 500]}
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    currentPageReportTemplate="Toon {first} tot {last} van {totalRecords} transacties"
  >
    <Column
      header={'Datum'}
      field={'date'}
      sortable
      body={(transaction: BankTransactions_bankTransactions) => iso8601_DdMmYyyy(transaction.date)}
    />
    <Column header="Iban" field={'ibanNumber'} sortable/>
    <Column header="Klant" field={'ibanNumber'} sortable
            body={(transaction: BankTransactions_bankTransactions) => {
              return find(clients, ["id", transaction.client?.id])?.name;
            }}
    />
    <Column header="Bic" field={'bic'}/>
    <Column header="Naam" field={'name'}
            sortable
            body={(transaction: BankTransactions_bankTransactions) => {
              return <div>
                <div>{transaction.name}</div>
                {!transaction.client && <div>
                  <AddIbanNumberToClientButton
                    ibanNumber={transaction.ibanNumber}
                    bankTransactions={allBankTransactions}
                  />
                </div>}
              </div>;
            }}
    />
    <Column header="Adres" field={'address'}/>
    <Column
      sortable
      header={'bedrag'}
      field={'amount'}
      body={(transaction: BankTransactions_bankTransactions) => formatCurrency(transaction.amount)}
    />
    <Column header="Omschrijving" field={'description'}/>

  </DataTable>;
};

export default NewTransactionsList;
