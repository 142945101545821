import React, {useState} from 'react';
import {Button} from "primereact/button";
import moment, {Moment} from "moment";
import {Calendar} from "primereact/calendar";
import {useMutation} from "@apollo/client";
import {
  ClientOrder_clientOrder,
  ClientOrderUpdate,
  ClientOrderUpdateVariables
} from "../../../../../../__generated__/types";
import {ClientOrderUpdateMutation} from "../../../../../../shared/queries/clientOrder.gql";
import {asIso8601, momentFromDdMmYyyy} from "../../../../../../shared/utils/date.utils";

interface ChangeDeliveryEndDateButtonProps {
  clientOrder: ClientOrder_clientOrder;
}

const ChangeDeliveryEndDateButton = (props: ChangeDeliveryEndDateButtonProps) => {
  const [clientOrderUpdate] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation);

  const [updatedEndDate, setUpdatedEndDate] = useState<Moment | undefined>();

  let onSave = () => {
    if (updatedEndDate) {
      const variables: ClientOrderUpdateVariables = {
        where: {id: props.clientOrder.id},
        data: {
          deliveryEndDate: asIso8601(updatedEndDate)
        },
      };
      clientOrderUpdate({variables});
    }
  };

  return <div className="border-1 border-300 surface-50 border-round p-2 px-4 my-2">
    <div className="flex align-items-center justify-content-between flex-wrap">
      <div className="text-xl font-medium">Wijzig de einddatum</div>
      <div className="flex align-items-center py-2">
        <div>Nieuwe einddatum:</div>
        <Calendar
          value={updatedEndDate ? updatedEndDate.toDate() : null}
          className="mx-2"
          inputClassName="p-dense"
          locale={'nl'}
          dateFormat={"dd-mm-yy"}
          showWeek
          onChange={(e) => {
            setUpdatedEndDate(momentFromDdMmYyyy(e.target.value));
          }}
        />
        <Button
          label={'Wijzig einddatum'}
          className="p-dense p-button-outlined"
          disabled={updatedEndDate === undefined}
          onClick={() => onSave()}
        />
      </div>
    </div>
  </div>;
};

export default ChangeDeliveryEndDateButton;
