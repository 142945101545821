import gql from "graphql-tag";
import { ProductFields_basicFragment } from "shared/queries/product.gql";

export const RevenueQuery = gql`
${ProductFields_basicFragment}
query Revenue {
  pakketten: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "pakketten-2023", code_not_contains: "winter"}
  }) {
    priceExcl
    product {...ProductFields_basic name vat}
  }

  winterpakketten: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    createdAt_gt: "2023-01-01"
    product: {code_starts_with: "pakketten-2023", code_contains: "winter"}
  }) {
    priceExcl
    product {...ProductFields_basic name vat}
  }

  zelfoogst: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "zelfoogst-2023"}
  }) {
    priceExcl
    product {...ProductFields_basic name vat}
  }

  ambassadeur: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "ambassadeur-"}
  }) {
    priceExcl
    product {...ProductFields_basic name vat}
  }

  eieren: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "eieren-" type: SUBSCRIPTION}
  }) {
    priceExcl
    ambassadorDiscount
    product {...ProductFields_basic name vat}
  }

  uien: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "ui-" type: SUBSCRIPTION}
  }) {
    priceExcl
    ambassadorDiscount
    product {...ProductFields_basic name vat}
  }

  aardappelen: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "aardappel-" type: SUBSCRIPTION}
  }) {
    priceExcl
    product {...ProductFields_basic name vat}
  }

  bloemen: productOrders(where: {
    clientOrder: {client: {email_not_in: ["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"]}}
    product: {code_starts_with: "bloemen-" type: SUBSCRIPTION}
  }) {
    priceExcl
    ambassadorDiscount
    product {...ProductFields_basic name vat}
  }
}`;
