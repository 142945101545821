import React, {memo, useState} from 'react';
import {
  ClientOrder_clientOrder,
  ClientOrder_clientOrder_invoice,
  DeleteInvoice
} from "../../../__generated__/types";

import {compact, sortBy, sum} from "lodash";
import {Column} from "primereact/column";
import {createdAtColumn} from "../../../components/columns";
import {formatCurrency} from "../../../shared/utils/currency.utils";
import {Button} from "primereact/button";
import {confirmPopup} from "primereact/confirmpopup";
import {useMutation} from "@apollo/client";
import {PDFDownloadLink} from "@react-pdf/renderer";
import ClientOrderInvoicePdf from "../../../shared/pdf/ClientOrderInvoicePdf";
import {ClientOrderInvoicePdfData} from "../../../shared/utils/invoice.utils";
import {DataTable} from "primereact/datatable";
import UpdateInvoiceDialog from './CreateInvoiceDialog/UpdateInvoiceDialog';
import InvoicePayments from "./InvoicePayments";
import {useUserObject} from "../../../shared/context/UserContext";
import PdfDialog from 'shared/pdf/PdfDialog';
import { DeleteInvoiceMutation } from 'shared/queries/invoice.gql';

interface InvoiceTableProps {
  clientOrder: ClientOrder_clientOrder;

}
export const DownloadClientOrderPdf = memo((props: { data: ClientOrderInvoicePdfData, farm: {code: string, name: string}; }) => {
  return (
    <PDFDownloadLink style={{color: 'black'}} document={<ClientOrderInvoicePdf farm={props.farm} data={props.data}/>}
                     fileName={props.data.filename}>
      {() => <Button icon={'pi pi-download'} className={'p-button-icon'}/>}
    </PDFDownloadLink>
  );
}, (prevProps, nextProps) => {
  return prevProps.data.filename === nextProps.data.filename
});

const InvoiceTable = (props: InvoiceTableProps) => {
  const {activeFarm} = useUserObject();
  const [deleteInvoice] = useMutation<DeleteInvoice>(DeleteInvoiceMutation, {
    refetchQueries: "active",
  });

  const [showPdfFor, setShowPdfFor] = useState<ClientOrder_clientOrder_invoice>();
  const [invoiceToUpdate, setInvoiceToUpdate] = useState<string>();

  const invoices = compact([props.clientOrder.invoice]);

  return <>
    {invoiceToUpdate && <UpdateInvoiceDialog
      onHide={() => setInvoiceToUpdate(undefined)}
      clientOrderId={props.clientOrder.id}
    />}

    {activeFarm && showPdfFor && !showPdfFor.file && <PdfDialog onHide={() => setShowPdfFor(undefined)}>
      <ClientOrderInvoicePdf data={showPdfFor} farm={activeFarm}/>
    </PdfDialog>}
    <DataTable
      className={"sf-table"}
      value={sortBy(invoices, ['createdAt'])}
      rowClassName={(invoice: ClientOrder_clientOrder_invoice) => {
        return {
          //paid: sum((invoice.payments || []).map(p => p.amount)) >= invoice.data.totalIncl,
          topAlign: true,
        };
      }}
      //autoLayout
      style={{minWidth: "auto"}}
    >
      <Column header={'Datum'} field={'createdAt'}
              body={createdAtColumn} style={{maxWidth: "100px"}}/>
      <Column header={'Nummer'} field={'number'} style={{maxWidth: "100px"}}/>
      <Column header={'Bedrag'}
              body={(i: ClientOrder_clientOrder_invoice) => formatCurrency(sum((i.payments || []).map(p => p.amount)))}
              style={{maxWidth: "120px"}}
      />
      <Column header={'Vervaldatum'}
              style={{maxWidth: "220px"}}
              body={(invoice: ClientOrder_clientOrder_invoice) => <InvoicePayments clientOrder={props.clientOrder} invoice={invoice}/>}
      />
      <Column body={(i: ClientOrder_clientOrder_invoice) => {
        return <div className="w-full flex flex-wrap justify-content-end gap-1">
          {!i.file && <Button icon={'pi pi-search'} className={'p-button-icon'} onClick={() => {
            setShowPdfFor(i);
          }}/>}
          {activeFarm && !i.file && <DownloadClientOrderPdf data={i} farm={activeFarm}/>}
          {activeFarm && i.file && <a href={i.file} target={'_blank'}>Bekijk</a>}
          {!i.file && <Button icon={'pi pi-refresh'} className={'p-button-icon'}
                   onClick={() => {
                     setInvoiceToUpdate(i.id);
                   }}/>}
          <Button icon={'pi pi-trash'} className={'p-button-icon'}
                  onClick={(event) => {
                    confirmPopup({
                      target: event.currentTarget,
                      message: 'Ben je zeker dat je deze factuur wil verwijderen',
                      icon: 'pi pi-exclamation-triangle',
                      acceptClassName: 'p-button-danger',
                      acceptLabel: 'Ja',
                      rejectLabel: 'Nee',
                      accept: () => deleteInvoice({
                        variables: {
                          id: i.id,
                        }
                      }).then(() => {
                      }),
                    });
                  }}/>
        </div>;
      }

      }/>
    </DataTable>
  </>;
};

export default InvoiceTable;
