import React from 'react';
import {compact} from "lodash";
import {useQuery} from "@apollo/client";
import {FarmConfiguration} from "../../../__generated__/types";
import {FarmConfigurationQuery} from "../farm.gql";
import FarmConfigValue from './FarmConfigValue';

interface FarmConfigViewerProps {
  farmId: string;
}

const FarmConfigViewer = (props: FarmConfigViewerProps) => {
  const {data} = useQuery<FarmConfiguration>(FarmConfigurationQuery, {variables: {id: props.farmId}});

  return <div className="grid">
    {compact(data?.farm?.configuration).map(config =>
      <FarmConfigValue item={config}/>)}

    <FarmConfigValue/>
  </div>;
};

export default FarmConfigViewer;
