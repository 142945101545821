import React, {useEffect, useState} from 'react';
import ProductAutoComplete from "../../../../components/ProductAutoComplete";
import {useSales} from "../../sales-context";
import {compact, get, keyBy, remove, sortBy, values} from 'lodash';
import {
  Products,
  ProductStatus,
  ProductType,
  SalesData_clientGroups,
  SalesData_clientOrders_productOrders,
  SalesData_products,
  Vat
} from "../../../../__generated__/types";
import {formatCurrency, formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {Column} from "primereact/column";
import {translatedUnit} from "../../../../shared/utils/unit.utils";
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from "primereact/row";
import {useQuery} from "@apollo/client";
import {ProductsQuery} from "../../../../shared/queries/product.gql";
import {asYyyyMmDd} from "../../../../shared/utils/date.utils";
import moment from "moment";
import {vat} from "../../../../shared/utils/vat.utils";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import {DataTable} from "primereact/datatable";
import {clientOrderTotals} from "../../../../utils/clientOrder.utils";
import {
  findProductPriceForClientGroup,
  productIsAvailableAsAlternativeUnitFor
} from "../../../../shared/utils/product.utils";
import {findAmbassadorDiscountProductOrderForProduct} from "../../../../shared/utils/productOrder.utils";
import ProductQuantityInput from "../../../../shared/components/ProductQuantityInput";
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import {useUserObject} from "../../../../shared/context/UserContext";

interface ProductOrder {
  id: string;
  product: {
    id: string;
    vat: Vat;
    availableAsAlternativeUnitFor: string[] | null;
    avgWeight: number | null
  } | null;
  productDescription: string | null;
  priceExcl: number;
  quantity: number;
  remark: string | null;
  ambassadorDiscount: boolean;
}

interface ProductOrderListProps {
  clientGroup: SalesData_clientGroups | undefined;
  productOrders: ProductOrder[];
  onChange: (productOrders: ProductOrder[]) => void;
  disabled: boolean;
  orderDiscount?: number | null;
}

const NEW_PRODUCT_ORDER = {id: 'new'};

const ProductOrderList = (props: ProductOrderListProps) => {
  const {activeFarm} = useUserObject();
  const {products} = useSales();
  const [activeProductOrder, setActiveProductOrder] = useState<Partial<ProductOrder>>(NEW_PRODUCT_ORDER);
  const [showCustomProductInput, setShowCustomProductInput] = useState<boolean>(false);
  const [productOrders, setProductOrders] = useState<ProductOrder[]>([]);
  const [editingRows, setEditingRows] = useState<any>({new: true});
  const {data: allProductsData} = useQuery<Products>(ProductsQuery, {variables: {promoEndDate: asYyyyMmDd(moment()), farmId: activeFarm?.id||''}});

  useEffect(() => {
    setProductOrders(props.productOrders);
  }, [props.productOrders]);

  // useEffect(() => {
  //   setProductOrders(state => {
  //     if(activeProductOrder.id) {
  //       const idx = state.findIndex(po => po.id === activeProductOrder.id);
  //       return set(state, `[${idx}]`, activeProductOrder);
  //     }
  //     return state;
  //   });
  // }, [activeProductOrder]);

  useEffect(() => {
    if (values(editingRows).length === 0) {
      setEditingRows({new: true});
    }
  }, [editingRows]);

  useEffect(() => {
    setActiveProductOrder(state => {
      const product = state.product && products[state.product.id];
      const productPriceForClientGroup = compact(compact(allProductsData?.products).find(p => p.id === product?.id)?.productPrices)
        .find(price => price.clientGroup?.id === props.clientGroup?.id);

      const priceExcl = (productPriceForClientGroup?.value && state.quantity) ? (productPriceForClientGroup.value * state.quantity) : 0;
      return ({
        ...state,
        priceExcl: state.priceExcl ? priceExcl : undefined,
      });
    })
  }, [props.clientGroup, products, allProductsData]);


  const totals = clientOrderTotals({
    orderDiscount: props.orderDiscount,
    productOrders: [...productOrders, activeProductOrder]
  });

  const footerGroup = <ColumnGroup>
    <Row>
      <Column
        footer={<div>
          <div>Subtotaal:</div>
          <div>Korting:</div>
          <div>Totaal:</div>
        </div>
        }
        colSpan={3}
        footerStyle={{textAlign: 'right'}}
      />
      <Column
        footer={<div>
          <div>{formatCurrency(totals.excl.subTotal)}</div>
          <div>{formatCurrency(totals.excl.orderDiscount)}</div>
          <div>{formatCurrency(totals.excl.total)}</div>
        </div>}
      />
      <Column
        footer={<div>
          <div>{formatCurrency(totals.incl.subTotal)}</div>
          <div>{formatCurrency(totals.incl.orderDiscount)}</div>
          <div>{formatCurrency(totals.incl.total)}</div>
        </div>}
      />
      {/*<Column*/}
      {/*  footer={formatCurrency(sum(props.productOrders.map((productOrder: ProductOrder) => {*/}

      {/*    let productOrderToUse = productOrder;*/}
      {/*    if (activeProductOrder && activeProductOrder.id === productOrder.id) {*/}
      {/*      productOrderToUse = activeProductOrder as SalesData_clientOrders_productOrders;*/}
      {/*    }*/}
      {/*    if (productOrderToUse.product) {*/}
      {/*      const vatValue = products[productOrderToUse.product.id]?.vat;*/}
      {/*      return vatValue ? productOrder.priceExcl * (1 - (props.orderDiscount || 0)) * (1 + vat(vatValue)) : 0;*/}
      {/*    }*/}
      {/*    return 0;*/}
      {/*  })))}/>*/}
    </Row>
  </ColumnGroup>;

  let productOrdersForTable = productOrders.filter(productOrder => !get(productOrder, 'ambassadorDiscount', false));
  return <div>
    <DataTable<Partial<ProductOrder>[]>
      className={"sf-table"}
      value={props.disabled
        ? [...productOrdersForTable]
        : [...productOrdersForTable, NEW_PRODUCT_ORDER]
      }
      editMode={props.disabled ? undefined : 'row'}
      footerColumnGroup={footerGroup}
      dataKey={'id'}
      //autoLayout
      editingRows={editingRows}
      onRowEditChange={() => {
        // KEEP ME: needs to exist, no implementation needed
      }}
      onRowEditSave={(e) => {
        const tempId = activeProductOrder.id === 'new' ? 'new_' + productOrders.length : e.data.id;
        const updatedProductOrders = {
          ...keyBy(productOrders, 'id'),
          [tempId]: {...activeProductOrder as SalesData_clientOrders_productOrders, id: tempId}
        };
        setProductOrders(values(updatedProductOrders));
        setActiveProductOrder(NEW_PRODUCT_ORDER);
        props.onChange(values(updatedProductOrders));
        setEditingRows({'new': true});
      }}
      onRowEditInit={(e) => {
        setEditingRows({[e.data.id]: true});
        setActiveProductOrder(e.data);
      }}
      onRowEditCancel={() => {
        setEditingRows({'new': true});
        setActiveProductOrder(NEW_PRODUCT_ORDER);
      }}
    >
      <Column
        header={'Product'}
        field={'product.id'}
        body={(productOrder: SalesData_clientOrders_productOrders) => {
          if (productOrder.product) {
            const item = products[productOrder.product.id];
            return <div>
              <div>{productOrder.ambassadorDiscount && 'Ambassadeurskorting: '}{item?.name}</div>
              <div className="color-orange text-xs">{item?.extra}</div>
            </div>;
          }
        }}
        editor={(columnProps) => {
          let productId = activeProductOrder.product?.id || columnProps.rowData.product?.id
          let product = products[productId];

          return <div>
            {!showCustomProductInput && <>
              <div className="p-fluid">
                <ProductAutoComplete
                  onChange={(product) => {
                    const productOrder = activeProductOrder;

                    const productPriceForClientGroup = compact(compact(allProductsData?.products).find(p => p.id === product?.id)?.productPrices)
                      .find(price => price.clientGroup?.id === props.clientGroup?.id);


                    const priceExcl = (productPriceForClientGroup?.value && productOrder.quantity) ? (productPriceForClientGroup.value * productOrder.quantity) : 0;
                    setActiveProductOrder(state => ({
                      ...state,
                      product: products[product.id],
                      priceExcl
                    }));
                  }}
                  products={sortBy(
                    values(products)
                      .filter(product => product.status === ProductStatus.ACTIVE && product.type === ProductType.SIMPLE),
                    'name')}
                  value={product}
                />
              </div>
              <div className="flex align-items-center justify-content-start mt-1">
                <Button
                  link
                  text
                  icon="pi pi-plus"
                  className="p-button-dense p-0"
                  label="Andere"
                  onClick={() => {
                    setShowCustomProductInput(true);
                  }}/>
              </div>
            </>}
            {showCustomProductInput && <div>
              <InputText
                value={activeProductOrder.productDescription || undefined}
                onChange={(e) => {
                  let productDescription = e.target.value;
                  setActiveProductOrder(state => ({
                    ...state,
                    productDescription,
                    priceExcl: NaN
                  }))
                }}
              />
            </div>}
          </div>
        }}
      />
      <Column
        header={'Eenheid'}
        body={(productOrder: SalesData_clientOrders_productOrders) => {
          let productOrderToUse = productOrder;
          if (activeProductOrder && activeProductOrder.id === productOrder.id) {
            productOrderToUse = activeProductOrder as SalesData_clientOrders_productOrders;
          }
          if (productOrderToUse.product && products[productOrderToUse.product.id]) {
            const {amount, unit, avgWeight} = products[productOrderToUse.product.id];
            if (avgWeight && amount && productIsAvailableAsAlternativeUnitFor(productOrderToUse.product, props.clientGroup?.id)) {
              return <div>1 {translatedUnit(productOrderToUse.product.alternativeUnit)}</div>;
            }

            return <div>{amount} {translatedUnit(unit)}</div>;
          }
        }}/>
      <Column
        header={'Aantal'}
        field={'quantity'}
        body={(productOrder: SalesData_clientOrders_productOrders) => {
          if (productOrder.product && products[productOrder.product.id]) {
            const {avgWeight} = products[productOrder.product.id];
            if (avgWeight && productIsAvailableAsAlternativeUnitFor(productOrder.product, props.clientGroup?.id)) {
              return <div>{formatDoubleDigit(productOrder.quantity / avgWeight)}</div>;
            }
            return <div>
              {formatDoubleDigit(productOrder.quantity)}
            </div>
          }
        }}
        editor={(options) => {
          let productOrder;
          let quantity;
          if (activeProductOrder.id === options.rowData.id && activeProductOrder.product) {
            productOrder = activeProductOrder;
          } else if (options.rowData.product) {
            productOrder = options.rowData;
          }
          quantity = productOrder?.quantity;

          if (productOrder?.product && products[productOrder.product.id]) {
            const {avgWeight} = products[productOrder.product.id];
            if (avgWeight && productIsAvailableAsAlternativeUnitFor(productOrder.product, props.clientGroup?.id)) {
              quantity = quantity / avgWeight
            }
          }

          let product: SalesData_products | undefined = undefined;
          if (activeProductOrder.product) {
            product = products[activeProductOrder.product.id];
          }
          if (product) {
            return <ProductQuantityInput
              value={quantity}
              allowNegative
              product={product}
              clientGroupId={props.clientGroup?.id}
              hideSaveButton
              onChange={value => {
                let productPriceForClientGroup = findProductPriceForClientGroup(product, props.clientGroup?.id);

                //TODO use promo price if present

                const priceExcl = (productPriceForClientGroup && value) ? (productPriceForClientGroup.value * value) : 0;
                setActiveProductOrder(state => ({
                  ...state,
                  quantity: value || 0,
                  priceExcl
                }))
              }}
            />;
          }
        }}
      />
      <Column
        header={'Prijs excl. btw'}
        field={'priceExcl'}
        body={(productOrder: SalesData_clientOrders_productOrders) => {
          let productOrderToUse: SalesData_clientOrders_productOrders = productOrder;
          if (activeProductOrder && activeProductOrder.id === productOrder.id) {
            productOrderToUse = activeProductOrder as SalesData_clientOrders_productOrders;
          }

          let discountProductOrder = findAmbassadorDiscountProductOrderForProduct(productOrders, productOrderToUse.product?.id);

          let value = productOrderToUse.priceExcl;

          if (productOrderToUse.priceExcl > 0) {
            value = productOrderToUse.priceExcl * (1 - (props.orderDiscount || 0));
          }

          return <div>
            {formatCurrency(value)} <br/>
            {discountProductOrder && formatCurrency(discountProductOrder.priceExcl)}
          </div>
        }}
      />
      <Column
        header={'Prijs incl. btw'}
        field={'priceIncl'}
        body={(productOrder: ProductOrder) => {
          let productOrderToUse = productOrder;
          if (activeProductOrder && activeProductOrder.id === productOrder.id) {
            productOrderToUse = activeProductOrder as ProductOrder;
          }
          if (productOrderToUse.product) {
            const vatValue = products[productOrderToUse.product.id]?.vat;

            let discountProductOrderExcl = productOrders
              .find(possibleDiscountProductOrder =>
                possibleDiscountProductOrder.product?.id === productOrder.product?.id
                && get(possibleDiscountProductOrder, 'ambassadorDiscount')
              )?.priceExcl || 0;
            let value = (productOrderToUse.priceExcl + discountProductOrderExcl) * (1 + vat(vatValue));
            if (productOrderToUse.priceExcl > 0) {
              value = (productOrderToUse.priceExcl + discountProductOrderExcl) * (1 - (props.orderDiscount || 0)) * (1 + vat(vatValue));
            }
            return <div>
              {vatValue && formatCurrency(value)}
            </div>;
          }
        }}
        editor={({rowData}) => {
          let productOrderToUse = rowData.productOrder || activeProductOrder;
          if (productOrderToUse.product) {
            const vatValue = products[productOrderToUse.product.id]?.vat;
            let value = productOrderToUse.priceExcl * (1 + vat(vatValue));
            if (productOrderToUse.priceExcl > 0) {
              value = productOrderToUse.priceExcl * (1 - (props.orderDiscount || 0)) * (1 + vat(vatValue));
            }
            return <div>
              {vatValue && formatCurrency(value)}
            </div>;
          }
        }}
      />
      {!props.disabled &&
        <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
      }
      {!props.disabled &&
        <Column body={(deletedProductOrder: SalesData_clientOrders_productOrders) => {
          return <>
            <ConfirmPopup/>
            <i className={'pi pi-trash'}
               onClick={(e) => {
                 confirmPopup({
                   target: e.currentTarget,
                   rejectLabel: "Nee",
                   acceptLabel: "Ja",
                   message: 'Ben je zeker dat je dit product wil verwijderen?',
                   icon: 'pi pi-exclamation-triangle',
                   accept: () => {
                     if (activeProductOrder.id === deletedProductOrder.id) {
                       setActiveProductOrder({});
                     }

                     let updatedProductOrders = [...productOrders];
                     remove(updatedProductOrders, (po) => {
                       return po.id === deletedProductOrder.id
                     });

                     props.onChange(updatedProductOrders);
                   },
                   reject: () => {
                   }
                 });
               }}
            /></>;
        }}/>
      }
    </DataTable>
  </div>;
};

export default ProductOrderList;
