import React from 'react';
import {Button} from "primereact/button";

interface ClientInvoicesLinkProps {
 clientId: string;
}

const ClientInvoicesLink = (props: ClientInvoicesLinkProps) => {
  return <Button
    text
    link
    type="button"
    icon="pi pi-external-link"
    className="p-button-dense"
    label="Ga naar factuuroverzicht"
    onClick={() => {
      window.open(`http://shop.groentegeweld.be/clientsBilling?client=${props.clientId}`, "_blank", "noreferrer");
    }}
  />;
};

export default ClientInvoicesLink;
