const prod = {
  environment: "production",
  graphQLHost: 'https://api.groentegeweld.be/graphql',
  redirectUrl: 'https://shop.groentegeweld.be/',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkZWZhdWx0QGRlZmF1bHQiLCJyb2xlcyI6WyJhZG1pbiJdfSwiaWF0IjoxNjE4ODY2ODcwLCJleHAiOjE2MTk0NzE2NzB9.vVhnXu0evyhIjZU1NWFYXMS0vp4PbCq026tJmAQ6SqI',
};

const dev = {
  environment: "development",
  // graphQLHost: 'http://localhost:4466',
  // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkZWZhdWx0QGRlZmF1bHQiLCJyb2xlcyI6WyJhZG1pbiJdfSwiaWF0IjoxNjE4ODY2ODcwLCJleHAiOjE2MTk0NzE2NzB9.vVhnXu0evyhIjZU1NWFYXMS0vp4PbCq026tJmAQ6SqI',
  token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkZWZhdWx0QGRlZmF1bHQiLCJyb2xlcyI6WyJhZG1pbiJdfSwiaWF0IjoxNjE4ODY2ODcwLCJleHAiOjE2MTk0NzE2NzB9.vVhnXu0evyhIjZU1NWFYXMS0vp4PbCq026tJmAQ6SqI',
  graphQLHost: 'https://api.groentegeweld.be/graphql',
  //graphQLHost: 'https://data.groentegeweld.be/',
  redirectUrl: 'http://localhost:3001/',
};

const appConfig = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export const PROMOCODE = 'TOV2023';

export default appConfig;
