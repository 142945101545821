import React, {useEffect, useState} from 'react';
import {chain, first, times} from "lodash";
import {findBasketProductOrder, findBoxProductOrder, hasUpcomingDelivery} from 'shared/utils/productOrder.utils';
import {SubscriptionClientOrders} from "../SubscriptionTable/SubscriptionTable";
import LabelPdf, {LabelData} from "./components/LabelPdf";
import PdfDialog from 'shared/pdf/PdfDialog';

interface LabelsDialogProps {
  clientOrders: SubscriptionClientOrders[];
  onHide: () => void;
}

const LabelsDialog = (props: LabelsDialogProps) => {
  const [labels, setLabels] = useState<LabelData[]>([]);

  useEffect(() => {
    const filtered = chain(props.clientOrders)
      .filter(clientOrder => {
        const basketProductOrder = findBasketProductOrder(clientOrder.productOrders);
        return basketProductOrder ? hasUpcomingDelivery(basketProductOrder) : false;
      })
      .map(clientOrder => {
        let basketProductOrder = findBasketProductOrder(clientOrder.productOrders);
        const deliveryLocation = first(basketProductOrder?.deliveries)?.deliveryLocation;
        let boxProductOrder = findBoxProductOrder(clientOrder.productOrders);
        let nrOfBoxes = boxProductOrder?.quantity || 1;
        let labelData = {
          type: basketProductOrder?.product?.name,
          name: `${clientOrder.client.firstName} ${clientOrder.client.name}`,
          location: deliveryLocation,
          nrOfBoxes,
        };

        return times(nrOfBoxes, (n) => ({...labelData, boxNumber: n+1}));
      })
      .flatten()
      .orderBy(['location', 'name'], ['asc', 'asc'])
      .value();

    setLabels(filtered);
  }, [props.clientOrders]);

  return <PdfDialog onHide={() => props.onHide()}>
    <LabelPdf labels={labels}/>
  </PdfDialog>;
};

export default LabelsDialog;
