import React from 'react';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from "@david.kucsai/react-pdf-table";
import { InvoiceType } from "../../__generated__/types";
import { styles } from './pdf-styles';
import MontserratMedium from "../../shared/pdf/fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../shared/pdf/fonts/Montserrat-Bold.ttf";

Font.register({family: 'Montserrat', src: MontserratMedium});
Font.register({family: 'Montserrat-Bold', src: MontserratBold});

export interface MembershipInvoicePdfDataProductLine {
  description: string,
  age?: string,
  period?: string,
  priceIncl: string,
  priceExcl: string,
  priceExclRaw: number,
  vatPercentage?: number,
  vat?: number,
  discountExcl?: string,
  discountExclRaw?: number,
}

export interface PdfData {
  type: InvoiceType;
  number: string;
  filename: string;
  data: {
    recipient: {
      name: string,
      businessName: string | null,
      addressLines: string[],
    },
    meta: {
      label: string,
      value: string,
    }[],
    productLines: MembershipInvoicePdfDataProductLine[],
    discount?: string,
    totalExcl: string,
    totalExclRaw: number,
    vatPercentage?: number,
    vat?: string,
    vat6?: string,
    vat21?: string,
    totalIncl: string,
    totalInclRaw: number,
    payments: {
      dueDate: string,
      amount: string,
    }[],
  };
}

interface MembershipInvoicePdfProps {
  data: PdfData;
}

// Create Document Component
const MembershipInvoicePdf = (props: MembershipInvoicePdfProps) => {
  const {data} = props.data;
  const payments = data.payments;
  return (
    <Document title={props.data.filename}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image style={styles.image} source={'logo.png'}/>
          </View>
          <View style={styles.recipient}>
            {/*{data.recipient.businessName && <Text>{data.recipient.businessName}</Text>}*/}
            <Text>{data.recipient.name}</Text>
            {data.recipient.addressLines.map(l => <Text key={l}>{l}</Text>)}
          </View>
        </View>

        <View style={styles.contentWrapper}>
          <Text style={styles.bold}>
            {[InvoiceType.PRO_FORMA_BASKET, InvoiceType.PRO_FORMA_SELF_HARVEST].includes(props.data.type) ? 'PRO FORMA FACTUUR' : 'FACTUUR'}
          </Text>
        </View>

        <View style={styles.contentWrapper}>
          <View style={styles.invoiceMeta}>
            {data.meta.map((m) => <View key={m.label}>
              <Text>{m.label}</Text>
              <Text style={styles.bold}>{m.value}</Text>
            </View>)}
          </View>
          <View style={styles.spacer}/>
        </View>

        <View style={styles.contentWrapper}>
          <Table
            data={data.productLines}
          >
            <TableHeader
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <TableCell style={styles.th} weighting={24}>Omschrijving</TableCell>
              {/*<TableCell style={{...styles.th, ...styles.alignRight}} weighting={4}>Btw</TableCell>*/}
              {/*<TableCell style={{...styles.th, ...styles.alignRight}} weighting={8}>Prijs excl. btw</TableCell>*/}
              <TableCell style={{...styles.th, ...styles.alignRight}} weighting={8}>Prijs incl. btw</TableCell>
            </TableHeader>
            <TableBody
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <DataTableCell style={styles.td} weighting={24} getContent={(r) =>
                <>
                  <Text>{r.description}</Text>
                  {r.details?.map((d:string) => <Text>- {d}</Text>)}
                </>
              }/>
              {/*<DataTableCell style={styles.tdRight} weighting={4} getContent={(r) => r.vatPercentage*100 + '%'}/>*/}
              {/*<DataTableCell style={styles.tdRight} weighting={8} getContent={(r) => r.priceExcl}/>*/}
              <DataTableCell style={styles.tdRight} weighting={8} getContent={(r) => r.priceIncl}/>
            </TableBody>
          </Table>
          {/*<View style={styles.spacer}/>*/}
          {/*<View style={styles.tableSummaryRow}>*/}
          {/*  <Text>Totaal excl. btw</Text>*/}
          {/*  <Text style={styles.tableSummaryRowValue}>{data.totalExcl}</Text>*/}
          {/*</View>*/}
          {/*<View style={styles.tableSummaryRow}>*/}
          {/*  <Text>Btw 6%</Text>*/}
          {/*  <Text style={styles.tableSummaryRowValue}>{data.vat6}</Text>*/}
          {/*</View>*/}
          {/*<View style={styles.tableSummaryRow}>*/}
          {/*  <Text>Btw 21%</Text>*/}
          {/*  <Text style={styles.tableSummaryRowValue}>{data.vat21}</Text>*/}
          {/*</View>*/}
          <View style={styles.spacer}/>
          <View style={styles.tableSummaryRow}>
            <Text>Totaal te betalen</Text>
            <Text style={{...styles.tableSummaryRowValue, ...styles.bold}}>{data.totalIncl}</Text>
          </View>
          <View style={styles.spacer}/>
          <View style={styles.tableSummaryRow}>
            <Text>{payments && payments.length > 1 ? 'Vervaldata' : 'Vervaldatum'}</Text>
            <View style={{...styles.tableSummaryRowValue}}>
              {payments && payments.length > 1 ?
                <Table data={payments}>
                  <TableHeader
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    includeTopBorder={false}
                  >
                    <TableCell style={{...styles.th}} weighting={2}>Datum</TableCell>
                    <TableCell style={{...styles.th, ...styles.alignRight}} weighting={1}>Bedrag</TableCell>
                  </TableHeader>
                  <TableBody
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    includeTopBorder={false}
                  >
                    <DataTableCell style={{...styles.td}} getContent={(r) => r.dueDate}/>
                    <DataTableCell style={{...styles.tdRight}} getContent={(r) => r.amount}/>
                  </TableBody>
                </Table>
                : <Text>{payments && payments[0] && payments[0].dueDate}</Text>}
            </View>
          </View>
          <View style={{
            marginTop: 30,
            color: '#EE7A31',
            fontWeight: "bold",
          }}>
            <Text>Opgelet: Onze bankrekening werd aangepast.</Text>
            <Text>Nieuwe bankrekening: BE46 5230 8118 5136</Text>
          </View>
        </View>

        <View fixed style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 125,
          borderTop: 1,
          borderTopColor: '#748F56',
          borderTopStyle: 'solid',
          color: '#748F56',
          padding: 20,
          fontSize: 9,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
        >
          <View>
            <Text>GROENTEGEWELD</Text>
            <Text>WEELDESTRAAT 168</Text>
            <Text>2381 WEELDE</Text>
            <Text>BTW BE0746.361.154</Text>
          </View>
          <View>
            <Text>info@groentegeweld.be</Text>
            <Text>www.groentegeweld.be</Text>
            <Text>facebook.be/groentegeweld</Text>
            <Text>+32 494 66 43 46 | +32 489 95 51 13‬</Text>
          </View>
          <View>
            <Text>BTW BE0746.361.154</Text>
            <Text>IBAN BE46 5230 8118 5136</Text>
            <Text>BIC: TRIOBEBB</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        {(props.data.type === InvoiceType.SELF_HARVEST_TRIAL || props.data.type === InvoiceType.SELF_HARVEST || props.data.type === InvoiceType.PRO_FORMA_SELF_HARVEST) &&
          <View style={{...styles.contentWrapper, fontSize: 8}}>
            <Text style={{color: '#EE7A31', fontWeight: "bold", fontSize: 8}}>
              Algemene voorwaarden / Engagementsverklaring zelfoogst: Oogstaandeelhouders 2022-2023</Text>
            <View style={styles.spacer}/>
            <Text style={styles.bold}>CSA Groentegeweld</Text>
            <View style={styles.spacer}/>
            <Text>
              De basis van Community Supported Agriculture is dat er goede afspraken gemaakt worden. Deze afspraken
              vormen
              de basis om de boer, de boerderij en de aandeelhouders respectvol te verbinden.
            </Text>
            <View style={styles.spacer}/>
            <Text style={{marginBottom: 4}}>
              1) De boeren van Groentegeweld geven aan wanneer het oogstseizoen van start gaat. Vanaf dit moment mag je
              zelf
              de aangegeven oogstbare groentes komen oogsten. (start normaal gezien op 1 mei, afhankelijk van het
              voorjaar...)
            </Text>
            <Text style={{marginBottom: 4}}>
              2) Inschrijven kan enkel met het hele gezin, je kan dus niet voor één persoon inschrijven wanneer je met
              twee
              of meerdere thuis bent.
            </Text>
            <Text style={{marginBottom: 4}}>
              3) Wanneer je komt oogsten bepaal je zelf (van zonsopgang tot zonsondergang 7 dagen op 7). Enkel
              zondagnamiddag
              is het veld ‘gesloten’. Zo heeft Willem en zijn gezin wat meer privacy in het weekend.
            </Text>
            <Text style={{marginBottom: 4}}>
              4) Met de fiets mag je tot op het veld rijden. Kom je met de auto dan vragen we om de auto aan de straat
              te
              parkeren, let hierbij op de opritten van de nabijgelegen huizen!
            </Text>
            <Text style={{marginBottom: 4}}>
              5) Wat, hoeveel en wanneer je oogst is niet bij alle gewassen precies bepaald. Het gaat om wat je als
              gezin/persoon vers gebruikt. Je volgt de oogstrichtlijnen zo goed als mogelijk op. Deze richtlijnen
              ontvang
              je via mail, via kleur- en naambordjes op het veld of via de informatie in de schuur.
            </Text>
            <Text style={{marginBottom: 4}}>
              6) Voor het oogsten heb je enkel verpakking nodig, een mand of bakjes zijn handig. Mesjes liggen in onze
              oogstbak. Gelieve ze ook netjes terug te leggen.
            </Text>
            <Text style={{marginBottom: 4}}>
              7) Oogstresten mag je ter plaatste (verspreid op het bed als mulchlaag) laten liggen, biologische
              groenteafval
              mag op de composthoop.
            </Text>
            <Text style={{marginBottom: 4}}>
              8) Tijdens het oogsten loop je enkel op de graspaden of de hakselhoutpaden. Op de teeltbedden lopen, mag
              niet.
            </Text>
            <Text style={{marginBottom: 4}}>
              9) De deelnemers blijven aansprakelijk voor zichzelf en hun kinderen.
            </Text>
            <Text style={{marginBottom: 4}}>
              10) Het doorverkopen, doorgeven, of systematisch fout oogsten van producten wordt als een ernstige
              overtreding
              gezien.
            </Text>
            <Text style={{marginBottom: 4}}>
              11) Hamsteren (invriezen, inmaken enz,) kan enkel bij een groene aanduiding.
            </Text>
            <Text style={{marginBottom: 4}}>
              12) Afhankelijk van het jaar en het weer, ziekten en plagen kunnen sommige teelten overvloedige oogst
              geven
              of
              juist volledig mislukken. Zowel het oogstrisico als de overvloed maken deel uit van het
              oogstaandeelhouderschap en worden gedeeld tussen de boeren en oogstaandeelhouders.
            </Text>
            <Text style={{marginBottom: 4}}>
              13) De deelnemers/aandeelhouders in de oogst kunnen tijdens de evaluatievergadering de boekhouding
              inkijken,
              inzage krijgen rond de biocontrole enz..
            </Text>
            <Text style={{marginBottom: 4}}>
              14) Het oogstaandeel wordt voor het volledige gezin/huishouden afgesloten voor een heel jaar. Een
              oogstaandeel
              kan niet worden terugbetaald of vroeger stopgezet.
              Een oogstaandeel wordt betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je
              inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste
              kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of
              Willem.
            </Text>
            <Text style={{marginBottom: 4}}>
              15) De prijs wordt jaarlijks geïndexeerd.
            </Text>
            <Text style={{marginBottom: 4}}>
              16) Deze prijs kan geëvalueerd worden op de evaluatievergadering met de deelnemers.
            </Text>
            <Text>
              17) De boeren kunnen bij overtredingen of vermoeden van het schenden van vertrouwen beslissen om het
              oogstaandeelhouderschap niet te verlengen of zelfs stop te zetten.
            </Text>
            <View style={styles.spacer}/>
            <Text style={styles.bold}>
              CSA Netwerk
            </Text>
            <View style={styles.spacer}/>
            <Text style={{marginBottom: 4}}>
              1) Je hebt als deelnemer toegang tot CSA Groentegeweld (uitgezonderd op zondagnamiddag). Het oogstaandeel
              omvat
              immers ook het ervaren van de omgeving, het beleven van de landbouw en het gebruik van de boerderij als
              gemeenschappelijke tuin.
            </Text>
            <Text style={{marginBottom: 4}}>
              2) Groentegeweld is lid van vzw CSA-netwerk Vlaanderen. Elk natuurlijk persoon of rechtspersoon die een
              oogstaandeel koopt bij een CSA-bedrijf, wordt deelnemer van een lokale afdeling van de vzw. Het
              CSA-bedrijf
              biedt actief mogelijkheden aan zijn oogstaandeelhouders tot de beleving van en de verbinding met de
              seizoenen, het land en de voedselproductie als instrument voor een hernieuwd bewustzijn van de individuele
              connectie met de natuurlijke omgeving.
            </Text>
            <Text>
              3) Als lokale afdeling organiseert Groentegeweld collectieve meehelpdagen en andere activiteiten die
              bijdragen
              tot de cohesie van de deelnemers en de boer binnen het CSA-bedrijf en de ontwikkeling van vaardigheden en
              kennis. Het kan ook gebeuren dat de boer een oproep doet om een acute werkpiek op te vangen. Al deze
              voornoemde momenten zijn vrijblijvend. De deelnemers kunnen hier desgewenst en vrijwillig aan
              participeren.
            </Text>
          </View>}
        {(props.data.type === InvoiceType.BASKET_TRIAL || props.data.type === InvoiceType.BASKET || props.data.type === InvoiceType.PRO_FORMA_BASKET) &&
          <View style={{...styles.contentWrapper, fontSize: 8}}>
            <Text style={{color: '#EE7A31', fontWeight: "bold", fontSize: 8}}>
              Engagementsverklaring pakketleden 2022-2023
            </Text>
            <View style={styles.spacer}/>
            <Text style={styles.bold}>
              CSA Groentegeweld
            </Text>
            <View style={styles.spacer}/>
            <Text>
              De basis van Community Supported Agriculture is dat er goede afspraken gemaakt worden. Deze afspraken
              vormen
              de basis om de boer, de boerderij en de aandeelhouders respectvol te verbinden.
            </Text>
            <View style={styles.spacer}/>
            <Text style={{marginBottom: 4}}>
              1) De boeren van Groentegeweld bepalen wat er in je pakket zit. Op je inschrijvingsformulier kan je één
              groente uitsluiten.
            </Text>
            <Text style={{marginBottom: 4}}>
              2) Afhankelijk van het jaar en het weer, ziekten en plagen kunnen sommige teelten overvloedige oogst geven
              of juist volledig mislukken. Zowel het oogstrisico als de overvloed maken deel uit van het
              oogstaandeelhouderschap en worden gedeeld tussen de boeren en oogstaandeelhouders.
            </Text>
            <Text style={{marginBottom: 4}}>
              3) Het afhalen van de pakketten kan op verschillende plaatsen. Je kiest een afhaalpunt voor het hele seizoen. De exacte afhaaluren worden steeds via de nieuwsbrieven meegedeeld.
              Kom je je pakket in Weelde ophalen met de auto dan vragen we om de auto aan de straat te parkeren, let
              hierbij op de opritten van de nabijgelegen huizen! Met de fiets mag je tot op het veld rijden.
            </Text>
            <Text style={{marginBottom: 4}}>
              4) Als oogstaandeelhouder krijg je twee blauwe bakken in bruikleen. Na het laatste pakket van het seizoen
              verwachten we beide bakken uiterlijk 1 week later op het afhaalpunt van jouw keuze.
            </Text>
            <Text style={{marginBottom: 4}}>
              5) Een handje toesteken mag altijd. Er worden ook meewerkdagen georganiseerd, deelname hieraan is welkom.
            </Text>
            <Text style={{marginBottom: 4}}>
              6) Als oogstaandeelhouder kan je tijdens de evaluatievergadering de boekhouding inkijken, inzage krijgen
              rond de biocontole enz..
            </Text>
            <Text style={{marginBottom: 4}}>
              7) Het oogstaandeel wordt voor het volledige gezin/huishouden afgesloten voor een volledig pakketseizoen.
              Een oogstaandeel kan niet worden terugbetaald of vroeger stopgezet.
            </Text>
            <Text style={{marginBottom: 4}}>
              8) Het wijzigen van een oogstaandeel (van groot naar klein of van klein naar groot) kan niet, tenzij na
              afspraak met Dirk en Willem. Zo garanderen we het beste grondgebruik en het minste voedselverspilling.
            </Text>
            <Text style={{marginBottom: 4}}>
              9) Een oogstaandeel wordt betaald voor eind februari van het nieuwe oogstseizoen (of twee weken na je
              inschrijving indien je later instapt). Op deze manier zijn we zeker van onze inkomsten en zijn de grootste
              kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid betalen? Dit kan, vraag het aan Dirk of
              Willem.
            </Text>
            <Text style={{marginBottom: 4}}>
              10) Het oogstaandeel wordt voor een vastgestelde periode afgesloten en betaald voor eind februari van het
              nieuwe
              oogstseizoen (of twee weken na je inschrijving indien je later instapt). Op deze manier zijn we zeker van
              onze inkomsten en zijn de grootste kosten (van het voorjaar) al zeker gedekt. Toch liever gespreid
              betalen?
              Dit kan, vraag het aan Dirk of Willem.
            </Text>
            <Text style={{marginBottom: 4}}>
              11) De prijs wordt jaarlijks geïndexeerd.
            </Text>
            <Text>
              12) Deze prijs kan geëvalueerd worden op de evaluatievergadering met de deelnemers.
            </Text>
            <View style={styles.spacer}/>
            <Text style={styles.bold}>
              CSA Netwerk
            </Text>
            <View style={styles.spacer}/>
            <Text style={{marginBottom: 4}}>
              1) Je hebt als deelnemer toegang tot CSA Groentegeweld (uitgezonderd op zondagnamiddag). Het oogstaandeel
              omvat immers ook het ervaren van de omgeving, het beleven van de landbouw en het gebruik van de boerderij
              als gemeenschappelijke tuin.
            </Text>
            <Text style={{marginBottom: 4}}>
              2) Groentegeweld is lid van vzw CSA-netwerk Vlaanderen. Elk natuurlijk persoon of rechtspersoon die een
              oogstaandeel koopt bij een CSA-bedrijf, wordt deelnemer van een lokale afdeling van de vzw. Het
              CSA-bedrijf
              biedt actief mogelijkheden aan zijn oogstaandeelhouders tot de beleving van en de verbinding met de
              seizoenen, het land en de voedselproductie als instrument voor een hernieuwd bewustzijn van de individuele
              connectie met de natuurlijke omgeving.
            </Text>
            <Text>
              3) Als lokale afdeling organiseert Groentegeweld collectieve meehelpdagen en andere activiteiten die
              bijdragen tot de cohesie van de deelnemers en de boer binnen het CSA-bedrijf en de ontwikkeling van
              vaardigheden en kennis. Het kan ook gebeuren dat de boer een oproep doet om een acute werkpiek op te
              vangen.
              Al deze voornoemde momenten zijn vrijblijvend. De deelnemers kunnen hier desgewenst en vrijwillig aan
              participeren.
            </Text>
          </View>}
        <View fixed style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 125,
          borderTop: 1,
          borderTopColor: '#748F56',
          borderTopStyle: 'solid',
          color: '#748F56',
          padding: 20,
          fontSize: 9,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
          <View>
            <Text>GROENTEGEWELD</Text>
            <Text>WEELDESTRAAT 168</Text>
            <Text>2381 WEELDE</Text>
            <Text>BTW BE0746.361.154</Text>
          </View>
          <View>
            <Text>info@groentegeweld.be</Text>
            <Text>www.groentegeweld.be</Text>
            <Text>facebook.be/groentegeweld</Text>
            <Text>+32 494 66 43 46 | +32 489 95 51 13‬</Text>
          </View>
          <View>
            <Text>BTW BE0746.361.154</Text>
            <Text>IBAN BE46 5230 8118 5136</Text>
            <Text>BIC: TRIOBEBB</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default MembershipInvoicePdf
