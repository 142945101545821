import React, {useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {ClientOrder_clientOrder, PaymentCreateWithoutInvoiceInput} from "../../../../__generated__/types";
import {productOrdersTotalIncl} from "../../../../utils/productOrder.utils";
import {calculatePayments} from 'pages/sales/utils/invoice.utils';
import moment from "moment";
import {momentFromDdMmYyyy} from "../../../../shared/utils/date.utils";

interface PaymentOptionsSelectionProps {
  clientOrder: ClientOrder_clientOrder;
  onChange: (payments: PaymentCreateWithoutInvoiceInput[]) => void,
}

const PaymentOptionsSelection = (props: PaymentOptionsSelectionProps) => {
  let totalIncl = productOrdersTotalIncl([props.clientOrder]);
  const [paymentOption, setPaymentOption] = useState<number>(1);
  const [prePayment, setPrePayment] = useState<number>(0);
  const [payments, setPayments] = useState<PaymentCreateWithoutInvoiceInput[]>([]);

  let firstPaymentDate = momentFromDdMmYyyy(props.clientOrder.invoice?.data.meta.find((m:{label:string}) => m.label ==='Vervaldatum').value) ||moment().add(1, 'month');

  return <div className="m-2">
    <h3>Betalingsopties</h3>

    <div className="field grid">
      <label htmlFor="nrOfPayments" className="col-3">Betalingswijze</label>
      <div className="col">
        <Dropdown
          value={paymentOption}
          options={[
            {value: 1, label: 'Eenmalig'},
            {value: 2, label: '2-delig'},
            {value: 3, label: '3-delig'},
            {value: 4, label: '4-delig'},
          ]}
          onChange={(e) => {
            const monthsPerPayment = e.value;
            let updatedPrePayment;
            if (monthsPerPayment > 2) {
              updatedPrePayment = Math.ceil(totalIncl * 0.5);
            } else {
              updatedPrePayment = 0;
            }
            setPrePayment(updatedPrePayment);
            let updatedPayments = calculatePayments(monthsPerPayment, totalIncl, updatedPrePayment, props.clientOrder.deliveryDate, firstPaymentDate);

            setPayments(updatedPayments);
            setPaymentOption(monthsPerPayment);

            props.onChange(updatedPayments);
          }}/>
        {/*<input id="nrOfPayments" type="text"*/}
        {/*       className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"/>*/}
      </div>
    </div>
    {paymentOption > 1 && <div className="field grid">
      <label htmlFor="prePayment" className="col-3">Bedrag eerste betaling</label>
      <div className="col">
        <InputNumber
          id={"prePayment"}
          currency={"EUR"}
          locale={'nl-NL'}
          mode={'currency'}
          inputClassName="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"
          value={prePayment}
          onChange={(e) => {
            setPrePayment(e.value || 0);
            let updatedPayments = calculatePayments(paymentOption, totalIncl, e.value || 0, props.clientOrder.deliveryDate, firstPaymentDate);
            setPayments(updatedPayments);
            props.onChange(updatedPayments);
          }}
        />
      </div>
    </div>}
  </div>;
};

export default PaymentOptionsSelection;
