import React from 'react';
import {useClientObject} from "../../ClientContext";
import {compact} from "lodash";
import {useAuth0} from '@auth0/auth0-react';
import {isAdministrator} from "../../shared/utils/user.utils";
import AdministratorDashboard from './Baskets/AdministratorDashboard';
import PageHeading from "../../components/PageHeading";
import {filterClientOrdersByCategory} from "../../utils/clientOrder.utils";
import {useNavigate} from "react-router-dom";

interface StartPageProps {

}

const StartPage = (props: StartPageProps) => {
  const {client} = useClientObject();
  const navigate = useNavigate();
  let {user} = useAuth0();

  let activeClientOrders = compact(client?.clientOrders);
  let basketClientOrders = filterClientOrdersByCategory(activeClientOrders, ['pakketten']);
  let selfHarvestClientOrders = filterClientOrdersByCategory(activeClientOrders, ['zelfoogst']);

  if (basketClientOrders.length > 0) {
    navigate('/mijnpakket');
  }
  console.log('client', client);

  return <div>
    <PageHeading hideBreadCrumb title={'Home'} path={[{label: 'Home', path: '/'}]}/>
    <div className={"grid"}>
      <div className="col-12">
        {/*{isAdministrator(user) && <AdministratorDashboard/>}*/}
        {/*{basketClientOrders.length > 0 && <ClientBasketDashboard clientOrders={basketClientOrders}/>}*/}
      </div>
    </div>
  </div>;
};

export default StartPage;
