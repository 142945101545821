import React from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {useUserObject} from "../../../shared/context/UserContext";
import {FarmConfigurationQuery, FarmConfigurationUpsertMutation} from '../farm.gql';
import {
  FarmConfiguration,
  UpsertFarmConfiguration,
  UpsertFarmConfigurationVariables
} from "../../../__generated__/types";
import {InputSwitch} from "primereact/inputswitch";
import {FarmConfigCodes, getFarmConfig, isEnabled} from 'shared/utils/farmConfig.utils';

interface SelfHarvestManagementProps {

}

const SelfHarvestManagement = (props: SelfHarvestManagementProps) => {
  const {activeFarm} = useUserObject();

  const {data} = useQuery<FarmConfiguration>(FarmConfigurationQuery, {variables: {id: activeFarm?.id || ''}});
  const [updateFarmConfiguration] = useMutation<UpsertFarmConfiguration>(FarmConfigurationUpsertMutation);

  return <div>
    <div>
      <InputSwitch checked={isEnabled(data?.farm?.configuration, FarmConfigCodes.selfHarvest)}
                   onChange={(e) => {
                     if (activeFarm) {
                       let farmConfig = getFarmConfig(data?.farm?.configuration, FarmConfigCodes.selfHarvest);
                       let variables: UpsertFarmConfigurationVariables = {
                         id: farmConfig?.id || '',
                         createData: {
                           code: FarmConfigCodes.selfHarvest.toString(),
                           value: {value: e.value},
                           farm: {
                             connect: {id: activeFarm.id}
                           }
                         },
                         updateData: {
                           value: {value: e.value},
                         }
                       };
                       updateFarmConfiguration({
                         variables: variables,
                         refetchQueries: "active"
                       });
                     }
                   }}
      />
    </div>

    <h1>Zelfoogst</h1>
    <h2>Periode</h2>
    <h2>Tarieven</h2>
    <h2>Extra's</h2>
  </div>;
};

export default SelfHarvestManagement;
