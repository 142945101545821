import React from 'react';
import {Button} from "primereact/button";
import {chain} from "lodash";
import {
    ActiveSubscriptions_clientOrders_productOrders_deliveries,
    DeliveryUpdate,
    DeliveryUpdateVariables
} from "../../../../../__generated__/types";
import {momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {Column} from "primereact/column";
import {useMutation} from "@apollo/client";
import {DeliveryUpdateMutation} from "../../../../../shared/queries/productOrder.gql";
import {SubscriptionClientOrders_productOrders} from "../../SubscriptionTable/SubscriptionTable";

interface MoveToFridayProps {
 productOrders: SubscriptionClientOrders_productOrders[];
}

const MoveToFriday = (props: MoveToFridayProps) => {
    const [updateDelivery] = useMutation<DeliveryUpdate>(DeliveryUpdateMutation);

    return <Column footer={() => {
      return <Button label={'Update leveringen naar vrijdag'} onClick={() => {
          let deliveryDates = chain(props.productOrders)
              .map('deliveries')
              .compact()
              .flatten()
              .filter((delivery: ActiveSubscriptions_clientOrders_productOrders_deliveries) => {
                  return delivery?.deliveryDate === null &&
                      momentFromIso8601(delivery?.plannedDeliveryDate).isoWeekday() === 6;
              })
              .compact()
              .value();

          deliveryDates.forEach(delivery => {
              const variables: DeliveryUpdateVariables = {
                  id: delivery.id,
                  data: {
                      plannedDeliveryDate: momentFromIso8601(delivery.plannedDeliveryDate).isoWeekday(5)
                  }
              };
              updateDelivery({variables});
          });

      }}/>;
  }} colSpan={2}/>;
};

export default MoveToFriday;
