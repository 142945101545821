import React, {useRef, useState} from 'react';
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {useMutation} from "@apollo/client";
import {ClientOrderUpdateMutation} from "../../../../../shared/queries/clientOrder.gql";
import {
  ClientOrderCompletionStatus,
  ClientOrderUpdate,
  ClientOrderUpdateVariables
} from "../../../../../__generated__/types";
import SubscriptionActions from "../../SubscriptionActions/SubscriptionActions";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useClientOrderDeleteMutation} from "../../../../../mutations/client.mutations";

interface RowActionsMenuProps {
  clientOrder: { id: string };
}

const RowActionsMenu = (props: RowActionsMenuProps) => {
  const menuRef = useRef<Menu>(null);
  const [updateClientOrder] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation)
  const [deleteClientOrder] = useClientOrderDeleteMutation();
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

  const registerCompletion = (completionStatus: ClientOrderCompletionStatus) => () => {
    const variables: ClientOrderUpdateVariables = {
      where: {
        id: props.clientOrder.id
      },
      data: {
        completionStatus
      }
    };
    updateClientOrder({variables});
  };

  return <div>
    <Menu model={[
      {
        label: "Bevestig inschrijvingseinde",
        items: [
          {
            label: 'Deelnemer zet verder',
            icon: 'pi pi-heart-fill',
            command: registerCompletion(ClientOrderCompletionStatus.RESUBSCRIBED),

          },
          {
            label: 'Deelnemer stopt',
            icon: 'pi pi-thumbs-down',
            command: registerCompletion(ClientOrderCompletionStatus.UNSUBSCRIBED),
          },
          {
            label: 'Beëindigd door GW',
            icon: 'pi pi-ban',
            command: registerCompletion(ClientOrderCompletionStatus.TERMINATED),
          }
        ]
      },
      {
        label: "Verwijderen",
        command: () => setConfirmDialog(true),
      },
    ]} popup ref={menuRef}/>
    <ConfirmDialog
      visible={confirmDialog}
      header="verwijderen"
      headerClassName="text-900"
      message={'Ben je zeker dat je dit abonnement wil verwijderen?'}
      icon={'pi pi-exclamation-triangle'}
      acceptLabel={'Ja'}
      acceptClassName={'p-button-danger p-button-outlined'}
      rejectLabel={'Nee'}
      rejectIcon="pi pi-trash"
      rejectClassName={'p-button-text p-button-link'}
      reject={() => setConfirmDialog(false)}
      accept={() => {
        deleteClientOrder({
          variables: {
            id: props.clientOrder.id,
          }
        });
      }}
    />
    <Button
      rounded text
      icon="pi pi-ellipsis-v"
      onClick={(event) => menuRef?.current?.toggle(event)}
    />
  </div>;
};

export default RowActionsMenu;
