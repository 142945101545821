import React from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {InvoiceQuery, InvoiceUpdateMutation} from "../../../../../../../shared/queries/invoice.gql";
import {
  Invoice, Invoice_client,
  InvoiceCreateInput,
  InvoiceUpdate, InvoiceUpdateVariables,
  InvoiceVariables, ProductType, ProductUnit, Vat
} from "../../../../../../../__generated__/types";
import DescriptionItem from "../../../../../../../shared/components/DescriptionList/DescriptionItem";
import {iso8601_DdMmYyyy, momentFromIso8601} from "../../../../../../../shared/utils/date.utils";
import {formatCurrency} from "../../../../../../../shared/utils/currency.utils";
import {Button} from "primereact/button";
import {compact, omit, orderBy} from "lodash";
import {productOrdersTotalIncl} from "../../../../../../../utils/productOrder.utils";
import {calculatePayments, createClientOrderInvoiceData} from "../../../../../utils/invoice.utils";

interface InvoiceInformationProps {
  invoiceId: string;
}


const InvoiceInformation = (props: InvoiceInformationProps) => {
  const variables: InvoiceVariables = {id: props.invoiceId};
  const {data, loading} = useQuery<Invoice>(InvoiceQuery, {variables});
  const [invoiceUpdate] = useMutation<InvoiceUpdate>(InvoiceUpdateMutation);

  if (loading) {
    return <div>loading</div>;
  }


  const updateInvoice = (
    invoice: {
      number: string,
      createdAt: string,
      clientOrders: {
        id: string,
        deliveryEndDate: string | null,
        deliveryDate: string,
        orderDiscount: number | null,
        delivery: boolean,
        productOrders: {
          id: string,
          productDescription: string | null,
          priceExcl: number,
          product: {
            name: string,
            unit: ProductUnit | null,
            amount: number | null,
            type: ProductType,
            avgWeight: number | null,
            availableAsAlternativeUnitFor: string[],
            alternativeUnit: ProductUnit | null,
            category?: { code: string | null } | null,
            vat: Vat,
          } | null,
          ambassadorDiscount: boolean,
          quantity: number
          deliveries: any[] | null
        }[] | null,
      }[] | null
    },
    clients: Invoice_client[],
  ) => {
    const clientOrders = compact(invoice.clientOrders);
    if(clients.length === 1) {
      const client = clients[0];
      const clientOrdersForInvoice = orderBy(clientOrders, 'deliveryDate');

      const invoiceNumber = invoice.number;

      let totalIncl = productOrdersTotalIncl(clientOrdersForInvoice);
      let payments = calculatePayments(1, totalIncl, 0, invoice.createdAt, momentFromIso8601(invoice.createdAt));

      const clientOrderInvoicePdfData = createClientOrderInvoiceData(clientOrdersForInvoice, client, invoiceNumber, false, momentFromIso8601(invoice.createdAt), payments);

      const invoiceData: InvoiceCreateInput = {
        ...omit(clientOrderInvoicePdfData, "paymentCreates"),
        payments: clientOrderInvoicePdfData.paymentCreates,
        clientOrders: {
          connect: clientOrdersForInvoice.map(clientOrder => ({id: clientOrder.id}))
        }
      };

      const variables: InvoiceUpdateVariables = {
        data: {
          ...invoiceData
        },
        id: props.invoiceId,
      };

      invoiceUpdate({variables, refetchQueries: "active"});
    } else if (clients.length > 1) {
      console.error("Multiple clients found for invoice");
    } else if (clients.length === 1) {
      console.error("No client found for invoice");
    }
  };


  return <div>
    <div className="font-medium text-2xl mb-3 color-orange uppercase">Factuurgegevens</div>
    <ul className="list-none p-0 m-3">
      <DescriptionItem label={'Nummer'}>{data?.invoice?.number}</DescriptionItem>
      <DescriptionItem label={'Naam'}>{data?.invoice?.filename}</DescriptionItem>
      <DescriptionItem label={'Datum'}>{iso8601_DdMmYyyy(data?.invoice?.createdAt)}</DescriptionItem>
      <DescriptionItem label={'Totaalbedrag'}>
        <div>{formatCurrency(data?.invoice?.data.totalIncl)}</div>
        <div>
          {data?.invoice && <Button
            label={"Herbereken factuur"}
            onClick={() => {
              data?.invoice && updateInvoice(data.invoice, compact(data.client));
            }}
          />}
        </div>
      </DescriptionItem>
    </ul>
  </div>;
};

export default InvoiceInformation;
