import {ClientOrderStatus} from "../../../__generated__/types";
import {compact, first, get} from "lodash";
import React from "react";
import {asDayDMmmYy, iso8601_DdMmYyyy} from "../../../shared/utils/date.utils";
import {formatCurrency, toPercentage} from "../../../shared/utils/currency.utils";
import CreateInvoiceAction from "./CreateInvoiceAction";
import MailButton from "./MailButton";
import {Badge, statusOptions} from "../../sales/OrderManagement/OrderManagement";
import {clientOrderTotalInclWithoutDelivery} from "../../../utils/clientOrder.utils";
import RegisterPaymentButton from "./RegisterPaymentButton";
import CreateAccountButton from "./CreateAccountButton";
import {
  expirationInfoForProductOrder,
  findNextDeliveryDateForBasketProductOrder,
} from "../../../shared/utils/productOrder.utils";
import RowActionsMenu from "./SubscriptionTable/components/RowActionsMenu";
import {SubscriptionClientOrders} from "./SubscriptionTable/SubscriptionTable";

export const ambassadorColumn = (clientOrder: SubscriptionClientOrders) => {
  let ambassadorProduct = compact(clientOrder.productOrders)
    .find(productOrder => productOrder.product?.category?.code === 'ambassadeur');
  return <div>{ambassadorProduct && <img width="20px" src="/extras/ambassador.png"/>}</div>;
};
export const accountColumn = (clientOrder: SubscriptionClientOrders) => {
  return <div>{clientOrder.client.auth0userId && <i className="pi pi-user"/>}</div>;
};

export const remarksColumn = (clientOrder: SubscriptionClientOrders) => {
  return <div>{clientOrder.client.remarks && <i className="pi pi-info"/>}</div>;
};

export const orderDiscountColumn = (clientOrder: SubscriptionClientOrders) => {
  return <div>{clientOrder.orderDiscount && toPercentage(clientOrder.orderDiscount)}</div>;
};

export const statusColumn = (clientOrder: SubscriptionClientOrders) => {
  return <Badge
    className={`status-${clientOrder.status}`}>{get(statusOptions, clientOrder.status)}</Badge>
};

export const nameColumn = (clientOrder: SubscriptionClientOrders) => {
  return <div>
    <div>{clientOrder.client.name} {clientOrder.client.firstName}</div>
    <div className="text-sm">
      <a className="text-500" href={`mailto:${clientOrder.client.email}`}>{clientOrder.client.email}</a>
      {clientOrder.client.secondaryEmail &&
        <div className="text-xs">
          {clientOrder.client.secondaryEmail.split(",").map(email =>
            <div><a className="text-500" href={`mailto:${email}`}>{email}</a></div>
          )}
        </div>
      }
    </div>
  </div>;
};

export const productColumn = (clientOrder: SubscriptionClientOrders) => {
  let mainProduct = (clientOrder.productOrders?.find(po => {
    return po.product?.category?.code && ['zelfoogst', 'pakketten'].indexOf(po.product.category.code) > -1;
  }))?.product;
  let extraProducts = compact(clientOrder.productOrders)?.slice(1);
  return <div>{mainProduct?.name} <span>(+{extraProducts.length} extras)</span></div>;
};
export const deliveryDatesColumn = (clientOrder: { deliveryEndDate: string, deliveryDate: string }) => {
  return <div>
    <div className="flex align-items-center"><span
      className="text-xs text-500 w-2rem text-right pr-1">van:</span> {iso8601_DdMmYyyy(clientOrder.deliveryDate)}</div>
    <div className="flex align-items-center"><span
      className="text-xs text-500 w-2rem text-right pr-1">tot:</span> {iso8601_DdMmYyyy(clientOrder.deliveryEndDate)}
    </div>
  </div>;
};

export const nextDeliveryDateColumn = (clientOrder: SubscriptionClientOrders) => {
  const nextDeliveryDate = findNextDeliveryDateForBasketProductOrder(clientOrder.productOrders);
  if (nextDeliveryDate) {
    return <div>
      <div className="flex align-items-center">{asDayDMmmYy(nextDeliveryDate)}</div>
    </div>;
  }
};

export const totalColumn = (clientOrder: SubscriptionClientOrders) => {
  return formatCurrency(clientOrderTotalInclWithoutDelivery(clientOrder));
};

export const actionsColumn = (clientOrder: SubscriptionClientOrders) => {
  let mainProductOrder = first(clientOrder.productOrders);
  let mainProduct = mainProductOrder?.product;
  //let idxOfTrialText = mainProduct?.name.toLowerCase().indexOf('proef');
  //let needsFollowUp = idxOfTrialText && idxOfTrialText > -1 && clientOrder.status === ClientOrderStatus.INVOICE_SENT;

  const isExpiring = mainProductOrder && expirationInfoForProductOrder(mainProductOrder).undelivered < 2;

  return <div className="flex flex-column ">
    <div className="flex align-items-center justify-content-between">
      <div className="flex ">
        {!clientOrder.client.auth0userId &&
          <CreateAccountButton
            client={clientOrder.client}
            productOrders={compact(clientOrder.productOrders)}
          />}
        {clientOrder.status === ClientOrderStatus.CREATED && <CreateInvoiceAction clientOrderId={clientOrder.id}/>}
      </div>
      <div><RowActionsMenu clientOrder={clientOrder}/></div>
    </div>
    {clientOrder.status === ClientOrderStatus.INVOICED && <MailButton clientOrderId={clientOrder.id}/>}
    {(clientOrder.status === ClientOrderStatus.INVOICE_SENT || clientOrder.status === ClientOrderStatus.PAID_PARTIALLY) &&
      <RegisterPaymentButton clientOrder={clientOrder}/>}
    {/*{isExpiring && <div>Op te volgen voor verlenging</div>}*/}
  </div>;
};
