import React, {Fragment} from 'react';
import classNames from "classnames";

interface PageHeadingProps {
  title: string;
  path: { label: string, path?: string }[];
  hideBreadCrumb?: boolean;
  meta?: {
    label: string,
    value: string,
    icon: string,
    iconColor?: string,
  }[];
}

const PageHeading = (props: PageHeadingProps) => {
  return <div className="surface-0 p-0 border-bottom-1 border-300 pb-2 mb-3">
    {(props.hideBreadCrumb == undefined || !props.hideBreadCrumb) &&
      <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-1 text-xs">
        {props.path.map((path, idx) =>
          <Fragment key={`${path}_${idx}`}>
            <li className={classNames()}>
              <a href={path.path} className={classNames(
                {"no-underline": !path.path},
                {"text-500 line-height-2 cursor-pointer": idx === 0},
                {"text-900 line-height-2": idx > 0}
              )}>{path.label}</a>
            </li>
            {idx < props.path.length - 1 && <li className="">
              <i className="pi pi-angle-right text-500 line-height-2"></i>
            </li>}
          </Fragment>
        )}
      </ul>}
    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
      <div>
        <div className="font-medium text-2xl text-900">{props.title}</div>
        <div className="flex align-items-center text-700 flex-wrap">
          {props.meta && props.meta.map((item, idx) =>
            <div key={`${item.value}_${idx}`} className={classNames(
              "flex align-items-center mt-1",
              {"mr-5": props.meta && idx < props.meta.length - 1}
            )}>
              <i className={`pi ${item.icon} ${item.iconColor} mr-2`}></i>
              <span>{item.value} {item.label}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>;
};

export default PageHeading;
