import React, {useState} from 'react';
import {PricelistData_products, UpdateProduct, UpdateProductVariables} from "../../../../../__generated__/types";
import {useMutation} from "@apollo/client";
import {UpdateProductMutation} from "../../../queries.gql";
import ProductQuantityInput from "../../../../../shared/components/ProductQuantityInput";
import {translatedUnit} from "../../../../../shared/utils/unit.utils";
import {Button} from "primereact/button";

interface MinOrderQuantityColumnProps extends PricelistData_products {
}

const MinOrderQuantityColumn = (props: MinOrderQuantityColumnProps) => {
  const [editMode, setEditMode] = useState<boolean>(props.minOrderQuantity == null);

  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {
    refetchQueries: "active"
  });

  const {minOrderQuantity, unit} = props;

  return <div>
    {minOrderQuantity && !editMode && <div className={"flex justify-content-between align-items-center"}>
      <div className="white-space-nowrap">{minOrderQuantity} {translatedUnit(unit)}</div>
      <Button
        icon={'pi pi-pencil '}
        className={'p-button-icon p-1 w-auto'}
        onClick={() => {
          setEditMode(true);
        }}
      />
    </div>}
    {(!minOrderQuantity || editMode) && <ProductQuantityInput
      clientGroupId={null}
      step={0.05}
      value={props.minOrderQuantity}
      product={props}
      inputClassName={'max-w-5rem'}
      onSave={(value) => {
        const variables: UpdateProductVariables = {
          id: props.id,
          data: {
            minOrderQuantity: value
          }
        };
        updateProduct({variables});
        setEditMode(false);
      }}
    />}
  </div>;
}

export default MinOrderQuantityColumn;
