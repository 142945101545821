import React, {useState} from 'react';
import SendRemindersDialog from "./SendRemindersDialog";
import {Button} from "primereact/button";
import {ActiveSubscriptions_clientOrders} from "../../../../__generated__/types";
import {map} from "lodash";

interface OverdueInvoicesMessageProps {
  clientOrders: ActiveSubscriptions_clientOrders[];
}

const OverdueInvoicesMessage = (props: OverdueInvoicesMessageProps) => {
  const [showSendRemindersDialog, setShowSendRemindersDialog] = useState<boolean>(false);

  return <div>
    <div>{`${props.clientOrders.length} ${props.clientOrders.length > 1 ? 'facturen zijn' : 'factuur is'} vervallen`}</div>
    {showSendRemindersDialog &&
      <SendRemindersDialog onHide={() => setShowSendRemindersDialog(false)}
                           clientOrderIds={map(props.clientOrders, 'id')}/>}
    <Button
      label={'Stuur herinneringen uit'}
      className="p-button-link p-0"
      onClick={() => {
        setShowSendRemindersDialog(true);
      }}
    />
  </div>;
};

export default OverdueInvoicesMessage;
