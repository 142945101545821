import React from 'react';
import PageHeading from "../../components/PageHeading";
import {TabMenu} from "primereact/tabmenu";
import {useNavigate, useLocation} from "react-router-dom";
import {compact, omit} from "lodash";

const BasketsManagementPageHeading = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const model = [
    {
      id: "dashboard",
      path: "/pakketten",
      command: () => navigate("/pakketten"),
      label: 'Dashboard',
    },
    {
      id: "Samenstellingen",
      path: "/pakketten/samenstelling",
      command: () => navigate("/pakketten/samenstelling"),
      label: 'Pakketsamenstellingen',
    },
    {
      id: "Pakketdag",
      path: "/pakketten/verwerken",
      command: () => navigate("/pakketten/verwerken"),
      label: 'Pakketdag',
    },
  ];

  let page = model.find(item => item.path === pathname);

  return <div>
    <PageHeading
      path={compact([{label: "Pakketten", path: "/pakketten"}, omit(page, 'path')])}
      title={'Verkoop'}
    />
    <div className="mb-4">
      <TabMenu model={model} activeIndex={model.findIndex(m => m.id === page?.id)}/>
    </div>
  </div>;
};

export default BasketsManagementPageHeading;
