import React, {useState} from 'react';
import {Button} from "primereact/button";
import {useMutation} from "@apollo/client";
import {
  ActiveSubscriptions_clientOrders_productOrders_deliveries,
  DeliveriesUpdate,
  DeliveriesUpdateVariables
} from "../../../../../../../../__generated__/types";
import {DeliveriesUpdateMutation} from "../../../../../../../../shared/queries/productOrder.gql";
import {Calendar} from "primereact/calendar";
import {Moment} from "moment/moment";
import {asIso8601, momentFromDdMmYyyy} from "../../../../../../../../shared/utils/date.utils";

interface ChangeDeliveryDateButtonProps {
  delivery: ActiveSubscriptions_clientOrders_productOrders_deliveries;
}

const ChangeDeliveryDateButton = (props: ChangeDeliveryDateButtonProps) => {
  const [deliveriesUpdate] = useMutation<DeliveriesUpdate>(DeliveriesUpdateMutation);
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = useState<Moment | undefined>();

  return <div
    className="border-1 border-300 surface-50 border-round p-2 px-4 my-2 flex align-items-center justify-content-between flex-wrap">
    <div className="text-xl font-medium">Wijzig de datum voor deze levering</div>
    <div className="flex align-items-center py-2">
      <div>Nieuwe leveringsdatum:</div>
      <Calendar
        value={updatedDeliveryDate ? updatedDeliveryDate.toDate() : undefined}
        className="mx-2"
        inputClassName="p-dense"
        locale={'nl'}
        dateFormat={"dd-mm-yy"}
        showWeek
        onChange={(e) => {
          setUpdatedDeliveryDate(momentFromDdMmYyyy(e.target.value));
        }}
      />
      <Button
        label={'Wijzig leveringsdatum'}
        className="p-dense p-button-outlined"
        disabled={updatedDeliveryDate === undefined}
        onClick={() => {
          if (updatedDeliveryDate) {
            const variables: DeliveriesUpdateVariables = {
              where: {
                id: props.delivery.id
              },
              data: {
                plannedDeliveryDate: asIso8601(updatedDeliveryDate)
              }
            };
            deliveriesUpdate({variables, refetchQueries: "active"});
          }
        }}
      />
    </div>
  </div>;
};

export default ChangeDeliveryDateButton;
