import React, {useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {ClientOrdersQuery} from 'shared/queries/clientOrder.gql';
import {Dialog} from "primereact/dialog";
import {ClientOrders, ClientOrdersVariables} from "../../../../__generated__/types";
import {Button} from 'primereact/button';
import {compact} from "lodash";
import {sendInvoiceToClient} from "../MailButton";
import InvoicePayments from "../InvoicePayments";

interface SendRemindersDialogProps {
  clientOrderIds: string[];
  onHide: () => void;
}

const SendRemindersDialog = (props: SendRemindersDialogProps) => {
  const variables: ClientOrdersVariables = {where: {id_in: props.clientOrderIds}};
  const {data} = useQuery<ClientOrders>(ClientOrdersQuery, {variables: variables});
  const [processedClientOrderIds, setProcessedClientOrderIds] = useState<string[]>([]);
  const [sending, setSending] = useState<boolean>(false);

  const overdueClientOrders = compact(data?.clientOrders);

  const sendReminderToOverdueInvoices = () => {
    setSending(true);
    overdueClientOrders.map(clientOrder =>
      sendInvoiceToClient(clientOrder, true, false, (invoice) => {
        setProcessedClientOrderIds(value => ([...value, clientOrder.id]));
      }, undefined, undefined)
    );
  };

  useEffect(() => {
    if (sending
      && processedClientOrderIds.length === overdueClientOrders.length
      && processedClientOrderIds.length > 0
    ) {
      setSending(false);
    }
  }, [processedClientOrderIds, overdueClientOrders])

  return <Dialog
    visible
    onHide={props.onHide}
    footer={<div>
      {overdueClientOrders.map(clientOrder => <div className="flex align-items-center">
        <div className="flex-grow-1 white-space-nowrap">{clientOrder.client.name} {clientOrder.client.firstName}</div>
        {clientOrder.invoice && <InvoicePayments invoice={clientOrder.invoice} clientOrder={clientOrder}/>}
        <div>{processedClientOrderIds.includes(clientOrder.id) ? 'Verstuurd' : 'Te versturen'}</div>
      </div>)}
      <Button
        disabled={sending}
        loading={sending}
        label={'Herinneringen versturen'}
        onClick={sendReminderToOverdueInvoices}
      />
    </div>}
  >

  </Dialog>;
};

export default SendRemindersDialog;
