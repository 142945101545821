import React, {Fragment, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {compact, get} from "lodash";
import styled from "styled-components";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import {
  Client_client,
  Client_client_customerInfo_members,
  ClientUpdate_CustomerInfo_Members,
  ClientUpdate_CustomerInfo_MembersVariables
} from "../../../../__generated__/types";
import {FormSection, FormWrapper} from "shared/components/soft-farms-forms";
import FormInput from "../../../../shared/components/Form/FormInput";
import {useMutation} from "@apollo/client";
import {ClientUpdate_CustomerInfo_MembersMutation} from "../../../../shared/queries/client.gql";
import {asDdMmYyyy, asIso8601, momentFromDdMmYyyy, momentFromIso8601} from "../../../../shared/utils/date.utils";
import {DataTable} from "primereact/datatable";

const Footer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;

  > *:last-child {
    text-align: right;
  }
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;

  > *:last-child {
    text-align: right;
  }
`;

const FamilyMembers = (props: { client: Client_client, editable: boolean }) => {
  const [updateMembers] = useMutation<ClientUpdate_CustomerInfo_Members>(ClientUpdate_CustomerInfo_MembersMutation, {refetchQueries: "active"});
let members = compact(props.client.customerInfo?.members);
const [selectedMember, setSelectedMember] = useState<Partial<Client_client_customerInfo_members>>({
  name: '',
  firstName: '',
  dateOfBirth: '',
});
const [idxEditingMember, setIdxEditingMember] = useState<number>();
const [showDialog, setShowDialog] = useState<boolean>(false);

const saveMember = () => {
  const {firstName, name, id, dateOfBirth} = selectedMember;
  if (name && firstName) {
    let update = id ? [{
      where: {id},
      data: {
        dateOfBirth: asIso8601(momentFromDdMmYyyy(dateOfBirth)),
        name,
        firstName,
      },
    }] : null;

    let create = !id ? [{
      dateOfBirth: asIso8601(momentFromDdMmYyyy(dateOfBirth)),
      name,
      firstName,
    }] : null;

    const variables: ClientUpdate_CustomerInfo_MembersVariables = {
      id: props.client.id,
      membersUpdate: {
        update: update,
        create: create,
      },
    };
    updateMembers({variables});
  }

  setShowDialog(false);
  setSelectedMember({});
};

const fields = [
  {
    label: 'Voornaam',
    field: 'firstName',
    type: 'Input',
  },
  {
    label: 'Naam',
    field: 'name',
    type: 'Input',
  },
  {
    label: 'Geboortedatum',
    field: 'dateOfBirth',
    type: 'Mask',
    mask: '99/99/9999',
  },
];

return <div>
  <ConfirmPopup/>
  {showDialog &&
    <Formik
      initialValues={selectedMember}
      onSubmit={saveMember}
      validationSchema={object().shape({
        'firstName': string().label('Voornaam').required(),
        'name': string().label('Naam').required(),
        'dateOfBirth': string().label('Geboortedatum').required(),
      })}
    >
      {(formik) => {
        const {isSubmitting, submitForm} = formik;
        return (
          <Dialog
            contentStyle={{width: 340}}
            draggable={false}
            closable={false}
            visible={showDialog}
            onHide={() => setShowDialog(false)}
            header={idxEditingMember ? 'Gezinslid wijzigen' : 'Gezinslid toevoegen'}
            footer={<div className="ui-dialog-buttonpane p-clearfix">
              <Button type={"button"} className={'p-button-link'} label={'Annuleren'}
                      onClick={() => setShowDialog(false)}
              />
              {!isSubmitting && <Button
                label={idxEditingMember !== undefined ? 'Wijzigen' : 'Toevoegen'}
                icon="pi pi-check"
                onClick={submitForm}
              />}
            </div>}
          >
            <Form id={'memberForm'}>
              <FormWrapper>
                <FormSection style={{marginBottom: 0, paddingBottom: 0}}>
                  {fields.map(field => {
                    return <Fragment>
                      <div>{field.label}</div>
                      <FormInput
                        validateWithoutTouch
                        name={field.field}
                        placeholder={field.label}
                        onChange={(value) => setSelectedMember(({...selectedMember, [field.field]: value}))}
                        value={get(selectedMember, field.field)}
                        mask={field.mask}
                      />
                    </Fragment>;
                  })}
                </FormSection>
              </FormWrapper>
            </Form>
          </Dialog>
        );
      }}
    </Formik>}

  <DataTable
    className={"sf-table"}
    value={members}
    //autoLayout
    emptyMessage={'Geen gezinsleden'}
    responsiveLayout={"scroll"}
  >
    <Column header={'Naam'}
            body={(member: Client_client_customerInfo_members) => `${member.firstName} ${member.name}`}/>
    <Column header={'Geboortedatum'} field={'dateOfBirth'}
            body={(member: Client_client_customerInfo_members) => `${asDdMmYyyy(momentFromIso8601(member.dateOfBirth))}`}/>
    {props.editable && <Column
      style={{maxWidth: '64px'}}
      body={(row: Client_client_customerInfo_members, column: any) =>
        <Actions className={"px-2"}>
          <Button
            className={'p-button-icon'}
            icon={'pi pi-pencil'}
            onClick={() => {
              setSelectedMember(row);
              setIdxEditingMember(column.rowIndex);
              setShowDialog(true);
            }}
          />
          <Button
            className={'p-button-icon'}
            icon={'pi pi-trash'}
            onClick={(event) => {
              confirmPopup({
                target: event.currentTarget,
                message: 'Wil je dit gezinslid verwijderen',
                icon: 'pi pi-exclamation-triangle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Ja',
                rejectLabel: 'Nee',
                accept: () => {
                  const variables: ClientUpdate_CustomerInfo_MembersVariables = {
                    id: props.client.id,
                    membersUpdate: {
                      delete: [{id: row.id}]
                    },
                  };
                  updateMembers({variables});
                },
              });
            }}
          />
        </Actions>}/>}
  </DataTable>
  <Footer>
    {props.editable && <Button
      className={'p-button-link'}
      onClick={(e) => {
        setShowDialog(!showDialog);
        setSelectedMember({});
        setIdxEditingMember(undefined);
        e.preventDefault();
      }}
      icon={'pi pi-user-plus'}
      label={'voeg een gezinslid toe'}
    />}
  </Footer>

</div>
}
;

export default FamilyMembers;
