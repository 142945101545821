import React from 'react';
import { RemoveShopClientMutation, ShopClientsQuery, ShopOrdersQuery } from "../Veldwinkel.gql";
import { useMutation, useQuery } from "@apollo/client";
import { RemoveShopClient, ShopClients, ShopClients_shopClients, ShopOrders, ShopOrders_shopOrders } from "../../../__generated__/types";
import { compact, groupBy, keyBy, orderBy } from "lodash";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import {DataTable} from "primereact/datatable";

const ClientListing = () => {
  const {data, refetch} = useQuery<ShopClients>(ShopClientsQuery);
  const {data: shopOrderData} = useQuery<ShopOrders>(ShopOrdersQuery);
  const [removeShopClient] = useMutation<RemoveShopClient>(RemoveShopClientMutation);

  const shopClients = compact(data?.shopClients);
  const clients = keyBy(compact(data?.clients), 'email');
  const shopOrders: { [key: string]: ShopOrders_shopOrders[] } = groupBy(compact(shopOrderData?.shopOrders), 'shopClient.id');
  return <div>
    <h2>Klanten</h2>

    <DataTable
      className={"sf-table"}
      value={orderBy(shopClients, [(client) => !!clients[client.email], (client) => client.name,], ['asc', 'asc'])}
    >
      {/*<Column field={'id'}/>*/}
      <Column
        header={'Deelnemer'}
        body={(shopClient: ShopClients_shopClients) => {
          const client = clients[shopClient.email];
          if (client) {
            return <div><i className="pi pi-user"/></div>;
          }
        }}
      />
      <Column
        header={'Klant'}
        field={'shopClient.id'}
        body={(client: ShopClients_shopClients) => {
          return `${client.name} ${client.firstName} `;
        }}
      />
      <Column
        header={'Telefoonnummer'}
        body={(shopClient: ShopClients_shopClients) => {
          return <div>
            <div>{shopClient.email}</div>
            <div>{shopClient.phone}</div>
          </div>;
        }}
      />
      <Column
        header={'Nieuwsbrief'}
        field={'shopClient.newsletter'}
        body={(client: ShopClients_shopClients) => client.newsletter ? 'Ja' : 'Nee'}
      />
      <Column
        header={'# bestellingen'}
        body={(shopClient: ShopClients_shopClients) => {
          const shopOrderForClient = shopOrders[shopClient.id];
          return <div>
            {shopOrderForClient?.length}

            {/*<div>{shopOrderForClient?.map(order => <div>{order.id}</div>)}</div>*/}
          </div>;
        }}
      />
      <Column
        header={'Acties'}
        body={(shopClient: ShopClients_shopClients) => {
          const shopOrdersForClient = shopOrders[shopClient.id];
          return <div>
            {shopOrdersForClient === undefined && <Button
              icon={'pi pi-trash'}
              className={'p-button-text p-button-danger'}
              onClick={(e) => {
                confirmPopup({
                  acceptLabel: 'Ja',
                  rejectLabel: 'Nee',
                  target: e.currentTarget,
                  message: 'Ben je zeker dat je deze klant wil verwijderen?',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                    removeShopClient({
                      variables: {
                        id: shopClient.id,
                      }
                    }).then(() => refetch())
                  },
                  reject: () => {
                  }
                });
              }}/>}
          </div>;
        }}
      />

    </DataTable>
  </div>;
};

export default ClientListing;
