import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import appConfig from "../../config.app";
import {Button} from "primereact/button";

interface WelcomePageProps {
  redirectPath: string | undefined;
}

const WelcomePage = (props: WelcomePageProps) => {
  const {loginWithRedirect} = useAuth0();
  let redirectUri = appConfig.redirectUrl;

  return <div className="absolute top-0 left-0 right-0 h-screen flex flex-column align-items-stretch justify-content-center overflow-scroll">
    <div className="">
      <div style={{backgroundImage: 'url(IMG_1658-landscape.jpeg)'}} className="w-full h-screen bg-cover bg-center overflow-scroll flex align-items-stretch flex-column">
        <div className="flex-initial flex align-items-center justify-content-center p-5">
          <img style={{height: '25vh'}} src={'https://www.groentegeweld.be/logo.png'}
               alt={'Groentegeweld logo'}/>
        </div>
        <div className="p-4 text-center flex flex-column align-items-center justify-content-around m-2 border-round-xl bg-white-alpha-90 m-4 my-0 mb-4">
          <div className="m-2 text-2xl font-bold text-center color-green">Welkom bij <br/> Groentegeweld</div>
          <div className="m-3 font-medium text-lg">Meld je aan en beheer jouw pakket- of zelfoogstabonnement.</div>

          <Button
            label="Aanmelden"
            className="m-3 text-2xl font-medium p-button-outlined color-green border-green border-2"
            icon="pi pi-sign-in"
            iconPos="right"
            onClick={() => loginWithRedirect({redirectUri})}
          />
        </div>
      </div>
    </div>
  </div>;
};

export default WelcomePage;
