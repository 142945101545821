import React, {useState} from 'react';
import {
  ActiveSubscriptions_clientOrders_productOrders,
  ProductOrderDelete,
  ProductOrderDeleteVariables
} from "../../../../../../__generated__/types";
import {Button} from "primereact/button";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useMutation} from "@apollo/client";
import { ProductOrderDeleteMutation } from 'shared/queries/productOrder.gql';

interface DeleteProductOrderButtonProps {
  productOrder: ActiveSubscriptions_clientOrders_productOrders;
}

const DeleteProductOrderButton = (props: DeleteProductOrderButtonProps) => {
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [deleteProductOrder] = useMutation<ProductOrderDelete>(ProductOrderDeleteMutation, {refetchQueries: "active"});
  return <div>
    <ConfirmDialog
      visible={confirmDialog}
      header="Product verwijderen"
      headerClassName="text-900"
      message={'Ben je zeker dat je dit product wil verwijderen?'}
      icon={'pi pi-exclamation-triangle'}
      acceptLabel={'Ja'}
      acceptClassName={'p-button-danger p-button-outlined'}
      rejectLabel={'Nee'}
      rejectIcon="pi pi-trash"
      rejectClassName={'p-button-text p-button-link'}
      reject={() => setConfirmDialog(false)}
      accept={() => {
        const variables: ProductOrderDeleteVariables = {
          id: props.productOrder.id
        };
        deleteProductOrder({variables});
      }}
    />
    <Button
      icon="pi pi-trash"
      className="p-button-danger p-button-text p-0"
      onClick={() => {
        setConfirmDialog(true);
      }}
    />
  </div>;
};

export default DeleteProductOrderButton;
