import React, {useEffect, useState} from 'react';
import {PricelistData_products, UpdateProduct, UpdateProductVariables} from "../../../../__generated__/types";
import {Button} from "primereact/button";
import {filterActiveProductPrices} from 'shared/utils/product.utils';
import moment from "moment";
import {asIso8601} from "../../../../shared/utils/date.utils";
import {ceil, set} from "lodash";
import {useMutation} from "@apollo/client";
import {UpdateProductMutation} from "../../queries.gql";

interface ExecuteIndexButtonProps {
  products: PricelistData_products[];
  onFinished: () => void;
}

interface StatusedUpdateProductVariables extends UpdateProductVariables {
  executed: boolean;
}

const ExecuteIndexButton = (props: ExecuteIndexButtonProps) => {
  const [productUpdates, setProductUpdates] = useState<StatusedUpdateProductVariables[]>([]);
  const [executing, setExecuting] = useState<boolean>();
  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {
    refetchQueries: "active"
  });

  const performIndex = () => {
    setProductUpdates(
      props.products
        .map(product => {
          const activeProductPrices = filterActiveProductPrices(product);

          const variables: StatusedUpdateProductVariables = {
            id: product.id,
            executed: false,
            data: {
              productPrices: {
                update: activeProductPrices.map(activeProductPrice => ({
                  where: {id: activeProductPrice.id},
                  data: {
                    endDate: asIso8601(moment())
                  }
                })),
                create: activeProductPrices.map(activeProductPrice => {
                  let clientGroup = activeProductPrice.clientGroup
                    ? {connect: {id: activeProductPrice.clientGroup.id}}
                    : null;
                  return ({
                    note: 'index-2023',
                    clientGroup: clientGroup,
                    base: !clientGroup,
                    startDate: asIso8601(moment()),
                    value: ceil(activeProductPrice.value * 1.1, 2),
                  });
                }),
              }
            }
          };
          return variables;
        })
    );
    setExecuting(true);
  };

  let executedProductOrders = productUpdates.filter(productUpdate => productUpdate.executed);

  useEffect(() => {
    if (executing) {
      productUpdates.forEach(async (productUpdate, idx) => {
        await updateProduct({variables: productUpdate})
          .then(() => {
            setProductUpdates(value => {
              let updatedValue = value;
              set(updatedValue, `[${idx}].executed`, true);
              return updatedValue;
            });
          });
      });
    }
  }, [executing, updateProduct]);

  useEffect(() => {
    if (executing && productUpdates.filter(pu => !pu.executed).length === productUpdates.length) {
      setExecuting(false);
      props.onFinished();
    }
  }, [productUpdates]);

  return <div>
    <Button
      loading={executing}
      disabled={executing}
      label={`Voer index 2023 door (10%) op ${props.products.length} producten`}
      onClick={performIndex}
    />
    {executing && <div>
      Status: {executedProductOrders.length} / {productUpdates.length}
    </div>}
  </div>;
};

export default ExecuteIndexButton;
