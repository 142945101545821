import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {compact, set} from 'lodash';
import {Column} from "primereact/column";
import {
  ProductCategories,
  ProductCategories_productCategories, ProductCategoryUpdate, ProductCategoryUpdateVariables,
  ProductCategoryUpsert,
  ProductCategoryUpsertVariables,
} from "../../../__generated__/types";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Toolbar} from "primereact/toolbar";
import SalesPageHeading from "../SalesPageHeading";
import {
  ProductCategoriesQuery,
  ProductCategoryUpdateMutation,
  ProductCategoryUpsertMutation
} from "../../../shared/queries/product.gql";
import {useMutation, useQuery} from "@apollo/client";
import DialogInputText from "../../../shared/components/DialogInputText";
import {FilterMatchMode} from "primereact/api";
import {TreeSelect} from "primereact/treeselect";
import {TreeTable} from "primereact/treetable";
import {productCategoriesTree} from "../../../shared/utils/productCategory.utils";
import CategoryIcon from "../../../shared/icons/CategoryIcon";

const ProductCategoryManagement = () => {
  const {data} = useQuery<ProductCategories>(ProductCategoriesQuery);
  const [upsertProductCategory] = useMutation<ProductCategoryUpsert>(ProductCategoryUpsertMutation, {refetchQueries: "active"});
  const [updateProductCategory] = useMutation<ProductCategoryUpdate>(ProductCategoryUpdateMutation);

  let emptyProductCategory: Partial<ProductCategories_productCategories> = {
    name: '',
    code: '',
    category: undefined,
  };

  const [productCategories, setProductCategories] = useState<ProductCategories_productCategories[]>([]);
  const [productCategoryDialog, setProductCategoryDialog] = useState(false);
  const [deleteProductCategoryDialog, setDeleteProductCategoryDialog] = useState(false);
  const [deleteProductCategoriesDialog, setDeleteProductCategoriesDialog] = useState(false);
  const [productCategory, setProductCategory] = useState<Partial<ProductCategories_productCategories>>(emptyProductCategory);
  const [selectedProductCategories, setSelectedProductCategories] = useState<ProductCategories_productCategories[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>();
  const [filters, setFilters] = useState<{ [key: string]: { value: null | any, matchMode: FilterMatchMode } }>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  });

  const toast = useRef<Toast>(null);
  const dt = useRef<TreeTable>(null);

  useEffect(() => {
    setProductCategories(compact(data?.productCategories));
  }, [data]);

  const openNew = () => {
    setProductCategory(emptyProductCategory);
    setSubmitted(false);
    setProductCategoryDialog(true);
  };

  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductCategoryDialog(false);
  };

  const hideDeleteProductCategoryDialog = () => {
    setDeleteProductCategoryDialog(false);
  };

  const hideDeleteProductCategoriesDialog = () => {
    setDeleteProductCategoriesDialog(false);
  };

  const header = <div className="p-0 m-0 border-0">
    <Toolbar
      className="p-toolbar p-component p-0 m-0 surface-0 border-0"
      start={<div>
        <span className="p-input-icon-left">
              <i className="pi pi-search"/>
              <InputText type="search" value={globalFilterValue || ''} onChange={onGlobalFilterChange}
                         placeholder="Search..."/>
          </span>
        <Button label="Nieuwe productcategorie" icon="pi pi-plus" className="p-button-link pl-0" onClick={openNew}/>
      </div>
      }
    />
  </div>;

  const saveProductCategory = () => {
    setSubmitted(true);
    let _productCategory = {...productCategory};
    let originalProductCategory = productCategories.find(po => po.id === productCategory.id);
    let productCategoryName = productCategory.name?.trim();
    if (_productCategory.name && productCategoryName) {
      let variables: ProductCategoryUpsertVariables = {
        id: _productCategory.id || '',
        update: {
          name: _productCategory.name,
          code: _productCategory.code,
          image: _productCategory.image,
        },
        create: {
          name: _productCategory.name,
          code: _productCategory.code,
          image: _productCategory.image,
        }
      };

      if (!_productCategory.category && originalProductCategory?.category?.id) {
        variables = {
          ...variables,
          update: {
            ...variables.update,
            category: {
              disconnect: true
            }
          }
        };
      }

      if (_productCategory.category?.id) {
        variables = {
          ...variables,
          create: {
            ...variables.create,
            category: {
              connect: {
                id: _productCategory.category.id,
              }
            }
          },
          update: {
            ...variables.update,
            category: {
              connect: {
                id: _productCategory.category.id,
              }
            }
          }
        };
      }

      upsertProductCategory({variables});

      if (productCategory.id) {
        toast.current && toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Productcategorie gewijzigd',
          life: 3000
        });
      } else {
        toast.current && toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Productcategorie aangemaakt',
          life: 3000
        });
      }

      setProductCategoryDialog(false);
      setProductCategory(emptyProductCategory);
    }
  };

  const editProductCategory = (productCategory: ProductCategories_productCategories) => {
    setProductCategory({...productCategory});
    setProductCategoryDialog(true);
  };

  const confirmDeleteProductCategory = (productCategory: ProductCategories_productCategories) => {
    setProductCategory(productCategory);
    setDeleteProductCategoryDialog(true);
  };

  const deleteProductCategory = () => {
    const matchingProductCategory = productCategories.find((val: ProductCategories_productCategories) => val.id !== productCategory.id);
    if (matchingProductCategory) {
      setProductCategory(productCategory);
    }
    setDeleteProductCategoryDialog(false);
    setProductCategory(emptyProductCategory);
    toast.current && toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Productcategorie verwijderd',
      life: 3000
    });
  };

  const deleteSelectedProductCategories = () => {
    let _productCategories = productCategories.filter((val: ProductCategories_productCategories) => !selectedProductCategories.includes(val));
    setProductCategories(_productCategories);
    setDeleteProductCategoriesDialog(false);
    setSelectedProductCategories([]);
    toast.current && toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Productcategorieën verwijderd',
      life: 3000
    });
  };

  const onSubCategoryChange = (e: any) => {
    let _productCategory = {...productCategory};
    if (e) {
      _productCategory['category'] = {id: e} as ProductCategories_productCategories;
    } else {
      _productCategory['category'] = undefined;
    }
    setProductCategory(_productCategory);
  };

  const onInputChange = (e: any, name: keyof ProductCategories_productCategories) => {
    const val = (e.target && e.target.value) || '';
    let _productCategory = {...productCategory};
    set(_productCategory, name, val);

    setProductCategory(_productCategory);
  };

  const actionBodyTemplate = (rowData: ProductCategories_productCategories) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-0 p-button-link p-mr-2"
                onClick={() => editProductCategory(rowData)}/>
        <Button icon="pi pi-trash" className="p-0 p-button-text p-button-danger"
                onClick={() => confirmDeleteProductCategory(rowData)}/>
      </React.Fragment>
    );
  }

  const productCategoryDialogFooter = (
    <React.Fragment>
      <Button label="Annuleren" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
      <Button label="Opslaan" icon="pi pi-check" className="p-button-text" onClick={saveProductCategory}/>
    </React.Fragment>
  );
  const deleteProductCategoryDialogFooter = (
    <React.Fragment>
      <Button label="Nee" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductCategoryDialog}/>
      <Button label="Ja" icon="pi pi-check" className="p-button-text" onClick={deleteProductCategory}/>
    </React.Fragment>
  );
  const deleteProductCategoriesDialogFooter = (
    <React.Fragment>
      <Button label="Nee" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductCategoriesDialog}/>
      <Button label="Ja" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProductCategories}/>
    </React.Fragment>
  );

  let options = productCategoriesTree(data?.productCategories);
  return (
    <div>
      <SalesPageHeading/>
      <div className="datatable-crud-demo">
        <Toast ref={toast}/>

        <div className="card">
          <TreeTable
            className="sf-table"
            ref={dt}
            value={options}
            globalFilter={globalFilterValue}
            header={header}
            paginator rows={50} rowsPerPageOptions={[10, 50, 100, 1000]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Toon {first} tot {last} van {totalRecords} productcategorieën"
          >
            <Column expander className="w-3rem"/>
            <Column
              className="w-3rem"
              field="visibleToCustomer"
              sortable
              body={({data: productCategory}) => {
                return <i
                  className={`cursor-pointer pi pi-eye${!productCategory.visibleToCustomer ? '-slash opacity-60' : ''}`}
                  onClick={() => {
                    const variables: ProductCategoryUpdateVariables = {
                      id: productCategory.id,
                      update: {
                        visibleToCustomer: !productCategory.visibleToCustomer
                      },
                    };

                    updateProductCategory({variables});
                  }}
                />;
              }}
            />
            <Column field="image" className="w-3rem" body={({data: productCategory}) => {
              return productCategory.image ?
                <CategoryIcon className="w-1rem h-1rem" icon={productCategory.image}/> : null;
            }}/>
            <Column field="name" header="Name" sortable/>
            <Column field="code" header="Code" sortable/>
            <Column className="w-8rem" body={(e) => {
              return actionBodyTemplate(e.data);
            }}></Column>
          </TreeTable>
        </div>

        <Dialog visible={productCategoryDialog} style={{width: '450px'}} header="Productcategorie Details" modal
                className="p-fluid"
                footer={productCategoryDialogFooter} onHide={hideDialog}>
          <DialogInputText
            label={'Naam'}
            id={'name'}
            onChange={(e) => {
              onInputChange(e, 'name');
            }}
            value={productCategory.name}
            required
            submitted={submitted}
          />
          <DialogInputText
            label={'Code'}
            id={'code'}
            onChange={e => onInputChange(e, 'code')}
            value={productCategory.code}
            required
            submitted={submitted}
          />
          <DialogInputText
            label={'Afbeelding'}
            id={'image'}
            onChange={e => onInputChange(e, 'image')}
            value={productCategory.image}
            submitted={submitted}
          />
          <div className="p-field mt-2">
            <label className="mb-3">Hoofdcategorie</label>
            <div>
              <TreeSelect
                value={productCategory.category?.id}
                onChange={(e) => onSubCategoryChange(e.value)}
                options={[{key: undefined, label: 'Geen hoofdcategorie'}, ...options]}
                className="md:w-20rem w-full"
                placeholder="Selecteer een hoofdcategorie"
                filter
              />
            </div>
          </div>
        </Dialog>

        <Dialog visible={deleteProductCategoryDialog} style={{width: '450px'}} header="Confirm" modal
                footer={deleteProductCategoryDialogFooter} onHide={hideDeleteProductCategoryDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
            {productCategory && <span>Ben je zeker dat je <b>{productCategory.name}</b> wil verwijderen?</span>}
          </div>
        </Dialog>

        <Dialog visible={deleteProductCategoriesDialog} style={{width: '450px'}} header="Confirm" modal
                footer={deleteProductCategoriesDialogFooter} onHide={hideDeleteProductCategoriesDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
            {productCategory && <span>Ben je zeker dat je de geselecteerde klanten wenst te verwijderen?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ProductCategoryManagement;
