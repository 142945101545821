import gql from "graphql-tag";

export const SequencesQuery = gql`
query Sequences($where: SequenceWhereInput!, $farmId: ID!) {
  sequences(where: {
    AND:[
      $where,
      {farm: {id: $farmId}                    }
    ]
  }) {
    id
    sequenceType
    prefix
    nextValue
    season
  }
}`;
