import React, {useEffect, useState} from 'react';
import {
  ClientOrderStatus,
  Clients, ClientsVariables,
  GetClientOrdersToProcess,
  GetClientOrdersToProcess_clientOrders,
  SalesData_clientGroups,
  SalesData_clientOrders_invoice
} from "../../../../../__generated__/types";
import {Column} from "primereact/column";
import {chain, compact, first, get, keys, orderBy, sum, uniq, values} from "lodash";
import {asDdMmYyyy, momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {formatCurrency} from "../../../../../shared/utils/currency.utils";
import {Badge, statusOptions} from '../../OrderManagement';
import MailButton from "../MailButton";
import RegisterPaymentButton from "../RegisterPaymentButton";
import ShowClientInvoice from "../ShowClientInvoice";
import styled from "styled-components";
import {useQuery} from "@apollo/client";
import {GetClientOrdersByStatusQuery} from "../../../queries.gql";
import {DataTable} from "primereact/datatable";
import {ClientsQuery} from "../../../../../shared/queries/client.gql";
import {Checkbox} from "primereact/checkbox";
import classNames from "classnames";
import InvoiceDetail from "./InvoiceDetail/InvoiceDetail";
import ClientInvoicesLink from "../ClientInvoicesLink";
import {paymentIsOverdue} from "../../../../SubscriptionsPage/components/Dashboard/DashboardItemRevenue";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface InvoiceTableProps {
  clientGroups: { [key: string]: SalesData_clientGroups };
}

type InvoiceData = {
  invoice: SalesData_clientOrders_invoice,
  name: string,
  totalIncl: string,
  createdAt: string,
  status: ClientOrderStatus,
  clientOrders: GetClientOrdersToProcess_clientOrders[],
  client: {
    id: string;
    name: string | null;
    firstName: string | null;
    companyName: string | null;
    street: string | null;
    streetNumber: string | null;
    group: { id: string };
    city: string | null;
    cityPostalCode: string | null;
    email: string | null;
    billingEmail: string | null;
    vatNumber: string | null;
  },
};

const YearOverview = styled.div<{ years: number }>`
  display: grid;
  grid-template-columns: max-content repeat(${props => props.years}, max-content);
  grid-gap: 8px;
`;

const orderedByOrderDate = (clientOrdersToProcess: GetClientOrdersToProcess_clientOrders[]) => {
  return orderBy(clientOrdersToProcess, (clientOrder) => {
    return momentFromIso8601(clientOrder.orderDate).unix();
  }, ['desc']);
};


const InvoiceTable = (props: InvoiceTableProps) => {
  const {activeFarm} = useUserObject();
  const [invoiceData, setInvoiceData] = useState<InvoiceData[]>([]);
  const [showPaid, setShowPaid] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data: clientsData} = useQuery<Clients>(ClientsQuery, {variables});
  const {data: clientOrdersData} = useQuery<GetClientOrdersToProcess>(GetClientOrdersByStatusQuery, {variables: {farmId: activeFarm?.id||'',status: [ClientOrderStatus.INVOICE_SENT, ClientOrderStatus.INVOICED, ClientOrderStatus.PAID]}});

  useEffect(() => {
    if (clientOrdersData && clientsData) {
      const clientOrders = orderedByOrderDate(compact(clientOrdersData?.clientOrders));
      setInvoiceData(chain(clientOrders)
        .groupBy('invoice.number')
        .mapValues(clientOrdersForSameInvoice => {
          const firstClientOrder: GetClientOrdersToProcess_clientOrders = first(clientOrdersForSameInvoice) as GetClientOrdersToProcess_clientOrders;
          const invoice = firstClientOrder.invoice as SalesData_clientOrders_invoice;

          return {
            invoice,
            name: invoice.filename,
            totalIncl: formatCurrency(invoice.data.totalIncl),
            createdAt: invoice.createdAt,
            status: firstClientOrder.status,
            clientOrders: clientOrdersForSameInvoice,
            client: firstClientOrder.client,
          };
        })
        .values()
        .value());
    }
  }, [clientOrdersData, clientsData]);

  const yearsForInvoices = uniq(invoiceData.map(i => momentFromIso8601(i.invoice.createdAt).year()))

  return <div>
    <YearOverview years={yearsForInvoices.length}>
      <div>&nbsp;</div>
      {yearsForInvoices.map(year =>
        <div>
          {year}
        </div>
      )}
      {values(props.clientGroups).map(clientGroup => <>
        <div>{clientGroup.name}</div>
        {yearsForInvoices.map(year =>
          <div>
            {formatCurrency(
              sum(invoiceData
                .filter(invoice => momentFromIso8601(invoice.invoice.createdAt).year() === year && invoice.client.group.id === clientGroup.id)
                .map(invoice => invoice.invoice.data.totalIncl))
            )
            }
          </div>
        )}
      </>)}
    </YearOverview>
    <div className="mx-1 text-xs flex align-items-center">
      <Checkbox checked={showPaid} onChange={(event) => setShowPaid(event.checked || false)}/>
      <div className="pl-2">Toon reeds betaalde facturen</div>
    </div>

    <DataTable
      className="sf-table"
      sortOrder={-1}
      sortMode={'single'}
      sortField={'invoice.number'}
      dataKey={'invoice.id'}
      value={showPaid ? invoiceData : invoiceData.filter(invoice => invoice.status !== ClientOrderStatus.PAID)}
      expandedRows={expandedRows}
      onRowToggle={(e) => {
        setExpandedRows(e.data as {[key: string]: boolean});
      }}
      rowExpansionTemplate={(invoice: InvoiceData) => {
        return <InvoiceDetail invoiceId={invoice.invoice.id}/>
      }}
      rowClassName={(invoice: InvoiceData) => {
        return classNames({
          expanded: keys(expandedRows).includes(invoice.invoice.id),
        });
      }}
    >
      <Column expander/>
      <Column
        header={'Status'}
        field={'status'}
        sortable
        sortField={'status'}
        body={(invoice: InvoiceData) => <Badge
          className={`status-${invoice.status}`}>{get(statusOptions, invoice.status)}</Badge>}
      />
      <Column header={'Klant'} field={'client.name'} sortable sortField={'client.name'}
              body={invoice => invoice.client.companyName || invoice.client.name}/>
      <Column header={'Factuur'} field={'name'} sortable/>
      <Column header={'Datum'} field={'createdAt'}
              sortable
              sortField={'orderDate'}
              body={(invoice: InvoiceData) => asDdMmYyyy(momentFromIso8601(invoice.createdAt))}
      />
      <Column header={'Vervaldatum'} field={'dueDate'}
              sortable
              sortField={'dueDate'}
              body={(invoice: InvoiceData) => {
                let payments = compact(invoice.invoice.payments);
                let firstPayment = first(payments);
                let isOverdue = payments.length === 1 && firstPayment && paymentIsOverdue(firstPayment);
                return <div className={classNames(
                  {
                    "text-red-500 font-bold": isOverdue
                  })}>
                  {asDdMmYyyy(momentFromIso8601(firstPayment?.dueDate))}
                </div>;
              }}
      />
      <Column header={'Totaal'} field={'totalIncl'}/>
      <Column header={'Acties'}
              body={(invoice: InvoiceData) => {
                return <div className="flex flex-column align-items-start">
                  <ShowClientInvoice invoice={invoice.invoice}/>
                  <ClientInvoicesLink clientId={invoice.client.id}/>
                  {invoice.status === ClientOrderStatus.INVOICED &&
                    <MailButton invoice={invoice.invoice} client={invoice.client}/>}
                  {invoice.status === ClientOrderStatus.INVOICE_SENT &&
                    <RegisterPaymentButton invoice={invoice.invoice}/>}
                </div>;
              }}
      />
    </DataTable>
  </div>;
};

export default InvoiceTable;
