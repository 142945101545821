import React from 'react';
import styled from "styled-components";

interface BillingStatusColumnProps {
  paid: boolean;
}

const StatusColored = styled.div`
  font-weight: 700;

  &.status-paid {
      color: #359b3a;
  }

  &.status-unpaid {
      color: #C63737;
  }
`;

const Badge = styled(StatusColored)`
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
  padding: 2px 2px;
  text-transform: uppercase;
  letter-spacing: .3px;
  font-size: 10px;

  &.status-paid {
      background-color: #C8E6C9;
  }

  &.status-unpaid {
      background-color: #FFCDD2;
  }
`;

const BillingStatusColumn = (props: BillingStatusColumnProps) => {
  return <div>
    {<Badge
      className={`status-${props.paid ? 'paid' : 'unpaid'}`}>
      {props.paid ? 'Betaald' : 'Te betalen'}
    </Badge>}
  </div>;
};

export default BillingStatusColumn;
