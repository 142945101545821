import React from 'react';
import {Document, Font, Image, Page, Text, View} from "@react-pdf/renderer";
import {styles} from "../../../../shared/pdf/pdf-styles";
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";
import {ClientOrderInvoicePdfProductLine} from "../../../../shared/utils/invoice.utils";

import MontserratMedium from "../../../../shared/pdf/fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../../../shared/pdf/fonts/Montserrat-Bold.ttf";

Font.register({family: 'Montserrat', src: MontserratMedium});
Font.register({family: 'Montserrat-Bold', src: MontserratBold});

export interface ClientOrderDeliveryNoteProductLine {
  description: string,
  productUnit?: string,
}

export interface ClientOrderDeliveryNotePdfData {
  filename: string,
  recipient: {
    businessName: string,
    addressLines: string[],
  }
  meta: { label: string, value: string }[],
  productLines: ClientOrderDeliveryNoteProductLine[],
}

interface ClientOrderDeliveryNotePdfProps {
  data: ClientOrderDeliveryNotePdfData;
}

const ClientOrderDeliveryNotePdf = (props: ClientOrderDeliveryNotePdfProps) => {
  const {recipient, meta, productLines} = props.data;
  return (
    <Document title={props.data.filename}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image style={styles.image} source={'logo.png'}/>
          </View>
          <View style={styles.recipient}>
            <Text>{recipient.businessName}</Text>
            {recipient.addressLines.map(l => <Text key={l}>{l}</Text>)}
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <Text style={styles.bold}>LEVERBON</Text>
        </View>

        <View style={styles.contentWrapper}>
          <View style={styles.invoiceMeta}>
            {meta.map((m) => <View key={m.label}>
              <Text>{m.label}</Text>
              <Text style={styles.bold}>{m.value}</Text>
            </View>)}
          </View>
          <View style={styles.spacer}/>
        </View>
        <View style={styles.contentWrapper}>
          <Table>
            <TableHeader
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <TableCell style={styles.th} weighting={22}>Omschrijving</TableCell>
              <TableCell style={styles.th} weighting={8}>Aantal</TableCell>
            </TableHeader>
          </Table>
          <Table data={productLines}>
            <TableBody
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <DataTableCell style={styles.td} weighting={22}
                             getContent={(r: ClientOrderInvoicePdfProductLine) => r.description}/>
              <DataTableCell style={styles.td} weighting={8}
                             getContent={(r: ClientOrderInvoicePdfProductLine) => r.productUnit}/>
            </TableBody>
          </Table>

          <View style={styles.spacer}/>
          <Text>Alle producten zijn van biologische land- en tuinbouwproducten - Garantie en controle</Text>
          <Text>BIO-BE-02</Text>
        </View>

        <View fixed style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 125,
          borderTop: 1,
          borderTopColor: '#748F56',
          borderTopStyle: 'solid',
          color: '#748F56',
          padding: 20,
          fontSize: 9,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
        >
          <View>
            <Text>GROENTEGEWELD</Text>
            <Text>WEELDESTRAAT 168</Text>
            <Text>2381 WEELDE</Text>
            <Text>BTW BE0746.361.154</Text>
          </View>
          <View>
            <Text>info@groentegeweld.be</Text>
            <Text>www.groentegeweld.be</Text>
            <Text>facebook.be/groentegeweld</Text>
            <Text>+32 494 66 43 46 | +32 489 95 51 13‬</Text>
          </View>
          <View>
            <Text>BTW BE0746.361.154</Text>
            <Text>IBAN BE46 5230 8118 5136</Text>
            <Text>BIC: TRIOBEBB</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ClientOrderDeliveryNotePdf;
