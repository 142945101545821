import React from 'react';
import {ceil, compact, first} from "lodash";
import classNames from "classnames";
import {formatDoubleDigit} from "../../../../../shared/utils/currency.utils";
import {vat} from "../../../../../shared/utils/vat.utils";
import {
  FarmConfigurationsByCode,
  PricelistData_clientGroups,
  PricelistData_products,
  PriceUnit,
  UpdateProduct,
  UpdateProductVariables
} from "../../../../../__generated__/types";
import {
  findProductPriceForClientGroupCode, findProductPriceForDefaultClientGroup,
  isIndexable,
  suggestedPointsFor
} from "../../../../../shared/utils/product.utils";
import {Button} from "primereact/button";
import {useMutation, useQuery} from "@apollo/client";
import {UpdateProductMutation} from "../../../queries.gql";
import moment from "moment";
import {FarmConfigurationsByCodeQuery} from "../../../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../../../shared/utils/farmConfig.utils";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface ProductPriceCellProps {
  product: PricelistData_products;
  avgPortionPrice: number;
  clientGroup?: PricelistData_clientGroups | undefined | null;
  selected: boolean;
  flexClientGroupCode: string;
}


const ProductPriceCell = (props: ProductPriceCellProps) => {
  const  {activeFarm} = useUserObject();
  const {product, clientGroup, selected} = props;
  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation);

  let clientGroupProductPrice = compact(product.productPrices).find(productPrice => productPrice.clientGroup?.id === clientGroup?.id);

  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id||'', code: FarmConfigCodes.clientGroups_flexCode}});

  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  let showInclVat = clientGroupProductPrice?.clientGroup?.default;
  let standardPrice = findProductPriceForDefaultClientGroup(product);
  if (clientGroup?.code === flexClientGroupCode && standardPrice) {
    let suggestedPoints = suggestedPointsFor(standardPrice.value, product.vat);
    let differentFromSuggestedPoints = suggestedPoints !== clientGroupProductPrice?.value;
    return <div>
      <div>
        {clientGroupProductPrice && <div className="flex flex-row">
          <div className={
            classNames(
              "flex justify-content-end align-items-center flex-column",
              {"font-bold": showInclVat}
            )
          }>
            <div
              className={classNames("pl-1 pr-1 text-sm text-700 text-right", {"text-red-800 font-bold": differentFromSuggestedPoints})}>
              {formatDoubleDigit(clientGroupProductPrice.value)} pt.
            </div>
            <div className={
              classNames("text-xs text-400 text-right")
            }>
              {suggestedPoints} pt.
            </div>
          </div>
          <div>
            {differentFromSuggestedPoints && <Button
              className="p-0 p-button-text text-orange w-auto"
              icon="pi pi-refresh"
              onClick={() => {
                let update = clientGroupProductPrice ? [{
                  where: {id: clientGroupProductPrice.id},
                  data: {endDate: moment()}
                }] : null;

                const variables: UpdateProductVariables = {
                  id: product.id,
                  data: {
                    productPrices: {
                      create: [{
                        value: suggestedPoints,
                        startDate: moment(),
                        clientGroup: {connect: {code: flexClientGroupCode}},
                        unit: PriceUnit.PORTION,
                      }],
                      update: update
                    }
                  }
                }
                updateProduct({variables});
              }}
            />}
          </div>
        </div>}

        {/*{!clientGroupProductPrice*/}
        {/*  && product.portionWillem && product.portionWillem === product.portionDirk && product.portionWillem > 0 &&*/}
        {/*  <Button*/}
        {/*    icon="pi pi-link"*/}
        {/*    className="p-o p-button-link"*/}
        {/*    onClick={() => {*/}
        {/*      if (product.portionWillem) {*/}
        {/*        let portionProductPriceCreate: ProductPriceCreateWithoutProductInput = {*/}
        {/*          value: product.portionWillem,*/}
        {/*          startDate: asIso8601(TODAY),*/}
        {/*          unit: PriceUnit.PORTION,*/}
        {/*          clientGroup: {*/}
        {/*            connect: {*/}
        {/*              id: clientGroup.id*/}
        {/*            }*/}
        {/*          },*/}
        {/*        };*/}

        {/*        const variables: UpdateProductVariables = {*/}
        {/*          id: props.product.id,*/}
        {/*          data: {*/}
        {/*            productPrices: {*/}
        {/*              create: [portionProductPriceCreate]*/}
        {/*            },*/}
        {/*          }*/}
        {/*        };*/}

        {/*        updateProduct({variables, refetchQueries: "active"});*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*}*/}
      </div>
    </div>;
  }

  if (clientGroupProductPrice?.value && (clientGroupProductPrice.value > 0)) {
    return <div>
      <div>
        <div className={
          classNames(
            "flex justify-content-end align-items-center",
            {"font-bold": showInclVat}
          )
        }>
          {formatDoubleDigit(clientGroupProductPrice?.value * (1 + (showInclVat ? vat(product.vat) : 0)))}
          <div className="pl-1 pr-1">&euro;</div>
        </div>
        <div className={classNames(
          "text-xs text-400 text-right",
          {"font-bold": showInclVat}
        )}>
          {showInclVat ? "incl." : "excl."} {vat(product.vat) * 100}%
        </div>
      </div>

      {isIndexable(product) && selected
        && <div className="flex justify-content-end align-items-center text-red-500 text-xs font-bold">
          {formatDoubleDigit(ceil(clientGroupProductPrice?.value * 1.1, 2))}
          <div className="pl-1 pr-1">&euro;</div>
        </div>}

    </div>;
  }
  return <div/>;
};

export default ProductPriceCell;
