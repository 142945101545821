import {useQuery} from '@apollo/client';
import {chain, compact, first} from 'lodash';
import React, {useEffect, useState} from 'react';
import {BasketCompositionsQuery} from "../../../shared/queries/basketComposition.gql";
import {
  BasketCompositions,
  BasketCompositions_basketCompositions, BasketCompositionsVariables,
  BasketCompositionType, FarmConfigurationsByCode
} from "../../../__generated__/types";
import {momentFromIso8601} from "../../../shared/utils/date.utils";
import {Moment} from "moment";
import WeekSelector from "./components/WeekSelector";
import classNames from "classnames";
import BasketsManagementPageHeading from "../BasketsManagementPageHeading";
import {Button} from 'primereact/button';
import HarvestView from './HarvestView/HarvestView';
import PackagingView from './PackagingView/PackagingView';
import {TODAY} from "../../../shared/config";
import {FarmConfigurationsByCodeQuery} from "../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../shared/utils/farmConfig.utils";
import {useUserObject} from "../../../shared/context/UserContext";

const BasketProcessingPage = () => {
  const  {activeFarm} = useUserObject();
  let fridayOfThisWeek = TODAY.startOf('isoWeek').isoWeekday(5);
  const [deliveryWeek, setDeliveryWeek] = useState<Moment>(TODAY);
  const [showHarvestView, setShowHarvestView] = useState<boolean>(true);
  const [showPackagingView, setShowPackagingView] = useState<boolean>(false);
  const [basketComposition, setBasketComposition] = useState<BasketCompositions_basketCompositions>();
  let variables: BasketCompositionsVariables = {
    farmId: activeFarm?.id||'',
    where: {
      type: BasketCompositionType.FIXED,
      deliveryWeek_lte: deliveryWeek.clone().add(2, 'w').startOf('isoWeek'),
      deliveryWeek_gte: deliveryWeek.clone().subtract(2, 'w').startOf('isoWeek'),
    }
  };
  const {data} = useQuery<BasketCompositions>(BasketCompositionsQuery, {
    //pollInterval: 3000,
    variables
  });

  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  useEffect(() => {
    let basketCompositionForDeliveryWeek = chain(data?.basketCompositions)
      .compact()
      .orderBy('deliveryWeek', 'asc')
      .find(basketComposition => momentFromIso8601(basketComposition.deliveryWeek).isSame(deliveryWeek, 'isoWeek'))
      .value();
    setBasketComposition(basketCompositionForDeliveryWeek);

  }, [data?.basketCompositions, deliveryWeek]);

  // const events: any[] = [
  //   {
  //     status: 'Draft',
  //     date: '15/10/2020 10:30',
  //     icon: 'pi pi-shopping-cart',
  //     color: '#9C27B0',
  //     image: 'game-controller.jpg',
  //     completed: true,
  //   },
  //   {status: 'Gepubliceerd', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7', ongoing: true,},
  //   {status: 'Geleverd', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800'},
  // ];
  //
  // const customizedMarker = (item: any) => {
  //   return (
  //     <span
  //       className={
  //         classNames(
  //           "flex w-2rem h-2rem align-items-center border-1 border-400 text-400 justify-content-center border-circle z-1 shadow-1",
  //           {"text-white border-2 border-green-300 bg-green-300": item.completed},
  //           {"border-2 border-yellow-300": item.ongoing},
  //         )}
  //       style={{borderColor: item.color}}
  //     >
  //       <i className="pi pi-check"></i>
  //     </span>);
  // };

  return <div>
    <BasketsManagementPageHeading/>
    <div className="grid">
      <div className="col-12 border-bottom-1 border-200 pb-3 flex align-items-center justify-content-between">
        <div className="">
          <WeekSelector
            onChange={(value) => {
              setDeliveryWeek(value);
            }}
            value={deliveryWeek}
          />
        </div>
        <div className="ml-3 flex align-items-center justify-content-start flex-grow-1">
          <Button
            className={classNames(
              "flex-grow-1 mr-1",
              {"p-button-outlined border-green color-green": !showHarvestView},
            )}
            label={"Oogst"}
            onClick={() => {
              setShowHarvestView(true);
              setShowPackagingView(false);
            }}
          />

          <Button
            className={classNames(
              "flex-grow-1",
              {"p-button-outlined border-green color-green": !showPackagingView},
            )}
            label={"Verdeling"}
            onClick={() => {
              setShowPackagingView(true);
              setShowHarvestView(false);
            }}
          />
        </div>
        {/*<Timeline value={events}*/}
        {/*          layout="horizontal"*/}
        {/*          align="top"*/}
        {/*          marker={customizedMarker}*/}
        {/*          content={(item) => item.status}/>*/}
      </div>
      {/*<div className="col-3 border-right-1 border-200">*/}
      {/*  {basketComposition && <div className="pt-3">*/}
      {/*    <div className="text-xl text-medium mb-2 text-primary">Aantal pakketten</div>*/}
      {/*    <ProductOrdersCounter*/}
      {/*      basketComposition={basketComposition}*/}
      {/*      deliveryWeek={deliveryWeek}*/}
      {/*    />*/}
      {/*  </div>}*/}

      {/*  <div className="text-xl tex-medium mb-2 border-top-1 border-200 mt-3 pt-3 text-primary">Te oogsten</div>*/}
      {/*  <BasketsHarvest deliveryWeek={deliveryWeek}/>*/}
      {/*</div>*/}

      <div className="col-12 pl-3">
        {showHarvestView && <HarvestView deliveryWeek={deliveryWeek} flexClientGroupCode={flexClientGroupCode}/>}
        {showPackagingView && <PackagingView deliveryWeek={deliveryWeek} basketComposition={basketComposition} flexClientGroupCode={flexClientGroupCode}/>}

        {/*{basketComposition && <BasketCompositionViewer expanded basketComposition={basketComposition}/>}*/}
        {/*{!basketComposition && deliveryWeekAsYyyyW && deliveryWeeks.includes(deliveryWeekAsYyyyW) &&*/}
        {/*  <div className="border-1 border-red-500 bg-red-100 p-3 text-red-500">Geen pakketsamenstelling gevonden</div>}*/}
      </div>
    </div>
  </div>;
};

export default BasketProcessingPage;
