import React, { ReactNode, useState } from 'react';

import { omit, get } from 'lodash';

export interface ShoppingCart {
  items: { [key: string]: { productId: string, amount: number }; }
}

interface MarketContextValue {
  shoppingCart: ShoppingCart;
  addToShoppingCart: (productId: string, amount: number) => void;
  removeFromShoppingCart: (productId: string) => void;
}

const DEFAULT_CONTEXT = {
  addToShoppingCart: () => {
  },
  removeFromShoppingCart: () => {},
  shoppingCart: {
    items: {}
  },
};
const MarketContext = React.createContext<MarketContextValue>(DEFAULT_CONTEXT);

export type Props = {
  children: ReactNode;
  marketContextValue?: MarketContextValue;
};


function MarketProvider(props: Props) {
  const {children, marketContextValue} = props;
  //const [value, setValue] = useStateWithLocalStorage<MarketContextValue>('shh', marketContextValue || DEFAULT_CONTEXT);
  const [value, setValue] = useState<MarketContextValue>(marketContextValue || DEFAULT_CONTEXT);

  const addToShoppingCart = (productId: string, amount: number) => {
    const newVar: MarketContextValue = {
      ...value,
      shoppingCart: {
        ...value.shoppingCart,
        items: {
          ...value.shoppingCart.items,
          [productId]: {
            productId,
            amount: get(value,`shoppingCart.items[${productId}].amount`, 0) + amount
          }
        }
      }
    };
    setValue(newVar);
  };

  const removeFromShoppingCart = (productId: string) => {
    const newVar: MarketContextValue = {
      ...value,
      shoppingCart: {
        ...value.shoppingCart,
        items: {
          ...omit(value.shoppingCart.items, productId),
        }
      }
    };
    setValue(newVar);
  };

  return (
    <MarketContext.Provider value={{
      ...value,
      addToShoppingCart,
      removeFromShoppingCart,
    }}>
      {children}
    </MarketContext.Provider>
  );
}

const useMarket = () => React.useContext(MarketContext);

export { MarketProvider, useMarket };
