import React from 'react';
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Row} from "primereact/row";

interface CreditCancelledDeliveriesProps {
 onClick: () => void;
}

const CreditCancelledDeliveries = (props: CreditCancelledDeliveriesProps) => {
  return <Row>
      <Column colSpan={5} footer={() =>
          <Button
              text
              label="Krediteer alle geannuleerde leveringen"
              icon="pi pi-minus-circle"
              severity="warning"
              onClick={() => {
                  props.onClick();
              }}
          />
      }/>
  </Row>;
};

export default CreditCancelledDeliveries;
