import {BasketType, Frequency, MembershipSubType, MembershipType} from "../../__generated__/types";
import {FormulaPeriod, FormulaType, SubscriptionSubType} from "../types/types";
import {formulas} from "../config.formulas";
import {
  calculateDeliveryWeeksForMembershipFromStartDate,
  calculateMembershipStartDate,
  calculateSelfHarvestTotalPrice
} from "./membership.utils";
import moment, {Moment} from "moment";
import {Member} from "../types/members.interface";
import {compact, values} from "lodash";

export const periodBySubscriptionProductCode = (subscriptionProductCode: string | undefined): FormulaPeriod | undefined => {
  let periods: FormulaPeriod[] = compact(formulaBySubscriptionProductCode(subscriptionProductCode)?.periods);
  return periods.find(p => [p.code, p.codeSmall, p.codeLarge].includes(subscriptionProductCode));
}

export const priceForSelfHarvest = (
  subscriptionDetails: {
    trial: boolean,
    biWeekly: boolean,
    membershipSubType?: MembershipSubType | undefined | null,
    type?: MembershipType | undefined,
    members: Member[],
    startDate?: Moment | undefined,
  },
  season: "2022-2023"
) => {
  const {
    trial, biWeekly, membershipSubType, type, members
  } = subscriptionDetails;
  let pricing = pricingForCurrentFormula(type, membershipSubType, undefined);

  if (!pricing || type === MembershipType.BASKETS || !membershipSubType) {
    return NaN;
  }

  let pricePerAdultMember;
  if (trial && pricing.trial) {
    //pricePerAdultMember = pricing.trial;
    pricePerAdultMember = calculateSelfHarvestTotalPrice(pricing.trial, members, trial, season, 1);
  } else {
    const start = subscriptionDetails.startDate || calculateMembershipStartDate(moment(), season, type, membershipSubType);
    let weeksInFormula = formulas[membershipSubType].weeks[season].length;
    let weeksInMembership = calculateDeliveryWeeksForMembershipFromStartDate(start, membershipSubType, season, biWeekly, biWeekly ? Frequency.BI_WEEKLY : Frequency.WEEKLY, trial).length;
    let ratio = weeksInMembership / weeksInFormula;
    pricePerAdultMember = calculateSelfHarvestTotalPrice(pricing.target, members, trial, season, ratio);
  }

  return pricePerAdultMember;
};

export const priceForBasket = (
  subscriptionDetails: {
    trial: boolean,
    biWeekly: boolean,
    startDate?: Moment | undefined,
    membershipSubType?: MembershipSubType | undefined | null,
    type?: MembershipType | undefined,
    basketType?: BasketType | undefined | null
  },
  season: "2022-2023") => {
  const {trial, biWeekly, membershipSubType, type, basketType, startDate} = subscriptionDetails;
  let pricing = pricingForCurrentFormula(type, membershipSubType, basketType);

  if (!pricing || type === MembershipType.SELF_HARVEST || !membershipSubType) {
    return NaN;
  }

  if (trial && pricing.trial) {
    return pricing.trial;
  }

  const start = startDate || calculateMembershipStartDate(moment(), season, type, membershipSubType);

  let weeksInFormula = formulas[membershipSubType].weeks[season].length;
  let weeksInMembership = calculateDeliveryWeeksForMembershipFromStartDate(start, membershipSubType, season, biWeekly, biWeekly ? Frequency.BI_WEEKLY : Frequency.WEEKLY, trial).length;
  let ratio = weeksInMembership / weeksInFormula;
  return ratio * pricing.target;
};

export const pricingForCurrentFormula = (
  type: MembershipType | undefined,
  membershipSubType: MembershipSubType | undefined | null,
  basketType: BasketType | undefined | null
) => {
  if (type === MembershipType.SELF_HARVEST) {
    return pricingForSelfHarvestFormula();
  } else {
    if (!membershipSubType) {
      return undefined;
    }
    return pricingForBasketFormula(membershipSubType, basketType);
  }
};

export const pricingForBasketFormula = (
  membershipSubType: MembershipSubType,
  basketType: BasketType | undefined | null,
) => {
  if (!basketType) {
    return undefined;
  }

  let price = formulaByType(membershipSubType)?.pricing.find((p: { type?: BasketType }) => p.type === basketType)?.price;
  if (price) {
    price = {
      ...price,
      target: price.target,
    }
  }
  return price;
};

export const formulaByType = (membershipSubType: SubscriptionSubType | MembershipSubType): FormulaType => {
  return formulas[membershipSubType];
};

export const formulaBySubscriptionProductCode = (subscriptionProductCode: string | undefined): FormulaType | undefined => {
  if (!subscriptionProductCode) {
    return undefined;
  }
  let formulaTypes: FormulaType[] = values(formulas);
  return formulaTypes
    .find(formula => compact(formula.periods).findIndex(p => [p.code, p.codeSmall, p.codeLarge].includes(subscriptionProductCode)) > -1);
};

export const pricingForSelfHarvestFormula = () => {
  return formulas[MembershipSubType.SELF_HARVEST]?.pricing[0].price;
};
