import React from "react";
import {compact, difference, first, get, last, map} from "lodash";
import {formatCurrency} from "../../../../../../shared/utils/currency.utils";
import {vat} from "../../../../../../shared/utils/vat.utils";
import {
  isBasketProductOrder,
  isBoxProductOrder, isExtraProductProductOrder,
  isFlexBasketProductOrder,
  isSelfHarvestProductOrder,
  productOrdersTotalIncl
} from "../../../../../../utils/productOrder.utils";
import FrequencyColumn from "./FrequencyColumn";
import DeleteProductOrderButton from "./DeleteProductOrderButton";
import {firstPlannedDelivery} from "shared/utils/productOrder.utils";
import {toCity} from "../../../../../../shared/utils/deliveryLocation.utils";
import {SubscriptionClientOrders_productOrders} from "../../../SubscriptionTable/SubscriptionTable";
import {
  deliveryWeeksForExtraSubscription,
  deliveryWeeksForSubscription
} from "../../../../../../shared/utils/subscription.utils";
import {Moment} from "moment";
import {
  asDayFullDdMmYyyy, asIso8601,
  iso8601_DayFullDdMmm,
  momentFromIso8601,
  momentFromYyyyW
} from "../../../../../../shared/utils/date.utils";
import {Button} from "primereact/button";
import moment from "moment";
import {ProductOrderUpdateVariables} from "../../../../../../__generated__/types";

export const productColumn = (productOrder: SubscriptionClientOrders_productOrders) => {
  return <div>
    <div>{productOrder.product?.name || productOrder.productDescription}</div>
    {isBasketProductOrder(productOrder) &&
      <div className="text-500">{toCity(firstPlannedDelivery(productOrder.deliveries)?.deliveryLocation)}</div>}
    {productOrder.portions !== null && productOrder.usedPortions !== null && isFlexBasketProductOrder(productOrder) &&
      <div>Resterende punten: {productOrder.portions - productOrder.usedPortions}</div>
    }
  </div>;

};
export const deliveriesColumn = (productOrder: SubscriptionClientOrders_productOrders,
                                 deliveryStartDate: Moment,
                                 mainSubscriptionProductCode: string | undefined,
                                 updateProductOrder: (opts: {}) => any) => {

  let isBasket = isBasketProductOrder(productOrder);
  let value = compact(productOrder.deliveries).length;
  if (isBasket) {
    return <div>{`${value} leveringen`}</div>;
  } else if (isExtraProductProductOrder(productOrder)) {
    let deliveryWeeks = deliveryWeeksForExtraSubscription(mainSubscriptionProductCode,
      productOrder.frequency,
      productOrder.product?.category?.code,
      moment(deliveryStartDate));

    let plannedDeliveryDates = map(productOrder.deliveries, d => momentFromIso8601(d.plannedDeliveryDate).startOf('day').isoWeekday(5).toDate().valueOf());

    let actualDeliveryDates = deliveryWeeks.weeks.map(week => momentFromYyyyW(week).startOf('day').isoWeekday(5).toDate().valueOf());

    let lastPlannedDelivery = last(plannedDeliveryDates);
    let missingDeliveryDates = difference(actualDeliveryDates, plannedDeliveryDates)
      .filter(d => lastPlannedDelivery && new Date(d) > new Date(lastPlannedDelivery))
      .map(d => moment(d));

    let priceExcl = (productOrder.priceExcl / plannedDeliveryDates.length)*(missingDeliveryDates.length+plannedDeliveryDates.length);
    console.log(productOrder.product?.code, missingDeliveryDates.map(d => asDayFullDdMmYyyy(moment(d))), priceExcl);

    if (value !== deliveryWeeks.weeks.length) {
      return <div>
        <div>{`${value} leveringen`}</div>
        <div><Button
          onClick={() => {
debugger;
            const variables: ProductOrderUpdateVariables = {
              where: {id: productOrder.id},
              data: {
                deliveries: {
                  create: missingDeliveryDates.map(date => ({
                    plannedDeliveryDate: asIso8601(date),
                    deliveryLocation: first(productOrder.deliveries)?.deliveryLocation
                  }))
                }
              }
            };

            updateProductOrder({variables, refetchQueries: "active"});

          }}
        >Corrigeer</Button></div>
      </div>;
    } else {
      return <div>
        {`${value} leveringen`}
      </div>;
    }
  } else if (isSelfHarvestProductOrder(productOrder)) {
    return <div>{`${value} weken`}</div>;
  } else if (isBoxProductOrder(productOrder)) {
    return <div>{`${productOrder.quantity} bakken`}</div>;
  }

  return <div>{productOrder.quantity}</div>
};

export const actionsColumn = (productOrder: SubscriptionClientOrders_productOrders) => {
  return <div>
    <DeleteProductOrderButton productOrder={productOrder}/>
  </div>
};

export const frequencyColumn = (productOrder: SubscriptionClientOrders_productOrders) => {
  return <FrequencyColumn productOrder={productOrder}/>;
};

export const priceInclColumn = (productOrders: SubscriptionClientOrders_productOrders[]) => (productOrder: SubscriptionClientOrders_productOrders) => {
  const vatValue = productOrder.product?.vat || productOrder.vat;

  let discountProductOrderExcl = productOrders
    .find(possibleDiscountProductOrder =>
      possibleDiscountProductOrder.product?.id === productOrder.product?.id
      && get(possibleDiscountProductOrder, 'ambassadorDiscount')
    )?.priceExcl || 0;
  return <div>
    {formatCurrency((productOrder.priceExcl + discountProductOrderExcl) * (1 + vat(vatValue)))}
  </div>;
};

export const totalFooterColumn = (clientOrder: {
  orderDiscount?: number | null | undefined;
  productOrders: SubscriptionClientOrders_productOrders[] | null;
}) => {
  return formatCurrency(productOrdersTotalIncl([clientOrder]));
};

