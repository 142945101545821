import React from 'react';
import {useQuery} from "@apollo/client";
import {ClientsQuery} from "../../../shared/queries/client.gql";
import {Dropdown} from "primereact/dropdown";
import {compact} from "lodash";
import {Clients, ClientsVariables} from "../../../__generated__/types";
import { clientName } from 'shared/utils/client.utils';
import {useUserObject} from "../../../shared/context/UserContext";

interface AdminClientSelectorProps {
  clientId: string | undefined;
  onChange: (clientId: string) => void;
}

const AdminClientSelector = (props: AdminClientSelectorProps) => {
  const {activeFarm} = useUserObject();
  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data} = useQuery<Clients>(ClientsQuery, {variables});
  return <div className="bg-blue-50 border-2 border-blue-500 p-2 m-2">
    <div className="flex align-items-center">
      <div>Selecteer een klant:</div>
      <div className="ml-2">
        <Dropdown
          filter showClear filterBy="fullName"
          options={compact(data?.clients)
            .map(client => ({
              ...client,
              fullName: clientName(client)
            }))
          }
          optionLabel={'fullName'}
          optionValue={'id'}
          value={props.clientId}
          onChange={(e) => {
            const clientId = e.value;
            props.onChange(clientId);
          }}
        />
      </div>
    </div>
  </div>;
};

export default AdminClientSelector;
