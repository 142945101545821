import styled from "styled-components";

export const WeekBar = styled.div<{ nrOfWeeks: number, width: string }>`
  //border-radius: 11px;
  //display: inline-flex;
  //flex-direction: row;
  //align-items: center;
  display: inline-grid;

  grid-template-columns: repeat(${props => props.nrOfWeeks}, ${props => `minmax(15px,${props.width})`});
  align-items: center;
  grid-gap: 1px;

  span.week {
    box-sizing: border-box;
    color: white;
    font-size: 11px;
    line-height: 11px;
    width: ${props => props.width};
    height: 15px;
    display: grid;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid var(--green);
    background-color: rgb(204 221 185);

    &:hover,
    &.selectedWeek {
      height: 30px;
    }

    &.weekWithDelivery {
      background-color: var(--green);
    }

    &.deliveryWeekWithDeliveredDelivery {
      //color: var(--green-20);
    }

    &.cancelledDelivery {
      border: 2px solid var(--red-300);
      background-color: var(--red-100);
    }

    &.possibleWeeks {
      background-color: var(--brown);
    }
  }

  span.month {
    font-size: 0.9em;
    text-transform: uppercase;
    color: var(--orange-40);
    text-align: center;
  }
`;
