import React from 'react';
import DashboardItem from "../../../../../components/Dashboard/DashboardItem";
import {asWeekYear, momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {chain, compact, first, map, times, uniq, xor} from "lodash";
import {useQuery} from "@apollo/client";
import {
  BasketYearOverview,
  BasketYearOverviewVariables,
  FarmConfigurationsByCode
} from "../../../../../__generated__/types";
import {DashboardItemYearOverviewQuery} from './DashboardItemYearOverview.gql';
import {Chart} from 'primereact/chart';
import moment from 'moment';
import {
  filterFixedBasketProductOrders,
  filterFlexBasketProductOrders
} from "../../../../../shared/utils/productOrder.utils";
import {useUserObject} from "../../../../../shared/context/UserContext";
import farmConfigValue from "../../../../FarmManagement/FarmConfigViewer/FarmConfigValue";
import FarmConfigValue from "../../../../FarmManagement/FarmConfigViewer/FarmConfigValue";
import {FarmConfigCodes, getFarmConfig} from "../../../../../shared/utils/farmConfig.utils";
import {FarmConfigurationsByCodeQuery} from "../../../../FarmManagement/farm.gql";
import {FlexCodes} from "../../../../../shared/types/types";

const DashboardItemYearOverview = () => {
  const {activeFarm} = useUserObject();
  // const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.baskets_flexCodes}});
  //
  // let flexCodes = first(farmConfig_flexCode?.farmConfigs)?.value.value as FlexCodes;

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
  const variables: BasketYearOverviewVariables = {farmId: activeFarm?.id||''};
  const {data, loading} = useQuery<BasketYearOverview>(DashboardItemYearOverviewQuery,{variables});

  const startDate = moment().startOf('month').subtract(12, 'months');
  const endDate = moment().endOf('months').add(8, 'months');

  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          fontColor: textColor
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500
          }
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      }
    }
  };

  // const flexCodes = getFarmConfig(activeFarm?.config, FarmConfigCodes.baskets_flexCodes);
  // let flexBaskets = filterFlexBasketProductOrders(compact(data?.productOrders), flexCodes);
  let flexBaskets = filterFlexBasketProductOrders(compact(data?.productOrders));
  let fixedBaskets = filterFixedBasketProductOrders(compact(data?.productOrders));

  let visibleWeeks = times(endDate.diff(startDate, "weeks"));

  let allFlexDeliveries = chain(flexBaskets)
    .map('deliveries')
    .flatten()
    .compact()
    .value();

  let allFixedDeliveries = chain(fixedBaskets)
    .map('deliveries')
    .flatten()
    .compact()
    .value();

  let weekData = visibleWeeks.map(weekIndex => {
    let week = startDate.clone().add(weekIndex, 'weeks');
    let newVar = {
      flex: allFlexDeliveries
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(week, 'week')).length,
      flexItems: allFlexDeliveries
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(week, 'week')),
      fixed: allFixedDeliveries
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(week, 'week')).length,
      fixedItems: allFixedDeliveries
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(week, 'week')),
    };

    return newVar
  });

  const chartData = {
    labels: visibleWeeks
      .map(weekIndex => {
        return asWeekYear(startDate.clone().add(weekIndex, 'weeks'))
      }),
    datasets: [
      {
        label: 'Flex',
        backgroundColor: documentStyle.getPropertyValue('--blue-500'),
        borderColor: documentStyle.getPropertyValue('--blue-500'),
        data: map(weekData, 'flex'),
      },
      {
        label: 'Vast',
        backgroundColor: documentStyle.getPropertyValue('--pink-500'),
        borderColor: documentStyle.getPropertyValue('--pink-500'),
        data: map(weekData, 'fixed'),
      }
    ]
  };


  return <DashboardItem
    width={12}
    color={'green'}
    icon={'icon-harvest-box'}
    label={"Jaaroverzicht"}
    value={''}
    loading={loading}
  >
    {loading && <div>Laden...</div>}

    <Chart
      type={"bar"}
      data={chartData}
      options={chartOptions}
    />

  </DashboardItem>;
};

export default DashboardItemYearOverview;
