// Create styles
import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingTop: 25,
    paddingBottom: 120,
  },
  header: {
    flexDirection: "column",
    justifyContent: 'space-between',
  },
  image: {
    marginBottom: 0,
  },
  section: {
    margin: 10,
    flexDirection: "row",
    justifyContent: 'space-between',
  },
  recipient: {
    margin: 10,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: 60,
  },
  th: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    border: 0,
    borderBottom: 1,
    borderBottomColor: '#748F56',
    borderBottomStyle: 'solid',
    padding: 2,
    color: '#748F56',
    fontSize: 10,
  },
  td: {
    border: 0,
    padding: 2,
    fontSize: 10,
  },
  tdRight: {
    border: 0,
    padding: 2,
    textAlign: "right",
    fontSize: 10,
  },
  products: {
    width: '100%',
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  contentWrapper: {
    margin: 20,
  },
  margin10: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
  },
  tableSummaryRowValue: {
    paddingLeft: 10,
    width: 120,
    textAlign: 'right',
  },
  tableSummaryRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 4,
    marginTop: 4,
  },
  spacer: {
    margin: 8,
  },
  alignRight: {
    textAlign: "right",
  },
  invoiceMeta: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
  small: {fontSize: 9},
  body: {
    fontSize: 10,
    marginBottom: 140
  },
});
