import React from 'react';
import { translatedUnit } from "../../../shared/utils/unit.utils";
import { HarvestItem } from "./HarvestList";
import { Checkbox } from "primereact/checkbox";

interface HarvestItemRowProps {
  value: HarvestItem;
  checked: boolean;
  onSelectionChange: (checked: boolean) => void;
}

const HarvestItemRow = (props: HarvestItemRowProps) => {
  const {product, amount} = props.value;
  const unit = product.unit;
  return <div style={{display: "contents"}}>
    <Checkbox
      value={product.id}
      checked={props.checked}
      onChange={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onSelectionChange(e.value);
      }}
    />
    {/*<div>*/}
    {/*  {product.image && <img style={{width: '30px'}} src={product.image}/>}*/}
    {/*</div>*/}
    <div>{product.name}</div>
    <div>{amount} {unit && translatedUnit(unit)}</div>
  </div>;
};

export default HarvestItemRow;
