import React, {PropsWithChildren} from 'react';

interface DescriptionItemProps {
 label: string;
}

const DescriptionItem = (props: PropsWithChildren<DescriptionItemProps>) => {
  return <li className="flex align-items-start py-1 px-2 border-top-1 border-300 flex-wrap">
    <div className="w-5 md:w-3 font-medium text-right px-2 color-green">
      {props.label}
    </div>
    <div className="text-900 w-7 md:w-8 md:flex-order-0 flex-order-1 flex flex-wrap gap-1">
      {props.children}
    </div>
  </li>;
};

export default DescriptionItem;
