import React, {useState} from 'react';
import {Dialog} from "primereact/dialog";
import DialogInputText from "../../../../shared/components/DialogInputText";
import {compact, isEmpty, omit, set} from "lodash";
import {RadioButton} from "primereact/radiobutton";
import classNames from "classnames";
import {Button} from "primereact/button";
import {
  ClientGroups,
  Clients_clients,
  ClientType,
  SalesData_clientGroups,
  UpsertClientVariables
} from "../../../../__generated__/types";
import {useQuery} from "@apollo/client";
import {ClientGroupsQuery} from "../../../../shared/queries/clientGroup.gql";
import {useClientUpsertMutation} from "../../../../mutations/client.mutations";
import {useUserObject} from "../../../../shared/context/UserContext";

interface ClientUpsertDialogProps {
  onUpdate: (client: Partial<Clients_clients>) => void;
  onCreate: (client: Partial<Clients_clients>) => void;
  onHide: () => void;
  client: Partial<Clients_clients> | null;
}

let emptyClient: Partial<Clients_clients> = {
  name: '',
  email: '',
  billingEmail: '',
  phone: '',
  street: '',
  streetNumber: '',
  city: '',
  cityPostalCode: '',
  vatNumber: '',
  ibanNumbers: [],
  group: undefined,
};

const ClientUpsertDialog = (props: ClientUpsertDialogProps) => {
  const {activeFarm} = useUserObject();
  const {data: clientGroupsData} = useQuery<ClientGroups>(ClientGroupsQuery, {variables: {farmId: activeFarm?.id}});
  let [upsertClient] = useClientUpsertMutation();

  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [client, setClient] = useState<Partial<Clients_clients>>(props.client || emptyClient);

  const hideDialog = () => {
    setSubmitted(false);
    props.onHide();
  };

  const saveClient = () => {
    setSubmitted(true);

    if (isValid) {
      let _client = {...client};

      const variables: UpsertClientVariables = {
        id: _client.id || '',
        createData: omit({
          ...omit(_client, ['transactionTotal', '__typename', 'id', 'createdAt', 'updatedAt']) as Clients_clients,
          ibanNumbers: {
            set: _client.ibanNumbers
          },
          farm: {
            connect: {id: activeFarm?.id ||''}
          },
          group: {
            connect: {id: _client.group?.id}
          }
        }, ["customerInfo", "bankTransactions"]),
        updateData: omit({
          ...omit(_client, ['transactionTotal', '__typename', 'id', 'createdAt', 'updatedAt']) as Clients_clients,
          ibanNumbers: {
            set: _client.ibanNumbers
          },
          farm: {
            connect: {id: activeFarm?.id ||''}
          },
          group: {
            connect: {id: _client.group?.id}
          }
        }, ["customerInfo", "bankTransactions"]),
      };

      upsertClient({variables: variables})

      if (client.id) {
        props.onUpdate(client);
      } else {
        props.onCreate(client);
      }
      hideDialog();
      setClient(emptyClient);
    }
  };

  const clientDialogFooter = (
    <React.Fragment>
      <Button label="Annuleren" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
      <Button disabled={!isValid} label="Opslaan" icon="pi pi-check" className="p-button-text" onClick={saveClient}/>
    </React.Fragment>
  );

  const onInputChange = (e: any, name: keyof Clients_clients) => {
    const val = (e.target && e.target.value) || '';
    let _client = {...client};
    set(_client, name, val);

    setClient(_client);

    const hasName: boolean = !isEmpty(_client.name) || !isEmpty(_client.companyName);

    setIsValid(hasName && !isEmpty(_client.group?.id));
  };

  const onClientGroupChange = (clientGroup: SalesData_clientGroups) => (e: any) => {
    let _client = {...client};
    _client['group'] = {id: e.value} as SalesData_clientGroups;
    _client['type'] = clientGroup.name !== 'Particulier' ? ClientType.BUSINESS : ClientType.CUSTOMER
    setClient(_client);
    const hasName: boolean = !isEmpty(_client.name) || !isEmpty(_client.companyName);

    setIsValid(hasName && !isEmpty(_client.group?.id));
  };

  return <Dialog visible={true} style={{width: '450px'}} header="Klant Details" modal className="p-fluid"
                 footer={clientDialogFooter} onHide={hideDialog}>
    <DialogInputText
      label={'Naam'}
      id={'name'}
      onChange={e => onInputChange(e, 'name')}
      value={client.name}
      submitted={submitted}
    />
    <DialogInputText
      label={'Voornaam'}
      id={'firstName'}
      onChange={e => onInputChange(e, 'firstName')}
      value={client.firstName}
      submitted={submitted}
    />
    <DialogInputText
      label={'Bedrijfsnaam'}
      id={'companyName'}
      onChange={e => onInputChange(e, 'companyName')}
      value={client.companyName}
      submitted={submitted}
    />
    <DialogInputText
      label={'E-mailadres'}
      id={'email'}
      onChange={e => onInputChange(e, 'email')}
      value={client.email}
      required
      submitted={submitted}
    />
    <DialogInputText
      label={'E-mailadres facturatie'}
      id={'billingEmail'}
      onChange={e => onInputChange(e, 'billingEmail')}
      value={client.billingEmail}
      submitted={submitted}
    />
    <DialogInputText
      label={'GSM / Telefoon'}
      id={'phone'}
      onChange={e => onInputChange(e, 'phone')}
      value={client.phone}
      submitted={submitted}
    />
    <DialogInputText
      label={'Straat'}
      id={'street'}
      onChange={e => onInputChange(e, 'street')}
      value={client.street}
      submitted={submitted}
    />
    <DialogInputText
      label={'Huisnummer'}
      id={'streetNumber'}
      onChange={e => onInputChange(e, 'streetNumber')}
      value={client.streetNumber}
      submitted={submitted}
    />
    <DialogInputText
      label={'Gemeente'}
      id={'city'}
      onChange={e => onInputChange(e, 'city')}
      value={client.city}
      submitted={submitted}
    />
    <DialogInputText
      label={'Postcode'}
      id={'cityPostalCode'}
      onChange={e => onInputChange(e, 'cityPostalCode')}
      value={client.cityPostalCode}
      submitted={submitted}
    />
    <DialogInputText
      label={'Btw-nummer'}
      id={'vatNumber'}
      onChange={e => onInputChange(e, 'vatNumber')}
      value={client.vatNumber}
      submitted={submitted}
    />
    <DialogInputText
      label={'Bankrekeningnummer'}
      id={'ibanNumber'}
      onChange={e => setClient(value => ({...value, ibanNumbers: (e.target.value || '').split(',')}))}
      value={client.ibanNumbers?.join(',')}
      submitted={submitted}
    />
    <div className="p-field mt-2">
      <label className="mb-3">Klantgroep</label>
      {submitted && !client.group && <small className="p-error">Klantgroep is verplicht.</small>}
      <div className="p-formgrid p-grid">
        {compact(clientGroupsData?.clientGroups).map(clientGroup =>
          <div className="p-field-radiobutton p-col-6">
            <RadioButton inputId={`clientGroup${clientGroup.id}`}
                         name="clientGroup"
                         value={clientGroup.id}
                         onChange={onClientGroupChange(clientGroup)}
                         required
                         className={classNames({'p-invalid': submitted && !client.group})}
                         checked={client.group?.id === clientGroup.id}/>
            <label htmlFor={`clientGroup${clientGroup.id}`}>{clientGroup.name}</label>
          </div>
        )}
      </div>
    </div>
  </Dialog>;
};

export default ClientUpsertDialog;
