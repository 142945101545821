import gql from "graphql-tag";

export const HarvestDeliveryReferencesQuery = gql`
query HarvestDeliveryReferences($referenceIds: [ID!]) {
  productOrderDeliveries(where: {
  	id_in: $referenceIds
  }) {
    deliveryLocation
    productOrder { id clientOrder { id client {id firstName name companyName}}}
  }
  shopOrders(where: {
  	id_in: $referenceIds
  }) {
    deliveryLocation
    id shopClient {id firstName email name}
  }
  clientOrders(where: {
  	id_in: $referenceIds
  }) {
    id
    client {id firstName name companyName city}
  }
}`;
