import React, {ReactNode, useEffect, useState} from 'react';

import {useLazyQuery} from "@apollo/client";
import {useAuth0} from "@auth0/auth0-react";
import {User, User_user, User_user_farms} from "../../__generated__/types";
import {compact, first, last} from "lodash";
import {UserQuery} from "./UserContext.gql";

interface UserContextValue {
  user: User_user | null,
  activeFarm: User_user_farms | null,
  setActiveFarm: (farm: User_user_farms) => void,
}

const DEFAULT_CONTEXT = {
  user: null,
  activeFarm: null,
  setActiveFarm: () => {
  },
};
const UserContext = React.createContext<UserContextValue>(DEFAULT_CONTEXT);

export type Props = {
  children: ReactNode;
  userContextValue?: UserContextValue;
};

const SESSION_STATE_ACTIVE_FARM_ID = 'planningState';

function UserProvider(props: Props) {
  const {children, userContextValue} = props;
  const {user} = useAuth0();
  const [value, setValue] = useState<UserContextValue>(userContextValue || DEFAULT_CONTEXT);

  const [getUser, {data, loading}] = useLazyQuery<User>(UserQuery);


  useEffect(() => {
    if (value.activeFarm?.id) {
      sessionStorage.setItem(SESSION_STATE_ACTIVE_FARM_ID, value.activeFarm.id);
    }
  }, [value]);

  useEffect(() => {
    let email = user?.email;
    if (email) {
      getUser({variables: {where: {email}}})
    }
  }, [user]);

  useEffect(() => {
    if (data?.user) {
      let userFarms = compact(data.user?.farms);


      let activeFarm: User_user_farms | null = null;
      const storedState = sessionStorage.getItem(SESSION_STATE_ACTIVE_FARM_ID);
      activeFarm = userFarms.find(farm => farm.id === storedState) || null;

      if (!activeFarm) {
        sessionStorage.removeItem(SESSION_STATE_ACTIVE_FARM_ID);
      }

      if (userFarms.length > 0) {
        activeFarm = first(userFarms) || null;
      }

      setValue(value => ({
        ...value,
        user: data.user,
        activeFarm,
      }))
    }
  }, [data]);

  const setActiveFarm = (farm: User_user_farms) => {
    setValue(value => ({
      ...value,
      activeFarm: farm,
    }))
  }

  return (
    <UserContext.Provider value={{
      ...value,
      setActiveFarm,
    }}>
      {children}
    </UserContext.Provider>
  );
}

const useUserObject = () => React.useContext(UserContext);

export {UserProvider, useUserObject};
