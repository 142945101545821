import {compact, intersection} from "lodash";
import {Permission} from "../../__generated__/types";


export const hasAtLeastOneMatchingPermission = (permissions: Permission[] | undefined, userPermissions: Permission[] | undefined) => {
  let nullSafePermissions = compact(permissions);
  if (nullSafePermissions.length === 0) {
    return true;
  }

  return intersection(nullSafePermissions, compact(userPermissions)).length > 0;
};

export const hasPlannerPermission = (user: { permissions: Permission[] } | null) =>
  compact(user?.permissions)
    .filter(permission => permission.startsWith("PLANNER_")).length > 0;


export const hasFarmManagementPermission = (user: { permissions: Permission[] } | null) =>
  hasPermission(user, Permission.FARM_MANAGEMENT_ADMIN);

export const hasAdminPermission = (user: { permissions: Permission[] } | null) =>
  hasPermission(user, Permission.PLANNER_ADMIN);

export const hasFarmAdminPermission = (user: { permissions: Permission[] } | null) =>
  hasPermission(user, Permission.PLANNER_FARM_ADMIN);

export const hasPermission = (user: { permissions: Permission[] } | null, permission: Permission) =>
  compact(user?.permissions)
    .findIndex(userPermission => userPermission === permission) > -1;
