import React, {useEffect, useState} from 'react';
import {createInvoiceData} from "../../../sales/utils/invoice.utils";
import {useMutation, useQuery} from "@apollo/client";
import {
    ClientOrder,
    ClientOrderUpdate,
    ClientOrderUpdateVariables,
    CreateClientOrderInvoiceVariables,
    MarkClientOrdersForInvoiceAsSent,
    PaymentCreateWithoutInvoiceInput,
    Sequences,
    Sequences_sequences,
    SequencesVariables,
    SequenceType
} from "../../../../__generated__/types";
import {ClientOrderQuery, ClientOrderUpdateMutation} from "../../../../shared/queries/clientOrder.gql";
import {SequencesQuery} from "../../../../shared/queries/sequence.gql";
import {compact, last, omit} from "lodash";
import {Button} from "primereact/button";
import {PDFViewer} from "@react-pdf/renderer";
import ClientOrderInvoicePdf from "../../../../shared/pdf/ClientOrderInvoicePdf";
import {Dialog} from "primereact/dialog";
import {nextInvoiceNumber} from "../../../../shared/utils/sequence.utils";
import {useCreateClientOrderInvoice} from 'mutations/invoice.mutations';
import {sendInvoiceToClient} from "../MailButton";
import {MarkClientOrdersForInvoiceAsSentMutation} from "../../../sales/queries.gql";
import PaymentOptionsSelection from "./PaymentOptionsSelection";
import {ClientOrderInvoicePdfData} from "../../../../shared/utils/invoice.utils";
import {Checkbox} from "primereact/checkbox";
import {InputNumber} from "primereact/inputnumber";
import SubscriptionProductOrderList
    from '../SubscriptionDetail/components/SubscriptionProductOrderList/SubscriptionProductOrderList';
import {useUserObject} from "../../../../shared/context/UserContext";
import {Message} from "primereact/message";

interface CreateInvoiceDialogProps {
    clientOrderId: string;
    onHide: () => void;
}

const CreateInvoiceDialog = (props: CreateInvoiceDialogProps) => {
    const dryRun = false;
    const {activeFarm} = useUserObject();
    const [invoiceData, setInvoiceData] = useState<ClientOrderInvoicePdfData>();
    const [sequence, setSequence] = useState<Sequences_sequences>();
    const [isSponsoring, setIsSponsoring] = useState<boolean>(false);
    const [invoiceNumber, setInvoiceNumber] = useState<string>();
    const [sending, setSending] = useState<boolean>(false);
    const [payments, setPayments] = useState<PaymentCreateWithoutInvoiceInput[]>([]);

    const {data, refetch} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});
    let variables: SequencesVariables = {
        farmId: activeFarm?.id || '',
        where: {
            sequenceType: SequenceType.INVOICE_CLIENT,
            season: null,
        }
    };
    const {data: sequenceData} = useQuery<Sequences>(SequencesQuery, {
        variables
    });
    const [createClientOrderInvoice] = useCreateClientOrderInvoice();

    const [clientOrderUpdate] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation, {refetchQueries: 'active'});
    const [markClientOrdersForInvoiceAsSentRemote] = useMutation<MarkClientOrdersForInvoiceAsSent>(MarkClientOrdersForInvoiceAsSentMutation, {refetchQueries: 'active'});

    useEffect(() => {
        const sequence = last(sequenceData?.sequences);
        if (sequence) {
            setInvoiceNumber(nextInvoiceNumber(sequence));
            setSequence(sequence);
        }
    }, [sequenceData]);


    useEffect(() => {
        if (data?.clientOrder && invoiceNumber) {
            const clientOrderInvoicePdfData = createInvoiceData(data?.clientOrder, invoiceNumber, isSponsoring, payments);
            setInvoiceData(clientOrderInvoicePdfData);
        }
    }, [data, invoiceNumber, payments, isSponsoring]);

    let saveInvoice = () => {
        setSending(true);
        if (sequence && data?.clientOrder && invoiceData) {
            let nextSequenceValue = sequence.nextValue + 1;

            const variables: CreateClientOrderInvoiceVariables = {
                nextSequenceValue,
                sequenceId: sequence.id,
                clientOrders: [data.clientOrder.id],
                invoiceData: {
                    ...omit(invoiceData, "paymentCreates"),
                    payments: invoiceData.paymentCreates,
                    clientOrders: {connect: [{id: data.clientOrder.id}]},
                },
            };

            createClientOrderInvoice({variables}).then(() => {
                refetch().then(r => {
                    if (r.data?.clientOrder) {
                        sendInvoiceToClient(r.data.clientOrder, false, dryRun, (invoice) => {
                            markClientOrdersForInvoiceAsSentRemote({
                                variables: {id: invoice.id}
                            }).then(() => {
                                setSending(false);
                                props.onHide();
                            })
                        }, undefined, undefined);
                    }
                });
            }).catch((e) => {
                console.error(e);
            });
        }
    };
    return <Dialog
        onHide={props.onHide}
        visible
        header={'Factuur maken'}
        footer={<>
            <Button className={'p-button-link'} label="Annuleren" onClick={props.onHide}/>
            <Button label="Factuur verzenden" disabled={!sequence || sending} loading={sending} onClick={saveInvoice}/>
            {dryRun && <div className="text-color-600">Testmodus</div>}
        </>}
    >
        {!sequence && <Message severity="error" text="Er is geen factuurnummerreeks gekend"/>}

        {data?.clientOrder && <SubscriptionProductOrderList
            hideTimeline
            clientOrder={data.clientOrder}
            disabled
        />}

        {data?.clientOrder && <PaymentOptionsSelection clientOrder={data?.clientOrder}
                                                       onChange={(payments) => {
                                                           setPayments(payments);
                                                       }}
        />}
        <div className="grid align-items-center m-2">
            <div className="col-3">
                <label htmlFor={'sponsoring'}>Sponsoringfactuur</label>
            </div>
            <div className="col-9">
                <Checkbox
                    className="mr-2"
                    checked={isSponsoring}
                    id="sponsoring"
                    onChange={e => setIsSponsoring(e.checked || false)}
                />
            </div>

            <div className="col-3">
                <label htmlFor={'sponsoring'}>Factuurkorting (%)</label>
            </div>
            <div className="col-9">
                <InputNumber
                    value={(data?.clientOrder?.orderDiscount || 0) * 100}
                    onValueChange={(e) => {
                        if (data?.clientOrder) {
                            const variables: ClientOrderUpdateVariables = {
                                where: {
                                    id: data.clientOrder.id,
                                },
                                data: {
                                    orderDiscount: (e.value || 0) / 100
                                }
                            };
                            clientOrderUpdate({variables});
                        }
                    }}
                    suffix="%"/>
            </div>
        </div>

        {activeFarm && invoiceData &&
            <PDFViewer style={{height: '90vh', width: '50vw', position: 'relative', margin: '0 auto'}}>
                <ClientOrderInvoicePdf data={invoiceData} farm={activeFarm}/>
            </PDFViewer>}

    </Dialog>;
};

export default CreateInvoiceDialog;
