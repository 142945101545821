import React from 'react';
import {useQuery} from "@apollo/client";
import {ClientOrdersQuery} from "../../../../../../shared/queries/clientOrder.gql";
import {ClientOrders, ClientOrdersVariables} from "../../../../../../__generated__/types";
import OrderTable from "../../OrderTable/OrderTable";
import {compact} from "lodash";
import InvoiceInformation from './components/InvoiceInformation';

interface InvoiceDetailProps {
  invoiceId: string;
}

const InvoiceDetail = (props: InvoiceDetailProps) => {
  const variables: ClientOrdersVariables = {
    where: {
      invoice: {id: props.invoiceId}
    }
  };

  const {data} = useQuery<ClientOrders>(ClientOrdersQuery, {variables});

  return <div>
    <div className="surface-0 p-4 border-200 border-1 align-items-stretch w-full">
      <InvoiceInformation invoiceId={props.invoiceId}/>
    </div>

    <OrderTable clientOrders={compact(data?.clientOrders)}/>
  </div>;
};

export default InvoiceDetail;
