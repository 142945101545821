import React, {InputHTMLAttributes} from 'react';
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Vat} from "../../__generated__/types";

interface VatDropdownProps extends InputHTMLAttributes<HTMLSelectElement> {
  onValueChange: (e: DropdownChangeEvent) => void;
  value: Vat | undefined;
}

const VatDropdown = (props: VatDropdownProps) => {
  return <Dropdown
    value={props.value}
    style={{width: '100%'}}
    options={
      [{value: Vat.VAT_6, label: '6%'}, {value: Vat.VAT_21, label: '21%'}, {value: Vat.VAT_0, label: '0%'}]
    }
    optionValue={'value'}
    optionLabel={'label'}
    onChange={(e) => {
      props.onValueChange(e);
    }}
  />;
};

export default VatDropdown;
