import React from 'react';
import styled from "styled-components";
import { get } from "lodash";
import { ShopOrderStatus } from "../../../../__generated__/types";

interface OrderStatusColumnProps {
  orderStatus: ShopOrderStatus;
}

const StatusColored = styled.div`
  font-weight: 700;

  &.status-DELIVERED {
      color: #359b3a;
  }

  &.status-UNDELIVERED {
      color: #C63737;
  }
   &.status-NEW {
      color: #23547B;
  }
`;

const Badge = styled(StatusColored)`
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
  padding: 2px 2px;
  text-transform: uppercase;
  letter-spacing: .3px;
  font-size: 10px;

  &.status-DELIVERED {
      background-color: #C8E6C9;
  }

  &.status-UNDELIVERED {
      background-color: #FFCDD2;
  }

  &.status-NEW {
      background-color: #B3E5FC;
  }
`;
const statusOptions: { [key in ShopOrderStatus]: string } = {
  [ShopOrderStatus.DELIVERED]: 'Afgeleverd',
  [ShopOrderStatus.NEW]: 'Nieuw',
  [ShopOrderStatus.UNDELIVERED]: 'Niet afgeleverd',
};

const OrderStatusColumn = (props: OrderStatusColumnProps) => {
  return <div>
    {props.orderStatus && <Badge
      className={`status-${props.orderStatus}`}>{get(statusOptions, props.orderStatus)}</Badge>}
  </div>;
};

export default OrderStatusColumn;
