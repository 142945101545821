import React, {useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useMutation, useQuery} from "@apollo/client";
import {PromoQuery, UpsertPromoMutation} from "./PromoDialog.gql";
import {
  ProductPromoType,
  PromoData,
  PromoData_productPromoes,
  UpsertPromotion,
  UpsertPromotionVariables
} from "../../../../../__generated__/types";
import {FormSection} from "../../../../../shared/components/soft-farms-forms";
import moment, {Moment} from "moment";
import {RadioButton} from "primereact/radiobutton";
import {compact, snakeCase, values} from "lodash";
import styled from "styled-components";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {asDayDdMmYyyy, momentFromDdMmYyyy, momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

interface PromoDialogProps {
  onHide: () => void;
  productId: string;
}


const PromoTypesContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 8px;
`;

const types: { label: string, value: ProductPromoType }[] = [
  {
    label: 'Kortingspercentage',
    value: ProductPromoType.DISCOUNT_PERCENTAGE,
  },
  {
    label: 'Vaste prijs',
    value: ProductPromoType.FIXED_PRICE,
  },
  {
    label: 'x + x gratis',
    value: ProductPromoType.PLUS_ONE,
  }
];
const PromoDialog = (props: PromoDialogProps) => {
  const {data} = useQuery<PromoData>(PromoQuery, {variables: {productId: props.productId}});

  const [upsertPromotion] = useMutation<UpsertPromotion>(UpsertPromoMutation);

  const [activePromoData, setActivePromoData] = useState<{
    type?: ProductPromoType,
    value?: number,
    secondValue?: number,
    startDate?: Moment,
    endDate?: Moment | null,
    id: string,
  }>({startDate: moment(), id: 'new'});

  useEffect(() => {
    const activePromo = compact(data?.productPromoes)
      .find(promo => (!promo.endDate || momentFromIso8601(promo.endDate).isSameOrAfter(moment(), 'd')));

    if (activePromo) {
      setActivePromoData({
        id: activePromo.id,
        type: activePromo.type,
        secondValue: activePromo.secondValue || undefined,
        value: activePromo.value,
        startDate: momentFromIso8601(activePromo.startDate),
        endDate: momentFromIso8601(activePromo.endDate),
      });
    }

  }, [data]);


  return <Dialog header={"Promoties"}
                 visible
                 closable
                 closeOnEscape
                 style={{width: '50vw'}}
                 onHide={() => props.onHide()}>

    <FormSection>
      <div className="label">Type</div>
      <PromoTypesContainer>
        {types.map(promoType => <>
          <RadioButton
            inputId={`type_${snakeCase(promoType.value)}`}
            value={promoType.value}
            checked={activePromoData.type === promoType.value}
            onChange={(e) => setActivePromoData(value => ({...value, type: e.value}))}
          />
          <label htmlFor={`type_${snakeCase(promoType.value)}`}>{promoType.label}</label></>)}
      </PromoTypesContainer>

      <div className="label">{
        activePromoData.type === ProductPromoType.DISCOUNT_PERCENTAGE
          ? ' Kortingspercentage'
          : activePromoData.type === ProductPromoType.PLUS_ONE
            ? 'Aantal'
            : 'Vaste prijs'
      }</div>
      <div>
        {activePromoData.type === ProductPromoType.DISCOUNT_PERCENTAGE
          ? <InputNumber
            id="stacked"
            value={activePromoData.value || 0}
            onValueChange={(e: any) => setActivePromoData(state => ({...state, value: e.value}))}
            locale={'nl-NL'}
            step={0.05}
            maxFractionDigits={2}
            minFractionDigits={0}
          />
          : activePromoData.type === ProductPromoType.PLUS_ONE
            ? <div>
              <InputNumber
                value={activePromoData.value || 0}
                onValueChange={(e: any) => setActivePromoData(state => ({...state, value: e.value}))}
                locale={'nl-NL'}
                maxFractionDigits={2}
                minFractionDigits={0}
              />
              +
              <InputNumber
                value={activePromoData.secondValue || 0}
                onValueChange={(e: any) => setActivePromoData(state => ({...state, secondValue: e.value}))}
                locale={'nl-NL'}
                maxFractionDigits={2}
                minFractionDigits={0}
              />
            </div>
            : <InputNumber
              id="stacked"
              value={activePromoData.value || 0}
              onValueChange={(e: any) => setActivePromoData(state => ({...state, value: e.value}))}
              locale={'nl-NL'}
              step={0.05}
              maxFractionDigits={2}
              minFractionDigits={0}
            />
        }
      </div>
      <div className="label">Van</div>
      <div>
        <Calendar
          value={activePromoData.startDate ? activePromoData.startDate.toDate() : new Date()}
          dateFormat={"dd-mm-yy"}
          showWeek
          onChange={(e) => setActivePromoData(state => ({...state, startDate: momentFromDdMmYyyy(e.target.value)}))}
        />
      </div>
      <div className="label">Tot</div>
      <div>
        <Calendar
          value={activePromoData.endDate ? activePromoData.endDate.toDate() : undefined}
          dateFormat={"dd-mm-yy"}
          showWeek
          onChange={(e) => setActivePromoData(state => ({...state, endDate: momentFromDdMmYyyy(e.target.value)}))}
        />
      </div>

      <div style={{gridColumn: '2 / span 1'}}>
        <Button
          onClick={() => {
            if (activePromoData.type && activePromoData.startDate) {
              let value = activePromoData.value;
              if (value) {
                const variables: UpsertPromotionVariables = {
                  promoId: activePromoData.id || 'new',
                  value,
                  secondValue: activePromoData.secondValue,
                  productId: props.productId,
                  startDate: activePromoData.startDate,
                  endDate: activePromoData.endDate,
                  type: activePromoData.type,
                };
                upsertPromotion({variables}).then(r => {
                  props.onHide();
                });
              }
            }
          }}
          label={'Promotie toevoegen'}
        />
      </div>
    </FormSection>

    <h3>Promoties</h3>
    <DataTable
      className={"sf-table"}
      value={compact(values(data?.productPromoes))}
    >
      <Column header={'Startdatum'} field={'startDate'}
              body={(promo) => asDayDdMmYyyy(momentFromIso8601(promo.startDate))}/>
      <Column header={'Einddatum'} field={'endDate'}
              body={(promo) => promo.endDate ? asDayDdMmYyyy(momentFromIso8601(promo.endDate)) : '-'}/>
      <Column header={'Type'} field={'type'}
              body={(promo: PromoData_productPromoes) => types.find(type => type.value === promo.type)?.label}/>
    </DataTable>
  </Dialog>;
};

export default PromoDialog;
