import React, { useEffect, useState } from 'react';
import {
  SalesData_clientOrders_invoice
} from "../../../../__generated__/types";
import { Button } from "primereact/button";
import moment from 'moment';
import { Dialog } from "primereact/dialog";
import { DataList } from "../../../../components/data-list";
import { InputNumber } from "primereact/inputnumber";
import { asIso8601, momentFromDdMmYyyy, momentFromIso8601 } from "../../../../shared/utils/date.utils";
import { Calendar } from "primereact/calendar";
import { useSales } from "../../sales-context";

interface RegisterPaymentButtonProps {
  invoice: SalesData_clientOrders_invoice;
}


const RegisterPaymentButton = (props: RegisterPaymentButtonProps) => {
  const [showRegisterPaymentDialog, setShowRegisterPaymentDialog] = useState<boolean>(false);
  const {addPaymentToInvoice} = useSales();

  const [payment, setPayment] = useState<{ paymentDate: string, amount: number }>({
    paymentDate: asIso8601(moment()),
    amount: 0,
  });

  useEffect(() => {
    setPayment({
      paymentDate: asIso8601(moment()),
      amount: props.invoice.data.totalIncl,
    });
  }, [props.invoice]);

  const footer = (
    <div>
      <Button className={'p-button-text'} label="Annuleer" icon="pi pi-times p-button-icon-left" onClick={() => {
        setShowRegisterPaymentDialog(false)
      }}/>
      <Button label="Ok" icon="pi pi-check" onClick={() => {
        addPaymentToInvoice(props.invoice.id, payment);
        setShowRegisterPaymentDialog(false)
      }}/>
    </div>
  );

  return <>
    <Dialog visible={showRegisterPaymentDialog}
            footer={footer}
            onHide={() => setShowRegisterPaymentDialog(false)}>
      <DataList>
        <span className="label">Betalingsdatum</span>
        <span>
          <Calendar
            locale={'nl'}
            dateFormat={"dd-mm-yy"}
            showWeek
            value={momentFromIso8601(payment.paymentDate).toDate()}
            onChange={(e: any) => {
              setPayment(state => ({...state, paymentDate: asIso8601(momentFromDdMmYyyy(e.target.value))}));
            }}
          />
        </span>
        <span className="label">Bedrag</span>
        <span>
          <InputNumber
            value={payment.amount || 0}
            locale={'nl-NL'}
            maxFractionDigits={2}
            minFractionDigits={2}
            onChange={(e: any) => {
              setPayment(state => ({...state, amount: e.value}));
            }}/>
        </span>
      </DataList>
    </Dialog>
    <Button
      className="p-button-dense"
      text
      link
      icon={'pi pi-wallet'}
      label={'Registreer betaling'}
      onClick={() => {
        addPaymentToInvoice(props.invoice.id, payment);
        //setShowRegisterPaymentDialog(true);
      }}
    />
  </>;
};

export default RegisterPaymentButton;
