import React from 'react';
import classNames from "classnames";
import {
  PricelistData_clientGroups,
  ProductAvailabilityCreateWithoutProductInput,
  ProductAvailabilityUpdateWithWhereUniqueWithoutProductInput,
  UpdateProduct,
  UpdateProductVariables
} from "../../../../../__generated__/types";
import {UpdateProductMutation} from "../../../queries.gql";
import {useMutation} from "@apollo/client";
import {
  availabilityFor,
  hasProductPriceForClientGroup,
  ProductPriceType
} from "../../../../../shared/utils/product.utils";
import {asIso8601} from "../../../../../shared/utils/date.utils";
import {compact} from "lodash";
import {Tooltip} from "primereact/tooltip";
import {Props} from "../../../../../shared/types/types";
import {TODAY} from "../../../../../shared/config";

interface AvailabilityProps {
  product: {
    id: string;
    productPrices: ProductPriceType[] | null
    productAvailabilities?: {
      id: string,
      clientGroup: { id: string } | null,
      endDate: string,
      startDate: string,
    }[] | null;
  };
  clientGroup: PricelistData_clientGroups;
  flex?: boolean;
}

const clientGroupCharacter: { [key: string]: string } = {
  "particulier": "P",
  "restaurants": "H",
  "detailhandel": "D",
  "kinderopvang": "K",
  "boeren": "B",
  "groentegeweld-flex": "F",
  "zelfoogst": "Z",
};

const Availability = (props: Props<AvailabilityProps>) => {
  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {refetchQueries: "active"});

  const {code, name, id} = props.clientGroup;
  let availability = availabilityFor(props.product, id);
  let hasCustomerPrice = hasProductPriceForClientGroup(props.product, id);

  const disabled = code === 'zelfoogst' ? false : !hasCustomerPrice;

  return <div
    id={`availability_${id}_${props.product.id}`}
    className={classNames(
      "custom-target-icon",
      "w-1rem h-1rem surface-500 border-circle flex flex-row align-items-center justify-content-center text-white font-bold mr-1 p-0",
      {"bg-orange": availability},
      {"opacity-30": disabled},
      {"cursor-pointer": !disabled},
      props.className,
    )}
    data-pr-tooltip={name}
    onClick={() => {
      let availabilityUpdate: ProductAvailabilityUpdateWithWhereUniqueWithoutProductInput | null = null;
      if (availability) {
        availabilityUpdate = {
          where: {id: availability.id},
          data: {
            endDate: asIso8601(TODAY),
          }
        }
      }

      let availabilityCreate: ProductAvailabilityCreateWithoutProductInput | null = null;
      if (!availability) {
        availabilityCreate = {
          startDate: asIso8601(TODAY),
          clientGroup: {connect: {id: id}}
        }
      }

      const variables: UpdateProductVariables = {
        id: props.product.id,
        data: {
          productAvailabilities: {
            update: compact([availabilityUpdate]),
            create: compact([availabilityCreate])
          },
        }
      };

      updateProduct({variables});
    }}>
    <Tooltip
      showDelay={500}
      position="top"
      target={`#availability_${id}_${props.product.id}`} mouseTrack mouseTrackTop={10}/>

    <div>{((code && clientGroupCharacter[code]) || name.substring(0, 1))}</div>
  </div>;
};

export default Availability;
