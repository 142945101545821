import React, {PropsWithRef, useState} from 'react';
import {Button} from "primereact/button";
import {compact, difference, first, map, uniq, without} from "lodash";
import {momentFromIso8601} from "../../../../shared/utils/date.utils";
import {
  BasketComposition_basketComposition,
  BasketCompositionConnectToDelivery,
  BasketCompositionConnectToDeliveryVariables,
  ProductOrdersForBasketComposition_productOrders
} from "../../../../__generated__/types";
import {
  filterProductOrdersMissingInBasketComposition,
  filterProductOrdersWithDifferentBasketComposition
} from "../../../../utils/productOrder.utils";
import {useMutation} from "@apollo/client";
import {BasketCompositionConnectToDeliveryMutation} from "../../../../shared/queries/basketComposition.gql";
import {Checkbox} from "primereact/checkbox";
import {totalPortions} from "../../../../shared/utils/basketComposition.utils";
import {getBasketSize} from "../../../../shared/utils/product.utils";
import {notificationsFor} from "../../../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";
import { productOrderHasErrorNotification } from './BasketCompositionViewer/BasketCompositionViewerDetail';

interface BasketCompositionErrorsProps {
  productOrders: ProductOrdersForBasketComposition_productOrders[];
  basketComposition: BasketComposition_basketComposition | null | undefined;
}

const BasketCompositionErrors = (props: PropsWithRef<BasketCompositionErrorsProps>) => {
  const {productOrders, basketComposition} = props;
  const [connectBasketCompositionToDelivery] = useMutation<BasketCompositionConnectToDelivery>(BasketCompositionConnectToDeliveryMutation, {refetchQueries: "active"});
  const [selectedProductOrders, setSelectedProductOrders] = useState<string[]>([]);

  let missingProductOrders = filterProductOrdersMissingInBasketComposition(productOrders, basketComposition)
  let productOrdersWithDifferentBasketComposition = filterProductOrdersWithDifferentBasketComposition(productOrders, basketComposition);

  let productOrdersWithDeliveryInSameWeekOfBasketComposition = productOrders
    .filter(productOrder => {
      return compact(productOrder.deliveries)
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(basketComposition?.deliveryWeek), 'isoWeek')).length > 0;
    }
  );

  let wrongDateConnectedProductOrders = difference(map(productOrders, 'id'), map(productOrdersWithDeliveryInSameWeekOfBasketComposition, 'id'));

  return <div>
    <div className="font-bold">Ontbrekende geplande leveringen</div>
    <div className="max-h-12rem overflow-scroll m-3">
      {
        productOrdersWithDeliveryInSameWeekOfBasketComposition
          .filter(productOrder => missingProductOrders.findIndex(missingProductOrder => missingProductOrder.id === productOrder.id) > -1)
          .map(productOrder =>
            <div className="grid">
              <div className="col-2">
                {props.basketComposition && !productOrderHasErrorNotification(productOrder, props.basketComposition) && <Checkbox
                  value={productOrder.id}
                  checked={selectedProductOrders.includes(productOrder.id)}
                  onChange={(e) => {
                    setSelectedProductOrders(value => {
                      if (e.checked) {
                        return uniq(([...value, e.value]));
                      } else {
                        return without(value, e.value);
                      }
                    });
                  }}
                />}
              </div>
              <div className="col-10">
                <div className="white-space-nowrap">{productOrder.clientOrder.client.firstName}&nbsp;{productOrder.clientOrder.client.name}</div>
                <div className="white-space-nowrap text-color-secondary text-sm">{productOrder.product?.name}</div>
              </div>

            </div>
          )
      }
    </div>
    <Button
      disabled={props.basketComposition === null || selectedProductOrders.length === 0}
      className="p-button-link"
      label="Wijs het pakket toe aan de ontbrekende leveringen"
      onClick={() => {
        if (basketComposition) {
          const {id, deliveryWeek} = basketComposition;

          let productOrdersWithoutErrorNotifications = productOrders
            .filter(po => !productOrderHasErrorNotification(po, basketComposition));

          let deliveryIds = productOrdersWithoutErrorNotifications
            .filter(productOrder => selectedProductOrders.includes(productOrder.id))
            .map(productOrder => compact(productOrder.deliveries)
              .find(delivery =>
                delivery.basketComposition === null &&
                momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(deliveryWeek), 'isoWeek'))
            )
            .map(delivery => delivery && delivery.id);

          const variables: BasketCompositionConnectToDeliveryVariables = {
            id: id,
            deliveries: {
              connect: compact(deliveryIds).map(deliveryId => ({id: deliveryId}))
            },
          };
          connectBasketCompositionToDelivery({variables});
        }
      }}
    />


    <div className="mt-3 font-bold">Leveringen op foutieve datum</div>
    {
      productOrders.filter(po => wrongDateConnectedProductOrders.indexOf(po.id) > -1)
        .map(productOrder =>
          <div className="grid">
            <div
              className="col-6">{productOrder.clientOrder.client.firstName} {productOrder.clientOrder.client.name}</div>
            <div className="col-6">{productOrder.product?.name}</div>
          </div>
        )
    }


    <div className="mt-3 font-bold">Leveringen met andere pakketsamenstelling</div>
    {
      productOrdersWithDifferentBasketComposition
        .map(productOrder =>
          <div className="grid">
            <div
              className="col-6">{productOrder.clientOrder.client.firstName} {productOrder.clientOrder.client.name}</div>
            <div className="col-6">{productOrder.product?.name}</div>
          </div>
        )
    }
  </div>;
};

export default BasketCompositionErrors;
