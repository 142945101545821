import { gql } from "@apollo/client";

export const ProductCategoriesQuery = gql`
query ProductCategoriesData {
  productCategories {
		id
  	name
  }
}
`;
