import React, {FunctionComponent, useEffect} from 'react';
import {Route, useLocation, useNavigate} from 'react-router-dom';
import styled from "styled-components";
import SFPage from "./shared/components/soft-farms-bootstrap";
import Sales from "./pages/sales/Sales";
import {useAuth0} from "@auth0/auth0-react";
import {compact, mapValues, values} from 'lodash';
import {Menubar} from "primereact/menubar";
import SecretRoute from 'shared/SecretRoute';
import ClientsBillingPage from "./pages/ClientsBillingPage/ClientsBillingPage";
import UserMenu from "./shared/components/UserMenu/UserMenu";
import {MarketProvider} from "./MarketContext";
import {MenuItem} from 'primereact/menuitem/menuitem';
import Veldwinkel from "./pages/Veldwinkel/Veldwinkel";
import FarmManagement from "./pages/FarmManagement/FarmManagement";
import appConfig from "./config.app";
import SubscriptionsPage from 'pages/SubscriptionsPage/SubscriptionsPage';
import {ClientProvider, useClientObject} from "./ClientContext";
import InitUser from "./pages/InitUser/InitUser";
import BasketsManagement from 'pages/BasketsManagement/BasketsManagement';
import BasketComposerPage from "./pages/BasketsManagement/BasketComposerPage/BasketComposerPage";
import ClientBasketDeliveriesPage from 'pages/ClientBasketDeliveriesPage/ClientBasketDeliveriesPage';
import ClientBasketPage from 'pages/ClientBasketDeliveriesPage/ClientBasketPage/ClientBasketPage';
import BasketProcessingPage from "./pages/BasketsManagement/BasketProcessingPage/BasketProcessingPage";
import FinancialManagement from "./pages/FinancialManagement/FinancialManagement";
import OrderManagement from "./pages/sales/OrderManagement/OrderManagement";
import Pricelist from "./pages/sales/Pricelist/Pricelist";
import ClientManagement from "./pages/sales/ClientManagement/ClientManagement";
import {SalesProvider} from "./pages/sales/sales-context";
import ProductCategoryManagement from "./pages/sales/ProductCategoryManagement/ProductCategoryManagement";
import ClientBasketFlexPage from "./pages/ClientBasketDeliveriesPage/ClientBasketFlexPage/ClientBasketFlexPage";
import {Routes} from "react-router";
import ScrollToTop from 'shared/components/ScrollToTop';
import TodayWarning from 'shared/components/TodayWarning';
import StockManagement from "./pages/sales/StockManagement/StockManagement";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import ClientBasketHistoryPage
  from "./pages/ClientBasketDeliveriesPage/ClientBasketHistoryPage/ClientBasketHistoryPage";
import {ClientByAuth0UserId_clientsByUserId, Permission} from "./__generated__/types";
import {filterBasketClientOrders, filterFlexBasketClientOrders} from "./utils/clientOrder.utils";
import InvoiceManagement from 'pages/sales/InvoiceManagement/InvoiceManagement';
import ProductOrderPointsPage from 'pages/PointsPage/ProductOrderPointsPage';
import RevenueManagement from "./pages/sales/RevenueManagement/RevenueManagement";
import BasketComposerPage2 from 'pages/BasketsManagement/BasketComposerPage2/BasketComposerPage2';
import FarmSwitch from "./shared/components/FarmSwitch";
import {UserProvider, useUserObject} from "./shared/context/UserContext";
import HomePage from "./pages/HomePage";
import {hasAtLeastOneMatchingPermission, hasFarmManagementPermission} from "./shared/utils/permission.utils";
import UnauthorizedAccessPage from "./shared/components/UnauthorizedAccessPage";
import ResetPage from "./pages/ResetPage/ResetPage";

export interface SFMenuItem extends MenuItem {
  roles?: string[];
  permissions?: Permission[];
  component?: any,
  path?: string,
  singlePage?: boolean,
  hidden?: boolean,
  unsecured?: boolean,
  hasAccess?: (user: any, client: ClientByAuth0UserId_clientsByUserId | null) => boolean,
}

const ROUTES: { [key: string]: SFMenuItem } = {
  '/': {
    label: 'Home',
    icon: 'pi pi-home',
    path: "/",
    component: () => <HomePage/>,
  },
  '/initUser': {
    hidden: true,
    label: 'User initialization',
    icon: 'pi pi-users',
    path: "/initUser",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: InitUser,
  },

  '/abonnementen': {
    label: 'Abonnementen',
    icon: 'pi pi-users',
    path: '/abonnementen',
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: SubscriptionsPage,

  },
  '/abonnementen/punten': {
    label: 'Punten',
    icon: 'pi pi-users',
    path: '/abonnementen/punten',
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: ProductOrderPointsPage,
  },
  '/pakketten': {
    label: 'Pakketten 2.0',
    icon: 'icon-harvest-box',
    path: '/pakketten',
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: BasketsManagement,

  },
  '/pakketten/samenstelling': {
    hidden: true,
    label: 'Pakketsamenstelling',
    icon: 'icon-harvest-box',
    path: '/pakketten/samenstelling',
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: BasketComposerPage,
  },
  '/pakketten/samenstelling2': {
    hidden: true,
    path: '/pakketten/samenstelling2',
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: BasketComposerPage2,
  },
  '/pakketten/verwerken': {
    hidden: true,
    label: 'Pakketten verwerken',
    icon: 'icon-harvest-box',
    path: '/pakketten/verwerken',
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: BasketProcessingPage,
  },

  '/mijnpakket': {
    hasAccess: (user, client) => {
      return user && client && filterBasketClientOrders(client?.clientOrders).length > 0;
    },
    permissions: [Permission.CLIENT_BASKET],
    hidden: false,
    label: 'Mijn pakket',
    path: '/mijnpakket',
    component: ClientBasketPage,
  },
  '/mijnpakket/leveringen': {
    hasAccess: (user, client) => {
      return user && client && filterBasketClientOrders(client?.clientOrders).length > 0;
    },
    permissions: [Permission.CLIENT_BASKET],
    hidden: true,
    label: 'Mijn leveringen',
    path: '/mijnpakket/leveringen',
    component: ClientBasketDeliveriesPage,
  },
  '/mijnpakket/historie': {
    hasAccess: (user, client) => {
      return user && client && filterFlexBasketClientOrders(client?.clientOrders).length > 0;
    },
    permissions: [Permission.CLIENT_BASKET],
    hidden: true,
    label: 'Ontvangen pakketten',
    path: '/mijnpakket/historie',
    component: ClientBasketHistoryPage,
  },
  '/mijnpakket/flex': {
    hidden: true,
    label: 'Flex pakket',
    icon: 'icon-harvest-box',
    path: '/mijnpakket/flex',
    component: ClientBasketFlexPage,
  },

  '/verkoop/': {
    label: 'Verkoop',
    icon: 'pi pi-euro',
    path: "/verkoop",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: Sales,
  },
  '/verkoop/verkopen': {
    hidden: true,
    label: 'Verkopen',
    icon: 'pi pi-euro',
    path: "/verkoop/verkopen",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: () => <SalesProvider><OrderManagement/></SalesProvider>,
  },
  '/verkoop/producten': {
    hidden: true,
    label: 'Verkoop',
    icon: 'pi pi-euro',
    path: "/verkoop/producten",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: Pricelist,
  },
  '/verkoop/facturatie': {
    hidden: true,
    label: 'Facturatie',
    icon: 'pi pi-euro',
    path: "/verkoop/facturatie",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: () => <SalesProvider><InvoiceManagement/></SalesProvider>,
  },
  '/verkoop/klanten': {
    hidden: true,
    label: 'Verkoop',
    icon: 'pi pi-euro',
    path: "/verkoop/klanten",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: () => <SalesProvider><ClientManagement/></SalesProvider>,
  },
  '/verkoop/stock': {
    hidden: true,
    label: 'Stock',
    icon: 'pi pi-euro',
    path: "/verkoop/stock",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: () => <StockManagement/>,
  },
  '/verkoop/productcategorieen': {
    hidden: true,
    label: 'Verkoop',
    icon: 'pi pi-euro',
    path: "/verkoop/productcategorieen",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: () => <SalesProvider><ProductCategoryManagement/></SalesProvider>,
  },
  '/verkoop/omzet': {
    hidden: true,
    label: 'Inkomsten',
    icon: 'pi pi-euro',
    path: "/verkoop/omzet",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: () => <SalesProvider><RevenueManagement/></SalesProvider>,
  },

  '/administratie/financieel': {
    hidden: true,
    label: 'Financieel',
    icon: 'pi pi-euro',
    path: "/administratie/financieel",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: FinancialManagement,
  },
  '/reset': {
    hidden: true,
    label: 'Reset',
    icon: 'pi pi-euro',
    path: "/reset",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: ResetPage,
  },
  '/veldwinkel': {
    label: 'Veldwinkel',
    icon: 'pi pi-shopping-cart',
    path: "/veldwinkel",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: Veldwinkel,
  },
  '/management': {
    label: 'Beheer',
    icon: 'pi pi-cog',
    path: "/management",
    permissions: [Permission.FARM_MANAGEMENT_ADMIN],
    component: FarmManagement,
  },

  // '/members': {
  //   label: 'Deelnemers',
  //   icon: 'pi pi-users',
  //   path: "/members",
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: MemberShipManagement,
  // },
  // '/pakketten-lijst': {
  //   label: 'Pakketten',
  //   icon: 'pi pi-users',
  //   path: "/pakketten-lijst",
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: Checklist,
  //
  // },

  // '/oogstfeest': {
  //   label: 'Oogstfeest',
  //   icon: 'pi pi-users',
  //   path: "/oogstfeest",
  //   roles: ['Administrator'],
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: Oogstfeest,
  // },
  // '/productList': {
  //   label: 'Beheer',
  //   icon: 'pi pi-cog',
  //   path: "/productList",
  //   roles: ['Administrator'],
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: ProductList,
  // },
  // '/harvestList': {
  //   label: 'Oogstlijst',
  //   icon: 'pi pi-users',
  //
  //   path: "/harvestList",
  //   roles: ['Administrator'],
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: HarvestList,
  // },
  // '/googleshop': {
  //   label: 'Googleshop',
  //   icon: 'pi pi-users',
  //   path: "/googleshop",
  //   roles: ['Administrator'],
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: GoogleShop,
  // },
  // '/baskets': {
  //   label: 'Pakketten 2.0',
  //   icon: 'pi pi-users',
  //   path: "/baskets",
  //   roles: ['Administrator'],
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: BasketsPage,
  // },
  // '/login': {
  //   label: 'Aanmelden',
  //   icon: 'pi pi-users',
  //   command: () => {
  //     return loginWithRedirect({redirectUri: config.redirectUrl});
  //   },
  //   //component: Login,
  // },
  // '/logout': {
  //   label: 'Afmelden',
  //   icon: 'pi pi-users',
  //   command: () => {
  //     return logout({returnTo: window.location.origin});
  //   }
  // },
  '/clientsBilling': {
    unsecured: true,
    singlePage: true,
    path: "/clientsBilling",
    component: ClientsBillingPage,
  },
  // '/market': {
  //   label: 'Markt',
  //   path: "/market",
  //   roles: ['customer_horeca'],
  //   component: HorecaPage,
  // },
  // '/bordjes': {
  //   label: 'Bordjes',
  //   hidden: true,
  //   path: "/bordjes",
  //   roles: ['Administrator'],
  //   permissions: [Permission.FARM_MANAGEMENT_ADMIN],
  //   component: SignsPage,
  // },
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas: 'header' 'content';
  position: absolute;
  grid-template-rows: max-content 1fr;

  @media only screen and (max-width: 610px) {
    grid-template-rows: 4rem 1fr;
  }
`;

const Topbar = styled.div`
  align-self: stretch;
  background: #ffffff;
  padding: 0;
  border-bottom: 2px solid var(--brown);

  .p-menubar {
    background-color: white;
  }
`;

const USER_MENU_ITEMS = [
  {
    label: 'Mijn account',
    icon: 'pi pi-user',
    command: () => {
    }
  },
  {
    label: 'Mijn bestellingen',
    icon: 'pi pi-list',
    command: () => {
    }
  },
  {
    label: 'Mijn facturen',
    icon: 'pi pi-money-bill',
    command: () => {
    }
  },
];


const App: FunctionComponent = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {user: auth0User, isAuthenticated, isLoading} = useAuth0();
  const {initialize, client} = useClientObject();
  const {user} = useUserObject();
  //const [showAccountLinking, setShowAccountLinking] = useState<boolean>(false);

  useEffect(() => {
    if (auth0User && auth0User.sub && auth0User.email) {
      initialize(auth0User.sub, auth0User.email);
    }
  }, [auth0User]);

  if (isLoading) {
    return <div/>;
  }

  const menuItems = ROUTES;
  const activeRoute = menuItems[pathname];
  const model = compact(values({
    ...mapValues(menuItems, (menuItem) => {
      const path = menuItem.path;
      if (menuItem.hidden) {
        return null;
      }

      if (path) {
        return ({...menuItem, command: () => navigate(path)});
      } else {
        return menuItem;
      }
    }),
    [pathname]: {...activeRoute, class: 'active'}
  }));

  return (
    <SFPage>
      <Wrapper>
        <div style={{gridArea: 'header', display: 'grid'}}>
          {(!activeRoute || !activeRoute.singlePage) &&
            <>
              <Topbar>
                <Menubar
                  start={<FarmSwitch createPermission={Permission.FARM_MANAGEMENT_ADMIN}/>}
                  //<img style={{height: '40px'}} src={'https://www.groentegeweld.be/logo.png'} alt={'Groentegeweld logo'}/>
                  end={<UserMenu items={USER_MENU_ITEMS} redirectUrl={appConfig.redirectUrl}/>}
                  model={
                    model.filter(menuItem => {
                      if (menuItem.hidden) {
                        return false;
                      }

                      if (menuItem.hasAccess) {
                        return menuItem.hasAccess(auth0User, client);
                      }

                      return hasFarmManagementPermission(user);
                    })
                  }>
                </Menubar>
              </Topbar>
            </>}
        </div>
        <div id="content" style={{gridArea: 'content', overflow: 'scroll'}} className={"p-1 sm:p-2 md:p-3 lg:p-4 pt-0"}>
          <TodayWarning/>
          <Routes>
            {values(menuItems)
              .filter(r => r.path)
              .map(({unsecured, component: Component, hasAccess, path, permissions}) => {
                  if(unsecured) {
                    return <Route key={path} path={path} element={<Component/>}/>;
                  }

                  if (!auth0User) {
                    return <Route key={path} path={path} element={<WelcomePage redirectPath={path}/>}/>
                  }

                  if (!hasAtLeastOneMatchingPermission(permissions, user?.permissions)) {
                    return <Route key={path} path={path} element={<UnauthorizedAccessPage/>}/>;
                  }

                  // Should be deleted once customers get a User record assigend
                  if ((!hasAccess || hasAccess(auth0User, client))) {
                    return <Route key={path} path={path} element={<Component/>}/>;
                  }

                  return <Route
                    key={path}
                    path={path}
                    element={
                      <SecretRoute routePermissions={permissions} isAuthenticated={isAuthenticated}>
                        <Component/>
                      </SecretRoute>
                    }
                  />;
                }
              )}
          </Routes>
        </div>
      </Wrapper>
    </SFPage>
  );
};

const RoutedApp = () => (
  <UserProvider>
    <ClientProvider>
      <MarketProvider>
        <ScrollToTop></ScrollToTop>
        <App/>
      </MarketProvider>
    </ClientProvider>
  </UserProvider>
);

export default RoutedApp;
