import React from 'react';
import { responsiveSizeClasses } from 'shared/constants';
import { Props } from 'shared/types/types';
import placeholder from "../placeholder.svg";

interface RoundImageProps {
 src?: string | null | undefined;
 size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const RoundImage = (props: Props<RoundImageProps>) => {
  let sizeClassNames = props.size ? responsiveSizeClasses[props.size] : responsiveSizeClasses.md;
  return <div className={sizeClassNames +" border-circle overflow-hidden border-2 border-green flex align-items-center justify-content-center"}>
    <img className={sizeClassNames} src={props.src || placeholder} alt={'placeholder'}/>
  </div>;
};

export default RoundImage;
