import React, {useRef, useState} from 'react';
import {Moment} from "moment";
import {useQuery} from "@apollo/client";
import {DeliveriesForDeliveryWeekQuery} from "../../../../shared/queries/productOrder.gql";
import {
  BasketCompositions_basketCompositions,
  DeliveriesForDeliveryWeek,
  DeliveryLocation, GetProducts, GetProducts_products, GetProductsVariables
} from "../../../../__generated__/types";
import {endOfIsoWeek, startOfIsoWeek} from "../../../../shared/utils/date.utils";
import {compact} from "lodash";
import {deliveryLocations} from 'shared/utils/deliveryLocation.utils';
import DeliveryLocationButton from "./DeliveryLocationButton";
import ClientOrderPackagingView from "./ClientOrderPackagingView";
import IndividualShopOrder from "../../../Veldwinkel/HarvestList/IndividualShopOrder";
import {GetProductsQuery} from "../../../../shared/queries/product.gql";
import {useUserObject} from "../../../../shared/context/UserContext";

interface PackagingViewProps {
  flexClientGroupCode: string;
  deliveryWeek: Moment;
  basketComposition: BasketCompositions_basketCompositions | null | undefined;
}

const PackagingView = (props: PackagingViewProps) => {
  const {deliveryWeek} = props;
  const {activeFarm} = useUserObject();

  const {data} = useQuery<DeliveriesForDeliveryWeek>(DeliveriesForDeliveryWeekQuery, {
    variables: {
      deliveryWeekStart: startOfIsoWeek(deliveryWeek),
      deliveryWeekEnd: endOfIsoWeek(deliveryWeek),
    }
  });

  const getProductsVariables: GetProductsVariables = {
    where: {},
    farmId: activeFarm?.id || '',
    clientGroupCode: props.flexClientGroupCode,
  };

  const {data: productsData} = useQuery<GetProducts>(GetProductsQuery, {
    variables: getProductsVariables
  });
  const products = compact(productsData?.products)

  const [clientId, setClientId] = useState<string | null>(null);
  const [activeDeliveryLocation, setActiveDeliveryLocation] = useState<DeliveryLocation | null | undefined>(null);

  let deliveries = compact(data?.productOrderDeliveries);
  let otherClientOrders = compact(data?.clientOrders);
  let shopOrders = compact(data?.shopOrders);

  let shopOrdersForClientId = shopOrders.filter(shopOrder => shopOrder.shopClient.id === clientId);

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: "center",
      });

    }, 300);
  };
  return <div className="grid mt-3">
    <div className="sm:col-fixed flex flex-column" style={{width: "22rem"}}>
      {/*<ProductOrdersCounter*/}
      {/*  basketComposition={props.basketComposition}*/}
      {/*  deliveryWeek={deliveryWeek}*/}
      {/*/>*/}
      {[...deliveryLocations, null].map(deliveryLocationDetail => {
        return <div className="mb-1" key={deliveryLocationDetail?.value+'something'}>
          <DeliveryLocationButton
            expanded={activeDeliveryLocation !== null && (activeDeliveryLocation === deliveryLocationDetail?.value)}
            deliveryLocation={deliveryLocationDetail}
            deliveries={deliveries}
            otherClientOrders={otherClientOrders}
            shopOrders={shopOrders}
            onLocationChange={(deliveryLocation) => {
              if (activeDeliveryLocation !== null && deliveryLocation === activeDeliveryLocation) {
                setClientId(null);
                setActiveDeliveryLocation(null);
              } else {
                if (deliveryLocation !== activeDeliveryLocation) {
                  setClientId(null);
                }
                setActiveDeliveryLocation(deliveryLocation);
              }
            }}
            onClientChange={(clientId) => {
              setClientId(clientId);
              handleClick();
            }}
          />
        </div>;
      })}
    </div>
    <div className="sm:col" ref={ref}>
      {shopOrdersForClientId.length === 0 && clientId &&
        <ClientOrderPackagingView products={products} deliveryWeek={props.deliveryWeek} clientId={clientId}/>}
      {shopOrdersForClientId.map(shopOrder =>
        <IndividualShopOrder shopOrder={shopOrder} products={[]}/>
      )}
    </div>

  </div>;
};

export default PackagingView;
