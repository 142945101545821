import React, {useEffect, useState} from 'react';
import {
  AllProducts_products,
  FindMembershipByShopClient,
  ShopOrderDetails,
  ShopOrderDetails_shopOrder_shopOrderItems,
  ShopOrders_shopOrders,
  ShopOrders_shopOrders_shopOrderItems,
  ShopOrderStatus,
  UpdateOrderPaymentStatus,
  UpdateOrderStatus,
  UpdateShopOrderItemDeliveredFlag
} from "../../../__generated__/types";
import {compact, difference, first} from "lodash";
import styled from "styled-components";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
  FindMembershipByShopClientQuery,
  ShopOrderDetailsQuery,
  UpdateOrderPaymentStatusMutation,
  UpdateOrderStatusMutation,
  UpdateShopOrderItemDeliveredFlagMutation
} from "../Veldwinkel.gql";
import {asDayDdMmYyyy, momentFromIso8601} from "../../../shared/utils/date.utils";
import {toNameAndCity} from "../../../shared/utils/deliveryLocation.utils";

import {Column} from "primereact/column";
import classNames from 'classnames';
import {ToggleButton} from "primereact/togglebutton";
import {formatCurrencySign} from "../../../shared/utils/currency.utils";
import {totalPrice} from 'shared/utils/Shop.utils';
import {ambassadorDiscountPercentage} from "../../../shared/utils/ambassador.utils";
import {DataTable} from "primereact/datatable";
import {productIsAvailableAsAlternativeUnitFor} from "../../../shared/utils/product.utils";
import {amountWithUnit} from "../../../shared/utils/unit.utils";

interface IndividualShopOrderProps {
  shopOrder: ShopOrders_shopOrders;
  products: AllProducts_products[];
}

const Container = styled.div`
  border: 1px solid var(--green);
  padding: 8px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 32px;
`;

const OrderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 4px;

  h4 {
    margin: 0;
    padding: 0;
  }
`;

const OrderActions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  grid-gap: 16px;
`;

const Packaging = styled.div`
  opacity: 0.2;
  text-align: center;

  i {
    font-size: 75px;
  }

  &.active {
    color: var(--orange);
    opacity: 1;
  }
`;

const IndividualShopOrder = (props: IndividualShopOrderProps) => {
  const {data} = useQuery<ShopOrderDetails>(ShopOrderDetailsQuery, {variables: {id: props.shopOrder.id}});
  const [findMembershipByShopClient, {data: membershipData}] = useLazyQuery<FindMembershipByShopClient>(FindMembershipByShopClientQuery);
  const [updateOrderStatus] = useMutation<UpdateOrderStatus>(UpdateOrderStatusMutation);
  const [updateOrderPaymentStatus] = useMutation<UpdateOrderPaymentStatus>(UpdateOrderPaymentStatusMutation);
  const [updateShopOrderItemDeliveredFlag] = useMutation<UpdateShopOrderItemDeliveredFlag>(UpdateShopOrderItemDeliveredFlagMutation);
  const [ambassadorOption, setAmbassadorOption] = useState<number>(0);

  useEffect(() => {
    const matchingMembership = first(membershipData?.memberships);
    if (matchingMembership) {
      setAmbassadorOption(ambassadorDiscountPercentage(matchingMembership.ambassador));
    }
  }, [membershipData]);

  useEffect(() => {
    if (data?.shopOrder) {
      findMembershipByShopClient({
        variables: {
          shopClientId: data?.shopOrder.shopClient.id
        }
      });
    }
  }, [data, findMembershipByShopClient]);

  if (data?.shopOrder) {
    const {id, shopClient, orderDate, deliveryDate, paid, deliveryLocation, shopOrderItems, promocode, extraPackaging, status} = data.shopOrder;
    return <Container>
      <OrderInfo style={{gridColumn: '1 / span 2'}}>
        <h4>{shopClient.firstName} {shopClient.name}</h4>
        <div>Leverdatum: <strong>{asDayDdMmYyyy(momentFromIso8601(deliveryDate))}</strong></div>
        <div><i className="pi pi-map-marker"/> {toNameAndCity(deliveryLocation)}</div>
        <div>{shopClient.email} <br/> {shopClient.phone}</div>
      </OrderInfo>
      <OrderActions>
        <Packaging className={classNames({active: extraPackaging})}>
          <i className="icon-harvest-box"/>
          <div>{extraPackaging ? 'Kartonnen zak voorzien' : 'Geen verpakking nodig'}</div>
        </Packaging>

        <ToggleButton onLabel="Verwerkt"
                      offLabel="Niet verwerkt"
                      onIcon="pi pi-check"
                      offIcon="pi pi-times"
                      checked={status === ShopOrderStatus.DELIVERED}
                      onChange={(e) => updateOrderStatus({
                        variables: {
                          id,
                          status: e.value ? ShopOrderStatus.DELIVERED : ShopOrderStatus.UNDELIVERED
                        }
                      }).then(r => console.log(r))}/>
        <ToggleButton onLabel="Betaald"
                      offLabel="Niet betaald"
                      onIcon="pi pi-check"
                      offIcon="pi pi-times"
                      checked={paid}
                      onChange={(e) => updateOrderPaymentStatus({
                        variables: {
                          id,
                          paid: e.value
                        }
                      }).then(r => console.log(r))}/>
      </OrderActions>
      <DataTable
        className={"sf-table"}
        value={compact(shopOrderItems)}
        selection={compact(shopOrderItems).filter(item => item.delivered)}
        // selection={selection}
        onSelectionChange={(e) => {
          const deliveredItems = compact(shopOrderItems);
          const addedItem = first(difference(e.value as ShopOrders_shopOrders_shopOrderItems[], deliveredItems.filter(item => item.delivered)));
          const removedItem = first(difference(deliveredItems, e.value as ShopOrders_shopOrders_shopOrderItems[]));

          if (addedItem) {
            updateShopOrderItemDeliveredFlag({
              variables: {
                id: addedItem.id,
                delivered: true
              }
            }).then(r => console.log(r));
          }

          if (removedItem) {
            updateShopOrderItemDeliveredFlag({
              variables: {
                id: removedItem.id,
                delivered: false
              }
            }).then(r => console.log(r));
          }
        }}
        selectionMode={'checkbox'}
        selectAll={false}
        dataKey="id"
      >
        <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
        <Column header={'Product'} field={'product.id'} body={(shopOrderItem) => {
          const product = shopOrderItem.product || props.products.find(product => shopOrderItem.product.id === product.id);
          return product?.name;
        }}/>
        <Column header={'Aantal'} field={'amount'} body={(shopOrderItem:ShopOrderDetails_shopOrder_shopOrderItems) => {

          const product = shopOrderItem.product;
          {/*HARDCODED ID*/}
          {/*HARDCODED ID*/}
          {/*HARDCODED ID*/}
          return product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'ckbuxfzg307wz0774ooelvsqa')
            ? amountWithUnit(shopOrderItem.amount / product.avgWeight, product.alternativeUnit)
            : amountWithUnit(shopOrderItem.amount, product.unit);
        }}/>
        <Column
          header={'Bedrag'}
          field={'priceIncl'}
          footer={() => formatCurrencySign(totalPrice(compact(shopOrderItems), extraPackaging, ambassadorOption, promocode))}
          body={(shopOrderItem) => formatCurrencySign(shopOrderItem.priceIncl)}/>
      </DataTable>
    </Container>;
  }

  return <div>Laden...</div>;
};

export default IndividualShopOrder;
