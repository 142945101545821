import { gql } from "@apollo/client";

export const PromoQuery = gql`
query PromoData($productId: ID!) {
  productPromoes(
    where: {
      product: {id: $productId}
    }
  ) {
    id
    startDate
    endDate
    type
    value
    secondValue
  }
}
`;

export const UpsertPromoMutation = gql`
mutation UpsertPromotion(
  $promoId: ID!
  $productId: ID!
  $startDate: DateTime!
  $endDate: DateTime
  $type: ProductPromoType!
  $value: Float!
  $secondValue: Float
) {
  updateProduct(
    where: {id: $productId}
    data: {
      productPromos: {
        upsert: {
          where: {id: $promoId}
          create: {
            startDate: $startDate
            endDate: $endDate
            type: $type
            value: $value
            secondValue: $secondValue
          }
          update: {
            startDate: $startDate
            endDate: $endDate
            type: $type
            value: $value
            secondValue: $secondValue
          }
        }
      }
    }
  ) {
    id
    productPromos {
      id
      startDate
      endDate
      type
      value
      secondValue
    }
  }

  # upsertProductPromo(
  #   where: {id: $promoId}
  #   create: {
  #     startDate: $startDate
  #     endDate: $endDate
  #     type: $type
  #     value: $value
  #     product: {
  #       connect: {
  #         id: $productId
  #       }
  #     }
  #   }
  #   update: {
  #     startDate: $startDate
  #     endDate: $endDate
  #     type: $type
  #     value: $value
  #   }
  # ) {
  #   id
  #   startDate
  # }
}`;
