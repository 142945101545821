import React, {ChangeEvent, useRef, useState} from 'react';
import {compact, keyBy, keys, orderBy} from "lodash";
import {
  ActiveSubscriptions,
  ActiveSubscriptions_clientOrders,
  ActiveSubscriptions_clientOrders_productOrders,
  ActiveSubscriptions_productsInClientOrders,
  ClientOrderCompletionStatus,
} from "../../../../__generated__/types";
import {DataTable} from "primereact/datatable";
import SubscriptionDetail from "../SubscriptionDetail/SubscriptionDetail";
import classNames from "classnames";
import {Column} from "primereact/column";
import {actionsColumn, deliveryDatesColumn, nameColumn, productColumn, remarksColumn, statusColumn} from "../columns";
import styled from "styled-components";
import {useQuery} from "@apollo/client";
import {ActiveSubscriptionsQuery} from "../../SubscriptionsPage.gql";
import {Checkbox} from 'primereact/checkbox';
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {FilterMatchMode} from "primereact/api";
import {Button} from "primereact/button";
import LabelsDialog from '../LabelsDialog/LabelsDialog';
import {expirationInfoForProductOrder, findBasketProductOrder} from "../../../../shared/utils/productOrder.utils";
import PotatoLabelsDialog from "../LabelsDialog/PotatoLabelsDialog";
import {asIso8601} from "../../../../shared/utils/date.utils";
import {TODAY} from "../../../../shared/config";
import {useUserObject} from "../../../../shared/context/UserContext";

const TableWrapper = styled.div`
  .p-button-link {
    padding: 0 4px;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 4px;
  }

  .p-datatable .p-datatable-tbody > tr.expanded {
    font-weight: bold;
  }

  .p-datatable .p-datatable-tbody > tr.expanded,
  .p-datatable .p-datatable-tbody > tr.expanded + tr {
    background-color: var(--surface-b);
  }
`;

export interface SubscriptionClientOrders extends ActiveSubscriptions_clientOrders {
  productOrders: SubscriptionClientOrders_productOrders[] | null;
}

export interface SubscriptionClientOrders_productOrders extends ActiveSubscriptions_clientOrders_productOrders {
  product: ActiveSubscriptions_productsInClientOrders | null;
}


const SubscriptionTable = () => {
  const {activeFarm} = useUserObject();
  const {
    data,
    loading
  } = useQuery<ActiveSubscriptions>(ActiveSubscriptionsQuery, {
    variables: {
      today: asIso8601(TODAY),
      farmId: activeFarm?.id || '',
    }
  });
  const products = keyBy(compact(data?.productsInClientOrders), 'id');

  const dt = useRef<DataTable<SubscriptionClientOrders[]>>(null);

  const [selectedSubscriptions, setSelectedSubscriptions] = useState<SubscriptionClientOrders[]>([]);
  const [showWinterBaskets, setShowWinterBaskets] = useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>();
  const [showLabelsPdf, setShowLabelsPdf] = useState<boolean>(false);
  const [showPotatoLabelsPdf, setShowPotatoLabelsPdf] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: { value: null | any, matchMode: FilterMatchMode } }>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  });

  const [expandedRows, setExpandedRows] = useState<any>();
  //const [expandedRows, setExpandedRows] = useState<any>({clhw6nc326nqy07750syal4hm: true});

  let subscriptions: SubscriptionClientOrders[] = compact(data?.clientOrders)
    .map(co => ({
      ...co,
      productOrders: compact(co.productOrders).map(po => ({...po, product: products[po.product?.id || '']}))
    }))
    .filter(co => compact(co.productOrders).findIndex(po => po.product?.code?.includes('-winter')) === -1)
  ;//.filter(c => c.id === "clfa5xo2rhmvf0775dflkzc0u");
  if (loading) {
    return <div>Loading</div>;
  }

  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = () => {
    return <div className="p-0 m-0 surface-0 border-0">
      <Toolbar
        className="p-toolbar p-component p-0 m-0 surface-0 border-0"
        start={<div>
          <span className="p-input-icon-left">
              <i className="pi pi-search"/>
              <InputText type="search" value={globalFilterValue || ''} onChange={onGlobalFilterChange}
                         placeholder="Search..."/>
          </span>
          {/*<ExportMailinglistsButton*/}
          {/*  subscriptions={subscriptions}*/}
          {/*/>*/}
        </div>
        }
        end={
          <div>
            <Button
              label={`Maak etiketten aan${selectedSubscriptions.length > 0 ? ` voor ${selectedSubscriptions.length} geselecteerde pakketten` : ''}`}
              className={'p-button-link'}
              onClick={() => {
                setShowLabelsPdf(true);
              }}
            />
            <Button
              label={`Maak aardappeletiketten aan${selectedSubscriptions.length > 0 ? ` voor ${selectedSubscriptions.length} geselecteerde pakketten` : ''}`}
              className={'p-button-link'}
              onClick={() => {
                setShowPotatoLabelsPdf(true);
              }}
            />
          </div>
        }
      />
    </div>;
  };

  return <TableWrapper>
    {showLabelsPdf &&
      <LabelsDialog clientOrders={selectedSubscriptions.length > 0 ? selectedSubscriptions : subscriptions}
                    onHide={() => setShowLabelsPdf(false)}/>}
    {showPotatoLabelsPdf &&
      <PotatoLabelsDialog clientOrders={selectedSubscriptions.length > 0 ? selectedSubscriptions : subscriptions}
                          onHide={() => setShowPotatoLabelsPdf(false)}/>}
    <div className=" mx-1 text-xs flex align-items-center">
      <Checkbox checked={showWinterBaskets} onChange={(event) => setShowWinterBaskets(event.checked || false)}/>
      <div className="pl-2">Toon winterpakketten 2023</div>
    </div>
    <DataTable<SubscriptionClientOrders[]>
      className={"sf-table"}
      ref={dt}
      value={subscriptions}
      //value={compact(subscriptions).filter(co => co.client.email ==="rubengodecharle@gmail.com")}
      style={{height: '70vh'}}
      //sortField="status"
      dataKey="id"
      globalFilter={globalFilterValue}
      globalFilterFields={["client.name", "client.firstName"]}
      scrollable
      sortMode={'multiple'}
      multiSortMeta={[
        {field: 'status', order: 1},
        {field: 'deliveryEndDate', order: 1},
      ]}
      header={header}
      scrollHeight={'70vh'}
      sortOrder={1}
      selectionMode="checkbox"
      selection={selectedSubscriptions}
      onSelectionChange={event => {
        setSelectedSubscriptions(event.value as SubscriptionClientOrders[]);
      }}
      expandedRows={expandedRows}
      onRowToggle={(e) => {
        setExpandedRows(e.data);
      }}
      rowExpansionTemplate={(clientOrder) => {
        return <SubscriptionDetail clientOrderId={clientOrder.id}/>
      }}
      rowClassName={(clientOrder) => {
        let basketProductOrder = findBasketProductOrder(clientOrder.productOrders);
        return classNames({
          expanded: keys(expandedRows).includes(clientOrder.id),
          'bg-red-100': basketProductOrder && expirationInfoForProductOrder(basketProductOrder).undelivered < 2,
          'line-through': clientOrder.completionStatus && [ClientOrderCompletionStatus.TERMINATED, ClientOrderCompletionStatus.UNSUBSCRIBED].includes(clientOrder.completionStatus),
          'text-gray-200 italic': clientOrder.completionStatus && [ClientOrderCompletionStatus.RESUBSCRIBED].includes(clientOrder.completionStatus),
        });
      }}
    >
      <Column expander style={{maxWidth: '40px'}}/>
      <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
      <Column
        header="Status" field={'status'} style={{maxWidth: '160px'}} sortable
        body={statusColumn}
      />
      {/*<Column*/}
      {/*  field={'client.membership.id'} style={{maxWidth: '50px'}} sortable*/}
      {/*  body={previousMembershipColumn}*/}
      {/*/>*/}

      {/*<Column body={ambassadorColumn} style={{maxWidth: '100px'}} sortable/>*/}
      {/*<Column body={accountColumn} style={{maxWidth: '100px'}} sortable/>*/}
      {/*<Column body={orderDiscountColumn} style={{maxWidth: '100px'}} sortable/>*/}

      <Column header="Naam" body={nameColumn} field="client.name" sortable/>
      <Column
        header="Product"
        body={productColumn}
        sortable
        sortField={"productOrders"}
        sortFunction={(e) => {
          return orderBy(subscriptions
            .map(sub => {
              let mainProduct = (sub.productOrders?.find(po => {
                return po.product?.category?.code && ['zelfoogst', 'pakketten'].indexOf(po.product.category.code) > -1;
              }))?.product;
              return {...sub, productName: mainProduct?.name}
            }), 'productName', e.order === -1 ? 'desc' : 'asc');
        }}
      />
      {/*<Column header="Volgende pakketlevering" body={nextDeliveryDateColumn} sortable field="nextDeliveryDate"*/}
      {/*        style={{maxWidth: '130px'}}/>*/}
      <Column header="Einddatum" body={deliveryDatesColumn} sortable field="deliveryEndDate"
              style={{maxWidth: '130px'}}/>
      {/*<Column header="Inschr. datum" body={createdAtColumn} sortable field="createdAt" style={{maxWidth: '130px'}}/>*/}
      {/*<Column header="Totaal" body={totalColumn} style={{maxWidth: '100px'}}/>*/}
      <Column header="Acties" body={actionsColumn} style={{maxWidth: '200px'}}/>
      <Column body={remarksColumn} style={{maxWidth: '100px'}} sortable/>
    </DataTable>
  </TableWrapper>;
};

export default SubscriptionTable;
