import React from 'react';
import { compact } from "lodash";
import {useUserObject} from "../context/UserContext";
import {Permission} from "../../__generated__/types";
import CreateFarmItem from "./CreateFarmItem";

interface FarmSelectorProps {
  createPermission: Permission;
}

const FarmSelector = (props: FarmSelectorProps) => {
  const {user, setActiveFarm} = useUserObject();

  return <div className="m-3 flex flex-column justify-content-center align-items-center">
    <div className="text-xl font-bold">Kies je boerderij:</div>
    <div className="flex">
      {compact(user?.farms).map(farm =>
        <div
          className="w-9rem m-2 border-1 border-200 flex flex-column align-items-center justify-content-center p-4 cursor-pointer hover:border-2 hover:surface-50"
          onClick={() => {
            setActiveFarm(farm);
          }}
        >
          <div className="w-6rem h-6rem border-circle flex align-items-center justify-content-center">
            {farm.logo && <img className="w-6rem" src={farm.logo} alt={'Groentegeweld logo'}/>}
          </div>
          <div className="font-bold text-lg">{farm.name}</div>
        </div>
      )}
      {user?.permissions.includes(props.createPermission) && <CreateFarmItem/>}
    </div>
  </div>;
};

export default FarmSelector;
