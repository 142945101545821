import {useQuery} from '@apollo/client';
import React from 'react';
import {RevenueQuery} from "./RevenueManagement.gql";
import {Revenue, Vat} from "../../../__generated__/types";
import SalesPageHeading from "../SalesPageHeading";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {get, keys, sum, sumBy} from "lodash";
import {formatCurrency} from "../../../shared/utils/currency.utils";
import {valueInclVat} from "../../../utils/clientOrder.utils";

interface RevenueManagementProps {

}

type ProductOrder = { priceExcl: number, ambassadorDiscount: boolean, product: { vat: Vat } };
const RevenueManagement = (props: RevenueManagementProps) => {
  const {data} = useQuery<Revenue>(RevenueQuery);

  let categories = keys(data).map(categoryKey => {
    let categoryOrders: ProductOrder[] = get(data, categoryKey);
    return ({
      name: categoryKey,
      totalIncl: sum(categoryOrders.map((productOrder) => valueInclVat(productOrder.priceExcl, productOrder.product.vat))),
      totalWithoutAmbassadorDiscounts: sum(categoryOrders.map((productOrder) =>
        productOrder.ambassadorDiscount ? 0 : valueInclVat(productOrder.priceExcl, productOrder.product.vat))),
      totalWithAmbassadorDiscounts: sum(categoryOrders.map((productOrder: {
        priceExcl: number,
        product: { vat: Vat }
      }) =>
        valueInclVat(productOrder.priceExcl, productOrder.product.vat))),
      ambassadorDiscounts: sum(categoryOrders
        .map((productOrder) =>
          (productOrder.ambassadorDiscount ? valueInclVat(productOrder.priceExcl, productOrder.product.vat) : 0)
        )),
    });
  });


  return <div>
    <SalesPageHeading/>
    <DataTable<{ name: string, totalWithAmbassadorDiscounts: number }[]>
      sortMode="single"
      dataKey="code"
      defaultSortOrder={1}
      value={categories}
      header={() => <div>{formatCurrency(sumBy(categories, 'totalWithAmbassadorDiscounts'))}</div>}
    >
      <Column field="name" sortable
              body={(product) => {
                return <div>
                  <div>{product.name}</div>
                  <div className="text-xs text-gray-400">{product.code}</div>
                </div>;
              }}/>
      <Column
        header="Totaal"
        field="totalWithAmbassadorDiscounts" sortable
        body={(product) => {
          return <div>
            <div>{formatCurrency(product.totalWithAmbassadorDiscounts)}</div>
            {product.ambassadorDiscounts < 0 && <div className="text-xs text-gray-400">
              = {formatCurrency(product.totalWithoutAmbassadorDiscounts)} <br/>
              - {formatCurrency(Math.abs(product.ambassadorDiscounts))} (amb. korting)</div>}
          </div>;
        }}
      />

    </DataTable>
  </div>;
};

export default RevenueManagement;
