export const formatCurrency = (value: number | undefined, maxFractionDigits?: number) => (value || 0).toLocaleString('nl-NL', {
  currency: 'EUR',
  minimumFractionDigits: maxFractionDigits!==undefined ? maxFractionDigits : 2,
  maximumFractionDigits: maxFractionDigits!==undefined ? maxFractionDigits : 2,
  useGrouping: true,
  currencyDisplay: 'code',
  style: 'currency',
});

export const formatCurrencySign = (value: number | undefined, maxFractionDigits?: number) => (value || 0).toLocaleString('nl-NL', {
  currency: 'EUR',
  minimumFractionDigits: maxFractionDigits!==undefined ? maxFractionDigits : 2,
  maximumFractionDigits: maxFractionDigits!==undefined ? maxFractionDigits : 2,
  useGrouping: true,
  currencyDisplay: 'symbol',
  style: 'currency',
});

export const formatCurrencyNoSign = (value: number | undefined, maxFractionDigits?: number) => `${(value || 0).toLocaleString('nl-NL', {
  minimumFractionDigits: maxFractionDigits !== undefined ? maxFractionDigits : 2,
  maximumFractionDigits: maxFractionDigits !== undefined ? maxFractionDigits : 2,
  useGrouping: true,
  style: 'decimal',
})}${maxFractionDigits === 0 ? ',-' : ''}`;
export const formatDoubleDigit = (value: number | undefined) => (value || 0).toLocaleString('nl', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
export const formatToMaxPrecision = (value: number | undefined, precision: number) => (value || 0).toLocaleString('nl', {
  minimumFractionDigits: 0,
  maximumFractionDigits: precision,
});
export const formatAbs = (value: number | undefined) => (value || 0).toLocaleString('nl', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const to25cents = (value: number | undefined) => {
  const safeValue = value || 0;
  const remainder = safeValue % 0.25;
  if (remainder <= 0.125) {
    return safeValue - remainder;
  }
  return safeValue - remainder + 0.25;
};

export const formatDoubleDigitStrict = (value: number | undefined) => (value || 0.00).toLocaleString('nl', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const toPercentage = (value: number| undefined) => `${value ? formatDoubleDigit(value * 100) : "-"}%`;
