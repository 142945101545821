import React, {useState} from 'react';
import {compact, first, times} from "lodash";
import {Tooltip} from "primereact/tooltip";
import classNames from "classnames";
import {Moment} from "moment";
import DeliveryWeekDetail from './DeliveryWeekDetail';
import {asDayFullDdMmYyyy, momentFromIso8601, momentFromYyyyWE} from "../../../../../../../../shared/utils/date.utils";
import {deliveryWeeksForSubscription} from "../../../../../../../../shared/utils/subscription.utils";
import {WeekBar} from "../../../../../../../../shared/components/WeekBar";
import {
  ActiveSubscriptions_clientOrders_productOrders_deliveries
} from "../../../../../../../../__generated__/types";
import {SubscriptionClientOrders_productOrders} from "../../../../../SubscriptionTable/SubscriptionTable";

interface PeriodCellProps {
  clientOrderId: string;
 productOrder: SubscriptionClientOrders_productOrders;
  deliveryDates: string[];
  clientOrderDeliveryDate: string;
  //selectedDelivery: ActiveSubscriptions_clientOrders_productOrders_deliveries | undefined;
  //onDeliverySelected: (delivery: ActiveSubscriptions_clientOrders_productOrders_deliveries) => void;
}

const PeriodColumn = (props: PeriodCellProps) => {
  const { productOrder, clientOrderDeliveryDate, deliveryDates } = props;
  //const [selectedDelivery, setSelectedDelivery] = useState<ActiveSubscriptions_clientOrders_productOrders_deliveries>();
  const [selectedDeliveryWeek, setSelectedDeliveryWeek] = useState<Moment>();
  let deliveries: ActiveSubscriptions_clientOrders_productOrders_deliveries[] = compact(productOrder.deliveries);
  if (deliveries.length < 1) {
    return <div></div>;
  }
  if (deliveryDates.length > 0) {
    let firstDeliveryDate = momentFromIso8601(deliveryDates[0]);

    if (firstDeliveryDate.isAfter(momentFromIso8601(clientOrderDeliveryDate), 'day')) {
      firstDeliveryDate = momentFromIso8601(clientOrderDeliveryDate);
    }

    let lastDeliveryDate = momentFromIso8601(deliveryDates[deliveryDates.length - 1]);

    let nrOfWeeks = lastDeliveryDate.diff(firstDeliveryDate, 'week') + 1;

    const deliveryWeeks = times(nrOfWeeks).map(nrOfWeek => firstDeliveryDate.clone().add(nrOfWeek, 'week'));
    const productOrderDeliveryDates = deliveries.map(del => momentFromIso8601(del.plannedDeliveryDate).format('YYYY-W E'));
    //const isoWeekDay = momentFromYyyyWE(first(productOrderDeliveryDates))?.isoWeekday() || 5;

    let subscriptionProductCode = productOrder.product?.code;
    let deliveryWeeksForProduct = deliveryWeeksForSubscription(subscriptionProductCode,
      momentFromIso8601(clientOrderDeliveryDate).isoWeekday(1));

    return <div className="h-full flex justify-content-center flex-column">
      <Tooltip target=".date"/>
      <WeekBar style={{position: "relative", width: "100%"}} width={'1fr'} nrOfWeeks={deliveryWeeks.length}>
        {
          deliveryWeeks
            //.map(deliveryWeek => deliveryWeek.isoWeekday(isoWeekDay))
            .map((deliveryWeek) => {

              let deliveryForDeliveryWeek = deliveries
                .find(delivery => momentFromIso8601(delivery.plannedDeliveryDate)
                  .isSame(deliveryWeek, 'isoWeek'));

              let deliveryWeekHasDelivery = !!deliveryForDeliveryWeek; //productOrderDeliveryDates.includes(deliveryWeek.format('YYYY-W E'));
              let deliveryWeekWithDeliveredDelivery = deliveryForDeliveryWeek && !!deliveryForDeliveryWeek.deliveryDate; //productOrderDeliveryDates.includes(deliveryWeek.format('YYYY-W E'));
              // console.log(deliveryWeekWithDeliveredDelivery, deliveryForDeliveryWeek);

              let deliveryWeekIsInPossibleProductWeeks = deliveryWeeksForProduct.possibleWeeks.includes(deliveryWeek.format('YYYY-W'));
              let deliveryWeekIsInProductWeeks = deliveryWeeksForProduct.weeks.includes(deliveryWeek.format('YYYY-W'));

              let timelineDate = deliveryForDeliveryWeek ? momentFromIso8601(deliveryForDeliveryWeek.plannedDeliveryDate) : deliveryWeek;

              return <>
              <span
                data-pr-tooltip={asDayFullDdMmYyyy(timelineDate)} data-pr-position="top"
                onClick={() => {

                  setSelectedDeliveryWeek(!timelineDate.isSame(selectedDeliveryWeek,'isoWeek') ? timelineDate : undefined);
                  // let clickedDelivery = deliveries.find(delivery =>
                  //   momentFromIso8601(delivery.plannedDeliveryDate).isSame(deliveryWeek, 'isoWeek'));
                  // if (clickedDelivery) {
                  //   if (selectedDelivery && clickedDelivery.id === selectedDelivery.id) {
                  //     setSelectedDelivery(undefined);
                  //   } else {
                  //     setSelectedDelivery(clickedDelivery);
                  //   }
                  // }
                }}
                className={classNames({
                  date: true,
                  week: true,
                  'cursor-pointer': true,
                  'crossed border-1 border-brown': deliveryWeekIsInProductWeeks
                    && !deliveryWeekHasDelivery,
                  'crossed cancelledDelivery': deliveryForDeliveryWeek?.cancelled,
                  selectedWeek: selectedDeliveryWeek?.isSame(timelineDate,'isoWeek'),
                  weekWithDelivery: deliveryWeekHasDelivery,
                  'pi pi-check': deliveryWeekWithDeliveredDelivery,
                  possibleWeeks: !deliveryWeekHasDelivery
                    && deliveryWeekIsInPossibleProductWeeks
                    && !deliveryWeekIsInProductWeeks
                })}></span>
              </>;
            })
        }
      </WeekBar>
      {selectedDeliveryWeek &&
        <DeliveryWeekDetail
          clientOrderId={props.clientOrderId}
          deliveryWeek={selectedDeliveryWeek}
          productOrder={productOrder}
        />
      }
    </div>;
  }
  return <div/>;
};

export default PeriodColumn;
