import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {
  DeliveriesUpdate,
  DeliveriesUpdateVariables,
  DeliveryLocation,
  ProductType
} from "../../../../../../__generated__/types";
import {useMutation} from "@apollo/client";
import {Dropdown} from "primereact/dropdown";
import {deliveryLocations} from 'shared/utils/deliveryLocation.utils';
import {DeliveriesUpdateMutation} from "../../../../../../shared/queries/productOrder.gql";

interface ChangeDeliveryLocationButtonProps {
  clientOrderId: string;
  forDelivery?: string;
  value?: DeliveryLocation | null;
}

const ChangeDeliveryLocationButton = (props: ChangeDeliveryLocationButtonProps) => {
  const [deliveriesUpdate] = useMutation<DeliveriesUpdate>(DeliveriesUpdateMutation);
  const [deliveryLocation, setDeliveryLocation] = useState<DeliveryLocation | null>(null);

  useEffect(() => {
    if(props.value) {
      setDeliveryLocation(props.value);
    } else {
      setDeliveryLocation(null);
    }
  }, [props.value]);

  return <div
    className="border-1 border-300 surface-50 border-round p-2 px-4 my-2 flex align-items-center justify-content-between flex-wrap">
    <div className="text-xl font-medium">Wijzig het afhaalpunt</div>
    <div className="flex align-items-center py-2">
      <div>Nieuw afhaalpunt:</div>
      <Dropdown
        className="mx-2 p-dense max-w-9rem w-9rem"
        options={deliveryLocations}
        value={deliveryLocation}
        onChange={(e) => setDeliveryLocation(e.value)}
      />
      <Button
        label={'Wijzig afhaalpunt'}
        className="p-dense p-button-outlined"
        disabled={deliveryLocation === undefined}
        onClick={() => {
          let variables: DeliveriesUpdateVariables;
          if (props.forDelivery) {
            variables = {
              where: {
                id: props.forDelivery
              },
              data: {
                deliveryLocation
              }
            }
          } else {
            variables = {
              where: {
                productOrder: {
                  product: {type: ProductType.SUBSCRIPTION},
                  clientOrder: {id: props.clientOrderId}
                },
                deliveryDate: null
              },
              data: {
                deliveryLocation
              }
            }
          }
          deliveriesUpdate({variables, refetchQueries: "active"});
        }}
      />
    </div>
  </div>;
};

export default ChangeDeliveryLocationButton;
