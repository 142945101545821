import React from 'react';
import {BasketCompositionStatus} from "../../../../../__generated__/types";

interface BasketCompositionStatusBadgeProps {
  status?: BasketCompositionStatus;
}

const basketCompositionStatus = (status: BasketCompositionStatus | undefined) => {
  if (status === BasketCompositionStatus.DRAFT) {
    return "Draft";
  } else if (status === BasketCompositionStatus.PUBLISHED) {
    return "Gepubliceerd";
  }
  return "ONBEKEND";
};

const StatusBadges = {
  [BasketCompositionStatus.DRAFT]: {
    label: "Draft",
    color: "blue",
  },
  [BasketCompositionStatus.PUBLISHED]: {
    label: "Gepubliceerd",
    color: "yellow",
  },
};

const BasketCompositionStatusBadge = (props: BasketCompositionStatusBadgeProps) => {
  return <div
    className={`p-2 flex align-items-center justify-content-center bg-${props.status && StatusBadges[props.status].color || 'red'}-100 text-${props.status && StatusBadges[props.status].color || 'red'}-500 border-round`}>
    {basketCompositionStatus(props.status)}
  </div>;
};

export default BasketCompositionStatusBadge;
