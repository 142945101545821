import React, {useRef, useState} from 'react';
import {asIso8601, momentFromIso8601} from 'shared/utils/date.utils';
import ProductAutoComplete from './ProductAutoComplete';
import BasketItemsTable from "./BasketItemsTable";
import {useMutation, useQuery} from "@apollo/client";
import {
    BasketCompositionDeleteMutation,
    BasketCompositionUpsertMutation
} from "../../../../../shared/queries/basketComposition.gql";
import {
    BasketCompositionDelete,
    BasketCompositions_basketCompositions,
    BasketCompositionStatus,
    BasketCompositionType,
    BasketCompositionUpsert,
    BasketCompositionUpsertVariables,
    BasketItemCreateWithoutCompositionInput,
    BasketItemUpdateWithoutCompositionDataInput,
    DeliveryLocation,
    FarmConfigurationsByCode,
    GetProducts_products,
    ProductOrdersForBasketComposition,
    ProductOrdersForBasketComposition_productOrders
} from "../../../../../__generated__/types";
import {compact, first, groupBy, keys} from "lodash";
import {Button} from "primereact/button";
import {ProductOrdersForBasketCompositionQuery} from "../../../../../shared/queries/productOrder.gql";
import ProductOrdersCounter from "./ProductOrdersCounter";
import {
    filterProductOrdersMissingInBasketComposition,
    filterProductOrdersWithDifferentBasketComposition
} from "../../../../../utils/productOrder.utils";
import {Message} from "primereact/message";
import BasketCompositionErrors from "../BasketCompositionErrors";
import {OverlayPanel} from "primereact/overlaypanel";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import DeliveriesInfo from './DeliveriesInfo';
import {Dialog} from "primereact/dialog";
import BasketCompositionForDelivery
    from "../../../../StartPage/Baskets/BasketCompositionForDelivery/BasketCompositionForDelivery";
import {
    filterFixedBasketProductOrders,
    filterFlexBasketProductOrders,
    filterProductOrdersByDeliveryLocations
} from "../../../../../shared/utils/productOrder.utils";
import DeliveryLocationSelection from "./DeliveryLocationSelection";
import {toCity} from "../../../../../shared/utils/deliveryLocation.utils";
import {Props} from 'shared/types/types';
import {
    FlexNotification,
    notificationsFor
} from "../../../../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";
import BasketCompositionNotification from "./BasketCompositionNotification";
import {totalPortions} from "../../../../../shared/utils/basketComposition.utils";
import {getBasketSize} from "../../../../../shared/utils/product.utils";
import {FarmConfigurationsByCodeQuery} from "../../../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../../../shared/utils/farmConfig.utils";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface BasketCompositionDetailProps {
    basketComposition: BasketCompositions_basketCompositions;
    products: GetProducts_products[];
}

export interface BasketItem {
    id: string;
    product: { id: string },

    quantitySmall: number,
    portionsSmall: number,
    deliveryLocationsSmall: DeliveryLocation[];

    quantityLarge: number,
    portionsLarge: number,
    deliveryLocationsLarge: DeliveryLocation[];

    packagingOrder: number;
}

export let productOrderHasErrorNotification = (productOrder: ProductOrdersForBasketComposition_productOrders, basketComposition: BasketCompositions_basketCompositions) => {
    let plannedDeliveryForBasketComposition = first(productOrder.plannedDeliveryForBasketComposition);
    //let additionalPortions = totalPortions(plannedDeliveryForBasketComposition?.basketComposition?.items, getBasketSize(productOrder?.product), plannedDeliveryForBasketComposition?.deliveryLocation);
    // let additionalPortions = plannedDeliveryForBasketComposition?.basketComposition
    //     ? totalPortions(plannedDeliveryForBasketComposition.basketComposition?.items, getBasketSize(productOrder.product), plannedDeliveryForBasketComposition.deliveryLocation)
    //     : totalPortions(basketComposition.items, getBasketSize(productOrder.product), plannedDeliveryForBasketComposition?.deliveryLocation);

    if (plannedDeliveryForBasketComposition) {
        let notifications = notificationsFor(productOrder);

        return notifications.findIndex(notification => notification.severity === 'error') > -1;
    }
};

const BasketCompositionViewerDetail = (props: Props<BasketCompositionDetailProps>) => {
    const {basketComposition} = props;
    const {activeFarm} = useUserObject();
    const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});
    let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

    const [showBasketContentsFor, setShowBasketContentsFor] = useState<DeliveryLocation | undefined>(undefined);
    const overlayPanelRef = useRef<OverlayPanel>(null);

    let deliveryWeek = momentFromIso8601(basketComposition.deliveryWeek);

    let basketCompositionId = props.basketComposition.id;
    let plannedDeliveryStartDate = asIso8601(deliveryWeek.clone().startOf('isoWeek'));
    let plannedDeliveryEndDate = asIso8601(deliveryWeek.clone().endOf('isoWeek'));

    const {data: productOrdersData} = useQuery<ProductOrdersForBasketComposition>(ProductOrdersForBasketCompositionQuery, {
        variables: {
            basketCompositionId,
            plannedDeliveryStartDate,
            plannedDeliveryEndDate
        }
    });

    const [upsertBasketComposition] = useMutation<BasketCompositionUpsert>(BasketCompositionUpsertMutation);
    const [deleteBasketComposition] = useMutation<BasketCompositionDelete>(BasketCompositionDeleteMutation, {refetchQueries: "active"});

    const updateProduct = (basketItem: {
        id: string | undefined,
        product: { id: string },

        quantitySmall: number,
        portionsSmall: number,
        deliveryLocationsSmall: DeliveryLocation[];

        quantityLarge: number,
        portionsLarge: number,
        deliveryLocationsLarge: DeliveryLocation[];

        delete?: boolean,
    }) => {
        let itemCreateInput: BasketItemCreateWithoutCompositionInput = {
            product: {connect: {id: basketItem.product.id}},

            quantitySmall: basketItem.quantitySmall,
            portionsSmall: basketItem.portionsSmall,
            deliveryLocationsSmall: {set: basketItem.deliveryLocationsSmall},

            quantityLarge: basketItem.quantityLarge,
            portionsLarge: basketItem.portionsLarge,
            deliveryLocationsLarge: {set: basketItem.deliveryLocationsLarge},
        };
        let itemUpdateInput: BasketItemUpdateWithoutCompositionDataInput = {
            product: {connect: {id: basketItem.product.id}},

            quantitySmall: basketItem.quantitySmall,
            portionsSmall: basketItem.portionsSmall,
            deliveryLocationsSmall: {set: basketItem.deliveryLocationsSmall},

            quantityLarge: basketItem.quantityLarge,
            portionsLarge: basketItem.portionsLarge,
            deliveryLocationsLarge: {set: basketItem.deliveryLocationsLarge},
        };

        const variables: BasketCompositionUpsertVariables = {
            where: {
                id: basketComposition.id,
            },
            update: {
                items: {
                    deleteMany: basketItem.delete ? [{id: basketItem.id}] : undefined,
                    upsert: !basketItem.delete ? [
                        {
                            where: {id: basketItem.id || ""},
                            update: itemUpdateInput,
                            create: itemCreateInput
                        }
                    ] : undefined
                }
            },
            create: {
                type: BasketCompositionType.FIXED,
                deliveryWeek: asIso8601(deliveryWeek),
                items: {
                    create: [itemCreateInput]
                }
            }
        };
        upsertBasketComposition({variables});
    };

    // let onPublish = () => {
    //   const variables: BasketCompositionUpdateVariables = {
    //     where: {id: basketComposition.id,},
    //     data: {status: BasketCompositionStatus.PUBLISHED},
    //   };
    //   updateBasketComposition({variables});
    // };

    let productOrders = compact(productOrdersData?.productOrders);

    let missingAssignedProductOrders = filterProductOrdersMissingInBasketComposition(productOrders, basketComposition)
        .filter(po => !productOrderHasErrorNotification(po, props.basketComposition));
    let productOrdersWithDifferentBasketComposition = filterProductOrdersWithDifferentBasketComposition(productOrders, basketComposition);

    let mediums = filterFixedBasketProductOrders(productOrders, ["medium"]);
    let firstSmallMatchingProductOrderForDeliveryLocation = first(filterProductOrdersByDeliveryLocations(mediums, compact([showBasketContentsFor])));
    let firstLargeMatchingProductOrderForDeliveryLocation = first(filterProductOrdersByDeliveryLocations(filterFixedBasketProductOrders(productOrders, ['large']), compact([showBasketContentsFor])));
    let notificationsForProductOrders = filterFlexBasketProductOrders(productOrders)
      .reduce((acc: FlexNotification[], productOrder) => {
        let plannedDeliveryForBasketComposition = first(productOrder.plannedDeliveryForBasketComposition);

        if (plannedDeliveryForBasketComposition) {
            // let additionalPortions = plannedDeliveryForBasketComposition.basketComposition
            //     ? totalPortions(plannedDeliveryForBasketComposition.basketComposition?.items, getBasketSize(productOrder.product), plannedDeliveryForBasketComposition.deliveryLocation)
            //     : totalPortions(basketComposition.items, getBasketSize(productOrder.product), plannedDeliveryForBasketComposition.deliveryLocation);

            let flexNotifications = notificationsFor(productOrder);
            if (flexNotifications.length > 0) {
                acc = [...acc, ...flexNotifications];
            }
        }
        return acc;
    }, []);

    let notificationsByCode = groupBy(notificationsForProductOrders, 'code');

    let basketItemRows = compact(basketComposition.items);

    return <>
        <div className="grid justify-content-start align-items-start">
            <div className="col-12 flex flex-column">
                {(
                        basketComposition.status === BasketCompositionStatus.PUBLISHED
                        && (missingAssignedProductOrders.length > 0 || productOrdersWithDifferentBasketComposition.length > 0)
                    )
                    &&
                    <>
                        <OverlayPanel ref={overlayPanelRef}>
                            <BasketCompositionErrors
                                productOrders={[...missingAssignedProductOrders, ...productOrdersWithDifferentBasketComposition]}
                                basketComposition={basketComposition}/>
                        </OverlayPanel>
                        <Message
                            severity="warn"
                            icon="pi pi-exclamation-triangle"
                            className="w-full m-1 text-left"
                            content={() => {
                                return <div className="w-full flex align-items-center">
                                    {missingAssignedProductOrders.length > 0 &&
                                        <div>{`Er ${missingAssignedProductOrders.length > 1 ? 'zijn' : 'is'} ${missingAssignedProductOrders.length} levering${missingAssignedProductOrders.length > 1 ? 'en' : ''} zonder samenstelling gevonden.`}</div>}
                                    {productOrdersWithDifferentBasketComposition.length > 0 &&
                                        <div>{`Er ${productOrdersWithDifferentBasketComposition.length > 1 ? 'zijn' : 'is'} ${productOrdersWithDifferentBasketComposition.length} levering${productOrdersWithDifferentBasketComposition.length > 1 ? 'en' : ''} met andere pakketsamenstelling gevonden.`}</div>}
                                    <br/>
                                    <Button
                                        label={'Meer info'}
                                        className="p-button-link p-0 underline"
                                        onClick={(e) => {
                                            overlayPanelRef.current?.toggle(e);
                                        }}
                                    />
                                </div>
                            }}/>
                    </>
                }
                {(notificationsForProductOrders.length > 0)
                    &&
                    keys(notificationsByCode).map((notificationCode) => {
                        let notifications = notificationsByCode[notificationCode];
                        return <BasketCompositionNotification
                            notificationCode={notificationCode}
                            notifications={notifications}
                            productOrders={productOrders}
                        />;
                    })
                }
            </div>

            <div className={"col-3"}>
                <ProductOrdersCounter
                    basketComposition={basketComposition}
                    deliveryWeek={deliveryWeek}
                />
            </div>
            <div className={"col-5"}>
                <DeliveriesInfo
                    basketComposition={basketComposition}
                    deliveryWeek={deliveryWeek}
                />
            </div>
        </div>

        <div className="m-2 mb-4">
            <BasketItemsTable
                products={props.products}
                flexClientGroupCode={flexClientGroupCode}
                basketCompositionId={props.basketComposition.id}
                deliveryWeek={deliveryWeek}
                //disabled={basketComposition.status === BasketCompositionStatus.PUBLISHED}
                basketItems={basketItemRows}
                productOrders={productOrders}
                onChange={updateProduct}
            />
            <div className="my-2"><ProductAutoComplete onChange={updateProduct}/></div>
        </div>

        <div>
            {showBasketContentsFor && <Dialog
                header={`Pakketinhoud ${toCity(showBasketContentsFor)}`}
                closable
                footer={() => <Button className="p-button-link text-800 underline" label="Sluiten"
                                      onClick={() => setShowBasketContentsFor(undefined)}/>}
                visible={!!showBasketContentsFor}
                onHide={() => setShowBasketContentsFor(undefined)}
            >
                <div className="grid">
                    <div className="col-6 p-4 border-right-1 border-500">
                        <div className="font-bold text-xl">Klein pakket</div>
                        {firstSmallMatchingProductOrderForDeliveryLocation && <BasketCompositionForDelivery
                            flexClientGroupCode={flexClientGroupCode}
                            showPortions={true}
                            basketCompositionId={props.basketComposition.id}
                            product={firstSmallMatchingProductOrderForDeliveryLocation.product}
                            delivery={{id: "", deliveryLocation: showBasketContentsFor}}
                        />}
                    </div>
                    <div className="col-6 p-4">
                        <div className="font-bold text-xl">Groot pakket</div>
                        {firstLargeMatchingProductOrderForDeliveryLocation && <BasketCompositionForDelivery
                            flexClientGroupCode={flexClientGroupCode}
                            showPortions={true}
                            basketCompositionId={props.basketComposition.id}
                            product={firstLargeMatchingProductOrderForDeliveryLocation.product}
                            delivery={{id: "", deliveryLocation: showBasketContentsFor}}
                        />}

                    </div>
                </div>
            </Dialog>}
            <div className="my-2 text-xs">
                <DeliveryLocationSelection
                    hideSelectAll
                    expandable={false}
                    label="Toon het pakket voor een klant in:"
                    value={compact([showBasketContentsFor])} onChange={(e) => setShowBasketContentsFor(first(e))}/>
            </div>
        </div>

        <div className="flex justify-content-between mt-2 border-top-1 border-300 pt-2 mt-4">
            <div>
                <ConfirmPopup/>
                <Button
                    disabled={basketComposition.status === BasketCompositionStatus.PUBLISHED}
                    icon={'pi pi-trash'}
                    label="Pakketsamenstelling verwijderen"
                    className='p-button-text p-button-danger pl-0'
                    onClick={(e) => {
                        confirmPopup({
                            acceptLabel: 'Ja',
                            rejectLabel: 'Nee',
                            target: e.currentTarget,
                            message: 'Ben je zeker dat je deze pakketsamenstelling wil verwijderen?',
                            icon: 'pi pi-exclamation-triangle',
                            accept: () => {
                                deleteBasketComposition({variables: {where: {id: basketComposition.id}}})
                            },
                            reject: () => {
                            }
                        });
                    }}/>
            </div>
            <div>
            </div>
        </div>
    </>;
};

export default BasketCompositionViewerDetail;
