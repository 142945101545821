import gql from "graphql-tag";
import { ProductFields_basicFragment } from "./product.gql";

export const ClientFieldsFragment = gql`
fragment ClientFields on Client {
  id
  name
  firstName
  dateOfBirth
  street
  streetNumber
  city
  cityPostalCode
  companyName
  email
  secondaryEmail
  newsletter
  whatsApp
  phone
  farm { id }
  type
  billingEmail
  vatNumber
  remarks
  group { id }
  ibanNumbers
  bankTransactions {
    id
    amount
  }
  auth0userId
  customerInfo {
    members {
      id
      name
      firstName
      dateOfBirth
    }
  }
}
`;

export const ClientUpdate_CustomerInfo_MembersMutation = gql`
  mutation ClientUpdate_CustomerInfo_Members($id: ID!, $membersUpdate: MemberUpdateManyWithoutCustomerInfoInput!) {
	updateClient(
    where: {id: $id}
    data: {
      customerInfo: {
        update: {
          members: $membersUpdate
        }
      }
    }
  ){
    id
    customerInfo {
      members {
        id
        name
        firstName
        dateOfBirth
      }
    }
  }
}

`;

export const ClientsQuery = gql`
${ClientFieldsFragment}
query Clients($farmId:ID!) {
  clients(where: {farm:{id:$farmId}}) {
    ...ClientFields
  }
}`;

export const ClientQuery = gql`
${ClientFieldsFragment}
query Client($id: ID!) {
  client(where: {id: $id}) {
    ...ClientFields
  }
}
`;

// export const ClientIdByEmailQuery = gql`
// ${ClientFieldsFragment}
// query ClientIdByEmail($email: String!) {
//   client(where: {email: $email}) {
//     id
//   }
// }
// `;

// export const ClientByEmailQuery = gql`
// ${ClientFieldsFragment}
// ${ProductFields_basicFragment}
// query ClientByEmail($email: String!) {
//   client(where: {email: $email}) {
//     ...ClientFields
//     clientOrders(
//       orderBy: createdAt_DESC
//     	first: 1
//       where: {
//         productOrders_some: {
//           product: {
//             category: {
//               code_in: ["zelfoogst", "pakketten", "leeggoed"]
//             }
//           }
//         }
//       }
//     ) {
//       extraProductOrders:  productOrders(where: {
//         ambassadorDiscount: false,
//         product: {
//         	type: SUBSCRIPTION,
//         	category: {code_in: ["bloemen", "uien", "eieren", "aardappelen"]
//         }
//         }})
//       {
//         createdAt
//         frequency
//         deliveries(orderBy: plannedDeliveryDate_DESC first: 1) {
//           plannedDeliveryDate
//           deliveryLocation
//         }
//         product {
//           ...ProductFields_basic
//           name
//         }
//       }
//       basketProductOrders: productOrders(where: {product: {category: {code: "pakketten"}}}) {
//         createdAt
//         deliveries(orderBy: plannedDeliveryDate_DESC first: 1) {
//           plannedDeliveryDate
//           deliveryLocation
//         }
//         product {
//           ...ProductFields_basic
//           name
//         }
//       }
//       selfHarvestProductOrders: productOrders(where: {product: {category: {code: "zelfoogst"}}}) {
//         createdAt
//         product {
//           ...ProductFields_basic
//           name
//         }
//       }
//       boxProductOrders: productOrders(where: {product: {category: {code: "leeggoed"}}}) {
//         quantity
//         product {
//           ...ProductFields_basic
//           name
//         }
//       }
//     }
//   }
// }
// `;

export const ClientByAuth0UserIdQuery = gql`
${ClientFieldsFragment}
${ProductFields_basicFragment}
query ClientByAuth0UserId($auth0userId: String!, $auth0Email: String!, $today:DateTime!) {
  clientsByUserId: clients(where: {farm:{code: "groentegeweld"}, auth0userId: $auth0userId}) {
    ...ClientFields
    createdAt
    clientOrders(where: {
      deliveryEndDate_gte: $today
    }, orderBy: deliveryEndDate_ASC) {
      id
      deliveryDate
      deliveryEndDate
      productOrders {
        id
        product {
          ...ProductFields_basic
          name
        }
      }
    }
  }

  user(where: {email: $auth0Email}) {
    id
  }
}
`;
export const ClientByIdQuery = gql`
${ClientFieldsFragment}
${ProductFields_basicFragment}
query ClientById($id: ID, $today:DateTime!) {
  client(where: {id:$id}) {
    ...ClientFields
    createdAt
    clientOrders(where: {
      deliveryEndDate_gte: $today
    }, orderBy: deliveryEndDate_ASC) {
      id
      deliveryDate
      deliveryEndDate
      productOrders {
        id
        product {
          ...ProductFields_basic
          name
        }
      }
    }
  }
}
`;

export const ClientUpdateMutation = gql`
mutation UpdateClient($id: ID!, $data: ClientUpdateInput!) {
  updateClient(
    where: {id: $id}
    data: $data
  ) {
    id
  }
}
`;

export const ClientUpsertMutation = gql`
mutation UpsertClient($id: ID, $createData: ClientCreateInput!, $updateData: ClientUpdateInput!) {
 	upsertClient(
    	where: {id: $id}
    	create:$createData
    	update: $updateData
  ) {
    id
  }
}
`;

export const ClientsForMailChimpQuery = gql`
${ProductFields_basicFragment}
${ClientFieldsFragment}
query ClientsForMailChimp($farmId: ID!) {
  clients(where: {farm: {id: $farmId}}) {
    ...ClientFields
    clientOrders(
      where: {
        productOrders_some: {product: {
          OR: [
            {code_starts_with: "pakketten"},
            {code_starts_with: "zelfoogst"}
          ]
        }}
      }
    ) {
      deliveryEndDate
      productOrders(
        where: {product: {
          OR: [
            {code_starts_with: "pakketten"},
            {code_starts_with: "zelfoogst"}
          ]
        }}
      ) {
      	product { ...ProductFields_basic }
      	deliveries(
          orderBy: plannedDeliveryDate_ASC
          where: {
            cancelled_not: true
          }
        ) {
          id
          deliveryLocation
          plannedDeliveryDate
          deliveryDate
        }
      }
    }
    group {
      code
    }
  }
}`;

