import S3 from "react-aws-s3-typescript";

const S3_BUCKET = 'groentegeweld';
const REGION = 'eu-west-3';

const ACCESS_KEY = 'AKIAWM6OJECGL6THF2OM';
const SECRET_ACCESS_KEY = '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';
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

export const ReactS3Client = new S3(config);
