import React, {useEffect, useState} from 'react';
import {chain, compact, flatten, keyBy, map, orderBy, sum} from "lodash";
import {Column} from "primereact/column";
import {
  AllProducts,
  RemoveShopOrder,
  ShopClients,
  ShopClients_shopClients,
  ShopOrders,
  ShopOrders_shopOrders,
  ShopOrderStatus,
  UpdateOrderPaymentStatus,
  UpdateOrderStatus
} from "../../../__generated__/types";
import {asDayFullDdMmYyyy, momentFromIso8601} from "../../../shared/utils/date.utils";
import {deliveryLocations} from "../../../shared/utils/deliveryLocation.utils";

import {useMutation, useQuery} from "@apollo/client";
import {
  AllProductsQuery,
  RemoveShopOrderMutation,
  ShopClientsQuery,
  ShopOrdersQuery,
  UpdateOrderPaymentStatusMutation,
  UpdateOrderStatusMutation
} from "../Veldwinkel.gql";
import BillingStatusColumn from "./components/BillingStatusColumn";
import OrderStatusColumn from "./components/OrderStatusColumn";
import {Button} from "primereact/button";
import {formatCurrencySign} from "../../../shared/utils/currency.utils";
import {totalPrice} from "../../../shared/utils/Shop.utils";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import {ambassadorDiscountPercentage, ambassadorOptionFromProductCode} from "../../../shared/utils/ambassador.utils";
import IndividualShopOrder from "../HarvestList/IndividualShopOrder";
import {createdAtColumn} from "../../../components/columns";
import {DataTable} from "primereact/datatable";
import {findAmbassadorProductOrder} from "../../../shared/utils/productOrder.utils";

interface ShopOrderListProps {

}

const ShopOrderList = (props: ShopOrderListProps) => {
  const {data: shopClientsData} = useQuery<ShopClients>(ShopClientsQuery);
  const {data: shopOrderData, refetch} = useQuery<ShopOrders>(ShopOrdersQuery);
  const {data: productsData} = useQuery<AllProducts>(AllProductsQuery);

  const [shopClients, setShopClients] = useState<{ [key: string]: ShopClients_shopClients }>({});

  const [updateOrderStatus] = useMutation<UpdateOrderStatus>(UpdateOrderStatusMutation);
  const [updateOrderPaymentStatus] = useMutation<UpdateOrderPaymentStatus>(UpdateOrderPaymentStatusMutation);
  const [removeShopOrder] = useMutation<RemoveShopOrder>(RemoveShopOrderMutation);

  const [expandedRows, setExpandedRows] = useState<any>();
  const [orderTotal, setOrderTotal] = useState<{ delivered: number, undelivered: number }[]>([{
    delivered: 0,
    undelivered: 0
  }]);

  useEffect(() => {
    setShopClients(keyBy(compact(shopClientsData?.shopClients), 'id'));

    const orderTotal: { undelivered: number; delivered: number }[] = chain(shopOrders)
      .map(shopOrder => {
        const items: { priceIncl: number, delivered: boolean }[] = compact(shopOrder.shopOrderItems);
        const undeliveredItems = items.filter(i => !i.delivered);

        const client = clients[shopOrder.shopClient.email];
        let ambassadorDiscount = 0;
        let ambassadorProductOrder = findAmbassadorProductOrder(compact(flatten(map(client?.clientOrders, 'productOrders'))));
        if (ambassadorProductOrder?.product?.code) {
          ambassadorDiscount = ambassadorDiscountPercentage(ambassadorOptionFromProductCode(ambassadorProductOrder?.product?.code));
        }



        const priceUndeliveredItems = totalPrice(undeliveredItems, false, ambassadorDiscount, shopOrder.promocode);
        const priceDeliveredItems = totalPrice(items.filter(i => i.delivered), shopOrder.extraPackaging, ambassadorDiscount, shopOrder.promocode);
        return {
          delivered: priceDeliveredItems,
          undelivered: priceUndeliveredItems,
        };
      })
      .value();
    setOrderTotal(orderTotal);
  }, [shopClientsData]);

  const shopOrders = compact(shopOrderData?.shopOrders);
  const clients = keyBy(compact(shopClientsData?.clients), 'email');

  const allProducts = compact(productsData?.products);

  return <div>
    <div>
      <div>Totaalbedrag afgeleverd: {formatCurrencySign(sum(orderTotal.map(total => total.delivered)))}</div>
      <div>Totaalbedrag niet afgeleverd: {formatCurrencySign(sum(orderTotal.map(total => total.undelivered)))}</div>
    </div>
    <ConfirmPopup/>

    <DataTable
      className={"sf-table"}
      //autoLayout
      responsiveLayout={'scroll'}
      dataKey={'id'}
      value={orderBy(shopOrders, (shopOrder) => shopOrder.deliveryDate.valueOf(), 'desc')}
      expandedRows={expandedRows}
      onRowToggle={(e) => {
        setExpandedRows(e.data);
      }}
      rowExpansionTemplate={(shopOrder: ShopOrders_shopOrders) =>
        <IndividualShopOrder shopOrder={shopOrder} products={allProducts}/>
      }
    >
      <Column expander/>
      <Column
        header={'Bestelstatus'}
        field={'status'}
        body={(shopOrder: ShopOrders_shopOrders) => <OrderStatusColumn orderStatus={shopOrder.status}/>}
      />
      <Column
        header={'Betaalstatus'}
        field={'paid'}
        body={(shopOrder: ShopOrders_shopOrders) => <BillingStatusColumn paid={shopOrder.paid}/>}
      />
      <Column
        header={'Afhaaldatum'}
        field={'deliveryDate'}
        body={(shopOrder: ShopOrders_shopOrders) => asDayFullDdMmYyyy(momentFromIso8601(shopOrder.deliveryDate))}
      />
      <Column
        header={'Besteldatum'}
        field={'createdAt'}
        body={createdAtColumn}
      />
      <Column
        header={'Afhaallocatie'}
        field={'deliveryLocation'}
        body={(shopOrder: ShopOrders_shopOrders) => deliveryLocations.find(dl => dl.value === shopOrder.deliveryLocation)?.city}
      />
      <Column
        header={'Telefoonnummer'}
        field={'shopClient.phone'}
      />
      <Column
        header={'Klant'}
        field={'shopClient.id'}
        body={(shopOrder: ShopOrders_shopOrders) => {
          const shopClient = shopClients[shopOrder.shopClient.id];
          return `${shopClient?.firstName} ${shopClient?.name}`;
        }}
      />
      <Column
        header={'Bedrag'}
        body={(shopOrder: ShopOrders_shopOrders) => {
          const items: { priceIncl: number, delivered: boolean }[] = compact(shopOrder.shopOrderItems);
          const undeliveredItems = items.filter(i => !i.delivered);

          const client = clients[shopOrder.shopClient.email];
          let ambassadorDiscount = 0;
          let ambassadorProductOrder = findAmbassadorProductOrder(compact(flatten(map(client?.clientOrders, 'productOrders'))));
          if (ambassadorProductOrder?.product?.code) {
            ambassadorDiscount = ambassadorDiscountPercentage(ambassadorOptionFromProductCode(ambassadorProductOrder?.product?.code));
          }

          const priceUndeliveredItems = totalPrice(undeliveredItems, shopOrder.extraPackaging, ambassadorDiscount, shopOrder.promocode);
          const totalPriceAllProducts = totalPrice(items, shopOrder.extraPackaging, ambassadorDiscount, shopOrder.promocode);
          return <div style={{fontStyle: ambassadorDiscount > 0 ? 'italic' : 'normal'}}>
            {formatCurrencySign(totalPriceAllProducts)}
            {shopOrder.status === ShopOrderStatus.DELIVERED && undeliveredItems.length > 0 &&
              <div style={{color: 'var(--invalid)'}}>-{formatCurrencySign(priceUndeliveredItems)}</div>}
          </div>;
        }}
      />
      <Column
        header={'Acties'}
        body={(shopOrder: ShopOrders_shopOrders) => {
          return <div>
            {<Button
              icon={'pi pi-trash'}
              id={'removeShopOrder'}
              className={'p-button-text p-button-danger'}
              onClick={(e) => {
                confirmPopup({
                  acceptLabel: 'Ja',
                  rejectLabel: 'Nee',
                  target: e.currentTarget,
                  message: 'Ben je zeker dat je dit order wil verwijderen?',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                    removeShopOrder({
                      variables: {
                        id: shopOrder.id,
                      }
                    }).then(r => refetch())
                  },
                  reject: () => {
                  }
                });
              }}/>}

            {shopOrder.status !== ShopOrderStatus.DELIVERED && <Button
              label='Markeer als verwerkt'
              className={'p-button-link'}
              onClick={(e) => updateOrderStatus({
                variables: {
                  id: shopOrder.id,
                  status: ShopOrderStatus.DELIVERED
                }
              }).then(r => console.log(r))}/>}
            {!shopOrder.paid && <Button
              label='Registreer betaling'
              className={'p-button-link'}
              onClick={(e) => updateOrderPaymentStatus({
                variables: {
                  id: shopOrder.id,
                  paid: true
                }
              }).then(r => console.log(r))}/>}
          </div>;
        }}
      />
    </DataTable>
  </div>;
};

export default ShopOrderList;
