import { gql } from "@apollo/client";
import {ProductFields_basicFragment} from "../../../shared/queries/product.gql";

export const AllOrdersQuery = gql`
  ${ProductFields_basicFragment}
query AllOrders($id: ID!) {
	clientOrders(where: {client: {id: $id}}, orderBy: deliveryDate_DESC) {
    id
    createdAt
    updatedAt
    orderDate
    deliveryDate
    delivery
    status
    orderDiscount
    client {
      id
    }
    productOrders {
      id
      product {
        ...ProductFields_basic
        name
        amount
        unit
      }
      priceExcl
      quantity
      remark
    }
    invoice {
      id
      createdAt
      type
      number
      filename
      file
      data
      payments {
        id
        paymentDate
        dueDate
        amount
        paid
      }
    }
  }
}

`;
