import React from 'react';
import styled from "styled-components";
import SideMenuItem from "./SideMenuItem";

export interface SideMenuItemModel {
  key: string;
  label: string;
  icon: string;
  active: boolean;
  component: any;
}

interface SideMenuProps {
  onItemSelect: (item: SideMenuItemModel) => void;
  activeMenuItem: SideMenuItemModel | undefined;
  model: SideMenuItemModel[];
}

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content;
  grid-row-gap: 16px;

  padding: 32px;
  background-color: var(--gray-300);
  border-right: 1px solid var(--gray-600);

  align-items: start;
`;

const SideMenu = (props: SideMenuProps) => {
  return <Container>
    {props.model.map(menuItem =>
      <SideMenuItem
        key={menuItem.key}
        model={menuItem}
        active={props.activeMenuItem?.key === menuItem.key}
        onClick={() => {
          props.onItemSelect(menuItem);
        }}
      />)}
  </Container>;
};

export default SideMenu;
