import React, {PropsWithChildren, ReactNode} from 'react';
import {Message, MessageProps} from "primereact/message";
import {chain, compact} from "lodash";

interface DashboardItemProps {
  icon?: string;
  iconText?: string;
  color?: string;
  label?: string | ReactNode;
  value?: string | number,
  valueDescription?: string,
  width?: number,
  messages?: MessageProps[],
  loading?: boolean,
}

const severities: { [key in 'success' | 'info' | 'warn' | 'error']: { color: string, weight: number } } = {
  error: {color: "red", weight: 4},
  warn: {color: "yellow", weight: 3},
  info: {color: "blue", weight: 2},
  success: {color: "green", weight: 1},
};

const DashboardItem = (props: PropsWithChildren<DashboardItemProps>) => {
  const severity = chain(props.messages)
    .map(message => message.severity ? severities[message.severity] : null)
    .compact()
    .maxBy(severity => severity.weight)
    .value();

  const border = `border-${severity ? 2 : 1} border-${severity ? `${severity.color}-500` : '50'} border-round`;

  let messages = compact(props.messages);
  return <div className={`flex h-full`}>
    <div className={`relative w-full surface-0 m-0 shadow-2 p-1 md:p-3 ${border} flex flex-column`}>
      {props.loading && <div
        className="absolute bottom-0 left-0 right-0 top-0 z-1 bg-white-alpha-80 flex align-items-center justify-content-center font-normal text-xl md:text-2xl lg:text-3xl">Laden...</div>}
      <div className="flex justify-content-between align-items-start">
        <div>
          {props.label && <span className={`block text-500 font-medium mb-3 ${!severity && 'text-900'}`}>{props.label}</span>}
          <div>
            <span className={`font-medium text-xl md:text-2xl lg:text-3xl`}>{props.value}</span>
            <span className={'font-normal text-xs md:text-sm lg:text-base text-gray-400'}> {props.valueDescription}</span>
          </div>
        </div>
        {props.icon && <div
          className={`flex align-items-center justify-content-center bg-${props.color || 'blue'}-100 border-round`}
              style={{width: '3rem', height: '3rem'}}>
          <i className={`${props.icon || 'pi -pi-shopping-cart'} text-${props.color || 'blue'}-500 text-3xl`}></i>
        </div>}
        {props.iconText && <div className={`p-2 bg-${props.color || 'blue'}-100 border-round text-${props.color || 'blue'}-500 text-sm`}>
          {props.iconText}
        </div>}
      </div>
      {props.children}
      {messages.length > 0 && <div className="mt-3 flex flex-column gap-1">
        {messages.map((message, idx) => <Message
          {...message}
          key={`${idx}_${message.severity}_dashboard_notification`}
          className="py-1 px-2 w-full justify-content-start"
        />)}
      </div>}
    </div>
  </div>;
};

export default DashboardItem;
