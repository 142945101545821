import React, {PropsWithChildren, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {keyBy, mapValues} from "lodash";
import {asIso8601, momentFromDdMmYyyy, momentFromIso8601} from "../../utils/date.utils";
import {Calendar} from "primereact/calendar";

interface EditableField<ObjectType> {
  field: keyof ObjectType,
  name: string,
  value: string | null,
  placeholder: string,
  width?: number,
  type?: 'date',
}

interface EditableDescriptionItemProps<ObjectType> {
  label: string,
  onChange: (updates: { [Property in keyof ObjectType]?: string | null }) => void,
  fields: EditableField<ObjectType>[];
  editMode?: boolean;
}

const EditableDescriptionItem = <ObjectType, >(props: PropsWithChildren<EditableDescriptionItemProps<ObjectType>>) => {
  const [editMode, setEditMode] = useState<boolean>(props.editMode || false);
  const [updatedFields, setUpdatedFields] = useState<{ [Property in keyof ObjectType]?: string | null }>(mapValues(keyBy(props.fields, 'field'), field => field.value));

  return <li className="flex align-items-start py-1 px-2 border-top-1 border-300 flex-wrap">
    <div className=" w-7 md:w-3 font-medium text-right px-2 color-green">{props.label}</div>
    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 flex flex-wrap gap-1">
      {!editMode && props.children}
      {editMode &&
        props.fields.map((field) => {
          let value = updatedFields[field.field];
          return field.type === 'date' ?
            <Calendar
              value={value ? (momentFromIso8601(value).toDate() || undefined) : ''}
              className="mx-2"
              inputClassName="p-dense"
              locale={'nl'}
              dateFormat={"dd-mm-yy"}
              showWeek
              onChange={(e) => {
                let updatedValue = momentFromDdMmYyyy(e.target.value);
                setUpdatedFields(value => ({
                    ...value, [field.field]: asIso8601(updatedValue) || ''
                  })
                );
              }}
            />
            : <InputText
              placeholder={field.placeholder}
              className={`w-${field.width || 6}`}
              value={value || ''}
              onChange={(e) => {
                //props.onChange(e.target.value);
                let updatedValue = e.target.value;
                setUpdatedFields(value => ({
                    ...value, [field.field]: updatedValue || ''
                  })
                );
              }}/>;
        })
      }
    </div>
    <div className="w-6 md:w-1 flex justify-content-end flex-column">
      {!editMode && <Button
        icon="pi pi-pencil"
        className="p-button-link"
        onClick={() => {
          setEditMode(!editMode);
        }}
      />}
      {editMode && <>
        <Button
          icon="pi pi-times"
          className="p-button-link"
          onClick={() => {
            setEditMode(!editMode);
            setUpdatedFields({});
          }}
        /><Button
        icon="pi pi-check"
        className="p-button-link"
        onClick={() => {
          setEditMode(!editMode);
          props.onChange(updatedFields);
        }}
      />
      </>}
    </div>
  </li>
};

export default EditableDescriptionItem;
