import {useQuery} from '@apollo/client';
import React from 'react';
import {FarmConfigurationsByCode} from "../../../__generated__/types";
import {first} from "lodash";
import SalesPageHeading from "../SalesPageHeading";
import {useUserObject} from "../../../shared/context/UserContext";
import StockTable from "./StockTable";
import {FarmConfigurationsByCodeQuery} from "../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../shared/utils/farmConfig.utils";

interface StockManagementProps {

}

const StockManagement = (props: StockManagementProps) => {
  const  {activeFarm} = useUserObject();
  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  return <div>
    <SalesPageHeading/>

    <div className="text-lg font-medium mb-2">Stock</div>

    {flexClientGroupCode && <StockTable flexClientGroupCode={flexClientGroupCode}/>}
  </div>;
};

export default StockManagement;
