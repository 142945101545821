import React, {useEffect, useState} from 'react';
import {chain, endsWith, first, times} from "lodash";
import {ActiveSubscriptions_clientOrders} from "../../../../__generated__/types";
import {findProductOrderByProductCodePrefix, hasUpcomingDelivery} from 'shared/utils/productOrder.utils';
import {SubscriptionClientOrders} from "../SubscriptionTable/SubscriptionTable";
import LabelPdf, {LabelData} from "./components/LabelPdf";
import PdfDialog from 'shared/pdf/PdfDialog';

interface PotatoLabelsDialogProps {
  clientOrders: SubscriptionClientOrders[];
  onHide: () => void;
}

const PotatoLabelsDialog = (props: PotatoLabelsDialogProps) => {
  const [labels, setLabels] = useState<LabelData[]>([]);

  useEffect(() => {
    const filtered = chain(props.clientOrders)
      .filter(clientOrder => {
        const potatoProductOrder = findProductOrderByProductCodePrefix(clientOrder.productOrders, "aardappel-");
        return potatoProductOrder
          ? hasUpcomingDelivery(potatoProductOrder) && !endsWith(potatoProductOrder?.product?.code || undefined, "-2.5kg")
          : false;
      })
      .map(clientOrder => {
        const potatoProductOrder = findProductOrderByProductCodePrefix(clientOrder.productOrders, "aardappel-");
        const deliveryLocation = first(potatoProductOrder?.deliveries)?.deliveryLocation;
        let nrOfBoxes = 1;
        let labelData = {
          type: potatoProductOrder?.product?.name,
          name: `${clientOrder.client.firstName} ${clientOrder.client.name}`,
          location: deliveryLocation,
          nrOfBoxes: 0,
        };

        return times(nrOfBoxes, (n) => ({...labelData, boxNumber: n + 1}));
      })
      .flatten()
      .orderBy(['location', 'name'], ['asc', 'asc'])
      .value();

    setLabels(filtered);
    console.log('effect', props.clientOrders, filtered);
  }, [props.clientOrders]);

  return <PdfDialog onHide={() => props.onHide()}>
    <LabelPdf labels={labels}/>
  </PdfDialog>;
};

export default PotatoLabelsDialog;
