import React from 'react';
import PageHeading from "../../components/PageHeading";
import {TabMenu} from "primereact/tabmenu";
import {useLocation, useNavigate} from "react-router-dom";
import {compact, omit} from "lodash";

interface SalesPageHeadingProps {

}

const SalesPageHeading = (props: SalesPageHeadingProps) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const model = [
    {
      id: "sales",
      path: "/verkoop",
      command: () => navigate("/verkoop"),
      icon: 'pi pi-fw pi-book',
      label: 'Dashboard',
    },
    {
      id: "orders",
      path: "/verkoop/verkopen",
      command: () => navigate("/verkoop/verkopen"),
      icon: 'pi pi-fw pi-book',
      label: 'Verkopen',
    },
    {
      id: "products",
      path: "/verkoop/producten",
      command: () => navigate("/verkoop/producten"),
      icon: 'pi pi-fw pi-list',
      label: 'Producten',
    },
    {
      id: "clients",
      path: "/verkoop/klanten",
      command: () => navigate("/verkoop/klanten"),
      icon: 'pi pi-fw pi-users',
      label: 'Klanten',
    },
    {
      id: "stock",
      path: "/verkoop/stock",
      command: () => navigate("/verkoop/stock"),
      icon: 'pi',
      label: 'Stock',
    },
    {
      id: "productCategories",
      path: "/verkoop/productcategorieen",
      command: () => navigate("/verkoop/productcategorieen"),
      icon: 'pi',
      label: 'Productcategorieën',
    },
    {
      id: "invoicing",
      path: "/verkoop/facturatie",
      command: () => navigate("/verkoop/facturatie"),
      icon: 'pi pi-euro',
      label: 'Facturatie',
    },
    {
      id: "revenue",
      path: "/verkoop/omzet",
      command: () => navigate("/verkoop/omzet"),
      icon: 'pi pi-euro',
      label: 'Omzet',
    },
  ];

  let page = model.find(item => item.path === pathname);

  let activeIndex = model.findIndex(m => m.id === page?.id);
  return <div>
    <PageHeading
      path={compact([{label: "Verkoop", path: "/verkoop"}, omit(page, 'path')])}
      title={model[activeIndex].label}
    />
    <div className="mb-4">
      <TabMenu model={model} activeIndex={activeIndex}/>
    </div>
  </div>;
};

export default SalesPageHeading;
