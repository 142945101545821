import { FormulaPeriod, FormulaType, SubscriptionSubType } from "../types/types";
import moment, { Moment } from "moment";
import { compact, first, intersection, last, slice, sum } from "lodash";
import { extraOptions, Option } from "../config.formulas";
import { formulaBySubscriptionProductCode } from "./formula.utils";
import { ageToFullEquivalent } from "./membership.utils";
import { calculateAgeAtStartOfSubscription } from "./date.utils";
import {TODAY} from "../config";

export interface DeliveryWeeksForSubscription {
  weeks: string[];
  possibleWeeks: string[];
}

export const deliveryWeeksForExtraSubscription = (
  subscriptionProductCode: string | undefined,
  frequencyCode: string | undefined | null,
  extraProductKey: string | undefined | null,
  startDate?: Moment
): DeliveryWeeksForSubscription => {
  let deliveryWeeks: DeliveryWeeksForSubscription = {weeks: [], possibleWeeks: []};
  let deliveryWeeksForMainSubscription = deliveryWeeksForSubscription(subscriptionProductCode, startDate);
  if (frequencyCode && extraProductKey) {
    deliveryWeeks = {
      weeks: deliveryWeeksForExtraSubscriptionInternal(deliveryWeeksForMainSubscription.weeks, extraProductKey, frequencyCode),
      possibleWeeks: deliveryWeeksForExtraSubscriptionInternal(deliveryWeeksForMainSubscription.possibleWeeks, extraProductKey, frequencyCode),
    };
  }
  return deliveryWeeks;
};

const deliveryWeeksForExtraSubscriptionInternal = (
  deliveryWeeksForSubscription: string[],
  extraProductCode: string,
  frequencyCode: string,
): string[] => {
  if (frequencyCode === 'date-Y') {
    return compact([first(deliveryWeeksForSubscription)]);
  }
  let option: Option | undefined = extraOptions.find(o => o.code === extraProductCode);

  let predicate: FilterPredicate = () => true;

  if (frequencyCode === 'date-2W') {
    predicate = (lastDeliveryWeek, currentDeliveryWeek) => currentDeliveryWeek.diff(moment(lastDeliveryWeek, 'YYYY-W'), 'w') > 1;
  } else if (frequencyCode === 'date-M') {
    predicate = (lastDeliveryWeek, currentDeliveryWeek) => lastDeliveryWeek.isoWeekday(5).isBefore(currentDeliveryWeek.isoWeekday(5), 'month');
  }

  let remainingDeliveryWeeks = filteredDeliveryWeeks(
    deliveryWeeksForSubscription,
    predicate
  );

  return option?.deliveryWeeks
    ? intersection(remainingDeliveryWeeks, option.deliveryWeeks) : remainingDeliveryWeeks;
};

type FilterPredicate = (lastDeliveryWeek: Moment, currentDeliveryWeek: Moment) => boolean;
const filteredDeliveryWeeks = (deliveryWeeks: string[], shouldAdd: FilterPredicate) =>
  deliveryWeeks
    .reduce((remainingDeliveryWeeks, deliveryWeekString) => {
      let lastDeliveryWeek = last(remainingDeliveryWeeks);

      if (!lastDeliveryWeek) {
        return [deliveryWeekString];
      }

      if (lastDeliveryWeek && shouldAdd(moment(lastDeliveryWeek, 'YYYY-W'), moment(deliveryWeekString, 'YYYY-W'))) {
        return [...remainingDeliveryWeeks, deliveryWeekString];
      }

      return remainingDeliveryWeeks;
    }, [] as string[]);

export const deliveryWeeksForSubscription = (subscriptionProductCode: string | undefined | null,
                                             startDate?: Moment) => {
  let formula = subscriptionProductCode && formulaBySubscriptionProductCode(subscriptionProductCode);
  let deliveryWeeks: DeliveryWeeksForSubscription = {weeks: [], possibleWeeks: []};
  if (formula && subscriptionProductCode) {
    deliveryWeeks = deliveryWeeksForSubscriptionInternal(formula, subscriptionProductCode, startDate);
  }
  return deliveryWeeks;
};

const deliveryWeeksForSubscriptionInternal = (formula: FormulaType,
                                              subscriptionProductCode: string,
                                              startDate?: Moment): DeliveryWeeksForSubscription => {

  let today = startDate?.clone().subtract(2, 'days') || TODAY;
  let filteredFormulaDeliveryWeeks: string[] = compact(formula.deliveryWeeks);
  let formulaPeriods: FormulaPeriod[] = compact(formula.periods);
  let matchingPeriod = formulaPeriods.find(p => [p.codeSmall, p.codeLarge, p.code].includes(subscriptionProductCode));

  if (matchingPeriod?.deliveryWeeks) {
    filteredFormulaDeliveryWeeks = matchingPeriod.deliveryWeeks;
  }

  let weeksForProductCode: string[] = [];
  let possibleWeeksForProductCode: string[] = [];

  let nrOfWeeksForProductCode: number = 0;
  let nrOfPossibleWeeksForProductCode: number = 0;

  let indexOfFirstWeek = filteredFormulaDeliveryWeeks.findIndex(week => moment(week, 'YYYY-W').isoWeekday(4).isAfter(today, 'd'));
  filteredFormulaDeliveryWeeks = filteredFormulaDeliveryWeeks.slice(indexOfFirstWeek);

  if (matchingPeriod) {
    nrOfWeeksForProductCode = matchingPeriod.nrOfWeeks;
    nrOfPossibleWeeksForProductCode = matchingPeriod.nrOfPossibleWeeks || nrOfWeeksForProductCode;

    if (matchingPeriod.biWeekly) {
      filteredFormulaDeliveryWeeks = filteredDeliveryWeeks(filteredFormulaDeliveryWeeks, (lastDeliveryWeek, currentDeliveryWeek) => {
        return lastDeliveryWeek.isBefore(currentDeliveryWeek.subtract(1, 'week'));
      });
    }

    weeksForProductCode = slice(filteredFormulaDeliveryWeeks, 0, nrOfWeeksForProductCode);
    possibleWeeksForProductCode = slice(filteredFormulaDeliveryWeeks, 0, nrOfPossibleWeeksForProductCode);
    if (formula.membershipSubType === SubscriptionSubType.BASKETS_FLEX) {
      possibleWeeksForProductCode = slice(compact(formula.deliveryWeeks), indexOfFirstWeek, nrOfPossibleWeeksForProductCode + indexOfFirstWeek);
    }
  }
  return {weeks: weeksForProductCode, possibleWeeks: possibleWeeksForProductCode}
};

export const selfHarvestTotalPriceExcl = (
  subscriptionProductCode: string,
  subscriptionProduct: { productPrices: ({ value: number } | null)[] | null },
  members: { deleted?: boolean, dateOfBirth: string }[]) => {
  let priceExcl = NaN;

  let formula = formulaBySubscriptionProductCode(subscriptionProductCode);
  if (formula) {
    const deliveryWeeks = deliveryWeeksForSubscriptionInternal(formula, subscriptionProductCode);
    let firstDeliveryWeek = first(deliveryWeeks.weeks);

    priceExcl = last(compact(subscriptionProduct.productPrices))?.value || 0;

    let nrOfFullEquivalents = sum(members
      .filter(m => !m.deleted)
      .map(m => ageToFullEquivalent(calculateAgeAtStartOfSubscription(m.dateOfBirth, moment(firstDeliveryWeek, 'YYYY-W')))));

    priceExcl = priceExcl
      * nrOfFullEquivalents;
  }

  return subscriptionProduct ? priceExcl : 0;
};

export const basketPriceExcl = (subscriptionProduct: { productPrices: ({ value: number } | null)[] | null },) => {
  return last(compact(subscriptionProduct.productPrices))?.value || 0;
};
