import React, {useRef, useState} from 'react';
import {useSales} from "../sales-context";
import {ClientOrderStatus, GetClientOrdersToProcess, SalesData_clientOrders} from "../../../__generated__/types";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {asIso8601} from "../../../shared/utils/date.utils";
import moment from "moment";
import ClientOrderUpsertForm from "./components/ClientOrderUpsertForm";
import styled from "styled-components";
import OrderTable from "./components/OrderTable/OrderTable";
import SalesPageHeading from "../SalesPageHeading";
import {Toolbar} from "primereact/toolbar";
import InvoiceTable from "./components/InvoiceTable/InvoiceTable";
import {useQuery} from "@apollo/client";
import {GetClientOrdersByStatusQuery} from "../queries.gql";
import {compact} from "lodash";
import {useUserObject} from "../../../shared/context/UserContext";

const Container = styled.div`
`;

export const statusOptions = {
  [ClientOrderStatus.CREATED]: 'nieuw',
  [ClientOrderStatus.DELIVERED]: 'Afgeleverd',
  [ClientOrderStatus.INVOICED]: 'Gefactureerd',
  [ClientOrderStatus.INVOICE_SENT]: 'Factuur verzonden',
  [ClientOrderStatus.PAID]: 'Betaald',
  [ClientOrderStatus.PAID_PARTIALLY]: 'Deels afbetaald',
  [ClientOrderStatus.PAYMENT_OVERDUE]: 'Betalingstermijn overschreden',
};

const StatusColored = styled.div`
  font-weight: 700;

  &.status-PAID {
    color: #359b3a;
  }

  &.status-INVOICED {
    color: #C63737;
  }

  &.status-DELIVERED {
    color: #8A5340;
  }

  &.status-CREATED {
    color: #23547B;
  }

  &.status-INVOICE_SENT {
    color: #ffbd91;
  }
`;

export const Badge = styled(StatusColored)`
  display: inline-block;
  margin: 2px;
  border-radius: 4px;
  padding: 4px 4px;
  text-transform: uppercase;
  //letter-spacing: .3px;
  font-weight: bold;
  font-size: 10px;

  &.status-PAID {
    background-color: #C8E6C9;
  }

  &.status-INVOICED {
    background-color: #FFCDD2;
  }

  &.status-DELIVERED {
    background-color: #FEEDAF;
  }

  &.status-CREATED {
    background-color: #B3E5FC;
  }

  &.status-INVOICE_SENT {
    background-color: #7b7873;
  }
`;

const OrderManagement = () => {
  const {clientGroups} = useSales();
  const {activeFarm} = useUserObject();

  let emptyClientOrder: Partial<SalesData_clientOrders> = {
    orderDate: asIso8601(moment()),
    deliveryDate: asIso8601(moment()),
    status: ClientOrderStatus.CREATED,
    delivery: false,
  };
  const [showNewClientOrder, setShowNewClientOrder] = useState(true);
  const [clientOrder, setClientOrder] = useState<Partial<SalesData_clientOrders>>(emptyClientOrder);
  const [showInvoicing, setShowInvoicing] = useState<boolean>(false);

  const {data} = useQuery<GetClientOrdersToProcess>(GetClientOrdersByStatusQuery,
    {variables: {farmId: activeFarm?.id||'', status: [ClientOrderStatus.DELIVERED, ClientOrderStatus.CREATED]}}
  );

  const toast = useRef<Toast>(null);

  const hideDialog = () => {
    setShowNewClientOrder(false);
    setClientOrder(emptyClientOrder);
  };
  let clientOrders = compact(data?.clientOrders);
  return <Container>
    <SalesPageHeading/>
    <Toast ref={toast}/>
    <Toolbar
      className="p-toolbar p-component p-0 m-0 surface-0 border-0"
      end={<div>
        <Button icon="pi pi-plus" className="p-button-link" label={'Nieuwe verkoop registreren'}
                onClick={() => setShowNewClientOrder(true)}/>
      </div>}
    />

    {showNewClientOrder && <div>
      <div className="text-xl font-medium mb-2">Nieuw order toevoegen</div>
      <ClientOrderUpsertForm
        onHide={hideDialog}
        clientOrder={clientOrder}
      />
    </div>}

    <div>
      <h1>Te verwerken bestellingen</h1>
      {clientOrders.length > 0 && <OrderTable clientOrders={clientOrders}/>}
      <Button
        label="Toon facturatie"
        onClick={() => {
          setShowInvoicing(!showInvoicing);
        }}
      ></Button>
      {showInvoicing && <>
        <h1>Op te volgen facturatie</h1>
        <InvoiceTable clientGroups={clientGroups}/>
      </>}
    </div>
  </Container>;
};

export default OrderManagement;
