import React from 'react';
import {Document, Image, Page, Text, View} from '@react-pdf/renderer';
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";
import {styles} from 'shared/pdf/pdf-styles';
import {formatCurrencySign} from "../utils/currency.utils";
import {ceil, first, groupBy, values} from 'lodash';
import {ClientType} from "../../__generated__/types";
import {asDdMmYyyy, momentFromIso8601} from "../utils/date.utils";
import {ClientOrderInvoicePdfData, ClientOrderInvoicePdfProductLine} from "../utils/invoice.utils";
import {vat} from "../utils/vat.utils";
import {getPdfFarmConfig} from "./pdf.farmConfig";

// Font.register({family: 'Montserrat', src: MontserratMedium});
// Font.register({family: 'Montserrat-Bold', src: MontserratBold});

interface ClientOrderInvoicePdfProps {
  data: ClientOrderInvoicePdfData;
  farm: {
    code: string;
    name: string;
  }
}

// Create Document Component
const ClientOrderInvoicePdf = (props: ClientOrderInvoicePdfProps) => {
  const {data} = props.data;
  const payments = data.payments;

  let pdfFarmConfig = getPdfFarmConfig(props.farm.code);
  let farmImageSource = pdfFarmConfig.logo;
  let farmImageStyles = pdfFarmConfig.logoStyles;

  let pageFooter = <View fixed style={{
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 125,
    borderTop: 1,
    borderTopColor: '#748F56',
    borderTopStyle: 'solid',
    color: '#748F56',
    padding: 20,
    fontSize: 9,
    justifyContent: 'space-between',
    flexDirection: 'row',
  }}>
    <View>
      {pdfFarmConfig.addressLines.map(line => <Text>{line}</Text>)}
    </View>
    <View>
      {pdfFarmConfig.contactLines.map(line => <Text>{line}</Text>)}
    </View>
    <View>
      {pdfFarmConfig.financialLines.map(line => <Text>{line}</Text>)}
    </View>
  </View>;


  let csaTerms = <View>
    <Text style={styles.bold}>
      CSA Netwerk
    </Text>
    <View style={styles.spacer}/>
    <Text style={{marginBottom: 4}}>
      1) Je hebt als deelnemer toegang tot CSA Groentegeweld (uitgezonderd op zondagnamiddag). Het oogstaandeel
      omvat immers ook het ervaren van de omgeving, het beleven van de landbouw en het gebruik van de boerderij
      als gemeenschappelijke tuin.
    </Text>
    <Text style={{marginBottom: 4}}>
      2) Groentegeweld is lid van vzw CSA-netwerk Vlaanderen. Elk natuurlijk persoon of rechtspersoon die een
      oogstaandeel koopt bij een CSA-bedrijf, wordt deelnemer van een lokale afdeling van de vzw. Het
      CSA-bedrijf
      biedt actief mogelijkheden aan zijn oogstaandeelhouders tot de beleving van en de verbinding met de
      seizoenen, het land en de voedselproductie als instrument voor een hernieuwd bewustzijn van de individuele
      connectie met de natuurlijke omgeving.
    </Text>
    <Text>
      3) Als lokale afdeling organiseert Groentegeweld collectieve meehelpdagen en andere activiteiten die
      bijdragen tot de cohesie van de deelnemers en de boer binnen het CSA-bedrijf en de ontwikkeling van
      vaardigheden en kennis. Het kan ook gebeuren dat de boer een oproep doet om een acute werkpiek op te
      vangen.
      Al deze
      voornoemde momenten zijn vrijblijvend. De deelnemers kunnen hier desgewenst en vrijwillig aan
      participeren.
    </Text>
  </View>;
  let tacData = props.data.data.includeTermsFor ? pdfFarmConfig.termsAndConditions[props.data.data.includeTermsFor] : null;

  return (
    <Document title={props.data.filename}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image style={{...styles.image, ...farmImageStyles}} source={farmImageSource}/>
          </View>
          <View style={styles.recipient}>
            {data.recipient.businessName && <Text>{data.recipient.businessName}</Text>}
            {data.recipient.name && <Text>tav {data.recipient.name}</Text>}
            {data.recipient.addressLines.map(l => <Text key={l}>{l}</Text>)}
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <Text style={styles.bold}>FACTUUR</Text>
        </View>

        <View style={styles.margin10}>
          <View style={styles.invoiceMeta}>
            {data.meta.map((m) => <View key={m.label}>
              <Text>{m.label}</Text>
              <Text style={styles.bold}>{m.value}</Text>
            </View>)}
          </View>
        </View>

        <View style={styles.margin10}>

          <Table>
            <TableHeader
              includeBottomBorder={false}
              includeLeftBorder={false}
              includeRightBorder={false}
              includeTopBorder={false}
            >
              <TableCell style={styles.th} weighting={22}>Omschrijving</TableCell>
              <TableCell style={styles.th} weighting={8}>Aantal</TableCell>

              {data.clientType === ClientType.BUSINESS && <>
                <TableCell style={{...styles.th, ...styles.alignRight}} weighting={12}>Eenheidsprijs</TableCell>
                <TableCell style={{...styles.th, ...styles.alignRight}} weighting={6}>Btw</TableCell>
                <TableCell style={{...styles.th, ...styles.alignRight}} weighting={10}>Prijs excl.</TableCell>
              </>}
              {data.clientType !== ClientType.BUSINESS &&
                <TableCell style={{...styles.th, ...styles.alignRight}} weighting={10}>Prijs incl.</TableCell>}
            </TableHeader>
          </Table>

          {[
            ...values(groupBy(data.productLines, "deliveryDate")),
          ]
            .map((productLines,) => {
              return <Table
                key={`key-${first(productLines)?.deliveryDate}`}
                data={productLines}
              >
                <TableHeader
                  includeBottomBorder={false}
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  includeTopBorder={false}
                >
                  <TableCell style={styles.th} weighting={68}>{productLines[0].deliveryDate}</TableCell>
                </TableHeader>
                <TableBody
                  includeBottomBorder={false}
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  includeTopBorder={false}
                >
                  <DataTableCell style={styles.td} weighting={22}
                                 getContent={(r: ClientOrderInvoicePdfProductLine) => {
                                   return <View>
                                     {r.description && <Text>{r.description}</Text>}
                                     {r.info && <Text style={{fontSize: 8}}>{r.info}</Text>}
                                   </View>;
                                 }}/>
                  <DataTableCell style={styles.td} weighting={8}
                                 getContent={(r: ClientOrderInvoicePdfProductLine) => {
                                   return <View>
                                     <Text>{r.productUnit}</Text>
                                   </View>;
                                 }}/>
                  {data.clientType === ClientType.BUSINESS &&
                    <DataTableCell style={styles.tdRight} weighting={12}
                                   getContent={(r: ClientOrderInvoicePdfProductLine) => {
                                     return <View>
                                       <Text>
                                         {r.description.startsWith("Korting")
                                           ? ''
                                           : r?.unitPriceExcl && formatCurrencySign(r.unitPriceExcl)}
                                       </Text>
                                     </View>;
                                   }}/>}
                  {data.clientType === ClientType.BUSINESS &&
                    <DataTableCell style={styles.tdRight} weighting={6}
                                   getContent={(r: ClientOrderInvoicePdfProductLine) => {
                                     return <View>
                                       <Text>
                                         {r.description.startsWith("Korting")
                                           ? ''
                                           : r?.vat && ceil(vat(r.vat) * 100, 0) + '%'}
                                       </Text>
                                     </View>;
                                   }}/>}
                  {data.clientType === ClientType.BUSINESS &&
                    <DataTableCell style={styles.tdRight} weighting={10}
                                   getContent={(r: ClientOrderInvoicePdfProductLine) => {
                                     return <View>
                                       <Text>
                                         {r?.totalPriceExcl && formatCurrencySign(r.totalPriceExcl)}
                                       </Text>
                                     </View>;
                                   }}/>}

                  {data.clientType !== ClientType.BUSINESS &&
                    <DataTableCell style={styles.tdRight} weighting={10}
                                   getContent={(r: ClientOrderInvoicePdfProductLine) => {
                                     return <View>
                                       <Text>
                                         {r?.totalPriceExcl !== 0 && r?.vat
                                           ? formatCurrencySign(r?.totalPriceExcl * (1 + vat(r?.vat)))
                                           : ''}
                                       </Text>
                                     </View>;
                                   }}/>}
                </TableBody>
              </Table>;
            })}

          <View style={styles.spacer}/>
          <View>
            {data.clientType === ClientType.BUSINESS && <>
              {data.subTotalExcl && data.subTotalExcl > 0 && <View style={styles.tableSummaryRow}>
                <Text>Subtotaal excl.</Text>
                <Text style={styles.tableSummaryRowValue}>{formatCurrencySign(data.subTotalExcl)}</Text>
              </View>}
              {data.discountExcl && data.discountExcl > 0 && <View style={styles.tableSummaryRow}>
                <Text>Korting</Text>
                <Text style={styles.tableSummaryRowValue}>-{formatCurrencySign(data.discountExcl)}</Text>
              </View>}
              {data.totalExcl > 0 && <View style={styles.tableSummaryRow}>
                <Text>Totaal excl. btw</Text>
                <Text style={styles.tableSummaryRowValue}>{formatCurrencySign(data.totalExcl)}</Text>
              </View>}
              {data.vat6 > 0 && <View style={styles.tableSummaryRow}>
                <Text>Btw 6%</Text>
                <Text style={styles.tableSummaryRowValue}>{formatCurrencySign(data.vat6)}</Text>
              </View>}
              {data.vat12 > 0 && <View style={styles.tableSummaryRow}>
                <Text>Btw 12%</Text>
                <Text style={styles.tableSummaryRowValue}>{formatCurrencySign(data.vat6)}</Text>
              </View>}
              {data.vat21 > 0 && <View style={styles.tableSummaryRow}>
                <Text>Btw 21%</Text>
                <Text style={styles.tableSummaryRowValue}>{formatCurrencySign(data.vat21)}</Text>
              </View>}
              <View style={styles.spacer}/>
            </>}
          </View>
          <View style={styles.tableSummaryRow}>
            <Text>Totaal te betalen</Text>
            <Text style={{...styles.tableSummaryRowValue, ...styles.bold}}>{formatCurrencySign(data.totalIncl)}</Text>
          </View>
          <View style={styles.tableSummaryRow}>
            <Text>{payments && payments.length > 1 ? 'Vervaldata' : 'Vervaldatum'}</Text>
            <View style={{...styles.tableSummaryRowValue}}>
              {payments && payments.length > 1 ?
                <Table data={payments}>
                  <TableHeader
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    includeTopBorder={false}
                  >
                    <TableCell style={{...styles.th}} weighting={2}>Datum</TableCell>
                    <TableCell style={{...styles.th, ...styles.alignRight}} weighting={1}>Bedrag</TableCell>
                  </TableHeader>
                  <TableBody
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    includeTopBorder={false}
                  >
                    <DataTableCell style={{...styles.td}} getContent={(r) => asDdMmYyyy(momentFromIso8601(r.dueDate))}/>
                    <DataTableCell style={{...styles.tdRight}} getContent={(r) => formatCurrencySign(r.amount)}/>
                  </TableBody>
                </Table>
                : <Text>{payments && payments[0] && asDdMmYyyy(momentFromIso8601(payments[0].dueDate))}</Text>}
            </View>
          </View>
          <View style={styles.spacer}/>
          <Text>Alle producten zijn van biologische land- en tuinbouwproducten - Garantie en controle</Text>
          <Text>BIO-BE-02</Text>
        </View>

        {pageFooter}
      </Page>
      {tacData && <Page size="A4" style={styles.page}>
        <View style={{...styles.contentWrapper, fontSize: 8}}>
          <Text style={{fontWeight: "bold", fontSize: 14, marginBottom: 8}}>
            Algemene voorwaarden / Engagementsverklaring: Oogstaandeelhouders 2023-2024
          </Text>
          <View style={{fontWeight: "bold", fontSize: 10}}/>
          <Text style={styles.bold}>{props.farm.name}</Text>
          <View style={styles.spacer}/>
          <Text>{pdfFarmConfig.termsAndConditions.intro}</Text>
          <View style={styles.spacer}/>
          <Text>{tacData.intro}</Text>
          <View style={styles.spacer}/>
          <Text style={styles.bold}>CSA</Text>
          {pdfFarmConfig.termsAndConditions.numberedParagraphs.map((item, idx) =>
            <Text key={`general-paragraph-${idx}`} style={{marginBottom: 4}}>{idx + 1}) {item}</Text>)}
          <View style={styles.spacer}/>

          <Text style={styles.bold}>{tacData.title}</Text>
          {tacData.numberedParagraphs.map((item, idx) => <Text
            key={`specific-paragraph-${idx}`} style={{marginBottom: 4}}>{idx + 1}) {item}</Text>)}
          <View style={styles.spacer}/>
        </View>
        {pageFooter}
      </Page>}
    </Document>
  );
};
export default ClientOrderInvoicePdf;
