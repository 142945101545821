import React, {useState} from 'react';
import styled from "styled-components";
import SideMenu, {SideMenuItemModel} from "./components/SideMenu";
import SelfHarvestManagement from "./SelfHarvestManagement/SelfHarvestManagement";
import Baskets from "./Baskets/Baskets";

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  padding: 32px;
`;

const menuItems: SideMenuItemModel[] = [
  {key: 'selfHarvest', label: 'Zelfoogst', icon: 'pi-users', active: true, component: SelfHarvestManagement},
  {key: 'baskets', label: 'Pakketten', icon: 'pi-cog', active: false, component: Baskets},
];

const FarmManagement = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<SideMenuItemModel>(menuItems[1]);
  return <Container>
    <SideMenu
      model={menuItems}
      onItemSelect={(menuItem: SideMenuItemModel) => setActiveMenuItem(menuItem)}
      activeMenuItem={activeMenuItem}
    />
    <Content style={{overflow: 'scroll'}}>
      {activeMenuItem && activeMenuItem.component()}
    </Content>
  </Container>;
};

export default FarmManagement;
