import React from 'react';
import OrdersTable from "./OrdersTable";
import {Clients_clients} from "../../../../__generated__/types";

interface ClientDetailProps {
  client: Clients_clients;
}

const ClientDetail = (props: ClientDetailProps) => {
  return <div>
    <h2>Leveringen</h2>
    <OrdersTable client={props.client}/>
  </div>;
};

export default ClientDetail;
