import React from 'react';
import {useClientObject} from "../../../ClientContext";
import {filterBasketClientOrders} from "../../../utils/clientOrder.utils";
import ClientOrderHistory from "./ClientOrderHistory";

const ClientBasketHistoryPage = () => {
  const {client} = useClientObject();

  return <div>
    <div className="text-xl md:text-2xl font-medium border-bottom-1 border-300 pb-1 mb-1">Mijn voorgaande pakketten
    </div>

    {filterBasketClientOrders(client?.clientOrders).map(clientOrder => <ClientOrderHistory clientOrderId={clientOrder.id}/>)}
  </div>;
};

export default ClientBasketHistoryPage;
