import React, {useState} from 'react';
import {chain, compact, first, get} from 'lodash';
import {Column} from "primereact/column";
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from "primereact/row";
import {
  ActiveSubscriptions_clientOrders_productOrders_deliveries,
  DeliveriesUpdateVariables,
  DeliveryUpdate,
  DeliveryUpdateVariables, ProductOrderUpdate
} from "../../../../../../__generated__/types";
import {actionsColumn, deliveriesColumn, priceInclColumn, productColumn, totalFooterColumn} from "./columns";
import styled from "styled-components";
import {DataTable} from "primereact/datatable";
import ChangeDeliveryDateButton from "./components/ChangeDeliveryDateButton/ChangeDeliveryDateButton";
import PeriodColumn from "./components/PeriodColumn/PeriodColumn";
import {Button} from 'primereact/button';
import CreditProductOrdersView from './components/CreditProductOrdersView/CreditProductOrdersView';
import {SubscriptionClientOrders_productOrders} from "../../../SubscriptionTable/SubscriptionTable";
import {momentFromIso8601} from "../../../../../../shared/utils/date.utils";
import {useMutation} from "@apollo/client";
import {DeliveryUpdateMutation, ProductOrderUpdateMutation} from "../../../../../../shared/queries/productOrder.gql";
import MoveToFriday from "../../../CreateInvoiceDialog/components/temp_MoveToFriday";
import CreditCancelledDeliveries from "../../../CreateInvoiceDialog/components/temp_CreditCancelledDeliveries";
import {filterProductOrdersByCategories} from "../../../../../../utils/productOrder.utils";

interface SubscriptionProductOrderList {
  disabled?: boolean;
  clientOrder: {
    id: string;
    deliveryDate: any;
    deliveryEndDate: any | null;
    orderDiscount?: number | null | undefined;
    productOrders: SubscriptionClientOrders_productOrders[] | null;
  };
  hideTimeline?: boolean;
}

const Container = styled.div`
  .p-datatable .p-datatable-tbody > tr > td.period-cell {
    padding: 0;
  }
`;

const SubscriptionProductOrderList = (props: SubscriptionProductOrderList) => {
  const [showMoveToFriday, setShowMoveToFriday] = useState<boolean>(false);
  const [showCreditCancelledDeliveries, setShowCreditCancelledDeliveries] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<ActiveSubscriptions_clientOrders_productOrders_deliveries>();
  const [showCreditProductOrdersView, setShowCreditProductOrdersView] = useState<boolean>(false);
  const [updateProductOrder] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation);

  let productOrders = compact(props.clientOrder.productOrders);
  let containsProductOrdersWithCancelledDeliveries = chain(productOrders)
    .map('deliveries')
    .flatten()
    .map('cancelled')
    .includes(true);

  const footerGroup = <ColumnGroup>
    <Row>
      <Column footer="Totaal:" footerStyle={{textAlign: 'right', width: '200px'}} colSpan={1}/>
      <Column footer={totalFooterColumn(props.clientOrder)} colSpan={1}/>
      {showMoveToFriday && <MoveToFriday productOrders={productOrders}/>}
    </Row>
    {showCreditCancelledDeliveries && containsProductOrdersWithCancelledDeliveries &&
        <CreditCancelledDeliveries onClick={() => setShowCreditProductOrdersView(true)}/>}
  </ColumnGroup>;
  let deliveryDates = chain(productOrders)
    .map('deliveries')
    .flatten()
    .map('plannedDeliveryDate')
    .sort()
    .compact()
    .value();

  deliveryDates.push(props.clientOrder.deliveryEndDate);

  return <Container>
    <DataTable
      className={"sf-table"}
      value={productOrders
        .filter(productOrder => !get(productOrder, 'ambassadorDiscount', false))
        .sort()
      }
      footerColumnGroup={footerGroup}
      dataKey={'id'}
      style={{width: '100%'}}
    >
      <Column
        header={'Product'}
        field={'product.id'}
        body={productColumn}
        style={{width: '200px'}}
      />
      <Column
        header={'Prijs incl. btw'}
        field={'priceIncl'}
        body={priceInclColumn(productOrders)}
        style={{width: '150px'}}
      />
      <Column
        header={'#'}
        body={(productOrder) => {
          let mainSubscriptionProductCode = first(filterProductOrdersByCategories(props.clientOrder.productOrders, ['zelfoogst', 'pakketten']))?.product?.code;
          return deliveriesColumn(productOrder,
            momentFromIso8601(props.clientOrder.deliveryDate),
            mainSubscriptionProductCode || undefined,
            updateProductOrder,
          );
        }}
        style={{width: '90px'}}
      />
      {/*<Column*/}
      {/*  header={'#'}*/}
      {/*  body={frequencyColumn}*/}
      {/*  style={{width: '40px'}}*/}
      {/*/>*/}
      {/*{deliveryWeeks.map(deliveryWeek =>*/}
      {/*  {*/}
      {/*    return <Column*/}
      {/*      header={"Periode"}*/}
      {/*      body={(productOrder: ActiveSubscriptions_clientOrders_productOrders) => {*/}
      {/*        const productOrderDeliveryDates = compact(productOrder.deliveries).map(del => momentFromIso8601(del.plannedDeliveryDate).format('YYYY-W E'));*/}
      {/*        if(productOrderDeliveryDates.includes(deliveryWeek.format('YYYY-W E'))) {*/}
      {/*          return "J";*/}
      {/*        }*/}
      {/*      }}*/}
      {/*      bodyClassName={'period-cell'}*/}
      {/*    />;*/}
      {/*  }*/}
      {/*)}*/}
      {!props.hideTimeline && <Column
        header={'Periode'}
        body={(productOrder) =>
          <PeriodColumn
            clientOrderId={props.clientOrder.id}
            productOrder={productOrder}
            deliveryDates={deliveryDates}
            clientOrderDeliveryDate={props.clientOrder.deliveryDate}
          />}
        bodyClassName={'period-cell'}
      />}
      {!props.disabled && <Column
          body={actionsColumn}
          style={{width: '40px'}}
      />}
    </DataTable>
    {selectedDelivery && <ChangeDeliveryDateButton delivery={selectedDelivery}/>}

    {showCreditProductOrdersView && <CreditProductOrdersView
      clientOrder={props.clientOrder} productOrders={productOrders}/>}
  </Container>;
};

export default SubscriptionProductOrderList;
