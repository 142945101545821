import React, { useState } from 'react';
import { asDayFullDdMmYyyy } from "../../../shared/utils/date.utils";
import moment, { Moment } from "moment";
import { uniq, values, without } from "lodash";
import { HarvestDayData, HarvestItem } from "./HarvestList";
import styled from "styled-components";
import HarvestItemRow from "./HarvestItemRow";
import classNames from "classnames";

interface HarvestDayProps {
  harvestDay: HarvestDayData;
  active: boolean;
  onSelected: (harvestDay: Moment) => void;
}

const Container = styled.div`
  border: 1px solid var(--green);
  padding: 8px;
  cursor: pointer;

  .productList {
    display: grid;
    grid-template-columns: 30px 3fr 90px;
    grid-gap: 8px 16px;
    align-items: center;
  }

  &.passed {
    opacity: 0.4;
  }

  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }

  &.active {
    background-color: var(--green-40);
    border-width: 3px;
  }
`;

const HarvestDay = (props: HarvestDayProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  return <Container className={classNames({active: props.active})} onClick={() => {
    props.onSelected(props.harvestDay.day);
  }}>
    <h2 className={'day'}><strong>{asDayFullDdMmYyyy(moment(props.harvestDay.day, 'x'))}</strong></h2>
    <div className={'productList'}>
      {values(props.harvestDay.harvestItems).map((harvestItem: HarvestItem) =>
        <HarvestItemRow
          value={harvestItem}
          checked={checkedItems.includes(harvestItem.product.id)}
          onSelectionChange={(checked: boolean) => setCheckedItems(value => {
            return checked ? uniq([...value, harvestItem.product.id]) : without(value, harvestItem.product.id);
          })}
        />
      )}
    </div>
  </Container>;
};

export default HarvestDay;
