import React, {useState} from 'react';
import {SalesData_clientOrders_invoice} from "../../../../__generated__/types";
import {Button} from "primereact/button";
import {PDFViewer} from "@react-pdf/renderer";
import ClientOrderInvoicePdf from "../../../../shared/pdf/ClientOrderInvoicePdf";
import {Dialog} from 'primereact/dialog';
import {useUserObject} from "../../../../shared/context/UserContext";

interface ShowClientInvoiceProps {
  invoice: SalesData_clientOrders_invoice;
}

const ShowClientInvoice = (props: ShowClientInvoiceProps) => {
  const {activeFarm} = useUserObject();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return <>
    {showDialog &&
      <Dialog
        header={'Factuur'}
        style={{width: '60vw', minHeight: '400px'}}
        visible={true}
        closeOnEscape
        closable
        onHide={() => setShowDialog(false)}
      >
        {activeFarm && props.invoice && <PDFViewer style={{height: '70vh', width: '100%'}}>
          <ClientOrderInvoicePdf data={props.invoice} farm={activeFarm}/>
        </PDFViewer>}
      </Dialog>
    }
    <Button
      text
      link
      className="p-button-dense"
      icon="pi pi-file-pdf"
      label={'Bekijk factuur'}
      onClick={() => {
        setShowDialog(true);
      }}
    />
  </>;
};

export default ShowClientInvoice;
