import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {chain, compact, first, mean, orderBy, sortBy, uniq, without} from "lodash";
import {formatDoubleDigit} from "../../../shared/utils/currency.utils";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {
  FarmConfigurationsByCode,
  PricelistData_clientGroups,
  PricelistData_products,
  ProductStatus,
  ProductType
} from "../../../__generated__/types";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import {Toast} from "primereact/toast";
import {DataTable, DataTableHeaderTemplateOptions} from "primereact/datatable";
import ProductUpsertDialog from "./components/ProductUpsertDialog";
import PromoDialog from "./components/PromoDialog/PromoDialog";
import Availability from "../ProductManagement/ProductList/Cells/Availability";
import PromoCell from "../ProductManagement/ProductList/Cells/PromoCell";
import MinOrderQuantityColumn from "./table/column/MinOrderQuantityColumn";
import ExecuteIndexButton from "./components/ExecuteIndexButton";
import {Checkbox} from "primereact/checkbox";
import {FilterMatchMode} from "primereact/api";
import {
  availabilityFor,
  findProductPriceForClientGroupCode,
  findProductPriceForDefaultClientGroup,
  isIndexable
} from "../../../shared/utils/product.utils";
import ExportPricelistButton from "./components/ExportPricelistButton";
import SetProductCategoryButton from "./components/SetProductCategoryButton";
import ProductPriceCell from "./table/cell/ProductPriceCell";
import {amountWithUnit} from "../../../shared/utils/unit.utils";
import {useQuery} from "@apollo/client";
import {FarmConfigurationsByCodeQuery} from "../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../shared/utils/farmConfig.utils";
import {useUserObject} from "../../../shared/context/UserContext";
import classNames from "classnames";

const TableWrapper = styled.div`
  .p-datatable .p-datatable-tbody > tr.available {
    background: #C8E6C9;
  }
`;

const units: { [key: string]: string } = {
  PIECE: "stuk",
  KILOGRAMS: "kg",
  GRAMS: "g",
  BUNCH: "bussel",
};

const Name = styled.div`
  span {
    font-size: 0.8em;
    color: #EE7A31;
  }
`;

const PricelistTable = (props: {
  onAddNewDiscount: () => any,
  value: PricelistData_products[],
  clientGroups: PricelistData_clientGroups[],
}) => {
  const {activeFarm} = useUserObject();
  const [products, setProducts] = useState<PricelistData_products[]>([]);
  const [productDialogMode, setProductDialogMode] = useState<'edit' | 'create' | 'copy' | undefined>();
  // const [productId, setProductId] = useState<string | undefined>("ckoddd8l3051i0775wjaxjdbf");
  const [productId, setProductId] = useState<string | undefined>();
  const [expandedRows, setExpandedRows] = useState<any>();
  const [showPromoDialogFor, setShowPromoDialogFor] = useState<string | undefined>(undefined);
  const [selectedProducts, setSelectedProducts] = useState<PricelistData_products[]>([]);
  const [selectedAvailabilitiesForClientGroups, setSelectedAvailabilitiesForClientGroups] = useState<string[]>([]);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>();
  const [filters, setFilters] = useState<{ [key: string]: { value: null | any, matchMode: FilterMatchMode } }>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  });

  const [selectedProductTypes, setSelectedProductTypes] = useState<ProductType[]>([ProductType.SIMPLE, ProductType.SUBSCRIPTION]);

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<PricelistData_products[]>>(null);
  const clientGroups = sortBy(props.clientGroups, 'order');

  const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {
    variables: {
      farmId: activeFarm?.id || '',
      code: FarmConfigCodes.clientGroups_flexCode
    }
  });
  let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

  useEffect(() => {
    const salesDataProducts = orderBy(props.value, ['name', 'status'], ['asc', 'asc']);
    setProducts(salesDataProducts);
  }, [props.value]);

  const openNew = () => {
    setProductId(undefined);
    setProductDialogMode('create');
  };

  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (options: DataTableHeaderTemplateOptions<PricelistData_products[]>) => {
    let selectedProducts = compact(options.props.selection as PricelistData_products[]);
    let indexableProducts = selectedProducts.filter(isIndexable);

    return <div className="p-0 m-0 surface-0 border-0">
      <Toolbar
        className="p-toolbar p-component p-0 m-0 surface-0 border-0"
        end={() => {
          const indexButton = selectedProducts.length > 0
            && indexableProducts.length === selectedProducts.length
            && <ExecuteIndexButton
              products={selectedProducts}
              onFinished={() => setSelectedProducts([])}
            />;
          return <div>
            <ExportPricelistButton
              products={products}
              clientGroups={clientGroups}
            />
            {selectedProducts.length > 0 && <SetProductCategoryButton
              products={selectedProducts}
              onFinished={() => setSelectedProducts([])}
            />}
            {indexButton}
          </div>;
        }}
        start={<div className="flex justify-content-end">
          <span className="p-input-icon-left mr-2">
              <i className="pi pi-search"/>
              <InputText type="search" value={globalFilterValue || ''} onChange={onGlobalFilterChange}
                         placeholder="Search..."/>
          </span>
          <Button label="Nieuw product" icon="pi pi-plus" className="p-button-link pl-0" onClick={openNew}/>
        </div>
        }
      />
    </div>;
  };

  const hideDialog = () => {
    setProductDialogMode(undefined);
    setProductId(undefined);
  };

  const onProductTypesChange = (e: any) => {

    let updatedProductTypes;
    if (e.checked) {
      updatedProductTypes = [...selectedProductTypes, e.value];
    } else {
      updatedProductTypes = [...without(selectedProductTypes, e.value)];
    }
    setSelectedProductTypes(updatedProductTypes);
    dt.current && dt.current.filter(updatedProductTypes, 'type', 'in');
  };

  const onSelectedAvailabilityChange = (e: any) => {
    let updatedProductTypes;
    if (e.checked) {
      updatedProductTypes = [...selectedProductTypes, e.value];
    } else {
      updatedProductTypes = [...without(selectedProductTypes, e.value)];
    }
    setSelectedProductTypes(updatedProductTypes);
    dt.current && dt.current.filter(updatedProductTypes, 'type', 'in');
  };

  const avgPricePerPortion = () => {
    return chain(products)
      .compact()
      .filter((p: PricelistData_products) => {
        let flexPrice = findProductPriceForClientGroupCode(p, flexClientGroupCode);
        let standardPrice = findProductPriceForDefaultClientGroup(p);
        return !!(flexPrice && standardPrice);
      })
      .map(p => {
        let flexPrice = findProductPriceForClientGroupCode(p, flexClientGroupCode);
        let standardPrice = findProductPriceForDefaultClientGroup(p);
        if (flexPrice?.value && standardPrice?.value) {
          return standardPrice.value / flexPrice.value
        }
        return 0;
      })
      .value()
  };

  const clientGroupColumnHeaders: { [key: string]: string } = {
    // "particulier": "Part.",
    // "restaurants": "Rest.",
    // "detailhandel": "Det.h.",
    // "kinderopvang": "Kind.",
    // "boeren": "Boer",
    // "flex": "Flex",
    // "zelfoogst": "Zelf.",
  };

  let avgPortionPrice = mean(avgPricePerPortion());


  let clientGroupsForAvailabilities = clientGroups.filter(cg => cg.code && !['boeren', 'kinderopvang'].includes(cg.code));
  let clientGroupsForPrices = clientGroups.filter(cg => cg.code && !['zelfoogst'].includes(cg.code));
  let headerColumnGroup = <ColumnGroup>
    <Row>
      {/*<Column header={'#'} rowSpan={2} headerStyle={{width: '1.5 rem'}}/>*/}
      <Column header={''} rowSpan={2}/>
      <Column header={''} selectionMode="multiple" rowSpan={2}/>
      <Column
        header={''}
        rowSpan={2}
        colSpan={clientGroupsForAvailabilities.length}
        filter
        showFilterMatchModes={false}
        showFilterMenuOptions={false}
        filterElement={
          <div className="flex flex-wrap flex-column justify-content-center gap-3">
            {clientGroupsForAvailabilities.map(clientGroup => {
              return <div key={clientGroup.id}>
                <Checkbox
                  id={`clientGroupAvailability_${clientGroup.id}`}
                  value={clientGroup.id}
                  checked={selectedAvailabilitiesForClientGroups.includes(clientGroup.id)}
                  onChange={(e) => {
                    setSelectedAvailabilitiesForClientGroups(value => {
                      if (e.checked) {
                        return uniq(([...value, e.value]));
                      } else {
                        return without(value, e.value);
                      }
                    });
                  }}/>
                <label htmlFor={`clientGroupAvailability_${clientGroup.id}`}>{clientGroup.name}</label>
              </div>;
            })}
          </div>
        }
      />
      <Column header={''} field={'productPromos'} rowSpan={2}/>
      <Column header={''} field={'image'} rowSpan={2}/>
      <Column header={''} field={'type'} rowSpan={2}
              filter
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              filterElement={
                <div className="flex flex-wrap flex-column justify-content-center gap-3">
                  {[
                    {label: 'Los', value: ProductType.SIMPLE},
                    {label: 'Abonnementen', value: ProductType.SUBSCRIPTION},
                  ].map((type) => {
                    return (
                      <div key={type.value} className="flex align-items-center">
                        <Checkbox inputId={type.label} name="category" value={type.value}
                                  onChange={onProductTypesChange}
                                  checked={selectedProductTypes.findIndex((item) => item === type.value) > -1}/>
                        <label htmlFor={type.label} className="ml-2">{type.label}</label>
                      </div>
                    );
                  })}
                </div>
              }
      />
      <Column className="p-1" header={'Categorie'} field={'category.id'} rowSpan={2}/>
      <Column className="p-1" header={'Product'} field={'name'} rowSpan={2}/>
      <Column className="p-1" header={'Eenheid'} rowSpan={2} sortable sortField={'unit'}/>
      <Column className="p-1" header={'Min. bestel'} rowSpan={2}/>
      {/*<Column header={'Stukgewicht'} rowSpan={2}/>*/}
      <Column className="p-1" style={{textAlign: 'center'}}
              header={() => <div className="text-center">Prijzen</div>}
              colSpan={2 + clientGroups.length}/>
      <Column header={''} rowSpan={2}/>
    </Row>
    <Row>
      <Column className="p-1" key={'basePriceHeader'} header={'Basis'}/>
      {clientGroupsForPrices.map(clientGroup =>
        <Column className="p-1" key={clientGroup.id}
                header={() => {
                  return <div>
                    <div>{clientGroup.code && (clientGroupColumnHeaders[clientGroup.code]) || clientGroup.name}</div>
                    <div
                      className="text-500 text-xs">{formatDoubleDigit((clientGroup.profitMargin - 1) * 100)}%
                    </div>
                  </div>;
                }}/>)
      }
    </Row>
  </ColumnGroup>;

  debugger;
  console.log(selectedAvailabilitiesForClientGroups.length > 0
    ? products
      .filter(product =>
        compact(selectedAvailabilitiesForClientGroups.map(clientGroupId => availabilityFor(product, clientGroupId))).length > 0
      )
    : products);
  return <div>
    {showPromoDialogFor &&
      <PromoDialog productId={showPromoDialogFor} onHide={() => setShowPromoDialogFor(undefined)}/>}

    <Toast ref={toast}/>

    <TableWrapper>
      <DataTable<PricelistData_products[]>
        className="sf-table"
        ref={dt}
        value={selectedAvailabilitiesForClientGroups.length > 0
          ? products
            .filter(product =>
              compact(selectedAvailabilitiesForClientGroups.map(clientGroupId => availabilityFor(product, clientGroupId))).length > 0
            )
          : products}
        dataKey={'id'}
        cellClassName={() => 'p-1'}
        showGridlines
        globalFilter={globalFilterValue}
        globalFilterFields={["name"]}
        header={header}
        expandedRows={expandedRows}
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
        rowExpansionTemplate={(product: PricelistData_products) => {
          return <div></div>
        }}
        selectionMode="checkbox"
        selection={selectedProducts}
        onSelectionChange={event => {
          setSelectedProducts(event.value as PricelistData_products[]);
        }}
        scrollable
        scrollHeight="50vh"
        paginator rows={50} rowsPerPageOptions={[10, 50, 100, 1000]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        headerColumnGroup={headerColumnGroup}
      >
        {/*<Column selectionMode="multiple"/>*/}
        <Column expander style={{maxWidth: '40px'}}/>
        <Column selectionMode="multiple" frozen></Column>

        {clientGroupsForAvailabilities.map(clientGroup => {
          return <Column
            frozen
            key={'clientGroupAvailability_' + clientGroup.code}
            field={'productAvailabilities'}
            style={{maxWidth: "20px"}}
            body={(product: PricelistData_products) => {
              return <Availability product={product}
                                   clientGroup={clientGroup}
              />;
            }}
          />;
        })}
        <Column
          frozen
          field={'Promo'}
          style={{textAlign: 'center'}}
          body={(product: PricelistData_products) => {
            return <PromoCell product={product} onPromoClick={() => setShowPromoDialogFor(product.id)}/>;
            // const hasActivePromo = compact(product.productPromos)
            //   .filter(promo => (!promo.endDate || momentFromIso8601(promo.endDate).isSameOrAfter(moment(), 'd')))
            //   .length > 0;
            //
            // return <><PromoIcon
            //   className={`pi pi-star${hasActivePromo ? '-fill' : ''}`}
            //   onClick={() => setShowPromoDialogFor(product.id)}
            // /></>;
          }}
        />
        <Column
          frozen
          field={'image'}
          style={{textAlign: 'center'}}
          body={(productListRow: PricelistData_products) => {
            return <div>{productListRow.image && <i className="pi pi-camera"/>}</div>
          }}
        />
        <Column
          frozen
          field={'type'}
          body={(productListRow: PricelistData_products) => {
            return <div>{productListRow.type === ProductType.SUBSCRIPTION &&
              <i className="pi pi-calendar"/>}</div>
          }}
        />
        <Column
          field={'category.id'}
          frozen
          style={{minWidth: "30px"}}
          body={(productListRow: PricelistData_products) => {
            return productListRow.category?.name;
          }}
        />
        <Column
          field={'name'}
          frozen
          style={{minWidth: "200px"}}
          body={(productListRow: PricelistData_products) => {
            return <div>
              {productListRow.code && <div className="text-xs text-color-secondary">{productListRow.code}</div>}
              <Name
                className={classNames({"line-through": productListRow.status === ProductStatus.HIDDEN})}>{productListRow.name}
                <br/><span>{productListRow.extra ? `(${productListRow.extra})` : ''}</span></Name>
            </div>
          }}
        />
        <Column
          field={'amount'}
          body={(productListRow: PricelistData_products) => {
            const {amount, unit, alternativeUnit, avgWeight} = productListRow;
            if (unit) {

              let availableAsAlternativeUnit = compact(productListRow.availableAsAlternativeUnitFor).length > 0;

              return <div>
                <div>{amount && amountWithUnit(amount, unit)}</div>
                {availableAsAlternativeUnit && avgWeight && amount &&
                  <div
                    className="text-xs text-700">= {amountWithUnit(amount / avgWeight, alternativeUnit)}</div>}
              </div>;
            } else {
              return <div/>;
            }
          }}
        />
        <Column
          field={'minOrderQuantity'}
          body={MinOrderQuantityColumn}
        />

        {[
          null,
          ...clientGroupsForPrices,
        ].map(clientGroup => {
          return <Column
            key={`${clientGroup ? clientGroup.id : `base`}_price`}
            field={'price'}
            body={(product: PricelistData_products) =>
              <ProductPriceCell
                flexClientGroupCode={flexClientGroupCode}
                product={product}
                avgPortionPrice={avgPortionPrice}
                clientGroup={clientGroup}
                selected={selectedProducts.findIndex(selectedProduct => selectedProduct.id === product.id) > -1}
              />}
          />
        })}

        <Column
          frozen
          alignFrozen="right"
          className={'p-2'}
          body={(product: PricelistData_products) => <>
            <i className={'pi pi-pencil'} onClick={() => {
              setProductId(product.id);
              setProductDialogMode('edit');
            }}/>
            <i className={'pi pi-copy'} onClick={() => {
              setProductId(product.id);
              setProductDialogMode('copy');
            }}/>
            {product.type !== ProductType.SUBSCRIPTION
              && compact(product.productPrices).findIndex(productPrice => productPrice.note === 'index-2023') === -1
              && <i className="pi pi-chart-line" onClick={() => {
                //   setPerformPriceIndexingFor(product.id);
              }}/>
            }
          </>
          }
        />
      </DataTable>
    </TableWrapper>

    {/*{performPriceIndexingFor &&*/}
    {/*  <PriceIndexingDialog productId={performPriceIndexingFor} onHide={() => setPerformPriceIndexingFor(undefined)}/>*/}
    {/*}*/}

    {productDialogMode &&
      <ProductUpsertDialog hideDialog={hideDialog} productId={productId} visible={true}
                           mode={productDialogMode}
                           clientGroups={clientGroups}/>}
  </div>
};

export default PricelistTable;
