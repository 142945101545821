import React from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {ProductOrdersForResetQuery} from "./RestPage.gql";
import {
    ProductOrdersForReset,
    ProductOrdersForReset_productOrders,
    ProductOrderUpdate, ProductOrderUpdateVariables
} from "../../__generated__/types";
import {DataTable} from 'primereact/datatable';
import {compact, first, last, sum, take, times} from "lodash";
import {Column} from 'primereact/column';
import {clientName} from "../../shared/utils/client.utils";
import {getBasketSize} from "../../shared/utils/product.utils";
import {Button} from 'primereact/button';
import {ProductOrderUpdateMutation} from "../../shared/queries/productOrder.gql";
import {
  asIso8601,
  iso8601_DdMmYyyy,
  momentFromIso8601,
  momentFromYyyyW,
  YyyyW_DayFullDdMmm
} from "../../shared/utils/date.utils";
import {formulaBySubscriptionProductCode, periodBySubscriptionProductCode} from "../../shared/utils/formula.utils";
import {deliveryWeeksForSubscription} from "../../shared/utils/subscription.utils";

interface ResetPageProps {

}

const basketPortions = {
    medium: [22, 23.5, 19, 21.5, 20, 26.5, 22, 30, 33.5, 23, 26, 27, 25.5, 25.5, 27.5, 29, 29, 34.5, 30.5, 30, 35, 28.5, 30, 33.5, 27.5, 28.5, 33.5, 26.5, 29.5, 26.5, 22.5],
    large: [33, 33.5, 40, 42, 46, 40, 47, 46.5, 37, 37, 38.5, 37.5, 34.5, 43.5, 44, 40, 49, 54, 43.5, 54.5, 46.5, 51, 37.5, 43.5, 45, 40, 38, 40, 36, 43.5, 35.5, 38, 37.5],
}


const ResetPage = (props: ResetPageProps) => {
    const {data, loading} = useQuery<ProductOrdersForReset>(ProductOrdersForResetQuery);
    const [updateProductOrder] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation);

    if (loading) {
        return "Loading";
    }

    return <div>
        <DataTable<ProductOrdersForReset_productOrders[]>
            value={compact(data?.productOrders)
                .map(productOrder => ({
                        ...productOrder,
                        suggestedPortions: (productOrder.usedPortions||0) + sum(
                            take(
                                getBasketSize(productOrder.product) === "medium" ? basketPortions.medium : basketPortions.large,
                                compact(productOrder.deliveries).length
                            )
                        )
                    })
                )}
        >
            <Column header="Naam" field="clientOrder.client.name"
                    body={(productOrder => clientName(productOrder.clientOrder.client))}/>
            <Column header="Product" field="product.code"/>
            <Column header="Verbruikte porties" field="usedPortions"/>
            <Column header="Porties" field="portions"/>
            <Column header="Laatste pakket" body={(productOrder:ProductOrdersForReset_productOrders) => {
              let deliveryWeeksForSubscription1 = productOrder.clientOrder.deliveryDate ? deliveryWeeksForSubscription(
                productOrder.product?.code?.replace('fixed', 'flex'),
                momentFromIso8601(productOrder.clientOrder.deliveryDate)
              ) : null;
              let lastPossibleWeek = deliveryWeeksForSubscription1 && last(deliveryWeeksForSubscription1.possibleWeeks);

              return <div>
                <div>laatste
                  pakket:{iso8601_DdMmYyyy(first(compact(productOrder.deliveries))?.plannedDeliveryDate)}</div>
                <div>einddatum: {iso8601_DdMmYyyy(productOrder.clientOrder.deliveryEndDate)}</div>
                <div>laatst mogelijke flexdatum: {lastPossibleWeek && YyyyW_DayFullDdMmm(lastPossibleWeek)}</div>
                <Button label={'Update einddatum'}
                        className="m-0 p-0 pl-1"
                        link
                        onClick={() => {
                          const variables: ProductOrderUpdateVariables = {
                            where: {
                              id: productOrder.id,
                            },
                            data: {
                              clientOrder: {
                                update: {
                                  deliveryEndDate: asIso8601(momentFromYyyyW(lastPossibleWeek).isoWeekday(5))
                                }
                              },
                            }
                          };
                          updateProductOrder({
                            variables,
                            refetchQueries: "active",
                          })
                        }}/>
              </div>;
            }
            }/>
            <Column header="Leveringen" field="deliveries"
                    body={productOrder => compact(productOrder.deliveries).length}/>
            <Column header="Voorspelde punten"
                    body={productOrder => {
                        return <div>
                            {compact(productOrder.deliveries).length > (getBasketSize(productOrder.product) === "medium" ? basketPortions.medium : basketPortions.large).length
                            && <div className="text-red-600">error</div>}
                            {productOrder.suggestedPortions}
                            {productOrder.portions !== productOrder.suggestedPortions &&
                                <Button label={'Update punten'}
                                        className="m-0 p-0 pl-1"
                                        link
                                     onClick={() => {
                                         const variables: ProductOrderUpdateVariables = {
                                             where: {
                                                 id: productOrder.id,
                                             },
                                             data: {
                                                 portions: productOrder.suggestedPortions,
                                             }
                                         };
                                         updateProductOrder({
                                             variables,
                                             refetchQueries: "active",
                                         })
                                     }}/>}
                        </div>;
                    }

                    }/>
        </DataTable>
    </div>;
};

export default ResetPage;
