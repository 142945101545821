import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import {ProductUnit} from "../../__generated__/types";
import {translatedUnit} from "../utils/unit.utils";
import {productIsAvailableAsAlternativeUnitFor} from "../utils/product.utils";

interface ProductQuantityInputProps {
  value: number | null;
  step?: number;
  disabled?: boolean;
  product: {
    id?: string;
    name: string;
    unit: ProductUnit | null;
    amount: number | null;
    availableAsAlternativeUnitFor: string[] | null;
    alternativeUnit: ProductUnit | null;
    avgWeight?: number | undefined | null;
    minOrderQuantity?: number | null;
  }
  onSave?: (value: number | null) => void;
  onChange?: (value: number | null) => void | boolean;
  clientGroupId: string | undefined | null;
  hideSaveButton?: boolean;
  allowZero?: boolean;
  disableAdd?: boolean;
  inputClassName?: string;
  allowHalfBunch?: boolean;
  allowNegative?: boolean;
}

const ProductQuantityInput = (props: ProductQuantityInputProps) => {
  const {product} = props;
  const [min, setMin] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [value, setValue] = useState<number | null>(props.value);
  const [isValid, setIsValid] = useState<boolean>(true);
  let availableAsAlternativeUnit = productIsAvailableAsAlternativeUnitFor(props.product, props.clientGroupId);
  const {amount, unit, minOrderQuantity} = product;

  useEffect(() => {
    let min = 1;
    if (product.avgWeight && productIsAvailableAsAlternativeUnitFor(props.product, props.clientGroupId)) {
      setStep(amount || 1);
      if (props.allowZero) {
        min = 0;
      }
      if (props.value && props.value > 0) {
        setValue((props.value) / product.avgWeight);
      } else {
        setValue(0)
      }
    } else {
      if (unit === ProductUnit.BUNCH) {
        setStep(props.allowHalfBunch ? 0.5 : 1);
      } else if (unit === ProductUnit.KILOGRAMS) {
        if (minOrderQuantity) {
          setStep(minOrderQuantity);
          min = minOrderQuantity;
        } else if (amount && amount !== 1) {
          min = amount;
          setStep(amount);
        } else {
          min = 0.25;
          setStep(0.25);
        }
      }
      if (props.allowZero) {
        min = 0;
      }
      setValue(props.value);
    }

    setMin(min);

    //OVERRULE all labove logic if props are given
    if (props.step) {
      setStep(step);
      setMin(props.step);
    }
  }, [product, props.value]);


  // useEffect(() => {
  //   setIsValid(!!(value
  //     && value >= min
  //     //  && value % step === 0
  //   ));
  // }, [value]);

  return <div>
    <div className='p-inputgroup align-items-center w-fit'>
      <InputNumber
        disabled={props.disabled}
        inputMode={"decimal"}
        //className={"flex-row-reverse"}
        inputClassName={`p-1 py-0 w-2rem ${props.inputClassName} text-center text-sm`}
        value={value}
        locale="nl-NL"
        maxFractionDigits={(product.unit === ProductUnit.PIECE) ? 0 : 2}
        // minFractionDigits={0}
        step={step}
        min={props.allowNegative ? undefined : min}
        max={props.disableAdd && value ? value : undefined}
        showButtons
        decrementButtonClassName={'text-sm'}
        incrementButtonClassName={'text-sm' + (props.disableAdd ? ' hidden' : '')}
        decrementButtonIcon={"pi pi-minus"}
        incrementButtonIcon={"pi pi-plus"}
        buttonLayout={"horizontal"}
        onValueChange={(e: any) => {
          let value = (product.amount && availableAsAlternativeUnit && product.avgWeight) ? (e.value * product.avgWeight) / product.amount : e.value;
          if (props.onChange) {
            let onChangeResult = props.onChange(value);
            if (onChangeResult !== false) {
              setValue(e.value);
            }
          } else {
            setValue(e.value);
          }
        }}/>
      <div className="pl-1 white-space-nowrap text-sm">
        {translatedUnit(availableAsAlternativeUnit ? product.alternativeUnit : product.unit, value)}
      </div>
      {!props.hideSaveButton && <Button
        disabled={!isValid}
        icon={'pi pi-check'}
        className={'p-button-success p-1 w-auto'}
        onClick={() => {
          props.onSave && props.onSave(value);
        }}
      />}
    </div>
  </div>;
};

export default ProductQuantityInput;
