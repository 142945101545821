import React from 'react';
import {momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {BasketCompositionSets_basketCompositionSets} from "../../../../../__generated__/types";
import classNames from "classnames";
import {Props} from 'shared/types/types';
import {formatAsPoints} from "../../../../../shared/utils/unit.utils";
import {BASKET_SIZES} from "../../BasketComposerPage2";
import {compact} from "lodash";
import SetHeader from "./components/SetHeader";

interface BasketCompositionSetViewerProps {
  set: BasketCompositionSets_basketCompositionSets;
  expanded?: boolean;
  onExpand?: () => void;
}

const BasketCompositionSetViewer = (props: Props<BasketCompositionSetViewerProps>) => {
  const {basketCompositions, status} = props.set;
  const deliveryWeek = momentFromIso8601(props.set.deliveryWeek);

  return <div className={classNames(`shadow-2 p-3 border-1 border-300 border-round ${props.className}`)}>
    <SetHeader deliveryWeek={deliveryWeek} expanded={props.expanded} onExpand={props.onExpand}/>

    {props.expanded &&
      <div>
        Status: {status}

        {BASKET_SIZES.map(size => {
          let compositionsForSize = compact(basketCompositions).filter(bc => bc.basketSize === size);
          return compositionsForSize.map(composition =>
            <div className="flex justify-content-between w-21rem">
              <div className="">{composition.basketSize}</div>
              <div className="">{composition.deliveryLocation || 'Standaard'}</div>
              <div className="">{formatAsPoints(composition.totalPortions || 0)}</div>
            </div>)
        })}
      </div>
    }

  </div>;
};

export default BasketCompositionSetViewer;
