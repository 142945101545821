import React, {useEffect, useState} from 'react';
import {createInvoiceData} from "../../../sales/utils/invoice.utils";
import {useMutation, useQuery} from "@apollo/client";
import {
  ClientOrder, ClientOrderUpdate, ClientOrderUpdateVariables,
  MarkClientOrdersForInvoiceAsSent,
  PaymentCreateWithoutInvoiceInput,
  UpdateClientOrderInvoiceVariables
} from "../../../../__generated__/types";
import {ClientOrderQuery, ClientOrderUpdateMutation} from "../../../../shared/queries/clientOrder.gql";
import {compact, omit} from "lodash";
import {Button} from "primereact/button";
import {PDFViewer} from "@react-pdf/renderer";
import ClientOrderInvoicePdf from "../../../../shared/pdf/ClientOrderInvoicePdf";
import {Dialog} from "primereact/dialog";
import {useUpdateClientOrderInvoice} from 'mutations/invoice.mutations';
import {sendInvoiceToClient} from "../MailButton";
import {MarkClientOrdersForInvoiceAsSentMutation} from "../../../sales/queries.gql";
import PaymentOptionsSelection from "./PaymentOptionsSelection";
import {ClientOrderInvoicePdfData} from "../../../../shared/utils/invoice.utils";
import {Checkbox} from "primereact/checkbox";
import {InputNumber} from "primereact/inputnumber";
import SubscriptionProductOrderList from '../SubscriptionDetail/components/SubscriptionProductOrderList/SubscriptionProductOrderList';
import {useUserObject} from "../../../../shared/context/UserContext";

interface UpdateInvoiceDialogProps {
  clientOrderId: string;
  onHide: () => void;
}

const UpdateInvoiceDialog = (props: UpdateInvoiceDialogProps) => {
  const {activeFarm} = useUserObject();
  const [invoiceData, setInvoiceData] = useState<ClientOrderInvoicePdfData>();
  const [orderDiscount, setOrderDiscount] = useState<number>();
  const [isSponsoring, setIsSponsoring] = useState<boolean>(false);
  const [sendToClient, setSendToClient] = useState<boolean>(true);
  const [sending, setSending] = useState<boolean>(false);
  const [payments, setPayments] = useState<PaymentCreateWithoutInvoiceInput[]>([]);

  const {data, refetch} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});
  const [updateClientOrderInvoice] = useUpdateClientOrderInvoice();
  const [updateClientOrder] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation);
  const [markClientOrdersForInvoiceAsSentRemote] = useMutation<MarkClientOrdersForInvoiceAsSent>(MarkClientOrdersForInvoiceAsSentMutation, {refetchQueries: 'active'});
  let clientOrder = data?.clientOrder;

  useEffect(() => {
    if (clientOrder?.invoice?.number) {
      let invoice = clientOrder?.invoice;
      const clientOrderInvoicePdfData = createInvoiceData(clientOrder, invoice.number, isSponsoring, payments, invoice);
      setInvoiceData(clientOrderInvoicePdfData);
    }
  }, [payments, isSponsoring]);

  let saveInvoice = () => {
    setSending(true);
    if (clientOrder && invoiceData && clientOrder.invoice?.id) {
      const variables: UpdateClientOrderInvoiceVariables = {
        oldInvoiceId: clientOrder.invoice?.id,
        invoiceData: {
          ...omit(invoiceData, "paymentCreates"),
          payments: invoiceData.paymentCreates,
          clientOrders: {connect: [{id: clientOrder.id}]},
        },
      };

      updateClientOrderInvoice({variables}).then(() => {
        refetch().then(r => {
          if (r.data?.clientOrder && sendToClient) {
            sendInvoiceToClient(r.data.clientOrder, false, false, (invoice) => {
              markClientOrdersForInvoiceAsSentRemote({
                variables: {id: invoice.id}
              }).then(r => {
                setSending(false);
                props.onHide();
              })
            }, undefined, undefined);
          } else if(!!r.data?.clientOrder?.invoice?.id) {
            markClientOrdersForInvoiceAsSentRemote({
              variables: {id: r.data.clientOrder.invoice.id}
            }).then(r => {
              setSending(false);
              props.onHide();
            })
          }
        });
      }).catch((e) => {
        console.error(e);
      });
    }
  };
  return <Dialog
    onHide={props.onHide}
    visible
    header={'Factuur maken'}
    footer={<>
      <Button className={'p-button-link'} label="Annuleren" onClick={props.onHide}/>
      <Button label="Factuur verzenden" disabled={sending} loading={sending} onClick={saveInvoice}/>
    </>}
  >
    {clientOrder && <SubscriptionProductOrderList hideTimeline clientOrder={clientOrder} />}

    {clientOrder && <PaymentOptionsSelection clientOrder={clientOrder}
                                             onChange={(payments) => {
                                               setPayments(payments);
                                             }}
    />}
    <div className="flex align-items-center">
      <Checkbox
        className="mr-2"
        checked={isSponsoring}
        id="sponsoring"
        onChange={e => setIsSponsoring(e.checked || false)}
      /> <label htmlFor={'sponsoring'}>Sponsoringfactuur</label>
    </div>
    <div className="field grid">
      <label htmlFor="orderDiscountInput" className="col-3">Korting</label>
      <div className="col p-inputgroup">
        <InputNumber
          value={clientOrder?.orderDiscount}
          mode="decimal"
          locale={'nl-NL'}
          maxFractionDigits={3}
          minFractionDigits={2}
          onChange={(e: any) => {
            setOrderDiscount(e.value);
          }}/>
        <Button icon="pi pi-check"
                disabled={!orderDiscount}
                onClick={() => {
                  const variables: ClientOrderUpdateVariables = {
                    data: {
                      orderDiscount
                    },
                    where: {id: clientOrder?.id}
                  };

                  updateClientOrder({variables, refetchQueries: "active"});
                }}
        />
      </div>
    </div>

    <div className="flex align-items-center m-2">
      <Checkbox
        className="mr-2"
        checked={sendToClient}
        id="sendToClient"
        onChange={e => setSendToClient(e.checked || false)}
      /> <label htmlFor={'dendToClient'}>Verstuur nieuwe factuur naar de deelnemer/klant</label>
    </div>

    {activeFarm && invoiceData && <PDFViewer style={{height: '90vh', width: '50vw', position: 'relative', margin: '0 auto'}}>
      <ClientOrderInvoicePdf data={invoiceData} farm={activeFarm}/>
    </PDFViewer>}

  </Dialog>;
};

export default UpdateInvoiceDialog;
