import React from 'react';
import {DeliveryLocation} from "../../../../__generated__/types";
import classNames from "classnames";
import {deliveryLocationValueToDetail} from "../../../../shared/utils/deliveryLocation.utils";

const DeliveryLocationCircle = (props: {
  deliveryLocation: DeliveryLocation,
  onClick?: (e: any) => void,
  isSelected?: boolean,
}) => {
  let deliveryLocationDetail = deliveryLocationValueToDetail(props.deliveryLocation);
  if (deliveryLocationDetail) {
    const {color, city} = deliveryLocationDetail;
    return <div
      className={classNames(
        "w-1-5rem h-1-5rem mx-1 text-sm font-bold border-circle border-1 flex align-items-center justify-content-center",
        `border-${color}`,
        {"cursor-pointer": props.onClick},
        {[`bg-${color} text-white`]: props.isSelected},
        {[`color-${color} bg-white opacity-60`]: !props.isSelected},
      )}
      onClick={(e) => props.onClick && props.onClick(e)}
    >
      {city.substring(0, 1)}
    </div>;
  }
  return null;
};

export default DeliveryLocationCircle;
