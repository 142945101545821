import { Vat } from "../../__generated__/types";

export const vat = (vat: Vat | undefined | null) => {
  return Vat.VAT_6 === vat
    ? 0.06
    : Vat.VAT_12 === vat
      ? 0.12
      : Vat.VAT_21 === vat
        ? 0.21
        : Vat.VAT_0 === vat
          ? 0
          : NaN;
};
