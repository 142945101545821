import React from 'react';
import {useQuery} from "@apollo/client";
import {BasketCompositionQuery} from "../../../../../../../../shared/queries/basketComposition.gql";
import {BasketComposition, BasketCompositionType, DeliveryLocation} from "../../../../../../../../__generated__/types";
import DescriptionItem from "../../../../../../../../shared/components/DescriptionList/DescriptionItem";
import {
  getBasketSize,
} from "../../../../../../../../shared/utils/product.utils";
import {totalPortions} from "../../../../../../../../shared/utils/basketComposition.utils";
import {compact} from "lodash";
import {formatDoubleDigit} from "../../../../../../../../shared/utils/currency.utils";

interface BasketCompositionInfoForDeliveryProps {
  delivery: {
    basketComposition?: { id: string } | null;
    deliveryLocation?: DeliveryLocation | null;
  }
  productOrder: { product?: { code?: string | null | undefined } | undefined | null } | null | undefined;
}

const BasketCompositionInfoForDelivery = (props: BasketCompositionInfoForDeliveryProps) => {
  const {data} = useQuery<BasketComposition>(BasketCompositionQuery, {variables: {id: props.delivery.basketComposition?.id}});

  let basketCompositionItems = compact(data?.basketComposition?.items);
  let basketSize = getBasketSize(props.productOrder?.product);

  return <li className="flex align-items-start py-1 px-2 border-top-1 border-300 flex-wrap">
    <div className=" w-7 md:w-3 font-medium text-right px-2 color-green">Pakketinfo</div>
    <div className="text-900 w-full md:w-8 gap-1">
      <DescriptionItem
        label={"Type"}>{data?.basketComposition?.type === BasketCompositionType.FIXED ? "Vast" : "Gepersonaliseerd"}</DescriptionItem>
      <DescriptionItem label={"Punten"}>
        <div>{formatDoubleDigit(totalPortions(basketCompositionItems, basketSize, props.delivery.deliveryLocation))} punten</div>
      </DescriptionItem>
    </div>
  </li>;
};

export default BasketCompositionInfoForDelivery;
