import React from 'react';
import styled from "styled-components";
import classNames from "classnames";

interface SideMenuItemProps {
  model: {
    label: string,
    icon: string,
  };
  active?: boolean;
  onClick: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 8px;


  cursor: pointer;

  &.active, &:hover {
    color: var(--orange);
    font-weight: bold;
  }
`;

const SideMenuItem = (props: SideMenuItemProps) => {
  return <Container
    className={classNames({active: props.active})}
    onClick={() => props.onClick()}
  >
    <i className={`pi ${props.model.icon}`}/>
    <span>{props.model.label}</span>
  </Container>;
};

export default SideMenuItem;
