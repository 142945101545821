import React from 'react';
import {ActiveSubscriptions_clientOrders} from "../../../__generated__/types";
import InvoicePayments from "./InvoicePayments";

interface RegisterPaymentButtonProps {
 clientOrder: ActiveSubscriptions_clientOrders;
}

const RegisterPaymentButton = (props: RegisterPaymentButtonProps) => {

  return <div>
    {props?.clientOrder?.invoice?.filename}
    {props.clientOrder.invoice && <InvoicePayments clientOrder={props.clientOrder} invoice={props.clientOrder.invoice}/>}
  </div>;
};

export default RegisterPaymentButton;
