import React from 'react';
import {Button} from "primereact/button";
import BasketCompositionForDelivery from "../BasketCompositionForDelivery/BasketCompositionForDelivery";
import {
    ClientOrderFields_productOrders,
    ClientOrderFields_productOrders_deliveries, FarmConfigurationsByCode
} from "../../../../__generated__/types";
import {useNavigate} from "react-router-dom";
import {isFlexBasketProductOrder} from "../../../../utils/productOrder.utils";
import {first} from "lodash";
import {useQuery} from "@apollo/client";
import {FarmConfigurationsByCodeQuery} from "../../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../../shared/utils/farmConfig.utils";

interface BasketContentsProps {
    basketProductOrder?: ClientOrderFields_productOrders;
    delivery?: ClientOrderFields_productOrders_deliveries;
    clientOrderId: string;
}

const BasketContents = (props: BasketContentsProps) => {
    const {delivery, basketProductOrder} = props;

    const navigate = useNavigate();
    const isFlex = props.basketProductOrder && isFlexBasketProductOrder(props.basketProductOrder);

    // HARDCODED FARM ID
    // let {activeFarm} = useUserObject();
    let {activeFarm} = {activeFarm: {id: 'clbuwjlqc8rvq0775qw2nhw6l'}};
    const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

    let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

    return <div className="col-12 md:col-6">
        <div className="flex justify-content-between align-items-center pb-1 border-bottom-1 border-green-alpha-40">
            <div className="text-base md:text-lg lg:text-xl font-medium">Pakketinhoud
            </div>
            {isFlex && basketProductOrder && delivery?.basketComposition && <Button
                label={'Personaliseer mijn pakket'}
                className={'p-button-link p-0 font-light text-xs mb-2'}
                icon={'pi pi-shopping-cart'}
                iconPos={'right'}
                onClick={() => navigate('/mijnpakket/flex?id=' + props.clientOrderId)}
            />}

        </div>

        {delivery?.basketComposition && basketProductOrder &&
            <div>
                {isFlex &&
                    <div className={"my-2"}>
                        <div className="text-sm font-normal">Het voorgestelde pakket</div>
                        <div className="text-xs text-500">(Wijzig je je pakket niet dan ontvang je onderstaand vast
                            pakket)
                        </div>
                    </div>
                }

                <BasketCompositionForDelivery
                    product={basketProductOrder.product}
                    showPortions={false}
                    delivery={delivery}
                    editable={false}
                    basketCompositionId={delivery.basketComposition.id}
                    flexClientGroupCode={flexClientGroupCode}/>
            </div>
        }

        {basketProductOrder && !delivery?.basketComposition &&
            <div className="text-yellow-500 m-2 mb-3">
                <div className="font-bold mb-1">Dit pakket is nog niet samengesteld.</div>
                <div className="text-sm font-medium">
                    Van zodra de boeren het pakket hebben samengesteld, kan je dit hier bekijken.
                    <br/>Heb je een flex abonnement? Kom dan zeker terug en kies zelf je groenten.
                </div>
            </div>
        }

        {isFlex && basketProductOrder && delivery?.basketComposition &&
            <div className='flex flex-column justify-content-between align-items-center'>
                <Button
                    label={'Personaliseer mijn pakket'}
                    className={'p-button mb-1'}
                    icon={'pi pi-shopping-cart'}
                    iconPos={'right'}
                    onClick={() => navigate('/mijnpakket/flex?id=' + props.clientOrderId)}
                />
            </div>
        }
    </div>;
};

export default BasketContents;
