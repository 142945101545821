import React from 'react';
import DashboardItem from "../../../../components/Dashboard/DashboardItem";
import {chain, compact, keyBy} from "lodash";
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {useQuery} from "@apollo/client";
import {ActiveSubscriptions} from "../../../../__generated__/types";
import {ActiveSubscriptionsQuery} from "../../SubscriptionsPage.gql";
import {asIso8601} from "../../../../shared/utils/date.utils";
import {TODAY} from "../../../../shared/config";
import {useUserObject} from "../../../../shared/context/UserContext";

const DashboardItemSelfHarvestSummary = () => {
  const {activeFarm} = useUserObject();

  const {data, loading} = useQuery<ActiveSubscriptions>(ActiveSubscriptionsQuery, {variables: {
      farmId: activeFarm?.id || '',
      today: asIso8601(TODAY)}});
  const products = keyBy(compact(data?.productsInClientOrders),'id');

  const selfHarvestSubscriptions = compact(data?.clientOrders)
    .map(co => ({...co, productOrders: compact(co.productOrders).map(po =>({...po, product: products[po.product?.id||'']}))}))
    .filter(clientOrder => compact(clientOrder.productOrders).filter(productOrder => productOrder.product?.category?.code === 'zelfoogst').length > 0);
  const selfHarvestVEs = chain(selfHarvestSubscriptions)
    .map('productOrders')
    .compact()
    .flatten()
    .filter(productOrder => productOrder.product?.category?.code === 'zelfoogst')
    .map('quantity')
    .sum()
    .value();

  return <DashboardItem
    width={3}
    label={'Zelfoogst'}
    value={selfHarvestSubscriptions.length}
    valueDescription={'inschrijvingen'}
    color={'green'}
    loading={loading}
  >
    <div className={'mb-3'}>
        <span className={`font-medium text-xl text-gray-800`}>{
          selfHarvestSubscriptions
            .filter(co => compact(co.productOrders).filter(po => !!po.product?.code && po.product.code.indexOf('trial') > -1).length > 0).length
        }</span>
      <span className={'font-normal text-sm text-gray-400'}> proef</span>
    </div>
    <div>
      <span className={`font-normal text-l text-gray-800`}>{formatDoubleDigit(selfHarvestVEs)}</span>
      <span className={'font-normal text-sm text-gray-400'}> volw. eq.</span>
    </div>
  </DashboardItem>;
};

export default DashboardItemSelfHarvestSummary;
