import React from 'react';
import PageHeading from "../../components/PageHeading";
import BankRecordsFileUpload from "./BankRecordsFileUpload";
import NewTransactionsList from './components/NewTransactionsList';

const FinancialManagement = () => {

  return <div>
    <PageHeading
      title={'Financiën'} path={[
      {label: "Financieel beheer", path: "/administratie/financieel"}
    ]}/>

    <div>
      <div className="text-xl font">Importeer rekeninguitreksels</div>
      <div>Upload hier je rekeninguitreksels: </div>
      <BankRecordsFileUpload/>
    </div>

    <div className="mt-3">
      <NewTransactionsList/>
    </div>
  </div>;
};

export default FinancialManagement;
