import React from 'react';
import {Client_client} from "../../../../__generated__/types";
import FamilyMembers from './FamilyMembers';
import EditableDescriptionItem from "../../../../shared/components/DescriptionList/EditableDescriptionItem";
import {useClientUpdateMutation} from "../../../../mutations/client.mutations";
import AccountInformation from "../AccountInformation/AccountInformation";
import {SubscriptionClientOrders_productOrders} from "../SubscriptionTable/SubscriptionTable";

interface PersonaProps {
  client: Client_client;
  productOrders: SubscriptionClientOrders_productOrders[];
}


const Persona = (props: PersonaProps) => {
  const {
    city,
    firstName,
    street,
    email,
    streetNumber,
    name,
    secondaryEmail,
    phone,
    cityPostalCode,
    remarks
  } = props.client;

  const [updateClient] = useClientUpdateMutation();

  const onChange = (updates: { [Property in keyof Client_client]?: string | null }) =>
    updateClient({
      variables: {
        data: updates,
        id: props.client.id,
      }
    })

  return <div className="grid grid-nogutter">
    <div className="md:col-6">
      <div className="font-medium text-2xl mb-3 color-orange uppercase">Persoonlijke gegevens</div>
      <ul className="list-none p-0 m-3">
        <EditableDescriptionItem<Client_client>
          fields={[
            {
              value: firstName,
              name: "Voornaam",
              field: "firstName",
              placeholder: "Voornaam",
              width: 5,
            }, {
              value: name,
              name: "Naam",
              field: "name",
              placeholder: "Naam",
              width: 6,
            },
          ]}
          onChange={onChange}
          label={'Naam'}>
          {firstName} {name}
        </EditableDescriptionItem>

        <EditableDescriptionItem
          fields={[
            {
              value: street,
              name: "Straat",
              field: "street",
              placeholder: "Straat",
              width: 9,
            },
            {
              value: streetNumber,
              name: "Huisnummer",
              field: "streetNumber",
              placeholder: "Huisnummer",
              width: 2,
            },
            {
              value: cityPostalCode,
              name: "Postcode",
              field: "cityPostalCode",
              placeholder: "Postcode",
              width: 3,
            },
            {
              value: city,
              name: "Gemeente",
              field: "city",
              placeholder: "Gemeente",
              width: 8,
            },
          ]}
          onChange={onChange}
          label={'Adres'}
        >
          {street} {streetNumber} <br/>
          {city} {cityPostalCode}
        </EditableDescriptionItem>

        <EditableDescriptionItem
          fields={[
            {
              value: email,
              name: "E-mailadres",
              field: "email",
              placeholder: "E-mailadres",
              width: 12,
            },
            {
              value: secondaryEmail,
              name: "Extra e-mailadressen",
              field: "secondaryEmail",
              placeholder: "Extra e-mailadressen",
              width: 12,
            },
            {
              value: phone,
              name: "Telefoon / GSM",
              field: "phone",
              placeholder: "Telefoon / GSM",
              width: 12,
            },
          ]}
          onChange={onChange}
          label={'Contact'}
        >
          <div className="grid grid-nogutter">
            <div className="px-0 py-1 col-1">
              <i className="pi pi-envelope"></i>
            </div>
            <div className="px-0 py-0 col-11">
              {email} <br/>{secondaryEmail && `(${secondaryEmail})`}
            </div>
            <div className="px-0 py-1 col-1">
              <i className="pi pi-phone"></i>
            </div>
            <div className="px-0 py-0 col-11">{phone}</div>
          </div>
        </EditableDescriptionItem>

        <div className="mt-2">
          <div className="font-medium text-2xl mb-3 color-orange uppercase">Account</div>
          <AccountInformation client={props.client} productOrders={props.productOrders}/>
        </div>
      </ul>
    </div>

    <div className="md:col-4">
      <div className="font-medium text-2xl mb-3 color-orange uppercase">Gezinssamenstelling</div>
      <div className="m-3">
        <FamilyMembers
          client={props.client}
          editable
        />
      </div>
    </div>
    {
      remarks &&
      <div className="md:col-4">
        <div className="mt-5 font-medium text-2xl mb-3 color-orange uppercase">Opmerkingen</div>
        <EditableDescriptionItem<Client_client>
          fields={[
            {
              value: remarks,
              name: "Opmerkingen",
              field: "remarks",
              placeholder: "",
            },
          ]}
          onChange={onChange}
          label={'Bij inschrijving'}>
          {remarks}
        </EditableDescriptionItem>
      </div>
    }

  </div>;
};

export default Persona;
