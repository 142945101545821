import React, {useRef} from 'react';
import classNames from "classnames";
import {
  BasketComposition_basketComposition,
  BasketCompositionStatus,
  ProductOrdersForBasketComposition_productOrders
} from "../../../../../../__generated__/types";
import {compact, difference, map} from "lodash";
import {OverlayPanel} from "primereact/overlaypanel";
import {momentFromIso8601} from "../../../../../../shared/utils/date.utils";
import {
  filterProductOrdersMissingInBasketComposition,
  filterProductOrdersWithDifferentBasketComposition
} from 'utils/productOrder.utils';
import BasketCompositionErrors from "../../BasketCompositionErrors";
import {
  notificationsFor
} from "../../../../../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";
import {clientName} from "../../../../../../shared/utils/client.utils";

interface CounterValuesProps {
  productOrders: ProductOrdersForBasketComposition_productOrders[];
  basketComposition: BasketComposition_basketComposition | null | undefined;
  status: BasketCompositionStatus | undefined | null;
}

const CounterValues = (props: CounterValuesProps) => {
  const {productOrders, basketComposition, status} = props;
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const clientsOverlayPanelRef = useRef<OverlayPanel>(null);

  let missingProductOrders = filterProductOrdersMissingInBasketComposition(productOrders, basketComposition);
  let productOrdersWithDifferentBasketComposition = filterProductOrdersWithDifferentBasketComposition(productOrders, basketComposition);

  let productOrdersWithDeliveryInSameWeekOfBasketComposition = productOrders.filter(productOrder => {
      return compact(productOrder.deliveries)
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(basketComposition?.deliveryWeek), 'isoWeek')).length > 0;
    }
  );
  let wrongDateConnectedProductOrders = difference(map(productOrders, 'id'), map(productOrdersWithDeliveryInSameWeekOfBasketComposition, 'id'));

  let inErrorState = status === BasketCompositionStatus.PUBLISHED &&
    (
      missingProductOrders.length > 0
      || wrongDateConnectedProductOrders.length > 0
      || productOrdersWithDifferentBasketComposition.length > 0
    );

  return <>
    {inErrorState && <OverlayPanel
      ref={overlayPanelRef}>
      <BasketCompositionErrors
        productOrders={productOrders}
        basketComposition={basketComposition}
      />
    </OverlayPanel>}

    <OverlayPanel
      ref={clientsOverlayPanelRef}>
      {productOrders.map(po => <div>{clientName(po.clientOrder.client)}</div>)}
    </OverlayPanel>
    <div
      className={classNames(
        "col-3 py-0 text-center",
        {"bg-red-100 cursor-pointer border-1 border-red-500": inErrorState}
      )}
      onClick={(e) => {
        if (inErrorState) {
          overlayPanelRef.current?.toggle(e);
        } else {
          clientsOverlayPanelRef.current?.toggle(e);
        }
      }}>
      {productOrdersWithDeliveryInSameWeekOfBasketComposition.length}
      {inErrorState &&
        <span
          className={"text-red-500 font-light"}> ({missingProductOrders.length + wrongDateConnectedProductOrders.length + productOrdersWithDifferentBasketComposition.length})</span>
      }
    </div>
  </>
    ;
};

export default CounterValues;
