import React, {useState} from 'react';
import {
  DeleteFarmConfiguration,
  FarmConfiguration_farm_configuration,
  UpsertFarmConfiguration,
  UpsertFarmConfigurationVariables
} from "../../../__generated__/types";
import {InputText} from "primereact/inputtext";
import {useMutation} from "@apollo/client";
import {FarmConfigurationDeleteMutation, FarmConfigurationUpsertMutation} from "../farm.gql";
import {useUserObject} from "../../../shared/context/UserContext";
import {Button} from 'primereact/button';
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";

interface FarmConfigValueProps {
  item?: FarmConfiguration_farm_configuration;
}

const FarmConfigValue = (props: FarmConfigValueProps) => {
  const {activeFarm} = useUserObject();
  const [updatedCode, setUpdatedCode] = useState<string | undefined>(props.item?.code);
  const [updatedValue, setUpdatedValue] = useState<string | undefined>(props.item ? JSON.stringify(props.item.value) : undefined);
  const [updateFarmConfiguration] = useMutation<UpsertFarmConfiguration>(FarmConfigurationUpsertMutation);
  const [deleteFarmConfiguration] = useMutation<DeleteFarmConfiguration>(FarmConfigurationDeleteMutation);

  return <>
    <div className="col-4 text-right">
      <div className='p-inputgroup flex-1'>
        <InputText
          value={updatedCode}
          onChange={(e) => {
            setUpdatedCode(e.target.value);
          }}
        />
        {activeFarm && updatedCode && <span className="p-inputgroup-addon cursor-pointer" onClick={() => {
          const variables: UpsertFarmConfigurationVariables = {
            id: props.item?.id || '',
            createData: {
              code: updatedCode,
              value: updatedValue && JSON.parse(updatedValue),
              farm: {
                connect: {id: activeFarm.id}
              }
            },
            updateData: {
              code: updatedCode,
              value: updatedValue ? JSON.parse(updatedValue) : props.item?.value,
            }
          };
          updateFarmConfiguration({variables})
            .then(() => {
              setUpdatedValue(undefined);
            });
        }}>
      <i className="pi pi-check"/>
    </span>}
      </div>
    </div>
    <div className="col-7 font-bold">
      <div className='p-inputgroup flex-1'>
        <InputText
          value={updatedValue}
          onChange={(e) => {
            setUpdatedValue(e.target.value);
          }}
        />
        {activeFarm && updatedValue && updatedCode &&
          <span className="p-inputgroup-addon cursor-pointer" onClick={() => {
            debugger;
            const variables: UpsertFarmConfigurationVariables = {
              id: props.item?.id || '',
              createData: {
                code: updatedCode,
                value: JSON.parse(updatedValue),
                farm: {
                  connect: {id: activeFarm.id}
                }
              },
              updateData: {
                code: updatedCode || props.item?.code,
                value: JSON.parse(updatedValue),
              }
            };
            updateFarmConfiguration({variables})
              .then(() => {
                setUpdatedValue(undefined);
              });
          }}>
      <i className="pi pi-check"/>
    </span>}
      </div>
    </div>
    <div className="col-1" >
      <ConfirmPopup/>
      <Button
        disabled={!props.item?.id}
        icon={'pi pi-trash'}
        className={'p-button-text p-button-danger'}
        onClick={(e) => {
          confirmPopup({
            acceptLabel: 'Ja',
            rejectLabel: 'Nee',
            target: e.currentTarget,
            message: 'Ben je zeker dat je deze configuratie wil verwijderen?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              deleteFarmConfiguration({variables: {id: props.item?.id}, refetchQueries: "active"})
            },
            reject: () => {
            }
          });
        }}/>
    </div>
  </>;
};

export default FarmConfigValue;
