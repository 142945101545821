import React from 'react';
import { AllOrders_clientOrders } from "../../../../__generated__/types";
import { Dialog } from "primereact/dialog";
import { PDFViewer } from "@react-pdf/renderer";
import ClientOrderDeliveryNotePdf, { ClientOrderDeliveryNotePdfData } from "./ClientOrderDeliveryNotePdf";
import { asDdMmYyyy, asDdMmYyyyNonNull, momentFromIso8601 } from "../../../../shared/utils/date.utils";
import { camelCase, compact } from "lodash";
import { clientToRecipient } from "../../../../shared/pdf/pdf-data.utils";
import { formatDoubleDigit } from "../../../../shared/utils/currency.utils";
import { translatedUnit } from "../../../../shared/utils/unit.utils";

interface ClientOrderDeliveryNotePdfDialogProps {
  onHide: () => void,
  clientOrder: AllOrders_clientOrders;
  client: {
    name: string | null, street: string | null
    streetNumber: string | null
    cityPostalCode: string | null
    city: string | null,
    vatNumber: string | null
  };
}

const ClientOrderDeliveryNotePdfDialog = (props: ClientOrderDeliveryNotePdfDialogProps) => {
  const {client, clientOrder} = props;
  const deliveryDate = momentFromIso8601(clientOrder.deliveryDate);
  const pdfData: ClientOrderDeliveryNotePdfData = {
    filename: 'Leverbon-' + asDdMmYyyy(deliveryDate) + '-' + camelCase(client.name || ''),
    recipient: clientToRecipient(client),
    meta: [
      {
        label: 'Leveringsdatum',
        value: asDdMmYyyyNonNull(deliveryDate),
      },
      {
        label: "Btw nummer klant", value: client.vatNumber ? `BTW ${client.vatNumber}` : '',
      },
    ],
    productLines: compact(clientOrder.productOrders).map(productOrder => {
      return {
        description: productOrder.product?.name || '-',
        productUnit: `${formatDoubleDigit(productOrder.quantity * (productOrder.product?.amount || 0))} ${translatedUnit(productOrder.product?.unit)}`,
      }
    }),
  };

  return <Dialog
    header={pdfData.filename}
    visible={true}
    onHide={() => props.onHide()}
    closeOnEscape
    closable>
    <PDFViewer style={{height: '90vh', width: '50vw'}}>
      <ClientOrderDeliveryNotePdf data={pdfData}/>
    </PDFViewer>
  </Dialog>;
};

export default ClientOrderDeliveryNotePdfDialog;
