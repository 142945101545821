import {useQuery} from '@apollo/client';
import React from 'react';
import {HarvestDeliveryReferencesQuery} from "./HarvestDeliveryReference.gql";
import {
  DeliveryLocation,
  HarvestDeliveryReferences,
  HarvestDeliveryReferencesVariables
} from "../../../../../__generated__/types";
import {QueryHookOptions} from "@apollo/client/react/types/types";
import {compact, groupBy, keys} from "lodash";
import {clientName} from 'shared/utils/client.utils';
import {toCity} from "../../../../../shared/utils/deliveryLocation.utils";

interface HarvestDeliveryReferenceProps {
  ids: string[];
  type: 'amountForBasketCompositions' | 'amountForExtraProducts' | 'amountForOtherClientOrders' | 'amountForShopOrders';
}

const HarvestDeliveryReference = (props: HarvestDeliveryReferenceProps) => {
  let options: QueryHookOptions<HarvestDeliveryReferences, HarvestDeliveryReferencesVariables> = {variables: {referenceIds: props.ids}};
  const {data} = useQuery<HarvestDeliveryReferences>(HarvestDeliveryReferencesQuery, options);

  let basketsByLocation = groupBy(data?.productOrderDeliveries, 'deliveryLocation');
  return <div>
    {
      props.type === 'amountForBasketCompositions' &&
      <div>
        {keys(basketsByLocation).map(deliveryLocation =>
          <div className="mb-4">
            <div className="text-xl uppercase font-bold pb-2 border-top-1 mt-2 border-300 flex justify-content-between">
              {toCity(deliveryLocation as DeliveryLocation)}
              <span>{basketsByLocation[deliveryLocation].length}</span>
            </div>
            {basketsByLocation[deliveryLocation].map(delivery =>
              <div>{clientName(delivery?.productOrder.clientOrder.client)}</div>)}
          </div>
        )}
        {/*{data?.productOrderDeliveries.map(delivery =>*/}
        {/*  <div>{clientName(delivery?.productOrder.clientOrder.client)} {toCity(delivery?.deliveryLocation)}</div>)}*/}
      </div>
    }
    {
      props.type === 'amountForExtraProducts' &&
      <div>
        {data?.productOrderDeliveries.map(delivery =>
          <div>{clientName(delivery?.productOrder.clientOrder.client)} {toCity(delivery?.deliveryLocation)}</div>)}
      </div>
    }
    {
      props.type === 'amountForShopOrders' &&
      <div>
        {data?.shopOrders.map(shopOrder =>
          <div>{clientName(shopOrder?.shopClient)} {toCity(shopOrder?.deliveryLocation)}</div>)}
      </div>
    }
    {
      props.type === 'amountForOtherClientOrders' &&
      <div>
        {compact(data?.clientOrders).map(clientOrder =>
          <div>{clientName(clientOrder.client)} {clientOrder.client.city}</div>)}
      </div>
    }
  </div>;
};

export default HarvestDeliveryReference;
