import React from 'react';
import DashboardItem from "../../../../components/Dashboard/DashboardItem";
import {chain, compact, keyBy, sum} from "lodash";
import {formatCurrency, formatCurrencyNoSign} from "../../../../shared/utils/currency.utils";
import {useQuery} from "@apollo/client";
import {ActiveSubscriptions, ActiveSubscriptions_clientOrders, Vat} from "../../../../__generated__/types";
import {ActiveSubscriptionsQuery} from "../../SubscriptionsPage.gql";
import {filterClientOrdersByCategory, totalRevenue} from "../../../../utils/clientOrder.utils";
import {asIso8601, momentFromIso8601} from "../../../../shared/utils/date.utils";
import moment from "moment/moment";
import OverdueInvoicesMessage from "./OverdueInvoicesMessage";
import {TODAY} from "../../../../shared/config";
import {useUserObject} from "../../../../shared/context/UserContext";

type ClientOrder = {
  productOrders: ({
    priceExcl: number,
    product: {
      category: { code: string | null } | null,
      vat: Vat,
    } | null
  })[] | null,
  invoice?: {
    id: string,
    payments?: {
      amount: number,
      paid?: boolean | null | undefined,
      dueDate: string,
    }[] | null | undefined,
  } | null | undefined,
};

const allPayments = (clientOrders: ClientOrder[]) => chain(clientOrders)
  .map('invoice')
  .compact()
  .uniqBy('id')
  .map('payments')
  .flatten()
  .value();


const totalInvoicedAmountFor = (clientOrders: ClientOrder[],) =>
  chain(allPayments(clientOrders))
    .map('amount')
    .sum()
    .value();

const totalPaidAmountFor = (clientOrders: ClientOrder[],) =>
  chain(allPayments(clientOrders))
    .filter(['paid', true])
    .map('amount')
    .sum()
    .value();


export const paymentIsOverdue = (payment: { paid?: boolean | null | undefined, dueDate: string }) =>
  !payment.paid && momentFromIso8601(payment.dueDate).isBefore(moment(), 'd');

export const invoiceHasOverduePayments =
  (invoice: { payments?: { paid?: boolean | null | undefined, dueDate: string }[] | null } | null | undefined) =>
    compact(invoice?.payments)
      .findIndex(paymentIsOverdue) > -1;

const overdueClientOrders = (clientOrders: ActiveSubscriptions_clientOrders[]): ActiveSubscriptions_clientOrders[] => clientOrders
  .filter(clientOrder => invoiceHasOverduePayments(clientOrder.invoice));

const DashboardItemRevenue = () => {
  const {activeFarm} = useUserObject();

  const {data, loading} = useQuery<ActiveSubscriptions>(ActiveSubscriptionsQuery, {variables: {farmId: activeFarm?.id || '',today: asIso8601(TODAY)}});
  const products = keyBy(compact(data?.productsInClientOrders),'id');

  const clientOrders = compact(data?.clientOrders)
    .filter(co => co.client.email && !["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"].includes(co.client.email))
    .map(co => ({...co, productOrders: compact(co.productOrders).map(po =>({...po, product: products[po.product?.id||'']}))}))
    .filter(co => compact(co.productOrders).findIndex(po => po.product?.code?.includes('-winter')) === -1);


  const revenueValues = {
    total: {
      totalAmount: totalRevenue(clientOrders),
      invoicedAmount: totalInvoicedAmountFor(clientOrders),
      paidAmount: totalPaidAmountFor(clientOrders),
      overdueInvoices: overdueClientOrders(clientOrders).length,
    },
    selfHarvest: {
      totalAmount: totalRevenue(clientOrders, ['zelfoogst']),
      invoicedAmount: totalInvoicedAmountFor(clientOrders),
      paidAmount: totalPaidAmountFor(clientOrders),
      overdueInvoices: overdueClientOrders(clientOrders).length,
    },
    baskets: {
      totalAmount: totalRevenue(clientOrders, ['pakketten']),
      invoicedAmount: totalInvoicedAmountFor(clientOrders),
      paidAmount: totalPaidAmountFor(clientOrders),
      overdueInvoices: overdueClientOrders(clientOrders).length,
    },
  };

  const noInvoice = sum(clientOrders
    .filter(clientOrder => !clientOrder.invoice));


  return <DashboardItem
    loading={loading}
    width={3}
    label={'Inkomsten'}
    icon={'pi pi-euro'}
    color={'yellow'}
    value={formatCurrencyNoSign(revenueValues.total.totalAmount)}
    valueDescription={'EUR'}
    messages={compact([
      revenueValues.total.overdueInvoices > 0 && {
        severity: 'error',
        content: () => {
          return <OverdueInvoicesMessage clientOrders={overdueClientOrders(clientOrders)}/>;
        },
      },
      noInvoice > 0 && {
        severity: 'warn',
        text: `${noInvoice} te factureren ${noInvoice > 1 ? 'abonnementen' : 'abonnement'}`
      }
    ])}
  >
    <div className={'mb-3'}>
      <div>
        <span
          className={`font-medium text-xl text-gray-800`}>{formatCurrencyNoSign(revenueValues.selfHarvest.totalAmount)}</span>
        <span className={'font-normal text-sm text-gray-400'}> zelfoogst</span>
      </div>
      <div>
        <span
          className={`font-medium text-xl text-gray-800`}>{formatCurrencyNoSign(revenueValues.baskets.totalAmount)}</span>
        <span className={'font-normal text-sm text-gray-400'}> pakketten</span>
      </div>
    </div>

    <div className={'mb-3'}>
      <div>
        <span
          className={`font-medium text-base text-gray-600`}>{formatCurrency(revenueValues.total.invoicedAmount)}</span>
        <span className={'font-normal text-sm text-gray-400'}> gefactureerd</span>
      </div>
      <div>
        <span
          className={`font-medium text-base text-gray-600`}>{formatCurrency(revenueValues.total.paidAmount)}</span>
        <span className={'font-normal text-sm text-gray-400'}> betaald</span>
      </div>
    </div>
  </DashboardItem>;
};

export default DashboardItemRevenue;
