import React from 'react';
import FarmSelector from "../shared/components/FarmSelector";
import {useUserObject} from "../shared/context/UserContext";
import {Permission} from "../__generated__/types";
import StartPage from "./StartPage/StartPage";

interface HomePageProps {

}

const HomePage = (props: HomePageProps) => {
  const {user, activeFarm} = useUserObject();
  return <div>
    {(user && !activeFarm)
      ? <FarmSelector createPermission={Permission.FARM_MANAGEMENT_ADMIN}/>
      : <StartPage/>
    }
  </div>;
};

export default HomePage;
