import React, {useState} from 'react';
import {ClientOrder_clientOrder} from "../../../../__generated__/types";
import {Button} from "primereact/button";
import {useClientOrderDeleteMutation} from "../../../../mutations/client.mutations";
import {ConfirmDialog} from "primereact/confirmdialog";

interface SubscriptionActionsProps {
 clientOrder: ClientOrder_clientOrder;
}

const SubscriptionActions = (props: SubscriptionActionsProps) => {
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [deleteClientOrder] = useClientOrderDeleteMutation();

  return <div>
    <ConfirmDialog
      visible={confirmDialog}
      header="verwijderen"
      headerClassName="text-900"
      message={'Ben je zeker dat je dit abonnement wil verwijderen?'}
      icon={'pi pi-exclamation-triangle'}
      acceptLabel={'Ja'}
      acceptClassName={'p-button-danger p-button-outlined'}
      rejectLabel={'Nee'}
      rejectIcon="pi pi-trash"
      rejectClassName={'p-button-text p-button-link'}
      reject={() => setConfirmDialog(false)}
      accept={() => {
        deleteClientOrder({
          variables: {
            id: props.clientOrder.id,
          }
        });
      }}
    />
    <Button
      className={'p-button-outlined p-button-danger'}
      label={"Verwijderen"}
      onClick={(event) => {
        setConfirmDialog(true);
      }}
  />
  </div>;
};

export default SubscriptionActions;
