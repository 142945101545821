import React from "react";
import {Navigate} from "react-router";
import {useUserObject} from "./context/UserContext";
import {hasAtLeastOneMatchingPermission, hasPlannerPermission} from "./utils/permission.utils";

const SecretRoute = ({isAuthenticated, routePermissions, children}: any) => {
  const {user} = useUserObject();

  const userHasPermission = hasAtLeastOneMatchingPermission(routePermissions, user?.permissions);

  const isAllowed = isAuthenticated && userHasPermission;

  if (isAllowed) {
    return <>{children}</>;
  } else {
    return <Navigate to={{
      pathname: '/',
      //state: {from: props.location}
    }}/>;
  }
};

export default SecretRoute;
