import React, {useRef} from 'react';
import {Button} from "primereact/button";
import classNames from "classnames";
import {DeliveryLocation} from "../../../../__generated__/types";
import {
  basketProductOrderHasInsufficientPoints_error,
  basketProductOrderHasInsufficientPoints_warning,
  basketProductOrderIsOutOfBalance,
  basketProductOrderIsRunningOutOfDeliveries_info,
  basketProductOrderIsRunningOutOfDeliveries_warning,
  PlannedDeliveryForBasketComposition
} from "../../../../utils/clientOrder.utils";
import {OverlayPanel} from 'primereact/overlaypanel';
import FlexSubscriptionStatusOverview from "./FlexSubscriptionStatusOverview";
import {sortBy} from "lodash";

interface FlexNotificationsProps {
  basketProductOrder?: ProductOrderForNotification | null;
  clientEmail?: string | null | undefined;
  clientOrderId: string;
  nextDelivery: PlannedDeliveryForBasketComposition | undefined | null;
  flag?: boolean;
}

// WHEN:
// - Nog maar 3 pakketten resterend
// - Te weinig punten voor het volgende pakket
// - Genoeg punten voor dit pakket, maar niet genoeg voor het volgende pakket.
// - Uit balans: je hebt niet meer voldoende punten om je pakketten te vullen zoals je gewend bent.

type FlexMessage =
  'outOfBalance_75' |
  'warning_lastBasket_insufficientPoints' |
  'warning_predicts_insufficiency' |
  'error_predicts_insufficiency' |
  'lastBasket_sufficientPoints' |
  'warning_runningOutOfDeliveries' |
  'info_runningOutOfDeliveries' |
  'error_lastBasket_insufficientPoints'
  ;

export interface FlexNotification {
  severity: 'warn' | 'info' | 'error';
  code: FlexMessage;
  title: string;
  description: string[][];
  productOrder?: ProductOrderForNotification;
}

const FLEX_MESSAGES: { [key in FlexMessage]: FlexNotification } = {
  outOfBalance_75: {
    title: "Vul tijdig je punten aan",
    code: "outOfBalance_75",
    severity: "info",
    description: [
      ['Je punten geraken sneller op', 'Je verbruikt meer punten dan voorzien per pakket. Je zal waarschijnlijk je laatste pakket(ten) niet kunnen vullen.'],
      ['Vul je punten aan', 'Klik op de knop om je punten aan te vullen (herinschrijven) en flex rustig verder. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  warning_runningOutOfDeliveries: {
    title: "Je laatste pakket",
    code: "warning_runningOutOfDeliveries",
    severity: "warn",
    description: [
      ['Deze week ontvang je het laatste pakket van jouw abonnement.'],
      ['Schrijf je opnieuw in', 'Klik op de knop om je opnieuw in te schrijven. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  info_runningOutOfDeliveries: {
    title: "Nog maar enkele pakketten",
    code: "info_runningOutOfDeliveries",
    severity: "info",
    description: [
      ['Je hebt nog maar enkele pakketten te ontvangen.'],
      ['Schrijf je opnieuw in', 'Klik op de knop om je opnieuw in te schrijven. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  warning_predicts_insufficiency: {
    title: "Onvoldoende punten voor het volgende pakket",
    code: "warning_predicts_insufficiency",
    severity: "warn",
    description: [
      ['Onvoldoende punten', 'Je zal waarschijnlijk onvoldoende punten over hebben voor het volgende pakket. Afhankelijk van de samenstelling zal je dit pakket wel of niet ontvangen.'],
      ['Vul je punten aan', 'Klik op de knop om je punten aan te vullen (herinschrijven) en flex rustig verder. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  warning_lastBasket_insufficientPoints: {
    title: "Je laatste pakket",
    code: "warning_lastBasket_insufficientPoints",
    severity: "warn",
    description: [
      ['Dit pakket zal je nog ontvangen.', 'Je ontvangt dit pakket nog, maar je kan niet of slechts beperkt flexen.' +
      'Om te kunnen blijven flexen en pakketten te blijven ontvangen, moet je je punten verder aanvullen.'],
      ['Vul je punten aan', 'Klik op de knop om je punten aan te vullen (herinschrijven) en flex rustig verder. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  error_lastBasket_insufficientPoints: {
    title: "Niet leverbaar pakket, onvoldoende punten voor dit pakket",
    code: "error_lastBasket_insufficientPoints",
    severity: "error",
    description: [
      ['Dit pakket zal je niet meer ontvangen', "Je hebt onvoldoende punten."],
      ['Wil je dit pakket toch ontvangen?', "Lees hieronder verder om je punten aan te vullen."],
      ['Vul je punten aan', 'Klik op de knop om je punten aan te vullen (herinschrijven) en flex rustig verder. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  error_predicts_insufficiency: {
    title: "Het volgende pakket is mogelijks niet leverbaar",
    code: "error_predicts_insufficiency",
    severity: "error",
    description: [
      ['Je hebt mogelijks onvoldoende punten', "Omdat je mogelijks te weinig punten hebt voor dit pakket, bestaat de kans dat je dit pakket niet zal ontvangen."],
      ['Wanneer weet ik of ik dit pakket nog ontvang?', "Van zodra wij de pakketinhoud hebben vastgelegd, zal je zien of je dit pakket nog zal ontvangen.", "Om te kunnen blijven flexen en pakketten te blijven ontvangen, moet je je punten verder aanvullen."],
      ['Vul je punten aan', 'Klik op de knop om je punten aan te vullen (herinschrijven) en flex rustig verder. Zo geniet je de komende maanden van nog meer lekkers op je bord.'],
    ],
  },
  lastBasket_sufficientPoints: {
    title: "lastBasket_sufficientPoints",
    code: "lastBasket_sufficientPoints",
    severity: "warn",
    description: [],
  },
};

type ProductOrderDeliveryForNotification = {
  id: string;
  deliveryDate: any | null;
  plannedDeliveryDate: any;
  deliveryLocation: DeliveryLocation | null;

  cancelled: boolean;
  basketComposition: {
    id: string;
  } | null;
};

type ProductOrderForNotification = {
  id: string;
  portions: number | null;
  usedPortions: number | null;
  deliveries: ProductOrderDeliveryForNotification[] | null;
  product: {
    id: string;
    name: string;
    code: string | null;
    flex?: boolean | null;
  } | null;
};

export const notificationsFor = (
  productOrder: ProductOrderForNotification | null | undefined,
): FlexNotification[] => {

  if (!productOrder) {
    return [];
  }
  let flexNotifications: FlexNotification[] = [];

  // - W: Einde leveringen, nog maar 2
  // - I: Einde leveringen, nog maar 3
  // - I: Uit balans
  // - E: Te weinig punten, ongeacht de volgende pakketsamenstelling
  // - W: Nog de helft (of meer) van de punten voor 1 pakket, ongeacht de volgende pakketsamenstelling
  //        Deelnemer krijgt nog een pakket, maar kan niet flex'en tenzij onder het resterend aantal punten

  if (basketProductOrderIsRunningOutOfDeliveries_warning(productOrder)) {
    flexNotifications.push(FLEX_MESSAGES.warning_runningOutOfDeliveries);
  } else if (basketProductOrderIsRunningOutOfDeliveries_info(productOrder)) {
    flexNotifications.push(FLEX_MESSAGES.info_runningOutOfDeliveries);
  }

  if (basketProductOrderHasInsufficientPoints_error(productOrder)) {
    flexNotifications.push(FLEX_MESSAGES.error_lastBasket_insufficientPoints);
  // } else if (!next?.basketComposition?.id && basketProductOrderPredictsInsufficiency_error(productOrder)) {
    //flexNotifications.push(FLEX_MESSAGES.error_predicts_insufficiency);
  // } else if (!next?.basketComposition?.id && basketProductOrderPredictsInsufficiency_warning(productOrder)) {
  //   flexNotifications = flexNotifications.filter(notification => notification.code !== 'warning_runningOutOfDeliveries');
  //   flexNotifications.push(FLEX_MESSAGES.warning_predicts_insufficiency);
  } else if (basketProductOrderHasInsufficientPoints_warning(productOrder)) {
    flexNotifications = flexNotifications.filter(notification => notification.code !== 'warning_runningOutOfDeliveries');
    flexNotifications.push(FLEX_MESSAGES.warning_lastBasket_insufficientPoints);
  } else if (basketProductOrderIsOutOfBalance(productOrder)) {
    flexNotifications.push(FLEX_MESSAGES.outOfBalance_75);
  }

  let errorNotifications = flexNotifications.filter(notification => notification.severity === "error");
  if (errorNotifications.length > 0) {
    flexNotifications = errorNotifications;
  }

  return flexNotifications.map(flexNotification => ({...flexNotification, productOrder}));
}

export const highestSeverity = (notifications: FlexNotification[]) => {
  let highestSeverity = null;
  if (notifications.findIndex(n => n.severity === "error") > -1) {
    highestSeverity = "error";
  } else if (notifications.findIndex(n => n.severity === "warn") > -1) {
    highestSeverity = "warn";
  } else if (notifications.findIndex(n => n.severity === "info") > -1) {
    highestSeverity = "info";
  }
  return highestSeverity;
};

export const highestSeverityIs = (notifications: FlexNotification[], severity: string) => highestSeverity(notifications) === severity;

const FlexNotifications = (props: FlexNotificationsProps) => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const {basketProductOrder, clientEmail} = props;

  if (!basketProductOrder) {
    return <div/>;
  }

  // const notificationsForClientOrder = notificationsFor(basketProductOrder, props.additionalPortions);
  const notificationsForClientOrder = notificationsFor(basketProductOrder);

  let notifications = sortBy(notificationsForClientOrder, 'severity').map((notification) => {
      const {severity, title, description} = notification;
      return <div key={notification.code} className={classNames(
        "border-1 p-2 md:p-4 m-1 md:m-2",
        {"border-orange-500 bg-orange-50": severity === "warn"},
        {"border-blue-500 bg-blue-50": severity === "info"},
        {"border-red-600 bg-red-100 border-2": severity === "error"},
      )}>
        <div className={classNames(
          "text-md md:text-lg lg:text-xl pb-1 md:pb-2 flex align-items-center",
          {"text-orange-500": severity === "warn"},
          {"text-blue-500": severity === "info"},
          {"text-red-500": severity === "error"},
        )}>
          <i className={classNames(
            "pi text-xl md:text-2xl lg:text-3xl",
            {"pi-exclamation-circle": severity === "warn"},
            {"pi-question-circle": severity === "info"},
            {"pi-exclamation-triangle": severity === "error"},
          )}/>
          <div className="pl-2">
            {severity === 'warn' && <div className="font-bold">OPGELET</div>}
            {severity === 'error' && <div className="font-bold">OPGELET</div>}
            <div className={classNames(
              {"font-medium": severity === "warn"},
              {"font-bold": severity === "info"},
              {"font-medium": severity === "error"},
            )}>
              {title}
            </div>
          </div>
        </div>
        {description.map(([title, ...text], idx) =>
          <div key={notification.code + idx} className="pt-1 md:pt-2">
            <div className="text-base md:text-md lg:text-lg font-bold">{title}</div>
            {text.map(line => <div className=" text-sm md:text-base">{line}</div>)}
          </div>
        )}

        <div className="flex flex-column justify-content-center">
          <Button
            className="m-4"
            severity={"warning"}
            outlined
            label={"Vul mijn punten aan"}
            onClick={() => {
              window.open(`https://www.mijnboer.be/groentegeweld/inschrijven?email=${clientEmail}`, '_blank');
            }}
          />
          <div className="text-xs md:text-sm font-italic">
            Via bovenstaande knop kom je op jouw persoonlijke herinschrijvingspagina terecht. De meeste gegevens zijn al
            voor
            jou ingevuld.
            <br/>Bevestig of wijzig je opties en vervolledig je inschrijving.
          </div>
        </div>
      </div>;
    }
  );
  return <div>
    {props.flag && notifications.length > 0 &&
      <div>
        <i className={classNames(
          {"pi pi-exclamation-circle text-orange-300": highestSeverityIs(notificationsForClientOrder, "warn")},
          {"pi pi-question-circle text-blue-500": highestSeverityIs(notificationsForClientOrder, "info")},
          {"pi pi-exclamation-triangle text-red-800": highestSeverityIs(notificationsForClientOrder, "error")},
        )}
           onClick={(e) => overlayPanelRef?.current?.toggle(e)}/>
        <OverlayPanel ref={overlayPanelRef}>
          <div className="flex row">
            <FlexSubscriptionStatusOverview clientOrderId={props.clientOrderId}
                                            deliveryDate={props.nextDelivery?.plannedDeliveryDate}/>
            {notifications}
          </div>
        </OverlayPanel>
      </div>
    }

    {!props.flag && notifications}


  </div>;
};

export default FlexNotifications;
