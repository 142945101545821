import React from 'react';
import DashboardItem from "../../../../components/Dashboard/DashboardItem";
import {chain, compact, keyBy} from "lodash";
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {useQuery} from "@apollo/client";
import {ActiveSubscriptions} from "../../../../__generated__/types";
import {ActiveSubscriptionsQuery} from "../../SubscriptionsPage.gql";
import {TODAY} from "../../../../shared/config";
import {asIso8601} from "../../../../shared/utils/date.utils";
import {useUserObject} from "../../../../shared/context/UserContext";

const DashboardItemBasketsSummary = () => {
  const {activeFarm} = useUserObject();

  const {
    data,
    loading
  } = useQuery<ActiveSubscriptions>(ActiveSubscriptionsQuery, {
    variables: {
      farmId: activeFarm?.id || '',
      today: asIso8601(TODAY)
    }
  });
  const products = keyBy(compact(data?.productsInClientOrders), 'id');
  const basketSubscriptions = compact(data?.clientOrders)
    .filter(co => co.client.email && !["vpeltwillem@gmail.com", "info@groentegeweld.be", "willem.vanpelt@me.com", "dirkhendrix13@gmail.com"].includes(co.client.email))
    .map(co => ({
      ...co,
      productOrders: compact(co.productOrders).map(po => ({...po, product: products[po.product?.id || '']}))
    }))
    .filter(co => compact(co.productOrders).findIndex(po => po.product?.code?.includes('-winter')) === -1)
    .filter(clientOrder => compact(clientOrder.productOrders)
      .filter(productOrder => productOrder.product?.id && products[productOrder.product.id]?.category?.code === 'pakketten').length > 0);

  const nrOfFlexBaskets = chain(basketSubscriptions)
    .map('productOrders')
    .compact()
    .flatten()
    .filter((po) => !!po.product?.code && po.product.code.indexOf('flex') > -1)
    .map('quantity')
    .sum()
    .value();

  const nrOfFixedBaskets = chain(basketSubscriptions)
    .map('productOrders')
    .compact()
    .flatten()
    .filter((po) => !!po.product?.code && po.product.code.indexOf('fixed') > -1)
    .map('quantity')
    .sum()
    .value();

  return <DashboardItem
    width={3}
    label={'Pakketten'}
    value={basketSubscriptions.length}
    valueDescription={'pakketten'}
    icon={'icon-harvest-box'}
    loading={loading}
  >

    <div className={'mb-3'}>
        <span className={`font-medium text-xl text-gray-800`}>{
          basketSubscriptions
            .filter(co => compact(co.productOrders).filter(po => !!po.product?.code && po.product.code.indexOf('trial') > -1).length > 0).length
        }</span>
      <span className={'font-normal text-sm text-gray-400'}> proef</span>
    </div>
    <div>
      <span className={`font-normal text-l text-gray-800`}>{formatDoubleDigit(nrOfFlexBaskets)}</span>
      <span className={'font-normal text-sm text-gray-400'}> flex</span>
    </div>
    <div>
      <span className={`font-normal text-l text-gray-800`}>{formatDoubleDigit(nrOfFixedBaskets)}</span>
      <span className={'font-normal text-sm text-gray-400'}> vast</span>
    </div>
  </DashboardItem>;
};

export default DashboardItemBasketsSummary;
