import React, { useState } from 'react';
import {
  ClientOrderStatus, DeleteClientOrder,
  GetClientOrdersToProcess_clientOrders,
  SalesData_clientOrders,
  SalesData_clientOrders_invoice
} from "../../../../__generated__/types";
import styled from 'styled-components';
import { useSales } from "../../sales-context";
import ClientOrderUpsertForm from "./ClientOrderUpsertForm";
import { Button } from "primereact/button";
import PdfDialog from './PdfDialog';
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import { ClientOrderDeleteMutation } from 'shared/queries/clientOrder.gql';
import {useMutation} from "@apollo/client";
import {useUserObject} from "../../../../shared/context/UserContext";

interface ClientOrderProps {
  clientOrder: GetClientOrdersToProcess_clientOrders;
  onHide: () => void;
}

const Container = styled.div`

`;

const ClientOrder = (props: ClientOrderProps) => {
  const {upsertClientOrder} = useSales();
  const {activeFarm} = useUserObject();
  const [pdfData, setPdfData] = useState<SalesData_clientOrders_invoice>();

  const markAsDelivered = (clientOrder: SalesData_clientOrders) => {
    upsertClientOrder({
      ...clientOrder,
      status: ClientOrderStatus.DELIVERED,
    });
  };

  const [deleteClientOrder] = useMutation<DeleteClientOrder>(ClientOrderDeleteMutation);

  return <Container>
    <ClientOrderUpsertForm
      clientOrder={props.clientOrder}
      onHide={() => {
        props.onHide();
      }}
    />
    <Button
      label={'Markeer als afgeleverd'}
      onClick={() => markAsDelivered(props.clientOrder)}
    />

    <div>
      <ConfirmPopup/>
      <Button
        icon={'pi pi-trash'}
        label="Bestelling verwijderen"
        className='p-button-text p-button-danger pl-0'
        onClick={(e) => {
          confirmPopup({
            acceptLabel: 'Ja',
            rejectLabel: 'Nee',
            target: e.currentTarget,
            message: 'Ben je zeker dat je deze bestelling wil verwijderen?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              deleteClientOrder({variables: {id: props.clientOrder.id}, refetchQueries: "active"})
              props.onHide();
            },
            reject: () => {
            }
          });
        }}/>
    </div>

    {/*<Button*/}
    {/*  label={'Maak factuur'}*/}
    {/*  onClick={() => createInvoice(props.clientOrder)}*/}
    {/*/>*/}
    {/*<i className={'pi pi-search'} onClick={() => {*/}
    {/*  debugger;*/}
    {/*  props.clientOrder.invoice && setPdfData(props.clientOrder.invoice);*/}
    {/*}}/>*/}

    {activeFarm && pdfData && <PdfDialog pdfData={pdfData} onHide={() => setPdfData(undefined)} farm={activeFarm}/>}

  </Container>;
};


export default ClientOrder;
