import { momentFromIso8601 } from "./date.utils";
import { chain, round } from "lodash";
import { PROMOCODE } from "../../config.app";

export const paymentDescription = (deliveryDate: string | undefined, name: string) => `w${deliveryDate && momentFromIso8601(deliveryDate).isoWeek()} ${name}`;

export const totalPrice = (items:
                             { priceIncl: number, }[],
                           packaging: boolean | undefined,
                           ambassadorDiscount: number,
                           promocode: string | null,
) => {
  let totalValue = chain(items)
    .map((item) => round(item.priceIncl, 2))
    .sum()
    .value() * (1 - ambassadorDiscount);
  if (packaging) {
    totalValue += 1.5;
  }

  let totalValueWithPromocodeDiscount = totalValue - (promocode === PROMOCODE ? 15 : 0);
  return totalValueWithPromocodeDiscount > 0 ? totalValueWithPromocodeDiscount : 0;
};
