import React, {useState} from 'react';
import {Column} from "primereact/column";
import {AllOrders, AllOrders_clientOrders, Clients_clients} from "../../../../__generated__/types";
import {asDdMmYyyy, momentFromIso8601} from "../../../../shared/utils/date.utils";
import {AllOrdersQuery} from "../ClientManagement.gql";
import {useQuery} from "@apollo/client";
import {compact} from "lodash";
import {Button} from "primereact/button";
import ClientOrderDeliveryNotePdfDialog from "./ClientOrderDeliveryNotePdfDialog";
import {DataTable} from "primereact/datatable";

interface OrdersTableProps {
  client: Clients_clients;
}

const OrdersTable = (props: OrdersTableProps) => {
  const {data} = useQuery<AllOrders>(AllOrdersQuery, {variables: {id: props.client.id}});

  const [activeDeliveryNoteFor, setActiveDeliveryNoteFor] = useState<AllOrders_clientOrders>();

  return <div>

    {activeDeliveryNoteFor &&
      <ClientOrderDeliveryNotePdfDialog
        onHide={() => setActiveDeliveryNoteFor(undefined)}
        clientOrder={activeDeliveryNoteFor}
        client={props.client}
      />}

    <DataTable
      className={"sf-table"}
      //autoLayout
      //responsiveLayout={'scroll'}
      value={compact(data?.clientOrders)}
    >
      <Column
        header={'Orderdatum'}
        field={'orderDate'}
        body={(clientOrder: AllOrders_clientOrders) => asDdMmYyyy(momentFromIso8601(clientOrder.orderDate))}
      />
      <Column
        header={'Leverbon'}
        body={(clientOrder: AllOrders_clientOrders) =>
          <Button
            label={'Leverbon'}
            className={'p-button-text'}
            onClick={(e) => {
              setActiveDeliveryNoteFor(clientOrder);
            }}
          />
        }
      />
    </DataTable>
  </div>;
};

export default OrdersTable;
