import React, {useRef} from 'react';
import {OverlayPanel} from "primereact/overlaypanel";
import {compact, map} from "lodash";
import {ProductUnit} from "../../../../__generated__/types";
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {amountWithUnit} from "../../../../shared/utils/unit.utils";
import {HarvestAmount} from './HarvestView';
import HarvestDeliveryReference from './HarvestDeliveryReference/HarvestDeliveryReference';

interface AmountCellProps {
  product: {
    id: string;
    harvestAmounts: {
      amountForBasketCompositions: HarvestAmount,
      amountForExtraProducts: HarvestAmount;
      amountForOtherClientOrders: HarvestAmount;
      amountForShopOrders: HarvestAmount;
    };
  };
  field: 'amountForBasketCompositions' | 'amountForExtraProducts' | 'amountForOtherClientOrders' | 'amountForShopOrders';
}

const AmountCell = (props: AmountCellProps) => {
  let amountUnits = props.product.harvestAmounts[props.field];
  const op = useRef<OverlayPanel>(null);
  return <div>
    <OverlayPanel ref={op} showCloseIcon>
      <div>
        {map(ProductUnit, (productUnit) => {
          return <div key={`detail-${props.product.id}-${productUnit}`}>
            {
              amountUnits[productUnit as ProductUnit].map((x) =>
                <div key={`${x.productAmount}-${x.orderAmount}` } className="mb-3">
                  {formatDoubleDigit(x.orderAmount)} x {amountWithUnit(x.productAmount, productUnit as ProductUnit)}
                  <div>{compact(x.referenceIds).length > 0 &&
                    <HarvestDeliveryReference ids={x.referenceIds} type={props.field}/>}</div>
                </div>
              )
            }
          </div>;
        })}
      </div>
    </OverlayPanel>
    {map(ProductUnit, (productUnit) => {
      return <div key={`${props.product.id}-${productUnit}`}>
        {
          amountUnits[productUnit as ProductUnit].map((x: {
              productAmount: number;
              orderAmount: number;
            }) =>
              <div key={`value-${x.productAmount}-${x.orderAmount}`} className="cursor-pointer"
                   onClick={(e) => {
                     return op.current?.toggle(e);
                   }}>
                {formatDoubleDigit(x.orderAmount)} x {amountWithUnit(x.productAmount, productUnit as ProductUnit)}
              </div>
          )
        }
      </div>;
    })}
  </div>
};

export default AmountCell;
