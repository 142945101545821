import React, {useRef, useState} from 'react';
import {BasketCompositionQuery} from "../../../../shared/queries/basketComposition.gql";
import {useMutation, useQuery} from "@apollo/client";
import {
    BasketComposition,
    BasketComposition_basketComposition_items,
    BasketItemCreateManyWithoutCompositionInput,
    BasketItemUpdateManyWithoutCompositionInput,
    DeliveryLocation,
    DeliveryUpdate, GetProducts, GetProductsVariables
} from '__generated__/types';
import {compact, filter, map, sum} from "lodash";
import {amountWithUnit, formatAsPoints} from "../../../../shared/utils/unit.utils";
import RoundImage from "../../../../shared/components/RoundImage";
import {responsiveSizeClasses} from "../../../../shared/constants";
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import {Button} from "primereact/button";
import {DeliveryUpdateMutation} from "../../../../shared/queries/productOrder.gql";
import {
    basketItemsForDelivery,
    updateBasketCompositionForFlexDelivery
} from "../../../../shared/utils/basketComposition.utils";
import {
  findProductPriceForClientGroupCode,
  getBasketSize,
  portionsBasedOnProductOrderAmount,
  portionsFieldKeyForSize, product_hasAvailability,
  productIsAvailableAsAlternativeUnitFor,
  quantityFieldKeyForSize
} from "../../../../shared/utils/product.utils";
import ProductQuantityInput from "../../../../shared/components/ProductQuantityInput";
import {Toast} from "primereact/toast";
import {GetProductsQuery} from "../../../../shared/queries/product.gql";
import {useUserObject} from "../../../../shared/context/UserContext";

interface BasketCompositionForDeliveryProps {
    flexClientGroupCode: string;
    basketCompositionId: string | undefined;
    imageSize?: 'small';
    delivery: {
        id: string;
        deliveryLocation: DeliveryLocation | null;
    };
    product: {
        code: string | null;
    } | null;
    editable?: boolean;
    disableAdd?: boolean;
    hideImage?: boolean;
    showPortions: boolean;
    onChange?: (productId: string, quantity: number, portions: number) => boolean;
}

const BasketCompositionForDelivery = (props: BasketCompositionForDeliveryProps) => {
    const {activeFarm} = useUserObject();
    const {data} = useQuery<BasketComposition>(BasketCompositionQuery, {variables: {id: props.basketCompositionId}});
    const [updateDelivery] = useMutation<DeliveryUpdate>(DeliveryUpdateMutation, {refetchQueries: "active"});
    const [saving, setSaving] = useState<boolean>(false);
    const {product, flexClientGroupCode} = props;
    const {deliveryLocation} = props.delivery;

    const getProductsVariables: GetProductsVariables = {
        where: {},
        farmId: activeFarm?.id || '',
        clientGroupCode: flexClientGroupCode,
    };

    const {data: productsData} = useQuery<GetProducts>(GetProductsQuery, {
        variables: getProductsVariables
    });
    const products = compact(productsData?.products);

    const toast = useRef<Toast>(null);

    if (product) {
        let quantityFieldKey = quantityFieldKeyForSize(getBasketSize(product));
        let portionsFieldKey = portionsFieldKeyForSize(getBasketSize(product));

        let basketItems = basketItemsForDelivery(data?.basketComposition?.items, product, deliveryLocation);

        let sizeKey = getBasketSize(product);

        let totalPortions = sum(map(basketItems, portionsFieldKey));

        const onDelete = (basketItem: BasketComposition_basketComposition_items) => (e: {
            currentTarget: HTMLElement | undefined
        }) => {
            confirmPopup({
                target: e.currentTarget,
                acceptLabel: 'Ja',
                rejectLabel: 'Nee',
                message: 'Ben je zeker dat je dit product wil verwijderen?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    let basketComposition = data?.basketComposition;
                    let deliveryId = props.delivery.id;

                    let itemsUpdateForFixedComposition = (
                        existingItems: BasketItemCreateManyWithoutCompositionInput | null | undefined,
                    ): BasketItemCreateManyWithoutCompositionInput => {
                        return {
                            ...existingItems,
                            create: filter(compact(existingItems?.create), item => item.id !== basketItem.id),
                        };
                    };
                    let itemsUpdateForCustomerBasedComposition = (): BasketItemUpdateManyWithoutCompositionInput => {
                        return {
                            delete: [{id: basketItem.id}]
                        }
                    };

                    if (deliveryLocation) {
                        updateBasketCompositionForFlexDelivery(
                            deliveryId,
                            basketComposition,
                            sizeKey,
                            product,
                            deliveryLocation,
                            updateDelivery,
                            itemsUpdateForFixedComposition,
                            itemsUpdateForCustomerBasedComposition
                        );
                    }
                },
                reject: () => {
                }
            });
        }

        return <div>
            <Toast ref={toast} position={'bottom-left'}/>

            <div className="flex align-items-stretch flex-column">
                {basketItems.map(basketItem => {
                        const product = products.find(p => p.id === basketItem.product.id)
                        if (!product) {
                            return null;
                        }
                        let quantityFieldValue = quantityFieldKey ? basketItem[quantityFieldKey] : 0;

                        const imgSizeClass = props.imageSize === 'small' ? "sm" : "lg";

                        let productPriceForFlex = findProductPriceForClientGroupCode(product, props.flexClientGroupCode);
                        return <div className="p-2 flex align-items-center border-bottom-1 border-200"
                                    style={{maxWidth: '400px'}}>
                            {!props.hideImage && <div className={responsiveSizeClasses[imgSizeClass]}>
                                <RoundImage size={imgSizeClass} src={product.image}/>
                            </div>}
                            <div className="ml-2 flex-grow-1">
                                <div className="font-bold color-green pb-1">{product.name}</div>
                                <div className="pl-2 flex align-items-center justify-content-between">
                                    <div className="flex-grow-1">
                                        {props.editable
                                            ? <ProductQuantityInput
                                                clientGroupId={productsData?.clientGroup?.id||''}
                                                value={quantityFieldValue}
                                                product={product}
                                                hideSaveButton
                                                disableAdd={props.disableAdd || !product_hasAvailability(product, props.flexClientGroupCode)}
                                                allowZero={false}
                                                onChange={(quantity) => {
                                                    if (props.onChange) {
                                                        let portions = portionsBasedOnProductOrderAmount(product, productPriceForFlex, quantity || 0);
                                                        let b = props.onChange(product.id, quantity || 0, portions);
                                                        return b;
                                                    } else {
                                                        return true;
                                                    }
                                                }
                                                }
                                                disabled={false}
                                            />
                                            :
                                            <div>{product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, productPriceForFlex?.clientGroup?.id)
                                                ? amountWithUnit(quantityFieldValue / product.avgWeight, product.alternativeUnit)
                                                : amountWithUnit(quantityFieldValue, product.unit)
                                            }</div>
                                        }

                                    </div>
                                    {props.showPortions && <div className=" text-800 font-medium">
                                        <span
                                            className="pl-2">= {portionsFieldKey && formatAsPoints(basketItem[portionsFieldKey])}</span>
                                    </div>}
                                    {props.editable && <div className="ml-2">
                                        <ConfirmPopup/>
                                        <Button
                                            icon={'pi pi-trash'}
                                            className='p-button-text p-button-danger p-0 w-fit'
                                            onClick={onDelete(basketItem)}/>
                                    </div>}
                                </div>
                            </div>
                        </div>;
                    }
                )}

                {props.showPortions &&
                    <div className="text-right font-medium text-lg pr-6 pt-1 border-top-1 border-200">
                        Totaal: <span className="pl-1 font-bold">{formatAsPoints(totalPortions)}</span>
                    </div>}
                {props.editable && <div className="mt-3 pt-3 border-300 border-top-1">
                    <ConfirmPopup/>
                    <Button
                        loading={saving}
                        disabled={saving}
                        className="w-full"
                        label="Pakket opslaan"
                        onClick={() => {
                            setSaving(true);
                            setTimeout(() => {
                                setSaving(false);
                                toast.current && toast.current.show({
                                    severity: 'success',
                                    summary: 'Je pakket werd opgeslagen',
                                    life: 3000
                                });
                            }, 1000);
                        }}/>
                </div>}
            </div>
        </div>;
    }
    return null;
};

export default BasketCompositionForDelivery;
