import {gql} from "@apollo/client";
import {ProductFields_basicFragment} from "../../shared/queries/product.gql";

export const ProductOrdersForResetQuery = gql`
    ${ProductFields_basicFragment}
    query ProductOrdersForReset {
        productOrders(
            where: {
                clientOrder: {client: {farm: {code: "groentegeweld"}}}
                product: {
                    category: {
                        code: "pakketten"
                    }
                    code_starts_with: "pakketten-2023-fixed-"
                    code_not_starts_with: "pakketten-2023-fixed-winter-"
                }
                deliveries_some: {
                    deliveryDate: null
                    cancelled: false
                }
            }
        ) {
            id
            clientOrder {client {name firstName} deliveryEndDate deliveryDate}
            deliveries(
                orderBy: plannedDeliveryDate_DESC
                where: {
                    deliveryDate: null
                    cancelled: false
                }
            ) {
                plannedDeliveryDate
                basketComposition { id type }
            }
            product { ...ProductFields_basic }
            portions
            usedPortions
        }
    }
`;
