import React from 'react';
import DashboardItemYearOverview from './BasketComposerPage/components/Dashboard/DashboardItemYearOverview';
import BasketsManagementPageHeading from "./BasketsManagementPageHeading";

const BasketsManagement = () => {
  return <div>
    <BasketsManagementPageHeading/>
    <div className="grid">
      {/*<div className="grid col-3 align-items-stretch">*/}
        {/*<div className="col-12"><DashboardItemNextBasket/></div>*/}
        {/*<div className="col-12"><DashboardItemBasketsSummary/></div>*/}
      {/*</div>*/}
      <div className="col-9"><DashboardItemYearOverview/></div>
    </div>
  </div>;
};

export default BasketsManagement;
