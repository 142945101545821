import React from 'react';
import SalesPageHeading from "./SalesPageHeading";

const Sales = () => {
  return <div>
    <SalesPageHeading/>
  </div>;
};

export default () => <Sales/>;
