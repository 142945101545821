import React from 'react';
import {
  BasketCompositions,
  BasketCompositions_basketCompositions,
  BasketCompositionsVariables,
  DeliveryLocation
} from "../../../../../__generated__/types";
import {Chart} from "primereact/chart";
import {chain, compact, range} from "lodash";
import {momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {totalPortions} from "../../../../../shared/utils/basketComposition.utils";
import {BasketSize} from "../../../../../shared/utils/product.utils";

import chartTrendline from 'chartjs-plugin-trendline';
import {useQuery} from "@apollo/client";
import {BasketCompositionsQuery} from "../../../../../shared/queries/basketComposition.gql";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface BasketCompositionPointsGraphProps {
  basketCompositionsSelector: Omit<BasketCompositionsVariables, "farmId">;
}

const BasketCompositionPointsGraph = ({basketCompositionsSelector}: BasketCompositionPointsGraphProps) => {
  const {activeFarm} = useUserObject();

  let variables: BasketCompositionsVariables = {...basketCompositionsSelector, farmId: activeFarm?.id||''};
  const {data} = useQuery<BasketCompositions>(BasketCompositionsQuery, {
    variables
  });

  let basketCompositions = compact(data?.basketCompositions);

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          fontColor: textColor
        }
      }
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500
          }
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        stacked: false,
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      }
    }
  };

  const dataForYear = (year: number, basketSize: BasketSize) => range(1, 52)
    .map(weekNumber => {
      return chain(basketCompositions)
        .filter(basketComposition =>
          momentFromIso8601(basketComposition.deliveryWeek).year() === year &&
          momentFromIso8601(basketComposition.deliveryWeek).isoWeek() === weekNumber
        )
        .map(basketComposition => totalPortions(compact(basketComposition.items), basketSize, DeliveryLocation.WEELDE))
        .mean()
        .value()
    });

  const chartData = {
    labels: range(1, 52),
    datasets: [
      {
        label: '2022 Klein',
        backgroundColor: documentStyle.getPropertyValue('--blue-100'),
        borderColor: documentStyle.getPropertyValue('--blue-100'),
        data: dataForYear(2022, 'medium'),
      },
      {
        label: '2023 Klein',
        backgroundColor: documentStyle.getPropertyValue('--purple-500'),
        borderColor: documentStyle.getPropertyValue('--purple-500'),
        data: dataForYear(2023, 'medium')
      }
    ]
  };

  return <div>
    <Chart
      type={"bar"}
      data={chartData}
      options={chartOptions}
      plugins={[chartTrendline]}
    />
  </div>;
};

export default BasketCompositionPointsGraph;
