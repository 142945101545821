import React from 'react';
import {render} from 'react-dom';
import moment from "moment";

import App from './App';

import 'moment/min/locales'
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import 'index.css';
import 'shared/index.css';

import {BrowserRouter} from "react-router-dom";
import Auth0ProviderWithHistory from "./shared/Auth0ProviderwithHistory";
import jwt from 'jsonwebtoken';
import {nl} from "./shared/utils/date.utils";
import {addLocale} from "primereact/api";
import {init} from 'emailjs-com';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import appConfig from "./config.app";
import numeral from "numeral";

init("user_Y9kGCPQbVv3OzSPh4cNJb");

moment.locale(['nl']);
addLocale('nl', nl);

numeral.register('locale', 'nl-be', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : function (number) {
    return number === 1 ? 'ste' : 'de';
  },
  currency: {
    symbol: '€'
  }
});
numeral.locale("nl-be");

// const getToken = (serviceName: string, stageName: string): string | undefined => {
//   const data = {
//     data: {
//       service: `${serviceName}@${stageName}`,
//       roles: ['admin'],
//
//     },
//   };
//   return jwt.sign(data, 'softFarmsGgw168!', {
//     expiresIn: '7d',
//   })
// };
//
const httpLink = createHttpLink({
  uri: appConfig.graphQLHost,
  //uri: '/api/myEndpoint/__graphql',
  credentials: 'same-origin',
  // headers: {
  //   Authorization: `Bearer ${getToken('default', 'default')}`
  // }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//     // split based on operation type
//     ({ query }) => {
//         const definition = getMainDefinition(query);
//         return (
//             definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//         );
//     },
//     wsLink,
//     httpLink,
// );

const client = new ApolloClient({
  //uri: '/default/default',
  cache: new InMemoryCache(),
  link: httpLink,
});

const WrappedApp = (
  <ApolloProvider client={client}>
    <BrowserRouter basename={'/'}>
      <Auth0ProviderWithHistory>
        <App/>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </ApolloProvider>
);

render(WrappedApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
