import React, { InputHTMLAttributes, useState } from 'react';
import { chain } from "lodash";
import { ProductCategoriesData } from "../../__generated__/types";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@apollo/client";
import { ProductCategoriesQuery } from "./ProductCategoryDropdown.gql";

interface ProductCategoryDropdownProps extends InputHTMLAttributes<HTMLSelectElement> {
  onValueChange: (value: string) => void;
}

const ProductCategoryDropdown = (props: ProductCategoryDropdownProps) => {
  const {data} = useQuery<ProductCategoriesData>(ProductCategoriesQuery);
  const [selectedValue, setSelectedValue] = useState<string | readonly string[] | number | undefined>(props.value);

  return <Dropdown
    value={selectedValue}
    style={{width: '100%'}}
    options={
      chain(data?.productCategories)
        .compact()
        .orderBy(['name'])
        .map(m => ({
          label: m.name,
          value: m.id
        }))
        .value()
    }
    onChange={e => {
      setSelectedValue(e.value);
      props.onValueChange(e.value);
    }}
  />;
};

export default ProductCategoryDropdown;
