import React, {ChangeEvent, useState} from 'react';
import {DataTable, DataTableHeaderTemplateOptions} from "primereact/datatable";
import {
    ClientGroups,
    GetProductsVariables,
    Products,
    Products_products,
    ProductStatus,
    ProductType
} from "../../../__generated__/types";
import {compact} from "lodash";
import {Column} from "primereact/column";
import Availability from "../ProductManagement/ProductList/Cells/Availability";
import StockCell from "./components/cells/StockCell";
import {amountWithUnit} from "../../../shared/utils/unit.utils";
import UpdateStockAction from "./components/cells/UpdateStockAction";
import {useQuery} from "@apollo/client";
import {GetProductsQuery} from "../../../shared/queries/product.gql";
import {ClientGroupsQuery} from "../../../shared/queries/clientGroup.gql";
import {FilterMatchMode} from "primereact/api";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {useUserObject} from "../../../shared/context/UserContext";

interface StockTableProps {
    flexClientGroupCode: string;
}

const StockTable = (props: StockTableProps) => {
    const {activeFarm} = useUserObject();

    const variables: GetProductsVariables = {
        clientGroupCode: props.flexClientGroupCode,
        where: {
            status: ProductStatus.ACTIVE,
            type: ProductType.SIMPLE,
        },
        farmId: activeFarm?.id || ''
    };
    const {data} = useQuery<Products>(GetProductsQuery, {
        variables
    });

    const {data: clientGroupsData} = useQuery<ClientGroups>(ClientGroupsQuery, {variables: {farmId: activeFarm?.id}});

    const [globalFilterValue, setGlobalFilterValue] = useState<string>();
    const [filters, setFilters] = useState<{ [key: string]: { value: null | any, matchMode: FilterMatchMode } }>({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    });

    const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = {...filters};

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = (options: DataTableHeaderTemplateOptions<Products_products[]>) =>
        <div className="p-0 m-0 surface-0 border-0">
            <Toolbar
                className="p-toolbar p-component p-0 m-0 surface-0 border-0"
                start={<div className="flex justify-content-end">
          <span className="p-input-icon-left mr-2">
              <i className="pi pi-search"/>
              <InputText type="search" value={globalFilterValue || ''} onChange={onGlobalFilterChange}
                         placeholder="Search..."/>
          </span>
                </div>
                }
            />
        </div>;

    return <DataTable<Products_products[]>
        className="sf-table"
        value={compact(data?.products)}
        header={header}

        globalFilter={globalFilterValue}
        globalFilterFields={["name"]}

        sortField="name"
        sortMode="single"
        sortOrder={1}
    >
        {compact(clientGroupsData?.clientGroups)
            .filter(clientGroup => clientGroup?.code && ["zelfoogst", "standaard", "groentegeweld-flex", "restaurants", "detailhandel",].includes(clientGroup.code))
            .map(clientGroup => {
                return <Column
                    frozen
                    key={'clientGroupAvailability_' + clientGroup.code}
                    field={'productAvailabilities'}
                    bodyClassName="w-2rem max-w-2rem"
                    // style={{maxWidth: "2rem", width: "2rem"}}
                    body={(product: Products_products) =>
                        <Availability
                            className="w-1-5rem h-1-5rem"
                            product={product}
                            clientGroup={clientGroup}
                        />}
                />;
            })}
        <Column
            header="Stock"
            field="latestStock"
            style={{maxWidth: "5rem"}}
            body={(product: Products_products) => <StockCell product={product}/>}
            sortable
        />
        <Column
            header="Product"
            field="name"
            sortable
            body={(product: Products_products) => {
                const {amount, unit, alternativeUnit, avgWeight} = product;
                let unitView;
                if (unit) {
                    let availableAsAlternativeUnit = compact(product.availableAsAlternativeUnitFor).length > 0;
                    unitView = <div className="text-xs text-500">
                        <div>{amount && amountWithUnit(amount, unit)}</div>
                        {availableAsAlternativeUnit && avgWeight && amount && <div
                            className="text-xs text-700">= {amountWithUnit(amount / avgWeight, alternativeUnit)}</div>}
                    </div>;
                } else {
                    unitView = <div/>;
                }
                return <div>
                    <div>{product.name}</div>
                    {unitView}
                </div>
            }}
        /><Column body={(product) => <UpdateStockAction product={product}/>}/>
    </DataTable>;
};

export default StockTable;
