import gql from "graphql-tag";

export const FarmConfigurationQuery = gql`
  query FarmConfiguration($id: ID!) {
    farm(where: {id:$id}) {
      configuration {
        id
        code
        value
      }
    }
  }
`;

export const FarmConfigurationsByCodeQuery = gql`
  query FarmConfigurationsByCode($farmId: ID!, $code: String!) {
      farmConfigs(where: {farm:{id:$farmId}, code: $code}) {
        id
        code
        value
      }
  }
`;

export const FarmConfigurationUpsertMutation = gql`
  mutation UpsertFarmConfiguration(
    $id: ID!,
    $createData: FarmConfigCreateInput!,
    $updateData: FarmConfigUpdateInput!
  ) {
    upsertFarmConfig(
      where: {id: $id}
      update: $updateData
      create: $createData
    ) {
      id
      code
      value
    }
  }`;

export const FarmConfigurationDeleteMutation = gql`
    mutation DeleteFarmConfiguration(
        $id: ID!
    ) {
        deleteFarmConfig(
            where: {id: $id}
        ) {
            id
        }
    }`;
