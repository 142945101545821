import {first} from "lodash";
import {momentFromIso8601} from "./date.utils";
import {TODAY} from "../config";

export const nextDelivery = <T extends {
  plannedDeliveryDate?: string | null | undefined,
  deliveryDate?: string | null | undefined
}>(deliveries: T[]): T | undefined => {
  return first(deliveries.filter(delivery => delivery.plannedDeliveryDate && delivery.deliveryDate === null && momentFromIso8601(delivery.plannedDeliveryDate).isAfter(TODAY)))
}
