import { gql } from "@apollo/client";

export const PricelistQuery = gql`
  query PricelistData($farmId: ID!) {
    clientGroups: clientGroups(where: {farm: {id: $farmId}}) {
      id
      name
      code
      profitMargin
      order
      default
    }
    products(where: {
      # id_in: ["ckoddd8l3051i0775wjaxjdbf","cl6y1thnk7pvh0775rhkqwisa","clgh39eo50tzz0775fhoetl0x","ckmv5uqzc4lnv0774m5jf45h3"]
      status_in: [ACTIVE, HIDDEN]
      farm: {id: $farmId}
      }
      ) {
      id
      code
      name
      image
      unit
      amount
      availableAsAlternativeUnitFor
alternativeUnit
      minOrderQuantity

      category {
        id
        name
      }
      productPrices(where: {endDate: null }) {
        id
        clientGroup { id code default }
        value
        base
        endDate
        note
        unit
      }
      productAvailabilities(where: {endDate: null }) {
        id
        clientGroup { id code }
        startDate
        endDate
        note
      }
      productPromos {
        id
        startDate
        endDate
      }
      portionWillem
      portionDirk
      type
      groupName
      extra
      avgWeight
      productAvailabilities(where: {endDate: null }) {
        id
        clientGroup { id code }
        startDate
        endDate
        note
      }
      status
      vat
    }
  }
`;
