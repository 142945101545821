import gql from "graphql-tag";

export const CreateUserMutation = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(
      data: $data
    ) {
      id
    }
  }
`;

export const UserDetailsQuery = gql`
query UserDetails($id: ID) {
  user(where: {id: $id}) {
    id
    auth0userId
    name
    firstName

    email
    secondaryEmail

    street
    streetNumber
    city
    cityPostalCode
    phone
    dateOfBirth
  }
}
`;
