import React from 'react';
import {
  ActiveSubscriptions_clientOrders_productOrders,
  Product,
  ProductOrderUpdate,
  ProductOrderUpdateVariables
} from "../../../../../../__generated__/types";
import {SelectButton} from "primereact/selectbutton";
import {ProductQuery} from "../../../../../../shared/queries/product.gql";
import {useMutation, useQuery} from '@apollo/client';
import {compact} from "lodash";
import {ProductOrderUpdateMutation} from "../../../../../../shared/queries/productOrder.gql";

interface FrequencyColumnProps {
  productOrder: ActiveSubscriptions_clientOrders_productOrders;
}

const FrequencyColumn = (props: FrequencyColumnProps) => {
  const {data: productData} = useQuery<Product>(ProductQuery, {variables: {id: props.productOrder.product?.id}});
  const [productOrderUpdate] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation, {
    refetchQueries: "active",
  });

  const updateProductOrderFrequency = (id: string, frequency: string) => {
    const variables: ProductOrderUpdateVariables = {
      where: {id},
      data: {
        frequency
      }
    };
    productOrderUpdate({variables});
  };

  return <div>
    {props.productOrder.frequency}

    {!props.productOrder.frequency &&
      <SelectButton
        optionLabel={'code'}
        optionValue={'code'}
        options={compact(productData?.product?.frequencies)}
        onChange={(e) => {
          updateProductOrderFrequency(props.productOrder.id, e.value);
        }}
      />
    }
  </div>;
};

export default FrequencyColumn;
