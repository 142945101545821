import {chain, compact} from "lodash";

export const productCategoryIcon = (category?: { code?: string | null, } | null): string | undefined => {
  return category?.code === 'eieren'
    ? 'eggs.svg'
    : category?.code === 'aardappelen'
      ? 'potatoes.svg'
      : category?.code === 'uien'
        ? 'onions.svg'
        : category?.code === 'ambassadeur'
          ? 'ambassador.svg'
          : undefined;
};


export const productCategoriesTree = (productCategories: ({
  id: string,
  name: string,
  category: { id: string } | null,
} | null)[] | undefined) => {
  let productCategoriesList = compact(productCategories);
  return chain(productCategoriesList)
    .filter(productCategory => productCategory.category === null)
    .map(mainProductCategory => ({
      key: mainProductCategory.id,
      label: mainProductCategory.name,
      data: mainProductCategory,
      children: productCategoriesList
        .filter(po => po.category?.id === mainProductCategory.id)
        .map(subProductCategory => ({
          key: subProductCategory.id,
          label: subProductCategory.name,
          data: subProductCategory,

        }))
      ,
    }))
    .value();
};

