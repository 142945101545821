import React from 'react';
import BasketCompositionForDelivery
    from "../../../StartPage/Baskets/BasketCompositionForDelivery/BasketCompositionForDelivery";
import {useQuery} from "@apollo/client";
import {BasketCompositionType, ClientOrder, FarmConfigurationsByCode} from "../../../../__generated__/types";
import {ClientOrderQuery} from "../../../../shared/queries/clientOrder.gql";
import {compact, first} from "lodash";
import {basketProductOrderHasInsufficientPoints_warning} from "../../../../utils/clientOrder.utils";
import {findBasketProductOrder} from "../../../../shared/utils/productOrder.utils";
import {totalPortions} from "../../../../shared/utils/basketComposition.utils";
import {getBasketSize} from "../../../../shared/utils/product.utils";
import {nextDelivery} from "../../../../shared/utils/clientOrder.utils";
import {FarmConfigurationsByCodeQuery} from "../../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../../shared/utils/farmConfig.utils";
import {useUserObject} from "../../../../shared/context/UserContext";

interface CustomerBasketCompositionProps {
    clientOrderId: string;
    onChange: (productId: string, quantity: number, portions: number) => boolean;
}

const CustomerBasketComposition = (props: CustomerBasketCompositionProps) => {
    const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});

    let {activeFarm} = useUserObject();

    const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

    let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

    let basketProductOrder = findBasketProductOrder(data?.clientOrder?.productOrders);
    let deliveries = compact(basketProductOrder?.deliveries);
    const firstUndelivered = nextDelivery(deliveries);

    let assignedBasketComposition = firstUndelivered?.basketComposition;
    const fixedBasketCompositionIsAssigned = assignedBasketComposition?.type === BasketCompositionType.FIXED;

    // let additionalPortions = totalPortions(firstUndelivered?.basketComposition?.items, getBasketSize(basketProductOrder?.product), firstUndelivered?.deliveryLocation);

    return <div>
        <div className="text-xl font-medium">
            {fixedBasketCompositionIsAssigned ? 'Het voorgestelde pakket' : 'Mijn samengesteld pakket'}
        </div>
        {fixedBasketCompositionIsAssigned &&
            <div>
                <div className="text-sm text-800">Wijzig je niets dan ontvang je het volgende vast pakket.</div>
            </div>
        }

        {firstUndelivered && basketProductOrder && data?.clientOrder &&
            <BasketCompositionForDelivery
                flexClientGroupCode={flexClientGroupCode}
                product={basketProductOrder.product}
                delivery={firstUndelivered}
                showPortions={true}
                basketCompositionId={firstUndelivered.basketComposition?.id}
                editable
                disableAdd={basketProductOrderHasInsufficientPoints_warning(basketProductOrder)}
                onChange={props.onChange}
            />
        }

        {basketProductOrder && !assignedBasketComposition &&
            <div className="p-2 bg-yellow-100 text-yellow-800 mx-1">Dit pakket is nog niet samengesteld.</div>
        }
    </div>;
};

export default CustomerBasketComposition;
