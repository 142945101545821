import React from 'react';
import {Dialog} from "primereact/dialog";
import {
  Clients_clients,
  ClientsForMailChimp,
  ClientsForMailChimp_clients,
  ClientsForMailChimp_clients_clientOrders_productOrders
} from "../../../../__generated__/types";
import {useQuery} from '@apollo/client';
import {ClientsForMailChimpQuery} from "../../../../shared/queries/client.gql";
import {chain, compact, first, flatten, keys, map, orderBy, remove, sortBy} from 'lodash';
import {productOrderIsTrial,} from "../../../../utils/productOrder.utils";
import {toTag} from "../../../../shared/utils/deliveryLocation.utils";
import {InputTextarea} from "primereact/inputtextarea";
import {
  filterBasketProductOrders, filterNextDeliveryDatesForBasketProductOrders,
  findBasketProductOrder,
  findFixedBasketProductOrder,
  findFlexBasketProductOrder,
  findNextDeliveryDateForBasketProductOrder,
  findProductOrderByProductCodePrefix,
  findSelfHarvestProductOrder
} from "../../../../shared/utils/productOrder.utils";
import {asDayFullDdMmYyyy} from "../../../../shared/utils/date.utils";
import {filterFixed15BasketClientOrders} from "../../../../utils/clientOrder.utils";
import {useUserObject} from "../../../../shared/context/UserContext";
import moment from "moment";

interface ExportToMailchimpProps {
  clients: Clients_clients[];
  onHide: () => void;
}

const tagBasedOnClientGroupCode = (client: { group: { code: string | null } }) => {
  if (client.group.code === "restaurants") {
    return "groep_horeca";
  } else if (client.group.code === "detailhandel") {
    return "groep_detailhandel";
  } else if (client.group.code === "standaard") {
    return "groep_particulier";
  } else if (client.group.code === "kinderopvang") {
    return "groep_kinderopvang";
  } else if (client.group.code === "boeren") {
    return "groep_boeren";
  }
};

const deliveryLocationTag = (productOrder: ClientsForMailChimp_clients_clientOrders_productOrders) =>
  toTag(first(productOrder.deliveries)?.deliveryLocation);

const addBasketSpecificTags = (productOrders: ClientsForMailChimp_clients_clientOrders_productOrders[], type: 'flex' | 'fixed'): string[] => {
  let tags = [];
  tags.push(`pakketten-2023_${type}`);

  if (findProductOrderByProductCodePrefix(productOrders, `pakketten-2023-${type}-trial`)) {
    tags.push(`pakketten-2023_${type}Proef`);
    tags.push(`pakketten-2023_proef`);
  }
  if (findProductOrderByProductCodePrefix(productOrders, `pakketten-2023-${type}-15`)) {
    tags.push(`pakketten-2023_${type}15`);
  }
  if (findProductOrderByProductCodePrefix(productOrders, `pakketten-2023-${type}-30`)) {
    tags.push(`pakketten-2023_${type}30`);
  }
  if (findProductOrderByProductCodePrefix(productOrders, `pakketten-2023-${type}-40`)) {
    tags.push(`pakketten-2023_${type}40`);
  }
  return tags;
};

const tagIfClientHasActiveBasket = (client: ClientsForMailChimp_clients) => {
  let productOrders = flatten(compact(client.clientOrders).map(co => compact(co.productOrders)))
    .filter(productOrder =>
      !productOrder.product?.code
      || (productOrder.product.code.indexOf('winter') === -1)
    );
  let tags = [];

  if (findFlexBasketProductOrder(productOrders)) {
    tags.push(...addBasketSpecificTags(productOrders, 'flex'));
  }

  if (findFixedBasketProductOrder(productOrders)) {
    tags.push(...addBasketSpecificTags(productOrders, 'fixed'));
  }

  let basketProductOrder = findBasketProductOrder(productOrders);
  if (basketProductOrder) {
    tags.push("pakketten-2023");
    tags.push(deliveryLocationTag(basketProductOrder))
  }

  let selfHarvestProductOrder = findSelfHarvestProductOrder(productOrders);
  if (selfHarvestProductOrder) {
    tags.push("zelfoogst-2023");
    if (productOrderIsTrial(selfHarvestProductOrder)) {
      tags.push("zelfoogst-2023_proef")
    }
  }
  return tags;
};

const ExportToMailchimp = (props: ExportToMailchimpProps) => {
  let {activeFarm} = useUserObject();
  const {data} = useQuery<ClientsForMailChimp>(ClientsForMailChimpQuery, {
    variables: {
      farmId: activeFarm?.id || ''
    }
  });
  const taggedClients = compact(data?.clients)
    .map(client => {
      let updatedTags = compact(flatten([
        tagBasedOnClientGroupCode(client),
        tagIfClientHasActiveBasket(client),
      ]));

      if (updatedTags.length === 1 && updatedTags[0] === 'groep_particulier') {
        updatedTags = [];
      }

      return {...client, tags: updatedTags};
    });
  let emails = chain(taggedClients)
    .filter(client => client.tags.length > 0)
    .map(client => {

      let emailRecords = [];
      let allProductOrders: ClientsForMailChimp_clients_clientOrders_productOrders[] = flatten(compact(map(client.clientOrders, 'productOrders')));
      remove(allProductOrders, (productOrder) => {
        return [
          "pakketten-2023-fixed-winter-medium", "pakketten-2023-fixed-winter-large",
          "pakketten-2023-flex-winter-medium", "pakketten-2023-flex-winter-large",
        ].includes(productOrder.product?.code || '');
      });
      let basketProductOrders = filterBasketProductOrders(allProductOrders);

      // TODO: need multiple
      let nextBasketDeliveryDates = sortBy(filterNextDeliveryDatesForBasketProductOrders(basketProductOrders));
      nextBasketDeliveryDates = nextBasketDeliveryDates.filter(nextDeliveryDate => nextDeliveryDate.isAfter(moment()))

      let nextDelivery = "-";


      if (nextBasketDeliveryDates.length > 0) {
        if (nextBasketDeliveryDates[0].year() === 2024) {
          nextDelivery = asDayFullDdMmYyyy(nextBasketDeliveryDates[0]) || '-';
        }
      }

      if (client.email) {
        emailRecords.push(
          `${client.email}\t${client.name}\t${client.firstName || ''}\t${nextDelivery}\t${client.tags.join(', ')}`
        );
      }
      if (client.secondaryEmail) {
        client.secondaryEmail.split(',')
          .forEach(secondaryEmail => emailRecords.push(
            `${secondaryEmail}\t${client.name}\t${client.firstName || ''}\t${nextDelivery}\t${client.tags.join(', ')}`
          ));
      }
      return emailRecords;
    })
    .flatten()
    .compact()
    .reduce((allEmails, emailRecord) => `${allEmails}\n${emailRecord}`, "")
    .value();

  let exportString = `email\tlastName\tfirstName\tnextBasket\ttags\n${emails}`;

  let tagsWithNrOfRecipients = chain(taggedClients)
    .map('tags')
    .flatten()
    .countBy()
    .value();
  return <Dialog
    visible
    onHide={() => props.onHide()}
    className="w-auto"
  >
    <div className="grid">
      <div className="col-6">
        <div className="grid">
          {orderBy(keys(tagsWithNrOfRecipients))
            .map(tag =>
              <>
                <div className="col-9">
                  <div className="pr-2 white-space-nowrap">{tag}</div>
                </div>
                <div className="col-3">{tagsWithNrOfRecipients[tag]}</div>
              </>
            )}
        </div>
      </div>
      <div className="col-6">
        <InputTextarea
          cols={100}
          rows={60}
          value={exportString}
        />
      </div>
    </div>
  </Dialog>;
};

export default ExportToMailchimp;
