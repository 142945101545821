import React from 'react';

// async function sendCreateUserRequest(client: Client_client) {
//   const tokenResponse = await fetch('https://dev-syqz0u3n.eu.auth0.com/oauth/token', {
//     method: 'POST',
//     headers: {'content-type': 'application/json'},
//     body: '{"client_id":"qBTfsct2hygMWSHOXZHbfVHdw8B5jewv","client_secret":"leqGNu0qYyFZBBDBF6DsnaQ1hwcINzLTwgv_XWp9gNEy8IW6eUPqSR7ejHwVNw4u","audience":"https://auth.softfarms.be/","grant_type":"client_credentials"}'
//   });
//
//   const tokenBody = await tokenResponse.json();
//   const metadataResponse = await fetch(`http://localhost:3101/api/reset-password`, {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${tokenBody['access_token']}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       user_id: client.auth0userId,
//     })
//   });
//
//   return await metadataResponse.json();
// }

const InitUser = () => {

  return <div>

  </div>;
};

export default InitUser;
