import React from 'react';
import {ClientOrder,} from "../../../../__generated__/types";
import {compact} from "lodash";
import styled from "styled-components";
import ClientInformation from "../ClientInformation/ClientInformation";
import SubscriptionInvoicing from '../SubscriptionInvoicing/SubscriptionInvoicing';
import {useQuery} from "@apollo/client";
import {ClientOrderQuery} from "../../../../shared/queries/clientOrder.gql";
import SubscriptionActions from "../SubscriptionActions/SubscriptionActions";
import ChangeStartDateButton from "./components/ChangeStartDateButton/ChangeStartDateButton";
import ChangeDeliveryLocationButton from "./components/ChangeDeliveryLocationButton/ChangeDeliveryLocationButton";
import SubscriptionProductOrderList from "./components/SubscriptionProductOrderList/SubscriptionProductOrderList";
import ClientOrderDetail from './components/ClientOrderDetail/ClientOrderDetail';
import ChangeDeliveryEndDateButton from "./components/ChangeDeliveryEndDateButton/ChangeDeliveryEndDateButton";

interface SubscriptionDetailProps {
  clientOrderId: string;
}

const Container = styled.div`
  width: 100%;
`;

const SubscriptionDetail = (props: SubscriptionDetailProps) => {
  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});

  let clientOrder = data?.clientOrder;
  if (clientOrder) {
    return <Container>
      <div className="xl:col-12 grid grid-nogutter">
        <div className="surface-0 p-4 border-200 border-1 align-items-stretch w-full">
          <ClientInformation clientId={clientOrder.client.id} productOrders={compact(clientOrder.productOrders)}/>
        </div>
      </div>
      <div className="xl:col-12 grid grid-nogutter">
        <div className="surface-0 p-4 border-200 border-1 w-full">
          <div className="font-medium text-2xl mb-3 color-orange uppercase">Abonnementsgegevens</div>
          <div className="m-3">
            <ClientOrderDetail clientOrder={clientOrder}/>
            <SubscriptionProductOrderList clientOrder={clientOrder}/>
            <ChangeStartDateButton clientOrder={clientOrder}/>
            <ChangeDeliveryEndDateButton clientOrder={clientOrder}/>
            {/*<MoveDeliveriesAfterDateButton clientOrder={clientOrder}/>*/}
            <ChangeDeliveryLocationButton clientOrderId={clientOrder.id}/>
          </div>
        </div>
      </div>
      <div className="lg:col-8 grid grid-nogutter">
        <div className="surface-0 p-4 border-200 border-1 w-full">
          <div className="font-medium text-2xl mb-3 color-orange uppercase">Facturatie</div>
          <div className="m-3"><SubscriptionInvoicing clientOrder={clientOrder}/></div>
        </div>
      </div>
      <div className="lg:col-2 lg:col-offset-10 text-right">
        <div className="m-3 mr-0"><SubscriptionActions clientOrder={clientOrder}/></div>
      </div>
    </Container>;
  }
  return null;
};

export default SubscriptionDetail;
