import React from 'react';
import {useQuery} from "@apollo/client";
import {Clients, Clients_clients, ClientsVariables} from "../../../__generated__/types";
import {ClientsQuery} from "../../../shared/queries/client.gql";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {compact} from "lodash";
import {useUserObject} from "../../../shared/context/UserContext";

interface ClientsDropdownProps {
  onChange: (client: Clients_clients | undefined | null) => void;
}

const ClientsDropdown = (props: ClientsDropdownProps) => {
  const {activeFarm} = useUserObject();
  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data} = useQuery<Clients>(ClientsQuery, {variables});

  return <Dropdown
    filter showClear filterBy="label"
    options={compact(data?.clients)
      .map(client => ({...client, label: `${client.name} ${client.firstName}`}))}
    optionLabel={'label'}
    optionValue={'id'}
    onChange={(e: DropdownChangeEvent) => {
      let client = compact(data?.clients).find(client => client.id === e.value);
      props.onChange(client);
    }}
  />
};

export default ClientsDropdown;
