import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {useSales} from "../sales-context";
import {compact, filter, get, map, sum} from 'lodash';
import {Column} from "primereact/column";
import {Clients, Clients_clients, ClientsVariables, ClientType} from "../../../__generated__/types";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Toolbar} from "primereact/toolbar";
import ClientDetail from "./components/ClientDetail";
import SalesPageHeading from "../SalesPageHeading";
import {ClientsQuery} from "../../../shared/queries/client.gql";
import {useQuery} from "@apollo/client";
import {formatCurrencySign} from "../../../shared/utils/currency.utils";
import ExportToMailchimp from "./components/ExportToMailchimp";
import {FilterMatchMode} from "primereact/api";
import ClientUpsertDialog from "./components/ClientUpsertDialog";
import {useUserObject} from "../../../shared/context/UserContext";

const ClientManagement = () => {
  const {clientGroups} = useSales();
  const {activeFarm} = useUserObject();
  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data: clientsData} = useQuery<Clients>(ClientsQuery, {variables});

  const [clients, setClients] = useState<Clients_clients[]>([]);
  const [clientDialog, setClientDialog] = useState(false);
  const [deleteClientDialog, setDeleteClientDialog] = useState(false);
  const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
  const [client, setClient] = useState<Partial<Clients_clients> | null>(null);
  const [selectedClients, setSelectedClients] = useState<Clients_clients[]>([]);
  const [expandedRows, setExpandedRows] = useState<any>();
  const [globalFilterValue, setGlobalFilterValue] = useState<string>();
  const [filters, setFilters] = useState<{ [key: string]: { value: null | any, matchMode: FilterMatchMode } }>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  });
  const [showMailchimpDialog, setShowMailchimpDialog] = useState<boolean>(false);

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<Clients_clients[]>>(null);

  const onGlobalFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = {...filters};

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    setClients(compact(clientsData?.clients));
  }, [clientsData]);

  const openNew = () => {
    setClient(null);
    setClientDialog(true);
  };

  const hideDeleteClientDialog = () => {
    setDeleteClientDialog(false);
  };

  const hideDeleteClientsDialog = () => {
    setDeleteClientsDialog(false);
  };

  const editClient = (client: Clients_clients) => {
    setClient({...client});
    setClientDialog(true);
  };

  const confirmDeleteClient = (client: Clients_clients) => {
    setClient(client);
    setDeleteClientDialog(true);
  };

  const deleteClient = () => {
    const matchingClient = clients.find((val: Clients_clients) => val.id !== client?.id);
    if (matchingClient) {
      setClient(client);
    }
    setDeleteClientDialog(false);
    setClient(null);
    toast.current && toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Client Deleted',
      life: 3000
    });
  };

  const deleteSelectedClients = () => {
    let _clients = clients.filter((val: Clients_clients) => !selectedClients.includes(val));
    setClients(_clients);
    setDeleteClientsDialog(false);
    setSelectedClients([]);
    toast.current && toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Clients Deleted',
      life: 3000
    });
  };

  const actionBodyTemplate = (rowData: Clients_clients) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"
                onClick={() => editClient(rowData)}/>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                onClick={() => confirmDeleteClient(rowData)}/>
      </React.Fragment>
    );
  }

  const header = <div className="p-0 m-0 border-0">
    <Toolbar
      className="p-toolbar p-component p-0 m-0 surface-0 border-0"
      start={<div>
        <span className="p-input-icon-left">
              <i className="pi pi-search"/>
              <InputText type="search" onInput={onGlobalFilterChange} value={globalFilterValue || ''}
                         placeholder="Search..."/>
          </span>
        <Button label="Nieuwe klant" icon="pi pi-plus" className="p-button-link p-ml-2" onClick={openNew}/>
      </div>
      }
      end={<div>
        <Button label="Exporteer naar Mailchimp" icon="pi pi-envelope" className="p-button-link p-ml-2"
                onClick={() => setShowMailchimpDialog(true)}/>

        {showMailchimpDialog && <ExportToMailchimp clients={clients} onHide={() => setShowMailchimpDialog(false)}/>}
      </div>}
    />
  </div>;

  const deleteClientDialogFooter = (
    <React.Fragment>
      <Button label="Nee" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClientDialog}/>
      <Button label="Ja" icon="pi pi-check" className="p-button-text" onClick={deleteClient}/>
    </React.Fragment>
  );
  const deleteClientsDialogFooter = (
    <React.Fragment>
      <Button label="Nee" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClientsDialog}/>
      <Button label="Ja" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedClients}/>
    </React.Fragment>
  );

  return (
    <div>
      <SalesPageHeading/>
      <div className="datatable-crud-demo">
        <Toast ref={toast}/>

        <div className="card">
          <DataTable<Clients_clients[]>
            ref={dt}
            selectionMode={"multiple"}
            value={clients.map(client => {
              return ({
                ...client,
                transactionTotal: sum(filter(map(client.bankTransactions, 'amount'), x => x > 0))
              });
            })}
            selection={selectedClients}
            onSelectionChange={(e) => setSelectedClients(e.value as Clients_clients[])}
            dataKey="id" paginator rows={200} rowsPerPageOptions={[5, 10, 25, 200]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} clients"
            globalFilter={globalFilterValue}
            globalFilterFields={["name", "firstName", "companyName", "email"]}
            header={header}
            className="sf-table"
            expandedRows={expandedRows}
            onRowToggle={(e) => {
              setExpandedRows(e.data);
            }}
            rowExpansionTemplate={(client: Clients_clients) => {
              return <ClientDetail client={client}/>;
            }}
          >
            <Column expander style={{width: '3em'}}/>
            <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
            <Column field="type" header="Type" sortable
                    body={(rowData: Clients_clients) =>
                      <span>{!rowData.type ? '' : rowData.type === ClientType.BUSINESS ? 'Bedrijf' : 'Particulier'}</span>}
            />
            <Column field="companyName" header="Bedrijfsnaam" sortable/>
            <Column field="name" header="Naam" sortable/>
            <Column field="firstName" header="Voornaam" sortable/>
            <Column field="email" header="E-mailadres" sortable/>
            <Column field="group.id" header="Groep"
                    body={(rowData: Clients_clients) => <span>{clientGroups[rowData.group.id]?.name}</span>}
                    sortable></Column>
            <Column field="phone" header="Telefoon"/>
            <Column field="id" header="ID"/>
            <Column field="city" header="Adres" body={(client: Clients_clients) => <div>
              {`${client.street} ${client.streetNumber}
                ${client.cityPostalCode} ${client.city}
              `}
            </div>}/>
            <Column
              field="transactionTotal"
              header="Transacties"
              body={(client: Clients_clients) => {
                return client.bankTransactions ?
                  <div>
                    <div>{client.bankTransactions && compact(client.bankTransactions).length}</div>
                    <div>{formatCurrencySign(get(client, 'transactionTotal'))}</div>
                  </div> : null
              }}
              sortable/>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
        {clientDialog && <ClientUpsertDialog
          client={client}
          onHide={() => setClientDialog(false)}
          onUpdate={() => {
            toast.current && toast.current.show({
              severity: 'success',
              summary: 'Klant aangemaakt',
              life: 3000
            });
          }}
          onCreate={() => {
            toast.current && toast.current.show({
              severity: 'success',
              summary: 'Klant gewijzigd',
              life: 3000
            });
          }}
        />}

        <Dialog visible={deleteClientDialog} style={{width: '450px'}} header="Confirm" modal
                footer={deleteClientDialogFooter} onHide={hideDeleteClientDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
            {client && <span>Ben je zeker dat je <b>{client.name}</b> wil verwijderen?</span>}
          </div>
        </Dialog>

        <Dialog visible={deleteClientsDialog} style={{width: '450px'}} header="Confirm" modal
                footer={deleteClientsDialogFooter} onHide={hideDeleteClientsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
            {client && <span>Ben je zeker dat je de geselecteerde klanten wenst te verwijderen?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ClientManagement;
