import React, {useState} from 'react';
import {pdf} from "@react-pdf/renderer";
import ClientOrderInvoicePdf from "../../../../shared/pdf/ClientOrderInvoicePdf";
import {SalesData_clientOrders_invoice} from "../../../../__generated__/types";
import {Button} from "primereact/button";
import emailjs from 'emailjs-com';
import {Dialog} from "primereact/dialog";
import {useSales} from "../../sales-context";
import {useUserObject} from "../../../../shared/context/UserContext";

interface MailButtonProps {
  invoice: SalesData_clientOrders_invoice;
  client: {
    id: string;
    email: string | null;
    billingEmail: string | null;
  };
}

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const MailButton = (props: MailButtonProps) => {
  const {markClientOrdersForInvoiceAsSent} = useSales();
  const {activeFarm} = useUserObject();

  const [showMailDialog, setShowMailDialog] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const fileName = props.invoice.filename;
  const recipient = props.client.billingEmail || props.client.email;

  const onClick = (dryRun: boolean) => async () => {
    if (activeFarm) {
      setSending(true);
      let invoice = props.invoice;
      const blobPromise = await pdf(ClientOrderInvoicePdf({
        data: invoice,
        farm: activeFarm,
      })).toBlob();

      const file = new File([blobPromise], fileName, {type: 'application/pdf'});
      const data = await toBase64(file);

      emailjs.send('service_8ch8mgd', 'template_7jv8ghn', {
        invoice_number: invoice.number,
        to: dryRun ? 'info@groentegeweld.be' : recipient,
        //to: 'info@groentegeweld.be',
        invoice: data,
        invoiceUrl: `http://shop.groentegeweld.be/clientsBilling?client=${props.client.id}`,
        invoiceFilename: fileName,
      })
        .then(() => {
          setSending(false);
          !dryRun && markClientOrdersForInvoiceAsSent(invoice.id);
        }, (error) => {
          console.log(error.text);
          setSending(false);
        });
    }
  };
  return <>
    <Dialog visible={showMailDialog} onHide={() => setShowMailDialog(false)}>
      <div>
        <p>De factuur wordt verzonden naar <strong>{recipient}</strong></p>
        <Button
          disabled={sending}
          label={'Versturen'}
          loading={sending}
          onClick={() => {
            return onClick(false)();
          }}
        />
        <Button
          disabled={sending}
          className={'p-button-text'}
          label={'Versturen als test (info@groentegeweld.be)'}
          loading={sending}
          onClick={onClick(true)}
        />
      </div>
      {/*<BlobProvider document={<ClientOrderInvoicePdf data={props.invoice}/>}>*/}
      {/*  {({blob, url, loading, error}) => {*/}
      {/*    // Do whatever you need with blob here*/}
      {/*    if (loading) {*/}
      {/*      return <div>Loading</div>;*/}
      {/*    }*/}
      {/*    if (blob) {*/}
      {/*      return ;*/}
      {/*    }*/}
      {/*  }}*/}
      {/*</BlobProvider>*/}
    </Dialog>
    <Button
      text
      link
      className="p-button-dense"
      icon={'pi pi-envelope'}
      label={'Verstuur de factuur'}
      onClick={() => {
        setShowMailDialog(true);
      }}
    />
  </>;
};

export default MailButton;
