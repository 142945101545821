import React, {useEffect, useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";
import {
  ClientGroups,
  ClientGroups_clientGroups,
  Product,
  Product_product,
  ProductFrequencies,
  ProductPrices,
  ProductStatus,
  ProductType,
  ProductUnit,
  SalesData_clientGroups,
  SalesData_products,
  UpdateProduct,
  UpdateProductVariables,
  UpsertProduct,
  UpsertProductVariables
} from "../../../../__generated__/types";
import {Dialog} from "primereact/dialog";
import {compact, map, omit, set, uniq, without} from "lodash";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import styled from "styled-components";
import ProductPriceOverview from "./ProductUpsertDialog/components/ProductPriceOverview";
import {useMutation, useQuery} from "@apollo/client";
import {ProductFrequenciesQuery, ProductPricesQuery, ProductQuery} from "../../../../shared/queries/product.gql";
import {UpdateProductMutation, UpsertProductMutation} from "../../queries.gql";
import {ReactS3Client} from "../../../../s3";
import {ClientGroupsQuery} from "../../../../shared/queries/clientGroup.gql";
import {asIso8601} from "../../../../shared/utils/date.utils";
import moment from "moment";
import {Checkbox} from "primereact/checkbox";
import {SelectButton} from "primereact/selectbutton";
import ProductCategoryDropdown from "../../../../components/Products/ProductCategoryDropdown";
import VatDropdown from "../../../../components/Products/VatDropdown";
import {ConfirmDialog} from "primereact/confirmdialog";
import placeholder from '../../../../shared/placeholder.svg';
import RoundImage from 'shared/components/RoundImage';
import {formatCurrency, formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import {InputSwitch} from "primereact/inputswitch";
import {findProductPriceForClientGroup} from "../../../../shared/utils/product.utils";
import {Dropdown} from "primereact/dropdown";
import {translatedUnit} from "../../../../shared/utils/unit.utils";
import {useUserObject} from "../../../../shared/context/UserContext";

interface ProductUpsertDialogProps {
  visible: boolean;
  hideDialog: () => void;
  clientGroups: SalesData_clientGroups[];
  productId?: string;
  mode: 'edit' | 'create' | 'copy';
}

let emptyProduct: Partial<Product_product> = {
  name: '',
};

const ProductImageContainer = styled.div`
  img {
    place-self: center;
    mix-blend-mode: multiply;
  }
`;

const Actions = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const ProductUpsertDialog = (props: ProductUpsertDialogProps) => {
  const {activeFarm} = useUserObject();
  const {data: productPricesData} = useQuery<ProductPrices>(ProductPricesQuery, {variables: {productId: props.productId || ''}});

  const [submitted, setSubmitted] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const {data: clientGroupData} = useQuery<ClientGroups>(ClientGroupsQuery, {variables: {farmId: activeFarm?.id}});
  const {data: productFrequenciesData} = useQuery<ProductFrequencies>(ProductFrequenciesQuery);
  const [product, setProduct] = useState<Partial<Product_product>>(emptyProduct);
  const {data: productData, refetch} = useQuery<Product>(ProductQuery, {variables: {id: props.productId || ''}});
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentMode, setCurrentMode] = useState<'edit' | 'create' | 'copy'>(props.mode);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const [isAvailableAsAlternativeUnit, setIsAvailableAsAlternativeUnit] = useState<boolean>(false);
  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation);
  const [upsertProductRemote] = useMutation<UpsertProduct>(UpsertProductMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          products: (previous) => {
            const product = data?.upsertProduct;
            return product ? [...previous, product] : previous;
          }
        }
      })
    }
  });

  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (productData?.product) {
      setProduct(productData?.product);
      setSelectedCategoryId(productData?.product.category?.id);
      setIsAvailableAsAlternativeUnit(productData.product.alternativeUnit !== undefined);

      if (currentMode === 'copy') {
        setProduct(value => ({
          ...value,
          id: undefined,
        }));
      }
    }
  }, [productData, currentMode]);

  const handleFileInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const onInputChange = (e: any, name: keyof Product_product) => {
    const val = (e.target && e.target.value) || (e.value) || '';
    let _product = {...product};
    set(_product, name, val);

    setProduct(_product);
    setSubmitted(false);
  };

  const upsertProduct = (upsertData: Partial<Product_product>, originalProduct: Product_product | undefined | null, image: any) => {
    const newFrequencyIds = map(compact(upsertData.frequencies), 'id');
    let variables: UpsertProductVariables = {
      id: upsertData.id || '',
      createData: {
        ...omit(upsertData, [
          '__typename', 'latestStock', 'availableAsAlternativeUnitFor', 'productPrices', 'productPromos', 'id', 'createdAt', 'updatedAt', 'pricePerClientGroup'
        ]) as SalesData_products,
        farm: {connect: {id: activeFarm?.id || ''}},
        category: null,
        productAvailabilities: null,
        availableAsAlternativeUnitFor: {set: upsertData.availableAsAlternativeUnitFor},
        vat: upsertData.vat,
        frequencies: {
          connect: compact(upsertData.frequencies).map(f => ({id: f.id}))
        },
        productPrices: {
          create: [
            ...compact(clientGroupData?.clientGroups).map(clientGroup => ({
              clientGroup: {connect: {id: clientGroup.id}},
              base: false,
              startDate: asIso8601(moment()),
              value: 0,
            })),
            {
              base: true,
              startDate: asIso8601(moment()),
              value: 0,
            }]
        },
      },
      updateData: {
        ...omit(upsertData, ['__typename', 'id', 'latestStock', 'availableAsAlternativeUnitFor', 'productPrices', 'productPromos', 'createdAt', 'updatedAt', 'pricePerClientGroup']) as SalesData_products,
        category: null,
        productAvailabilities: null,
        availableAsAlternativeUnitFor: {set: upsertData.availableAsAlternativeUnitFor},
        vat: upsertData.vat,
        productPrices: undefined,
        frequencies: {
          connect: newFrequencyIds.map(f => ({id: f})),
          disconnect: compact(originalProduct?.frequencies)
            .filter(productFrequency => !newFrequencyIds.includes(productFrequency.id))
            .map(f => ({id: f.id}))
        },
      }
    };

    if (selectedCategoryId) {
      variables = {
        ...variables,
        updateData: {
          ...variables.updateData,
          category: {
            connect: {id: selectedCategoryId}
          }
        },
        createData: {
          ...variables.createData,
          category: {
            connect: {id: selectedCategoryId}
          }
        }
      };
    }

    upsertProductRemote({
      variables: variables
    }).then(async r => {
      if (r.data && r.data.upsertProduct) {
        setCurrentMode('edit');
        refetch({id: r.data.upsertProduct.id});
        if (image) {
          ReactS3Client
            .uploadFile(image)
            .then((data: any) => {
              if (r.data?.upsertProduct.id) {
                const variables: UpdateProductVariables = {
                  id: r.data.upsertProduct.id,
                  data: {
                    image: data.location,
                  }
                };
                updateProduct({variables});
              }
            })
            .catch((err: any) => console.error(err))
        }
      }
    });
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name && product.name.trim()) {
      let _product = {...omit(product, 'pricePerClientGroup')};
      if (product.id) {
        upsertProduct(_product, productData?.product, selectedFile);

        toast.current && toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Product gwijzigs',
          life: 3000
        });
      } else {
        upsertProduct(_product, productData?.product, selectedFile);

        toast.current && toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Product aangemaakt',
          life: 3000
        });
      }
      //
      // if(product.id) {
      //   props.hideDialog();
      //   setProduct(emptyProduct);
      // }
    }
  };

  const onDeleteProduct = () => {
    let _product = {...product, status: ProductStatus.ARCHIVED};
    if (product.id) {
      upsertProduct(_product, productData?.product, selectedFile);

      toast.current && toast.current.show({
        severity: 'success',
        summary: 'Geslaagd',
        detail: 'Product verwijderd',
        life: 3000
      });
      props.hideDialog();
      setProduct(emptyProduct);
    }
  };

  const productDialogFooter = (
    <React.Fragment>
      {product.id !== undefined && <>
        <ConfirmDialog
          visible={confirmDialog}
          header="verwijderen"
          headerClassName="text-900"
          message={'Ben je zeker dat je dit product wil verwijderen?'}
          icon={'pi pi-exclamation-triangle'}
          acceptLabel={'Ja'}
          acceptClassName={'p-button-danger p-button-outlined'}
          rejectLabel={'Nee'}
          rejectIcon="pi pi-trash"
          rejectClassName={'p-button-text p-button-link'}
          reject={() => setConfirmDialog(false)}
          accept={() => onDeleteProduct()}
        />
        <Button
          icon={'pi pi-trash'}
          label={'Verwijderen'}
          className={'p-button-text p-button-danger'}
          onClick={() => {
            setConfirmDialog(true);
          }}
        />
        {/*<Button*/}
        {/*icon={'pi pi-trash'}*/}
        {/*className={'p-button-text p-button-danger'}*/}
        {/*onClick={(e) => {*/}
        {/*  confirmPopup({*/}
        {/*    target: e.currentTarget,*/}
        {/*    message: 'Ben je zeker dat je dit product wil verwijderen?',*/}
        {/*    icon: 'pi pi-exclamation-triangle',*/}
        {/*    accept: () => {*/}
        {/*      onDeleteProduct();*/}
        {/*    },*/}
        {/*    reject: () => {*/}
        {/*    }*/}
        {/*  });*/}
        {/*}}/>*/}
      </>}

      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => {
        setProduct(emptyProduct);
        props.hideDialog();
      }}/>
      {/*<Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => {*/}
      {/*  saveProduct();*/}
      {/*}}/>*/}
    </React.Fragment>
  );

  return <>
    <Toast ref={toast}/>
    <Dialog visible={props.visible}
            header="Product Details"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={() => {
              setProduct(emptyProduct);
              setSubmitted(false);
              props.hideDialog()
            }}>
      <div className="formgrid grid">
        <div className="col-3">
          <ProductImageContainer
            className='h-full productImage border-1 border-round-lg border-300 w-full flex align-items-center flex-column pt-4'>
            <RoundImage src={product.image || placeholder} size="xl"/>
            <input type="file" onChange={handleFileInput} className="flex flex-column max-w-11rem mt-2"/>
          </ProductImageContainer>
        </div>
        <div className="col-9">
          <div className="field">
            <label htmlFor="name">Naam</label>
            <div>
              <InputText id="name"
                         value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                         className={classNames("text-3xl font-bold", {'p-invalid': submitted && !product.name})}/>
              {submitted && !product.name && <small className="p-error">Name is required.</small>}

              <div className="p-field">
                <Checkbox
                  id="hidden"
                  onChange={(e) => {
                    let _product = {...product, status: e.checked ? ProductStatus.HIDDEN : ProductStatus.ACTIVE};
                    setProduct(_product);
                    setSubmitted(false);
                  }}
                  autoFocus
                  checked={product.status === ProductStatus.HIDDEN}
                />
                <label htmlFor="hidden">Verberg product</label>
              </div>
              <div className="py-2">
                <Checkbox
                  id="showMinOrderQuantityByDefault"
                  onChange={(e) => {
                    let _product = {...product, showMinOrderQuantityByDefault: e.checked};
                    setProduct(_product);
                    setSubmitted(false);
                  }}
                  autoFocus
                  checked={!!product.showMinOrderQuantityByDefault}
                />
                <label htmlFor="showMinOrderQuantityByDefault">Toon standaard in minimum bestelhoeveelheid</label>
              </div>
            </div>
          </div>

          <div className="field flex align-items-center justify-content-between">
            <div className="flex align-items-center justify-content-start">
              <div
                className="product-unit-viewer overflow-hidden w-8rem flex flex-column align-items-stretch border-400 border-3 border-round-2xl">
                <div className="p-2 text-center text-lg">Per</div>
                <InputNumber
                  className="w-full m-0"
                  inputClassName="border-0 text-center text-2xl font-bold p-1"
                  value={product.amount || 0}
                  locale={'nl-NL'}
                  maxFractionDigits={product.unit === ProductUnit.PIECE ? 0 : 2}
                  minFractionDigits={0}
                  onChange={(e: any) => {
                    onInputChange(e, 'amount');
                  }}/>

                <Dropdown
                  className="border-0 border-top-1 border-400"
                  value={product.unit}
                  onChange={(e) => {
                    if (e.value !== ProductUnit.KILOGRAMS) {
                      setIsAvailableAsAlternativeUnit(false);
                    } else {
                      setIsAvailableAsAlternativeUnit(false);
                    }
                    onInputChange(e, 'unit');
                  }}
                  options={[
                    {
                      value: ProductUnit.PIECE,
                      label: "stuk",
                    }, {
                      value: ProductUnit.KILOGRAMS,
                      label: "kg",
                    }, {
                      value: ProductUnit.BUNCH,
                      label: "bussel",
                    }, {
                      value: ProductUnit.GRAMS,
                      label: "gram",
                    },
                  ]}
                />
              </div>

              <div className="border-1 border-300 surface-50 border-round-right-2xl border-left-none p-2">
                <div>
                  <div>
                    <Checkbox id="alternativeUnitCheckbox"
                              onChange={() => setIsAvailableAsAlternativeUnit(!isAvailableAsAlternativeUnit)}
                              checked={isAvailableAsAlternativeUnit}/>
                    <label htmlFor="alternativeUnitCheckbox">Ook beschikbaar als</label>
                  </div>
                  {isAvailableAsAlternativeUnit && <div>
                    <SelectButton
                      className="p-selectbutton-dense border-0 border-top-1 border-400"
                      value={product.alternativeUnit}
                      onChange={(e) => {
                        onInputChange(e, 'alternativeUnit');
                      }}
                      options={[
                        {
                          value: ProductUnit.PIECE,
                          label: "Stuk",
                        },
                        {
                          value: ProductUnit.BUNCH,
                          label: "Bussel",
                        },
                      ]}
                    />
                    <div>
                      {product.unit === ProductUnit.KILOGRAMS && product.alternativeUnit
                        ?
                        <div className="flex align-items-center mt-2">
                          <InputNumber
                            value={(product.amount && product.avgWeight) ? product.amount / product.avgWeight : 0}
                            locale={'nl-NL'}
                            className="w-4rem"
                            inputClassName="text-center text-xl font-bold p-1"
                            maxFractionDigits={3}
                            minFractionDigits={0}
                            step={0.5}
                            onChange={(e: any) => {
                              if (e.value === 0) {
                                onInputChange({value: 0}, 'avgWeight');
                              } else if (product.amount) {
                                onInputChange({value: product.amount / e.value}, 'avgWeight');
                              }
                            }}/>
                          {product.avgWeight && product.avgWeight > 0 &&
                            <div
                              className="pl-2">= {formatDoubleDigit(product.avgWeight)} kg
                              / {product.alternativeUnit && translatedUnit(product.alternativeUnit)}</div>}
                        </div>
                        : product.unit === ProductUnit.BUNCH || product.unit === ProductUnit.PIECE
                          ?
                          <div>Gemiddeld gewicht
                            per {product.unit && translatedUnit(product.unit)}</div>
                          :
                          null
                      }
                    </div>
                  </div>}
                </div>

              </div>
            </div>
            <div>
              <label htmlFor="vat" className="pb-2">Btw</label>
              <VatDropdown
                id={"vat"}
                value={product.vat}
                onValueChange={(e) => onInputChange(e, 'vat')}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          {isAvailableAsAlternativeUnit &&
            <DataTable<ClientGroups_clientGroups[]>
              value={compact(clientGroupData?.clientGroups).filter(clientGroup => clientGroup.code !== 'zelfoogst')}
              className="sf-table"
            >
              <Column header="Klantengroep" field={'name'}/>
              <Column header="Als bussel"
                      body={(clientGroup) => {
                        let availableAsAlternativeUnit = compact(product.availableAsAlternativeUnitFor).includes(clientGroup.id);
                        return <InputSwitch disabled={!product.avgWeight || product.avgWeight === 0}
                                            checked={availableAsAlternativeUnit} onChange={(event) => {
                          if (product.id) {
                            let updatedAvailableAsAlternativeUnitFor = event.value
                              ? uniq([...compact(product.availableAsAlternativeUnitFor), clientGroup.id])
                              : without(compact(product.availableAsAlternativeUnitFor), clientGroup.id);

                            const variables: UpdateProductVariables = {
                              id: product.id,
                              data: {
                                availableAsAlternativeUnitFor: {
                                  set: updatedAvailableAsAlternativeUnitFor
                                }
                              }
                            };
                            updateProduct({variables, refetchQueries: "active"});
                          }
                        }}/>
                      }}
              />
              <Column
                header={"Prijs excl."}
                body={(clientGroup) => {
                  let productPrices = compact(productPricesData?.productPrices);
                  let productPrice = findProductPriceForClientGroup({productPrices}, clientGroup.id);

                  return formatCurrency(productPrice?.value);
                }}
              >

              </Column>
            </DataTable>}
        </div>
      </div>


      <div>
        <div className={'productData'}>
          <div className="p-field">
            <Checkbox id="type"
                      checked={product.type === ProductType.SUBSCRIPTION}
                      onChange={(e) => {
                        onInputChange({value: e.checked ? ProductType.SUBSCRIPTION : ProductType.SIMPLE}, 'type');
                      }}
            />
            <label htmlFor="type">Abonnement</label>
          </div>
          {product.type === ProductType.SUBSCRIPTION &&
            <>
              <div className="p-field">
                <label htmlFor="category">Categorie</label>
                <ProductCategoryDropdown
                  id={"category"}
                  value={selectedCategoryId || product.category?.id}
                  onValueChange={(value) => setSelectedCategoryId(value)}
                />
              </div>
              <div className="p-field">
                <label htmlFor="code">Identifier</label>
                <InputText
                  id="code"
                  value={product.code || activeFarm?.code || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if(activeFarm?.code) {
                      if (value.startsWith(activeFarm.code)) {
                        onInputChange(e, 'code');
                      } else {
                        onInputChange({value:`${activeFarm.code}|${value}`}, 'code');
                      }
                    }
                  }}
                  autoFocus
                />
              </div>
              <div className="p-field">
                <label htmlFor="code">Flexproduct</label>
                <Checkbox
                  id="flex"
                  onChange={(e) => {
                    let _product = {...product, flex: e.checked};
                    setProduct(_product);
                    setSubmitted(false);
                  }}
                  autoFocus
                  checked={!!product.flex}
                />
              </div>
              <div className="p-field">
                <label htmlFor="frequencies">Frequentie</label>
                <SelectButton
                  id="frequencies"
                  options={compact(productFrequenciesData?.productFrequencies)}
                  optionLabel={'name'}
                  optionValue={'id'}
                  multiple
                  value={compact(product.frequencies).map(f => f.id)}
                  onChange={(e) => {
                    const value = e.value.map((i: string) => ({id: i}));
                    onInputChange({value}, 'frequencies');
                  }}
                />
              </div>
            </>}

          <Actions>
            <Button
              disabled={submitted}
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              onClick={saveProduct}
            />
          </Actions>

        </div>

        <div>
          <ProductPriceOverview
            productId={product.id}
          />
        </div>
      </div>
    </Dialog>
  </>;
};

export default ProductUpsertDialog;
