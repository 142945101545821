import React from 'react';
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import styled from "styled-components";

interface DialogInputTextProps {
  label: string;
  id?: string;
  value?: string | null;
  onChange: (e: any) => void;
  required?: boolean;
  submitted: boolean;
}

const Container = styled.div`
`;

const DialogInputText = (props: DialogInputTextProps) => {
  const isInvalidInput = props.required && props.submitted && !props.value;
  return <Container className="p-field mt-2 mb-2">
    <label htmlFor={props.id}>{props.label}</label>
    <InputText
      id={props.id}
      value={props.value || ''}
      onChange={(e) => props.onChange(e)}
      required={props.required}
      className={classNames({'p-invalid': isInvalidInput})}
    />
    {isInvalidInput && <small className="p-error">{props.label} is verplicht.</small>}
  </Container>;
};

export default DialogInputText;
