import React, {useEffect, useState} from 'react';
import {formatCurrency, formatDoubleDigit} from "../../../../../../shared/utils/currency.utils";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {
  ClientGroups_clientGroups,
  Product_product,
  ProductPrices_productPrices,
  UpdateProduct,
  UpdateProductVariables
} from "../../../../../../__generated__/types";
import {asIso8601} from "../../../../../../shared/utils/date.utils";
import moment from "moment";
import {compact} from "lodash";
import {useMutation} from "@apollo/client";
import {UpdateProductMutation} from "../../../../queries.gql";
import {Checkbox} from "primereact/checkbox";
import {vat} from "../../../../../../shared/utils/vat.utils";

interface ClientGroupPriceProps {
  product: Product_product;
  productPrices: ProductPrices_productPrices[];
  clientGroup: ClientGroups_clientGroups;
  onChange: () => void;
  suggestedUpdate: number | undefined;
  onSelectionChange: (selected: boolean) => void;
  ignoreWithBasePriceUpdate: boolean;
  onBasePriceUpdated: () => void;
  disabled: boolean;
}

const ClientGroupPrice = (props: ClientGroupPriceProps) => {
  const [updatedValue, setUpdatedValue] = useState<number>(0);

  const {product, clientGroup} = props;
  const clientGroupId = clientGroup.id;
  const productPrice: ProductPrices_productPrices | undefined = compact(props.productPrices).find(price => price.clientGroup?.id === clientGroupId && price.endDate === null);
  const defaultSuggestedPriceForClientGroup = (compact(props.productPrices).find(price => price.base)?.value || 0) * clientGroup.profitMargin;

  useEffect(() => {
    setUpdatedValue(productPrice?.value || 0);
  }, [productPrice]);

  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {refetchQueries: "active"  });

  return <>
    <div>{clientGroup.name}</div>
    <div>{formatCurrency(defaultSuggestedPriceForClientGroup)}</div>
    <div>
      <InputNumber
        disabled={props.disabled}
        value={updatedValue}
        mode="currency"
        currency="EUR"
        locale={'nl-NL'}
        maxFractionDigits={5}
        minFractionDigits={2}
        onChange={(e: any) => {
          setUpdatedValue(e.value);
          //onChange(productPrice?.id, e.value);
        }}/>
    </div>
    <div>
      <InputNumber
        disabled={props.disabled}
        value={updatedValue * (1 + vat(product.vat))}
        mode="currency"
        currency="EUR"
        locale={'nl-NL'}
        maxFractionDigits={5}
        minFractionDigits={2}
        onChange={(e: any) => {
          setUpdatedValue(e.value/(1 + vat(product.vat)));
          //onChange(productPrice?.id, e.value);
        }}/>
    </div>
    <div>
      {(productPrice && productPrice.value !== updatedValue || (updatedValue && !productPrice)) && <Button
        className={'p-button-text'}
        label={'Wijzig prijs (individuele verhoging)'}
        onClick={() => {
            let updateOldProductPrice = undefined;
          if (productPrice) {
            updateOldProductPrice = {
              where: {id: productPrice.id},
              data: {
                endDate: asIso8601(moment()),
              }
            };
          }
            let variables: UpdateProductVariables = {
              id: props.product.id,
              data: {
                productPrices: {
                  update: compact([updateOldProductPrice]),
                  create: [
                    {
                      value: updatedValue,
                      base: productPrice?.base || false,
                      startDate: asIso8601(moment()),
                      clientGroup: {connect: {id: props.clientGroup.id}}
                    }
                  ]
                },
              }
            };
            updateProduct({variables}).then(() => props.onBasePriceUpdated());
        }}
      />}

      {props.suggestedUpdate &&
        <div>
          <Checkbox
            checked={!props.ignoreWithBasePriceUpdate}
            onChange={(e) => {
              props.onSelectionChange(e.value)
            }}/>
          {formatCurrency(props.suggestedUpdate)}
        </div>
      }

      {defaultSuggestedPriceForClientGroup && formatDoubleDigit(productPrice?.value) !== formatDoubleDigit(defaultSuggestedPriceForClientGroup) &&
        <Button
          className={'p-button-text'}
          label={'Stel in op standaard marge'}
          onClick={() => {
            if (defaultSuggestedPriceForClientGroup && productPrice) {
              const variables: UpdateProductVariables = {
                id: product.id,
                data: {
                  productPrices: {
                    update: [
                      {
                        where: {id: productPrice.id},
                        data: {endDate: asIso8601(moment())}
                      }
                    ],
                    create: [
                      {
                        value: defaultSuggestedPriceForClientGroup,
                        base: false,
                        startDate: asIso8601(moment()),
                        clientGroup: {connect: {id: clientGroup.id}},
                      }
                    ],
                  }
                }
              };
              updateProduct({variables}).then(() => props.onChange());
            }
          }}
        />}
    </div>
  </>;
};

export default ClientGroupPrice;
