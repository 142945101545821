import React from 'react';
import appConfig from "../../config.app";
import moment from "moment/moment";
import {asDayFullDdMmYyyy} from "../utils/date.utils";
import {TODAY} from "../config";

const TodayWarning = () =>
  !TODAY.isSame(moment(), 'day') && appConfig.environment === "development"
    ? <div className="m-8 mb-0">
      <div className="border-1 border-red-500 bg-red-100 text-red-500 p-3">
        Vandaag is gezet op: {asDayFullDdMmYyyy(TODAY)}
      </div>
    </div>
    : null;

export default TodayWarning;
