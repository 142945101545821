import React, {useState} from 'react';
import SubscriptionTable from './components/SubscriptionTable/SubscriptionTable';
// import SubscriptionDashboard from "./components/SubscriptionDashboard/SubscriptionDashboard";
import PageHeading from "../../components/PageHeading";
import SubscriptionDashboard from "./components/SubscriptionDashboard/SubscriptionDashboard";
import {Button} from "primereact/button";

interface SubscriptionsPageProps {

}

const SubscriptionsPage = (props: SubscriptionsPageProps) => {
  const [showDashboard, setShowDashboard] = useState<boolean>(false);
  return <div>
    <PageHeading title={'Abonnementen'} path={[]}/>

    <Button
      label={showDashboard ? 'Toon overzicht' : 'Verberg overzicht'}
      link
      onClick={() => setShowDashboard(!showDashboard)}
    />
    {showDashboard && <SubscriptionDashboard/>}

    <SubscriptionTable/>
  </div>;
};

export default SubscriptionsPage;
