import styled from "styled-components";

export const DataList = styled.div<{columns?: number}>`
  display: grid;
  grid-template-columns: max-content repeat(${props => props.columns || 1}, 1fr);
  grid-gap: 4px;
  margin-bottom: 16px;

  > .label {
    text-align: right;
    font-weight: 200;
  }
  > :not(.label) {
    font-weight: 600;
  }

  .fullWidth {
    grid-column: 2 / ${props => (props.columns || 1) + 2};
  }
`;

export const DataMultilist = styled.div<{columns?: number}>`
  display: grid;
  grid-template-columns: max-content repeat(${props => props.columns || 1}, max-content);
  grid-column-gap: 8px;
  grid-row-gap: 4px;

  > .label {
    text-align: right;
    font-weight: 200;

  }
  > :not(.label) {
    font-weight: 600;
    max-width: 300px;
  }
`;

