import React, {useRef, useState} from 'react';
import {Button} from "primereact/button";
import {ProductOrderUpdateMutation} from "../../../../../../../../../shared/queries/productOrder.gql";
import {
  BasketCompositionType,
  BasketCompositionUpdateOneWithoutDeliveriesInput,
  ProductOrderUpdate,
  ProductOrderUpdateVariables
} from "../../../../../../../../../__generated__/types";
import {useMutation} from "@apollo/client";
import {ConfirmPopup} from 'primereact/confirmpopup';

interface CancelDeliveryButtonProps {
  delivery: {
    id: string,
    cancelled: boolean,
    plannedDeliveryDate: string,
    basketComposition?: { id: string, type: BasketCompositionType } | undefined | null
  };
  productOrder: { id: string } | null | undefined;
}

const CancelDeliveryButton = (props: CancelDeliveryButtonProps) => {
  const [updateProductOrder] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation);
  const [visible, setVisible] = useState<boolean>(false);
  const buttonElement = useRef<any>(null);

  return <div>
    <ConfirmPopup
      target={buttonElement.current || undefined}
      visible={visible}
      onHide={() => setVisible(false)}
      message={() => <div>
        Are you sure you want to proceed?
        <Button label="alles" onClick={() => {
          const variables: ProductOrderUpdateVariables = {
            where: {id: props.productOrder?.id},
            data: {
              deliveries: {
                updateMany: [{
                  where: {
                    plannedDeliveryDate_gte: props.delivery.plannedDeliveryDate
                  },
                  data: {
                    cancelled: true,
                  }
                }]
              }
            }
          };
          updateProductOrder({variables, refetchQueries: "active"});
        }}></Button>
      </div>}
      icon="pi pi-exclamation-triangle"
      accept={() => {
        // TODO: detach
        let basketCompositionInput: BasketCompositionUpdateOneWithoutDeliveriesInput | null = null;
        if (!props.delivery.cancelled) {
          if (props.delivery.basketComposition?.type === BasketCompositionType.FIXED) {
            basketCompositionInput = {
              disconnect: true,
            };
          }
          if (props.delivery.basketComposition?.type === BasketCompositionType.CUSTOMER_BASED) {
            basketCompositionInput = {
              delete: true,
            };
          }
        }
        const variables: ProductOrderUpdateVariables = {
          where: {id: props.productOrder?.id},
          data: {
            deliveries: {
              update: [{
                where: {id: props.delivery.id},
                data: {
                  cancelled: !props.delivery.cancelled,
                  basketComposition: basketCompositionInput
                }
              }]
            }
          }
        };
        updateProductOrder({variables, refetchQueries: "active"});
      }}
      reject={() => {
      }}
    />

    <Button
      ref={buttonElement}
      text
      label={props.delivery.cancelled ? 'Pakket heractiveren' : 'Pakket annuleren'}
      icon="pi pi-minus-circle"
      severity="warning"
      onClick={() => {
        setVisible(!visible)
        // confirmPopup({
        //   acceptLabel: 'Ja',
        //   rejectLabel: 'Nee',
        //   target: e.currentTarget,
        //   message: () => <div className="text-right">
        //     <div>Ben je zeker dat je deze levering wil annuleren?</div>
        //     <Button label="alles" onClick={() => {}}></Button>
        //   </div>,
        //   icon: 'pi pi-exclamation-triangle',
        //   accept: () => {
        //     const variables: ProductOrderUpdateVariables = {
        //       where: {id: props.productOrder?.id},
        //       data: {
        //         deliveries: {
        //           update: [{
        //             where: {id: props.delivery.id},
        //             data: {cancelled: true}
        //           }]
        //         }
        //       }
        //     };
        //     updateProductOrder({variables, refetchQueries: "active"});
        //   },
        //   reject: () => {
        //   }
        // })
      }}
    />
  </div>;
};

export default CancelDeliveryButton;
