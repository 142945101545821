import React from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {chunk} from 'lodash';
import {DeliveryLocation, InvoiceType} from '__generated__/types';
import {toCity} from "../../../../../shared/utils/deliveryLocation.utils";

export interface PdfData {
  type: InvoiceType;
  number: string;
  filename: string;
}

interface LabelPdfProps {
  labels: LabelData[];
}

export interface LabelData {
  type: string | undefined,
  name: string,
  location: DeliveryLocation | null | undefined
}

export const labelStyles = StyleSheet.create({
  page: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    // paddingTop: 35,
    // paddingBottom: 140,
    display: 'flex',
    flexDirection: "row",
    flexWrap: "wrap",
    margin: '1cm',
  },
  labelContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'black',
    height: '9.5cm',
    width: '13.85cm',
    position: 'relative',
  },
  logo: {
    position: "absolute",
    top: '0.5cm',
    left: '4cm',
    right: '4cm',
  },
  boxesInfo: {
    paddingTop: '0.25cm',
    fontSize: 8,
    textAlign: 'center',
  },
  footer: {
    position: "absolute",
    bottom: '0.5cm',
    left: '0.5cm',
    right: '0.5cm',
    fontSize: 10,
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
  },
});

// '#EE7A31'
// '#748F56'

// Create Document Component
const LabelPdf = (props: LabelPdfProps) => {
  return (
    <Document>
      {chunk(props.labels, 4).map(page =>
        <Page size="A4" orientation={'landscape'} style={labelStyles.page}>
          {page.map(basket => <>
            <Label basket={basket}/>
          </>)}
        </Page>
      )}
    </Document>
  );
};

const Label = ({basket}: { basket: any }) => {
  return <View style={labelStyles.labelContainer}>
    <View style={labelStyles.logo}>
      <Image source={'logo.png'}/>
    </View>

    <View style={{
      position: 'absolute',
      top: '3.5cm',
      left: 0,
      right: 0,
    }}>
      <Text style={{textAlign: 'center'}}>{basket.type}</Text>
      <Text style={{textAlign: 'center', fontSize: 20, fontWeight: "bold"}}>{basket.name}</Text>
      <Text style={{
        textAlign: 'center',
        fontSize: 16,
        fontWeight: "bold",
        //color: `var(--${color})`,
      }}>{basket.location ? toCity(basket.location) : ""}</Text>
      {basket.nrOfBoxes > 0 && <View style={labelStyles.boxesInfo}>
        <Text>Bak {basket.boxNumber} van {basket.nrOfBoxes}</Text>
        {basket.nrOfBoxes === 1
          ? <Text style={{fontWeight: "bold"}}>Laat deze bak achter op het afhaalpunt.</Text>
          : <Text style={{fontWeight: "bold"}}>Breng deze bak volgende keer proper mee terug.</Text>
        }
      </View>}


    </View>

    <View style={labelStyles.footer}>

      <View style={{...labelStyles.info, marginTop: '10px'}}>
        <Text>Willem - 0032 494 66 43 46</Text>
        <Text>www.groentegeweld.be</Text>
      </View>
      <View style={labelStyles.info}>
        <Text>Dirk - 0032 489 95 51 13</Text>
        <Text>info@groentegeweld.be</Text>
      </View>
      <Text style={{fontSize: 8}}>Deze bak blijft eigendom van Groentegeweld.</Text>
    </View>
  </View>;
};

export default LabelPdf;
