import React, {useRef} from 'react';
import {OverlayPanel} from "primereact/overlaypanel";
import {Message} from "primereact/message";
import classNames from "classnames";
import {Button} from "primereact/button";
import {
  FlexNotification
} from "../../../../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";
import {first} from "lodash";
import {ProductOrdersForBasketComposition_productOrders} from "../../../../../__generated__/types";

interface BasketCompositionNotificationProps {
 notificationCode: string;
 notifications: FlexNotification[];
 productOrders: ProductOrdersForBasketComposition_productOrders[];
}

const BasketCompositionNotification = (props: BasketCompositionNotificationProps) => {
  const { notificationCode, notifications, productOrders } = props;
  const overlayPanelRef = useRef<OverlayPanel>(null);
  let severity = first(notifications)?.severity;
  let title = first(notifications)?.title;
  let code = first(notifications)?.code;

  return <div key={`notifications_${notificationCode}`}>
    <OverlayPanel ref={overlayPanelRef}>
      {notifications.map(notification => {
        let productOrder = productOrders.find(po => po.id === notification.productOrder?.id);
        return <div>
          {productOrder?.clientOrder.client.name} {productOrder?.clientOrder.client.firstName}
        </div>;
      })}
    </OverlayPanel>
    <Message
      severity={severity as 'success' | 'info' | 'warn' | 'error' | undefined}
      className="w-full m-1 text-left"
      content={() => {
        return <div className="w-full flex align-items-center">
          <i className={classNames(
            "pi text-xl mr-1",
            {"pi-exclamation-triangle": severity === "warn"},
            {"pi-exclamation-circle": severity === "info"},
            {"pi-exclamation-triangle": severity === "error"},
          )}/>
          <div>
            <div>{code}: {title}</div>
            ({notifications.length} {notifications.length > 0 ? 'pakketten' : 'pakket'})
          </div>
          &nbsp;
          <Button
            label={'Meer info'}
            className="p-button-link p-0 underline"
            onClick={(e) => {
              overlayPanelRef.current?.toggle(e);
            }}
          />
        </div>
      }}/>
  </div>;
};

export default BasketCompositionNotification;
