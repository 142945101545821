import React, {useState} from 'react';
import {first} from "lodash";
import {asIso8601, momentFromDdMmYyyy} from "../../../shared/utils/date.utils";
import {useMutation, useQuery} from "@apollo/client";
import {BasketCompositionUpsertMutation} from "../../../shared/queries/basketComposition.gql";
import {
    BasketCompositionStatus,
    BasketCompositionsVariables,
    BasketCompositionType,
    BasketCompositionUpsert,
    BasketCompositionUpsertVariables,
    FarmConfigurationsByCode
} from '__generated__/types';
import BasketsManagementPageHeading from "../BasketsManagementPageHeading";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {Moment} from "moment";
import BasketCompositionPointsGraph from "./components/BasketCompositionPointsGraph/BasketCompositionPointsGraph";
import YearlyPointsOverview from "./components/YearlyPointsOverview";
import {FarmConfigurationsByCodeQuery} from "../../FarmManagement/farm.gql";
import {FarmConfigCodes} from "../../../shared/utils/farmConfig.utils";
import {useUserObject} from "../../../shared/context/UserContext";
import BasketCompositionsList from "./BasketCompositionsList";

const BasketComposerPage = () => {
    const {activeFarm} = useUserObject();
    const {data: farmConfig_flexCode} = useQuery<FarmConfigurationsByCode>(FarmConfigurationsByCodeQuery, {variables: {farmId: activeFarm?.id || '', code: FarmConfigCodes.clientGroups_flexCode}});

    let flexClientGroupCode = first(farmConfig_flexCode?.farmConfigs)?.value.value;

    const [upsertBasketComposition] = useMutation<BasketCompositionUpsert>(BasketCompositionUpsertMutation, {refetchQueries: "active"});
    const [dateForNewBasketComposition, setDateForNewBasketComposition] = useState<Moment | undefined>(undefined);
    const [showPointsGraph, setShowPointsGraph] = useState<boolean>(false);

    const variables: BasketCompositionsVariables = {
        farmId: activeFarm?.id||'',
        where: {
            type: BasketCompositionType.FIXED,
            basketCompositionSet: null
        }
    };

    const createNewBasketComposition = () => {
        if (activeFarm?.id && dateForNewBasketComposition) {

            let deliveryWeek = asIso8601(dateForNewBasketComposition);
            const variables: BasketCompositionUpsertVariables = {
                where: {
                    id: '',
                },
                update: {},
                create: {
                    farm: {connect: {id: activeFarm.id}},
                    type: BasketCompositionType.FIXED,
                    status: BasketCompositionStatus.DRAFT,
                    deliveryWeek,
                }
            };
            upsertBasketComposition({variables});
        }
    };

    let onSetDate = (e: any) => {
        setDateForNewBasketComposition(momentFromDdMmYyyy(e.target.value).startOf('isoWeek').startOf('day'))
    };

    return <div>
        <BasketsManagementPageHeading/>

        {showPointsGraph && <BasketCompositionPointsGraph
            basketCompositionsSelector={variables}
        />}

        <div className="flex justify-content-between mb-2">
            <div>
                <Button
                    label={`${showPointsGraph ? 'Verberg' : 'Toon'} puntenoverzicht`}
                    icon="pi pi-plus"
                    className="p-button-link p-0 pl-3"
                    onClick={() => setShowPointsGraph(!showPointsGraph)}
                />
            </div>
            <div className="flex justify-content-end align-items-center">
                <div className="pr-2">Kies een datum:</div>
                <Calendar
                    locale={'nl'}
                    dateFormat={"dd-mm-yy"}
                    showWeek
                    value={dateForNewBasketComposition?.toDate()}
                    onChange={onSetDate}
                />
                <Button
                    disabled={!dateForNewBasketComposition}
                    label="Nieuw pakket maken"
                    icon="pi pi-plus"
                    className="p-button-link p-0 pl-3"
                    onClick={createNewBasketComposition}
                />
            </div>
        </div>
        <YearlyPointsOverview basketCompositionsSelector={variables}/>

        {flexClientGroupCode && <BasketCompositionsList basketCompositionsSelector={variables} flexClientGroupCode={flexClientGroupCode}/>}
    </div>;
};

export default BasketComposerPage;
