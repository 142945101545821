import {FileUpload} from 'primereact/fileupload';
import React, {createRef, useEffect, useState} from 'react';
import XLSX from "xlsx";
import {Moment} from "moment";
import {asIso8601, momentFromDdMmYyyy} from "../../shared/utils/date.utils";
import numeral from 'numeral';
import {Button} from "primereact/button";
import {useMutation, useQuery} from "@apollo/client";
import {BankTransactionsCreateMutation} from "../../shared/queries/bankTransaction.gql";
import {
  BankTransactionCreateWithoutBankAccountInput,
  BankTransactionsCreate,
  BankTransactionsCreateVariables,
  Clients,
  Clients_clients, ClientsVariables,
} from "../../__generated__/types";
import {ClientsQuery} from "../../shared/queries/client.gql";
import {chunk, compact, first, omit, without} from "lodash";
import {useUserObject} from "../../shared/context/UserContext";

interface TransactionXlsRow {
  date: string,
  amount: string,
  ibanNumber: string,
  bic: string,
  name: string,
  address: string,
  sct: string,
  description: string,
  bankAccountIbanNumber: string,
  bankAccountBalance: string,
}

const header = ["date", "bankAccountIbanNumber", "amount", "ibanNumber", "bic", "name", "address", "sct", "description", "bankAccountBalance"];

const handleTransactionsFormXls = (file: File, onLoaded: (data: TransactionXlsRow[]) => void) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e: any) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data: TransactionXlsRow[] = XLSX.utils.sheet_to_json(ws, {
      header,
      raw: false,
      rawNumbers: false,
    });
    onLoaded(data);
    /* Update state */
  };
  if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
};

export type UploadedTransaction = {
  date: Moment,
  amount: number,
  ibanNumber: string,
  bic: string,
  name: string,
  address: string,
  sct: string,
  description: string,
  bankAccountIbanNumber?: string,
  bankAccountBalance: number,
};

const BankRecordsFileUpload = () => {
  //const {data: bankTransactionsData} = useQuery<BankTransactions>(BankTransactionsQuery);
  const {activeFarm} = useUserObject();
  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data: clientData} = useQuery<Clients>(ClientsQuery, {variables});
  //const {data: invoiceData} = useQuery<Invoices>(InvoicesQuery);

  const fileUploadRef = createRef<FileUpload>();
  const [xlsRows, setXlsRows] = useState<TransactionXlsRow[]>([]);
  const [createsToProcess, setCreatesToProcess] = useState<BankTransactionCreateWithoutBankAccountInput[][]>([]);
  const [uploadedTransactions, setUploadedTransactions] = useState<UploadedTransaction[]>([]);
  const [createBankTransaction] = useMutation<BankTransactionsCreate>(BankTransactionsCreateMutation);

  const clients: Clients_clients[] = compact(clientData?.clients);

  useEffect(() => {
    setUploadedTransactions(xlsRows.map((row): UploadedTransaction => {
      let amount = numeral(row.amount).value() || 0;
      return ({
        ...row,
        date: momentFromDdMmYyyy(row.date),
        amount: amount,
        bankAccountBalance: numeral(row.bankAccountBalance).value() || 0,
      });
    }));
  }, [xlsRows]);

  useEffect(() => {
    let firstBatch = first(createsToProcess);
    if (firstBatch) {
      let ibanNumber = first(uploadedTransactions)?.bankAccountIbanNumber;
      if (ibanNumber) {
        const variables: BankTransactionsCreateVariables = {ibanNumber, transactionCreates: firstBatch};
        let options: any = {variables};
        if (createsToProcess.length === 1) {
          options = {...options, refetchQueries: 'active'};
        }
        createBankTransaction(options)
          .then(() => setCreatesToProcess(value => firstBatch ? without(value, firstBatch) : value))
        ;
      }
    }
  }, [createsToProcess]);


  return <div className="flex align-items-center">
    <FileUpload
      ref={fileUploadRef}
      customUpload
      name="bestelling"
      auto
      uploadHandler={(e) => {
        handleTransactionsFormXls(e.files[0], setXlsRows);
        fileUploadRef.current && fileUploadRef.current.clear();

      }}/>
    <Button
      className="ml-2"
      disabled={xlsRows.length === 0}
      loading={createsToProcess.length > 0}
      label={`Upload ${xlsRows.length} transacties toe`}
      onClick={() => {
        let chunks = chunk(uploadedTransactions, 20);
        let creates = chunks.map(transactionsChunk => {
          return transactionsChunk
            //.filter((transaction) => !bankTransactionStrings.includes(bankTransactionAsString(transaction)))
            .map((uploadedTransaction): BankTransactionCreateWithoutBankAccountInput => {
              const matchingClient = clients.find(client => client.ibanNumbers.includes(uploadedTransaction.ibanNumber));
              let client = null;
              if (matchingClient) {
                client = {
                  connect: {
                    id: matchingClient.id
                  }
                };
              }
              return {
                ...omit(uploadedTransaction, 'bankAccountIbanNumber'),
                date: asIso8601(uploadedTransaction.date),
                client
              };
            });
        });
        setCreatesToProcess(creates);
      }}/>
    {createsToProcess.length > 0 && <div className="ml-2">
      Nog te uploaden: {createsToProcess.length} batches
    </div>}
  </div>;
};

export default BankRecordsFileUpload;

