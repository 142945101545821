import { AmbassadorOption } from "../../__generated__/types";
import { AMBASSADOR_OPTIONS } from "../config.formulas";

export const ambassadorDiscountPercentage = (ambassadorOption: AmbassadorOption | null) => {
  return AMBASSADOR_OPTIONS.find(option => option.value === ambassadorOption)?.discount || 0;
};

export const ambassadorOptionDetails = (ambassadorOption: AmbassadorOption | null) => {
  return AMBASSADOR_OPTIONS.find(option => option.value === ambassadorOption);
};

export const ambassadorOptionFromProductCode = (ambassadorProductCode: string) => {
  if (ambassadorProductCode === 'ambassadeur-50') {
    return AmbassadorOption.AMBASSADOR_50;
  } else if (ambassadorProductCode === 'ambassadeur-100') {
    return AmbassadorOption.AMBASSADOR_100;
  } else if (ambassadorProductCode === 'ambassadeur-200') {
    return AmbassadorOption.AMBASSADOR_200;
  } else if (ambassadorProductCode === 'ambassadeur-275') {
    return AmbassadorOption.AMBASSADOR_275;
  }

  return null;
};
