import React, {useState} from 'react';
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {Moment} from "moment/moment";
import {
  BasketCompositionStatus,
  BasketCompositionType,
  BasketSize,
  CreateBasketCompositionSet,
  CreateBasketCompositionSetVariables
} from "../../../../__generated__/types";
import {useMutation} from "@apollo/client";
import {CreateBasketCompositionSetMutation} from "../../../../shared/queries/basketComposition.gql";
import {asIso8601, momentFromDdMmYyyy} from "../../../../shared/utils/date.utils";
import {BASKET_SIZES} from "../BasketComposerPage2";

const AddNewBasketCompositionSetForm = () => {
  const [createBasketCompositionSet] = useMutation<CreateBasketCompositionSet>(CreateBasketCompositionSetMutation, {refetchQueries: "active"});
  const [deliveryDate, setDeliveryDate] = useState<Moment | undefined>(undefined);

  let onSetDate = (e: any) => {
    setDeliveryDate(momentFromDdMmYyyy(e.target.value).startOf('day'))
  };

  const createNewBasketCompositionSet = () => {
    if (deliveryDate) {
      let deliveryWeek = asIso8601(deliveryDate);
      const variables: CreateBasketCompositionSetVariables = {
        data: {
          status: BasketCompositionStatus.DRAFT,
          deliveryWeek,
          basketCompositions: {
            create: BASKET_SIZES.map(basketSize => ({
              basketSize,
              type: BasketCompositionType.FIXED,
            }))
          }
        }
      };
      createBasketCompositionSet({variables});
    }
  };

  return <div className="flex justify-content-end align-items-center">
    <div className="pr-2">Kies een datum:</div>
    <Calendar
      locale={'nl'}
      dateFormat={"dd-mm-yy"}
      showWeek
      value={deliveryDate?.toDate()}
      onChange={onSetDate}
    />
    <Button
      disabled={!deliveryDate}
      label="Nieuw pakket maken"
      icon="pi pi-plus"
      className="p-button-link p-0 pl-3"
      onClick={createNewBasketCompositionSet}
    />
  </div>;
};

export default AddNewBasketCompositionSetForm;
