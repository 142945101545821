import { FormulaType, SubscriptionSubType, SubscriptionType } from "./types/types";
import { AmbassadorOption, BasketType, MembershipExtraType } from "../__generated__/types";

export type ExtraProductCategoryCodes = 'bloemen' | 'eieren' | 'aardappelen' | 'uien';

export interface Option {
  name: string;
  code: ExtraProductCategoryCodes;
  type: MembershipExtraType;
  image: string;
  description: string;
  frequency?: string;
  orderText: string;
  productUnit?: string;
  deliveryWeeks?: string[];
  isoWeeks?: number[];
  //products: { id: string | null, name: string, price?: number, allowBiWeekly: boolean, frequency: 'weekly' | 'monthly' | 'biWeekly' | 'yearly' }[];
  restrictedFormulas?: SubscriptionType[];
  period?: {
    [key in SubscriptionSubType]: string | undefined;
  }
}

export interface AmbassadorOptionInterface {
  value: AmbassadorOption,
  discount: number,
  amount: number,
  code: string,
  wage: number,
  refererDiscount?: number,
  gifts: { value: string, main: boolean }[],
}

export const AMBASSADOR_OPTIONS: AmbassadorOptionInterface[] = [
  {
    value: AmbassadorOption.AMBASSADOR_50,
    amount: 50,
    discount: 0,
    wage: 1530,
    code: 'ambassadeur-50',
    gifts: [
      {value: "Een rondleiding op het veld voor 4 personen", main: true}
    ],
  },
  {
    value: AmbassadorOption.AMBASSADOR_100,
    amount: 100,
    discount: 0.05,
    wage: 1720,
    code: 'ambassadeur-100',
    gifts: [
      {value: "Een rondleiding op het veld voor 4 personen", main: false},
      {value: "5% korting op je extra aankopen tijdens het hele seizoen en in de volgende stap", main: true},
    ]
  },
  {
    value: AmbassadorOption.AMBASSADOR_200,
    amount: 200,
    discount: 0.15,
    wage: 2100,
    code: 'ambassadeur-200',
    refererDiscount: 30,
    gifts: [
      {value: "Een rondleiding op het veld voor 4 personen", main: false},
      {value: "15% korting op je extra aankopen tijdens het hele seizoen en in de volgende stap", main: true},
      {value: "Gegraveerde Groentegeweld pollepel", main: true},
      {value: "EUR 30 terug als je een nieuwe deelnemer aanbrengt **", main: true},
    ]
  },
  {
    value: AmbassadorOption.AMBASSADOR_275,
    amount: 275,
    discount: 0.20,
    wage: 2400,
    code: 'ambassadeur-275',
    refererDiscount: 40,
    gifts: [
      {value: "Een rondleiding op het veld voor 4 personen", main: false},
      {value: "20% korting op je extra aankopen tijdens het hele seizoen en in de volgende stap", main: true},
      {value: "Een dahlia boeket van eigen veld bezorgd tijdens het seizoen", main: true},
      {value: "EUR 40 terug als je een nieuwe deelnemer aanbrengt **", main: true},
    ]
  },
];

let basketDeliveryWeeks = [
  '2023-18',
  '2023-19',
  '2023-20',
  '2023-21',
  '2023-22',
  '2023-23',
  '2023-24',
  '2023-25',
  '2023-26',
  '2023-27',
  '2023-28',
  //"2023-29", 21 juli nationale feestdag
  '2023-30',
  '2023-31',
  '2023-32',
  '2023-33',
  '2023-34',
  '2023-35',
  '2023-36',
  '2023-37',
  '2023-38',
  '2023-39',
  '2023-40',
  '2023-41',
  '2023-42',
  '2023-43',
  '2023-44',
  '2023-45',
  '2023-46',
  '2023-47',
  '2023-48',
  '2023-49',
  '2023-50',
  '2023-51',
  //"2023-52" Kerstmis week,
  //januari geen pakketten
  '2024-5',
  '2024-7',
  '2024-9',
  '2024-11',
  '2024-13',
  '2024-14',
  '2024-15',
  '2024-16',
  '2024-17',
  '2024-18',
  '2024-19',
  '2024-20',
  '2024-21',
  '2024-22',
  '2024-23',
  '2024-24',
  '2024-25',
  '2024-26',
  '2024-27',
  '2024-28',
  '2024-29',
  '2024-30',
  '2024-31',
  '2024-32',
  '2024-33',
  '2024-34',
  '2024-35',
  '2024-36',
  '2024-37',
  '2024-38',
  '2024-39',
  '2024-40',
  '2024-41',
  '2024-42',
  '2024-43',
  //"2024-44", 1 november wapenstilstand
  '2024-45',
  '2024-46',
  '2024-47',
  '2024-48',
  '2024-49',
  '2024-50',
  '2024-51',
  //'2024-52',"2025-1","2025-2","2025-3","2025-4", januari
  '2025-6',
  '2025-8',
  '2025-10',
  '2025-12',
  '2025-14',
  '2025-15',
  '2025-16',
  '2025-17',
  '2025-18',
  '2025-19',
  '2025-20',
  '2025-21',
  '2025-22',
  '2025-23',
  '2025-24',
  '2025-25',
  '2025-26',
  '2025-27',
  '2025-28',
  '2025-29',
  '2025-30',
  '2025-31',
  '2025-32',
  /*"2025-33" olv hemelvaart,*/ '2025-34',
  '2025-35',
  '2025-36',
  '2025-37',
  '2025-38',
  '2025-39',
  '2025-40',
  '2025-41',
  '2025-42',
  '2025-43',
  '2025-44',
  '2025-45',
  '2025-46',
  '2025-47',
  '2025-48',
  '2025-49',
  '2025-50',
  '2025-51',
  '2025-52',
];

let potatoDeliveryWeeks = [
  '2023-2',
  '2023-6',
  '2023-10',
  '2023-14', // winterpakket
  '2023-35',
  '2023-36', // september
  '2023-40',
  '2023-41', // oktober
  '2023-44',
  '2023-45', // november
  '2023-48',
  '2023-49', // december
  '2024-5',
  '2024-6', // feb
  '2024-9',
  '2024-10', // maa
  '2024-14',
  '2024-15', // apr

  '2024-36',
  '2024-37', // sep
  '2024-40',
  '2024-41', // okt
  '2024-44',
  '2024-45', // nov
  '2024-49',
  '2024-50', // dec
  '2025-6',
  '2025-7', // feb
  '2025-10',
  '2025-11', // maa
  '2025-14',
  '2025-15', // apr

  '2025-18',
  '2025-37', // sep
  '2025-40',
  '2025-41', // okt
  '2025-45',
  '2025-46', // nov
  '2025-49',
  '2025-50', // dec
];

let onionDeliveryWeeks = [
  '2023-2',
  '2023-6',
  '2023-10',
  '2023-14', // winterpakket
  '2023-18',
  '2023-19', // mei
  '2023-22',
  '2023-23', // juni
  '2023-27',
  '2023-28', // juli
  '2023-31',
  '2023-32', // augustus
  '2023-35',
  '2023-36', // september
  '2023-40',
  '2023-41', // oktober
  '2023-44',
  '2023-45', // november
  '2023-48',
  '2023-49', // december
  '2024-5',
  '2024-6', // feb
  '2024-9',
  '2024-10', // maa
  '2024-14',
  '2024-15', // apr
  '2024-18',
  '2024-19', // mei
  '2024-23',
  '2024-24', // jun
  '2024-27',
  '2024-28', // jul
  '2024-31',
  '2024-32', // eug
  '2024-36',
  '2024-37', // sep
  '2024-40',
  '2024-41', // okt
  '2024-44',
  '2024-45', // nov
  '2024-49',
  '2024-50', // dec
  '2025-6',
  '2025-7', // feb
  '2025-10',
  '2025-11', // maa
  '2025-14',
  '2025-15', // apr
  '2025-18',
  '2025-19', // mei
  '2025-23',
  '2025-24', // jun
  '2025-27',
  '2025-28', // jul
  '2025-31',
  '2025-32', // aug
  '2025-36',
  '2025-37', // sep
  '2025-40',
  '2025-41', // okt
  '2025-45',
  '2025-46', // nov
  '2025-49',
  '2025-50', // dec
];

let selfHarvestDeliveryWeeks = [
  '2023-18',
  '2023-19',
  '2023-20',
  '2023-21',
  '2023-22',
  '2023-23',
  '2023-24',
  '2023-25',
  '2023-26',
  '2023-27',
  '2023-28',
  '2023-29',
  '2023-30',
  '2023-31',
  '2023-32',
  '2023-33',
  '2023-34',
  '2023-35',
  '2023-36',
  '2023-37',
  '2023-38',
  '2023-39',
  '2023-40',
  '2023-41',
  '2023-42',
  '2023-43',
  '2023-44',
  '2023-45',
  '2023-46',
  '2023-47',
  '2023-48',
  '2023-49',
  '2023-50',
  '2023-51',
  '2023-52',
  '2024-1',
  '2024-2',
  '2024-3',
  '2024-4',
  '2024-5',
  '2024-6',
  '2024-7',
  '2024-8',
  '2024-9',
  '2024-10',
  '2024-11',
  '2024-12',
  '2024-13',
  '2024-14',
  '2024-15',
  '2024-16',
  '2024-17',
  '2024-18',
  '2024-19',
  '2024-20',
  '2024-21',
  '2024-22',
  '2024-23',
  '2024-24',
  '2024-25',
  '2024-26',
  '2024-27',
  '2024-28',
  '2024-29',
  '2024-30',
  '2024-31',
  '2024-32',
  '2024-33',
  '2024-34',
  '2024-35',
  '2024-36',
  '2024-37',
  '2024-38',
  '2024-39',
  '2024-40',
  '2024-41',
  '2024-42',
  '2024-43',
  '2024-44',
  '2024-45',
  '2024-46',
  '2024-47',
  '2024-48',
  '2024-49',
  '2024-50',
  '2024-51',
  '2024-52',
  '2025-1',
  '2025-2',
  '2025-3',
  '2025-4',
  '2025-5',
  '2025-6',
  '2025-7',
  '2025-8',
  '2025-9',
  '2025-10',
  '2025-11',
  '2025-12',
  '2025-13',
  '2025-14',
  '2025-15',
  '2025-16',
  '2025-17',
  '2025-18',
  '2025-19',
  '2025-20',
  '2025-21',
  '2025-22',
  '2025-23',
  '2025-24',
  '2025-25',
  '2025-26',
  '2025-27',
  '2025-28',
  '2025-29',
  '2025-30',
  '2025-31',
  '2025-32',
  '2025-33',
  '2025-34',
  '2025-35',
  '2025-36',
  '2025-37',
  '2025-38',
  '2025-39',
  '2025-39',
  '2025-40',
  '2025-41',
  '2025-42',
  '2025-43',
  '2025-44',
  '2025-45',
  '2025-46',
  '2025-47',
  '2025-48',
  '2025-49',
  '2025-50',
  '2025-51',
  '2025-52',
];

/**
 * @deprecated Switch to db product names
 * */
export const periodLabels: { [key: string]: string } = {
  'zelfoogst-2023-trial': 'Zelfoogst proefperiode',
  'zelfoogst-2023': 'Zelfoogst',
  'pakketten-2023-fixed-trial-medium': 'Klein pakket: Vast proefperiode',
  'pakketten-2023-fixed-trial-large': 'Groot pakket: Vast proefperiode',
  'pakketten-2023-fixed-15-medium': 'Klein pakket: Vast15',
  'pakketten-2023-fixed-15-large': 'Groot pakket: Vast15',
  'pakketten-2023-fixed-30-medium': 'Klein pakket: Vast30',
  'pakketten-2023-fixed-30-large': 'Groot pakket: Vast30',
  'pakketten-2023-fixed-40-medium': 'Klein pakket: Vast40',
  'pakketten-2023-fixed-40-large': 'Groot pakket: Vast40',
  'pakketten-2023-flex-trial-medium': 'Klein pakket: Flex proef',
  'pakketten-2023-flex-trial-large': 'Groot pakket: Flex proef',
  'pakketten-2023-flex-15-medium': 'Klein pakket: Flex15',
  'pakketten-2023-flex-15-large': 'Groot pakket: Flex15',
  'pakketten-2023-flex-30-medium': 'Klein pakket: Flex30',
  'pakketten-2023-flex-30-large': 'Groot pakket: Flex30',
  'pakketten-2023-flex-40-medium': 'Klein pakket: Flex40',
  'pakketten-2023-flex-40-large': 'Groot pakket: Flex40',
};

export const formulas: { [key in SubscriptionSubType]: FormulaType } = {
  'YEAR_BASKET': {
    active: false,
    formulaType: SubscriptionType.BASKETS,
    membershipSubType: SubscriptionSubType.YEAR_BASKET,
    disabled: true,
    weeks: {
      '2022-2023': [18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 2, 4, 6, 8, 10, 12, 14, 16],
    },
    allowTrial: true,
    name: 'Jaarpakket',
    shortDescription: 'Seizoenspakket + winterpakket',
    description: [
      '12 maanden: mei - april',
      '+/- 41 pakketten',
      'Wekelijks een pakket van mei t.e.m. dec, tweewekelijks van jan t.e.m. april',
    ],
    pricing: [
      {
        title: 'Klein pakket',
        type: BasketType.STANDARD,
        price: {
          target: 600,
          trial: 150,
        }
      },
      {
        title: 'Groot pakket',
        type: BasketType.LARGE,
        price: {
          target: 860,
          trial: 215,
        }
      },
    ],
  },
  'SEASONAL_BASKET': {
    active: false,
    formulaType: SubscriptionType.BASKETS,
    membershipSubType: SubscriptionSubType.SEASONAL_BASKET,
    disabled: true,
    weeks: {
      '2022-2023': [18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51],
    },
    name: 'Seizoenspakket',
    allowTrial: true,
    shortDescription: 'Wekelijks van mei t.e.m. december',
    description: [
      '8 maanden: mei - december',
      '+/-32 pakketten',
      'Wekelijks een pakket',
    ],
    pricing: [
      {
        title: 'Klein pakket',
        type: BasketType.STANDARD,
        price: {
          target: 480,
          trial: 150,
        }
      },
      {
        title: 'Groot pakket',
        type: BasketType.LARGE,
        price: {
          target: 688,
          trial: 215,
        }
      },
    ]
  },
  'WINTER_BASKET': {
    active: false,
    formulaType: SubscriptionType.BASKETS,
    membershipSubType: SubscriptionSubType.WINTER_BASKET,
    disabled: false,
    weeks: {
      '2022-2023': [2, 4, 6, 8, 10, 12, 14, 16],
    },
    name: 'Winterpakket',
    allowTrial: false,
    shortDescription: 'Tweewekelijks van januari t.e.m. april',
    description: [
      '4 maanden: januari - april',
      '+/-8 pakketten',
      'Tweewekelijks een pakket',
    ],
    pricing: [
      {
        title: 'Klein pakket',
        type: BasketType.STANDARD,
        price: {
          target: 135,
        },
      },
      {
        title: 'Groot pakket',
        type: BasketType.LARGE,
        price: {
          target: 193.5,
        }
      },
    ]
  },
  [SubscriptionSubType.SELF_HARVEST]: {
    active: false,
    productCode: 'zelfoogst-2023',
    productCodeTrial: 'zelfoogst-2023-proef',
    formulaType: SubscriptionType.SELF_HARVEST,
    membershipSubType: SubscriptionSubType.SELF_HARVEST,
    deliveryWeeks: selfHarvestDeliveryWeeks,
    periods: [
      {
        label: 'Proef',
        description: 'Kom 8 weken oogsten',
        code: 'zelfoogst-2023-trial',
        nrOfWeeks: 8,
        nrOfPossibleWeeks: 8,
        order: 2,
        showTimeline: true,
      },
      {
        label: '1 jaar',
        description: 'Kom 365 dagen lang oogsten en ervaar elk seizoen',
        code: 'zelfoogst-2023',
        nrOfWeeks: 52,
        nrOfPossibleWeeks: 52,
        order: 1,
        showTimeline: true,
      },
    ],
    disabled: true,
    description: [
      "Oogst heel het jaar rond",
      "Kies zelf de groenten die je graag hebt",
      "Leer je kinderen ontdekken waar de groenten op hun bord vandaan komen",
      "Geniet van de rust en ontvlucht even de dagelijkse drukte",
      "Ontmoet andere deelnemers en neem deel aan leuke activiteiten",
    ],
    name: 'Zelfoogst',
    weeks: {
      '2022-2023': [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    },
    allowTrial: true,
    shortDescription: 'Kom oogsten waar jij zin in hebt en wanneer het jou past. Wekelijks word je op de hoogte gehouden van het huidige aanbod.',
    pricing: [
      {
        title: 'Zelfoogst',
        price: {
          target: 405,
          trial: 75,
        }
      },
    ],
  },
  [SubscriptionSubType.BASKETS_FIXED]: {
    active: true,
    formulaType: SubscriptionType.BASKETS,
    membershipSubType: SubscriptionSubType.BASKETS_FIXED,
    periods: [
      {
        hidden: true,
        label: 'Winter',
        description: 'Winterpakket 2023',
        codeSmall: 'pakketten-2023-fixed-winter-medium',
        codeLarge: 'pakketten-2023-fixed-winter-large',
        nrOfWeeks: 8,
        nrOfPossibleWeeks: 8,
        order: 4,
        portionsSmall: 215,
        portionsLarge: 320,
        showTimeline: true,
        deliveryWeeks: ["2023-2", "2023-4", "2023-6", "2023-8", "2023-10", "2023-12", "2023-14", "2023-16"],
      },

      {
        label: 'Proef',
        description: 'Probeer 8 pakketten',
        codeSmall: 'pakketten-2023-fixed-trial-medium',
        codeLarge: 'pakketten-2023-fixed-trial-large',
        nrOfWeeks: 8,
        nrOfPossibleWeeks: 10,
        order: 4,
        portionsSmall: 215,
        portionsLarge: 320,
        showTimeline: true,
      },
      {
        label: 'Vast15',
        description: `Haal 15 pakketten`,
        codeSmall: 'pakketten-2023-fixed-15-medium',
        codeLarge: 'pakketten-2023-fixed-15-large',
        biWeekly: true,
        nrOfWeeks: 15,
        nrOfPossibleWeeks: 35,
        order: 3,
        portionsSmall: 405,
        portionsLarge: 610,
        showTimeline: true,

      },
      {
        label: 'Vast30',
        description: 'Haal 30 pakketten op',
        codeSmall: 'pakketten-2023-fixed-30-medium',
        codeLarge: 'pakketten-2023-fixed-30-large',
        nrOfWeeks: 30,
        nrOfPossibleWeeks: 35,
        order: 2,
        portionsSmall: 810,
        portionsLarge: 1220,
        showTimeline: true,
      },
      {
        label: 'Vast40',
        description: 'Haal 40 pakketten op',
        codeSmall: 'pakketten-2023-fixed-40-medium',
        codeLarge: 'pakketten-2023-fixed-40-large',
        nrOfWeeks: 40,
        nrOfPossibleWeeks: 45,
        order: 1,
        portionsSmall: 1100,
        portionsLarge: 1650,
        showTimeline: true,
      }
    ],
    deliveryWeeks: basketDeliveryWeeks,
    disabled: false,
    weeks: {
      '2022-2023': [18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 2, 4, 6, 8, 10, 12, 14, 16],
    },
    allowTrial: true,
    name: 'Vast pakket',
    shortDescription: 'Samengesteld door de boer',
    description: [],
    pricing: [],
  },
  [SubscriptionSubType.BASKETS_FLEX]: {
    active: true,
    formulaType: SubscriptionType.BASKETS,
    membershipSubType: SubscriptionSubType.BASKETS_FLEX,
    deliveryWeeks: basketDeliveryWeeks,
    periods: [
      {
        label: 'Proef',
        description: 'Probeer 8 pakketten',
        codeSmall: 'pakketten-2023-flex-trial-medium',
        codeLarge: 'pakketten-2023-flex-trial-large',
        nrOfWeeks: 8,
        nrOfPossibleWeeks: 10,
        order: 4,
        portionsSmall: 215,
        portionsLarge: 320,
        showTimeline: true,

      },
      {
        label: 'Flex15',
        description: 'Stel 15 keer zelf je pakket samen',
        codeSmall: 'pakketten-2023-flex-15-medium',
        codeLarge: 'pakketten-2023-flex-15-large',
        biWeekly: true,
        nrOfWeeks: 15,
        nrOfPossibleWeeks: 35,
        order: 3,
        portionsSmall: 405,
        portionsLarge: 610,
        showTimeline: true,
      },
      {
        label: 'Flex30',
        description: 'Stel 30 keer zelf je pakket samen',
        codeSmall: 'pakketten-2023-flex-30-medium',
        codeLarge: 'pakketten-2023-flex-30-large',
        nrOfWeeks: 30,
        nrOfPossibleWeeks: 35,
        order: 2,
        portionsSmall: 810,
        portionsLarge: 1220,
        showTimeline: true,
      },
      {
        label: 'Flex40',
        description: 'Stel 40 keer zelf je pakket samen',
        codeSmall: 'pakketten-2023-flex-40-medium',
        codeLarge: 'pakketten-2023-flex-40-large',
        nrOfWeeks: 40,
        nrOfPossibleWeeks: 45,
        order: 1,
        portionsSmall: 1100,
        portionsLarge: 1650,
        showTimeline: true,
      }
    ],
    disabled: false,
    weeks: {
      '2022-2023': [18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 2, 4, 6, 8, 10, 12, 14, 16],
    },
    allowTrial: true,
    name: 'Flexibel pakket',
    shortDescription: 'Samengesteld door de jezelf',
    description: [],
    pricing: [],
  },
};

export const extraOptions: Option[] = [
  {
    name: "Bloemenplukabonnement",
    type: MembershipExtraType.FLOWERS,
    code: 'bloemen',
    image: "flowers.jpg",
    description: "",
    period: {
      SELF_HARVEST: "Gedurende het hele zelfoogstseizoen",
      YEAR_BASKET: undefined,
      SEASONAL_BASKET: undefined,
      WINTER_BASKET: undefined,
      BASKETS_FIXED: undefined,
      BASKETS_FLEX: undefined,
    },
    restrictedFormulas: [SubscriptionType.SELF_HARVEST],
    orderText: "Ik wil ook",
  },
  {
    name: "Eieren",
    type: MembershipExtraType.EGGS,
    code: 'eieren',
    image: "eieren.jpeg",
    description: "Onze eigen kippen smullen dagelijks van de groenresten die de tuin met zich meebrengt. Hiernaast krijgen ze biologische legkorrel. Die halen we bij Jef Dries, onze buurman en bio-eierboer! Als onze kippen zelf niet genoeg eieren leggen, kopen we bij hem extra eieren aan!",
    period: {
      SELF_HARVEST: "Gedurende het hele zelfoogstseizoen",
      YEAR_BASKET: "Gedurende de volledige duur van je pakket",
      SEASONAL_BASKET: "Gedurende de volledige duur van je pakket",
      WINTER_BASKET: "Gedurende de volledige duur van je pakket",
      BASKETS_FIXED: "Gedurende de volledige duur van je pakket",
      BASKETS_FLEX: "Gedurende de volledige duur van je pakket",
    },
    frequency: "Wekelijks of tweewekelijks",
    orderText: "Ik wil ook",
    productUnit: "doosje",
    // products: [
    //   // {id: null, name: "Geen (2)-wekelijkse eieren"},
    //   {id: "1", name: "Wekelijks 6 eieren", price: 2.40, allowBiWeekly: false, frequency: 'weekly'},
    //   {id: "2", name: "Wekelijks 12 eieren", price: 4.80, allowBiWeekly: false, frequency: 'weekly'},
    //   {id: "3", name: "2-wekelijks 6 eieren", price: 2.40, allowBiWeekly: true, frequency: 'biWeekly'},
    //   {id: "4", name: "2-wekelijks 12 eieren", price: 4.80, allowBiWeekly: true, frequency: 'biWeekly'},
    // ],
  },
  {
    name: "Aardappelen",
    type: MembershipExtraType.POTATOES,
    code: 'aardappelen',
    image: "aardappelen.jpeg",
    frequency: "Maandelijks (1ste maandag van de maand)",
    description: "Een echte tractorteelt, die laten we liever over aan Tuinderij De Kraanvogel in Esbeek. " +
      "Zij voorzien ons jaarlijks van vastkokende en kruimige bewaaraardappelen.",
    period: {
      SELF_HARVEST: "Vanaf september t.e.m. april",
      YEAR_BASKET: "Vanaf september t.e.m. april",
      SEASONAL_BASKET: "Vanaf september t.e.m. december",
      WINTER_BASKET: "Gedurende de volledige duur van je pakket",
      BASKETS_FIXED: "Vanaf september t.e.m. april",
      BASKETS_FLEX: "Vanaf september t.e.m. april",
    },
    orderText: "Ik wil ook",
    productUnit: "maand",
    deliveryWeeks: potatoDeliveryWeeks,
    isoWeeks: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 1, 2, 6, 10, 14],
    // products: [
    //   // {id: "none", name: "Geen maandelijkse aardappelen"},
    //   {id: "5", name: "Maandelijks 2,5kg kruimige aardappelen", price: 5, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "7", name: "Maandelijks 5kg kruimige aardappelen", price: 9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "9", name: "Maandelijks 10kg kruimige aardappelen", price: 17, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "6", name: "Maandelijks 2,5kg vastkokende aardappelen", price: 5, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "8", name: "Maandelijks 5kg vastkokende aardappelen", price: 9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "10", name: "Maandelijks 10kg vastkokende aardappelen", price: 17, allowBiWeekly: true, frequency: 'monthly'},
    // ],
  },
  {
    name: "Uien",
    type: MembershipExtraType.ONIONS,
    code: 'uien',
    image: "uien.jpeg",
    description: "De uien halen we bij bevriende boeren uit ons netwerk. Zo kunnen we jullie een goed biologisch product garanderen dat lokaal werd geteeld.",
    orderText: "Ik wil ook",
    productUnit: "maand",
    deliveryWeeks: onionDeliveryWeeks,
    // products: [
    //   // {id: "none", name: "Geen maandelijkse uien"},
    //   {id: "11", name: "Maandelijks 1,25kg rode uien", price: 1.9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "12", name: "Maandelijks 1,25kg gele uien", price: 1.9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "13", name: "Maandelijks 1,25kg gemengde uien", price: 1.9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "14", name: "Maandelijks 2kg rode uien", price: 2.9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "15", name: "Maandelijks 2kg gele uien", price: 2.9, allowBiWeekly: true, frequency: 'monthly'},
    //   {id: "16", name: "Maandelijks 2kg gemengde uien", price: 2.9, allowBiWeekly: true, frequency: 'monthly'},
    // ],
    frequency: "Maandelijks (1ste maandag van de maand)",
    period: {
      SELF_HARVEST: "Gedurende het hele zelfoogstseizoen",
      YEAR_BASKET: "Gedurende de volledige duur van je pakket",
      SEASONAL_BASKET: "Gedurende de volledige duur van je pakket",
      WINTER_BASKET: "Gedurende de volledige duur van je pakket",
      BASKETS_FIXED: "Gedurende de volledige duur van je pakket",
      BASKETS_FLEX: "Gedurende de volledige duur van je pakket",
    },
  }
];
