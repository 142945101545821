import { gql } from "@apollo/client";
import { ProductFields_basicFragment } from "shared/queries/product.gql";

export const AllProductsQuery = gql`
query AllProducts {
  products {
    id
    name
    unit
    amount
    image
    productPromos {
      id
      startDate
      endDate
      type
      value
      secondValue
    }
  }
}`;


export const ShopClientsQuery = gql`
${ProductFields_basicFragment}
query ShopClients {
  shopClients {
    id
    name
    firstName
    email
    newsletter
    phone
  }
  clients(where: {
    clientOrders_some: {
      	productOrders_some: {
          product: {code_starts_with: "ambassadeur-"}
        }
      }
    }) {
    name
    firstName
    email
    clientOrders(
      orderBy: deliveryEndDate_DESC
      first: 1
      where: {
      productOrders_some: {
        product: {code_starts_with: "ambassadeur-"}
      }
    }) {
      deliveryEndDate
      productOrders(
        where: {
          product: {code_starts_with: "ambassadeur-"}
        }
      ) {product { ...ProductFields_basic }}
    }
  }
}`;

export const ShopOrdersQuery = gql`
query ShopOrders {
   shopOrders(where: {
    createdAt_gt: "2023-12-31"
  }) {
    id
    orderDate
    deliveryDate
    deliveryLocation
    paid
    status
    extraPackaging
    promocode
    createdAt
    shopClient {
      id
      phone
      email
    }
    shopOrderItems {
      id
      delivered
      product {
        id
        name
      }
      currentPromo { id }
      amount
      priceIncl
    }
  }
}`;

export const UpdateOrderStatusMutation = gql`
mutation UpdateOrderStatus($id: ID!, $status: ShopOrderStatus!) {
  updateShopOrder(
    where: {id: $id}
    data: {
      status: $status
    }
  ) {
    id
    status
  }
}
`;

export const UpdateOrderPaymentStatusMutation = gql`
mutation UpdateOrderPaymentStatus($id: ID!, $paid: Boolean!) {
  updateShopOrder(
    where: {id: $id}
    data: {
      paid: $paid
    }
  ) {
    id
    paid
  }
}
`;

export const RemoveShopOrderMutation = gql`
mutation RemoveShopOrder($id: ID!) {
  deleteManyShopOrderItems(
    where: {
      shopOrder: {
        id: $id
      }
    }
  ) {
    count
  }
  deleteManyShopOrders(
    where: {id: $id}
  ) {
    count
  }
}`;

export const LinkShopClientAndMembershipMutation  = gql`
mutation LinkShopClientAndMembership($membershipId: ID!, $shopClientId: ID!) {
  updateMembership(
    where: {
      id: $membershipId,
    }
    data: {
      shopClient: {
        connect: {
          id: $shopClientId
        }
      }
    }
  ) {
    shopClient {
      id
    }
  }
}`;

export const RemoveShopClientMutation = gql`
mutation RemoveShopClient($id: ID!) {
  deleteShopClient(
    where: {id: $id}
  ) {
    id
  }
}`;

export const UpdateShopOrderItemDeliveredFlagMutation = gql`
mutation UpdateShopOrderItemDeliveredFlag($id: ID!, $delivered: Boolean!) {
  updateShopOrderItem(
    where: {id: $id}
    data: {
      delivered: $delivered
    }
  ) {
    id
    delivered
  }
}
`;

export const FindMembershipByShopClientQuery = gql`
query FindMembershipByShopClient($shopClientId: ID!) {
  memberships(where: {
    shopClient: {
      id: $shopClientId
    }
  }) {
    id
    ambassador
  }
}
`;

export const ShopOrderDetailsQuery = gql`
query ShopOrderDetails($id: ID!) {
   shopOrder(where: {id: $id}) {
    id
    orderDate
    deliveryDate
    deliveryLocation
    paid
    status
    extraPackaging
    promocode
    shopClient {
      id
      name
      firstName
      email
      phone
    }
    shopOrderItems {
      id
      delivered
      currentPromo { id }
      product {
        id
        name
        amount
        unit
        avgWeight
        alternativeUnit
        availableAsAlternativeUnitFor
      }
      amount
      priceIncl
    }
  }
}
`;
