import {calculateAgeAtStartOfSeason} from "./date.utils";
import {BasketType, Frequency, MembershipSubType, MembershipType} from "../../__generated__/types";
import { endYearForSeason, firstBasketDeliveryOfSeason, firstSelfHarvestDayOfSeason, firstWinterBasketDeliveryOfSeason, startOfSeason, startYearForSeason } from "./season.util";
import {CLIENT_SETTINGS, ClientSettings} from "../clientConfig";
import moment, {Moment} from "moment";
import {formulas} from "../config.formulas";
import {indexOf, intersection, last, slice} from "lodash";
import { SubscriptionSubType, SubscriptionType } from "../types/types";

export const deliveryWeeksForExtraOption = (
  productFrequency: Frequency | undefined,
  productIsoWeeks: number[] | undefined,
  season: '2022-2023',
  membershipSubType: MembershipSubType | null | undefined,
  startDate: Moment | undefined,
  endDate: Moment | undefined,
  membershipBiWeekly: boolean,
  trial: boolean,
) => {
  if (!productFrequency) {
    return 0;
  }

  let deliveryWeeks = 0;
  let deliveryWeekNumbers = [];
  if (membershipSubType) {
    if (productFrequency !== Frequency.YEARLY) {
      deliveryWeekNumbers = calculateDeliveryWeeksForMembership(
        startDate,
        endDate,
        membershipSubType,
        season,
        membershipBiWeekly, productFrequency
      );
      if (productIsoWeeks) {
        deliveryWeekNumbers = intersection(deliveryWeekNumbers, productIsoWeeks);
      }
      deliveryWeeks = deliveryWeekNumbers.length;
    } else if (productFrequency === Frequency.YEARLY) {
      deliveryWeeks = 1;
    }
  }

  return deliveryWeeks;
};

export const totalPriceInclDiscount = (
  productPrice: number | undefined,
  deliveryWeeks: number,
  discountPercentage: number,
) => {
  let totalPrice = deliveryWeeks * (productPrice || 0);

  return discountPercentage > 0 ? totalPrice * (1 - discountPercentage) : totalPrice;
};

export const discount = (
  productPrice: number | undefined,
  deliveryWeeks: number,
  discountPercentage: number,
) => {
  let totalPrice = deliveryWeeks * (productPrice || 0);

  return discountPercentage > 0 ? totalPrice * (discountPercentage) : totalPrice;
};

export const calculateMembershipStartDate = (
  today: Moment,
  season: string,
  membershipType: MembershipType | undefined,
  membershipSubType: MembershipSubType | undefined
) => {
  let start;

  if (membershipType === undefined
    || (membershipType === MembershipType.BASKETS && membershipSubType === undefined)) {
    return undefined;
  }

  if (membershipType === MembershipType.SELF_HARVEST) {
    start = firstSelfHarvestDayOfSeason(season);
    if (start.isSameOrBefore(today, 'd')) {
      start = today.clone().add(1, 'd');
    }
  } else {
    if (membershipSubType === MembershipSubType.WINTER_BASKET) {
      start = firstWinterBasketDeliveryOfSeason(season);
    } else {
      start = firstBasketDeliveryOfSeason(season);
    }
    if (start.isSameOrBefore(today, 'w')) {
      start = today.clone().startOf('isoWeek').add(1, 'w');
    }
  }
  return start;
};

// ERROR: This function does not take deliveryWeeks into account!!
export const calculateMembershipEndDate = (
  today: Moment,
  season: string,
  membershipType: MembershipType | undefined,
  membershipSubType: MembershipSubType | undefined,
  trial: boolean,
  biWeekly: boolean,
) => {
  let end;

  if (membershipType === undefined
    || (membershipType === MembershipType.BASKETS && membershipSubType === undefined)
    || (membershipType === MembershipType.BASKETS && membershipSubType === MembershipSubType.WINTER_BASKET && trial)
  ) {
    return undefined;
  }

  if (membershipType === MembershipType.SELF_HARVEST) {
    end = endYearForSeason(season).month('3').endOf('M');
    if (trial) {
      let startDate = calculateMembershipStartDate(today, season, membershipType, membershipSubType);
      if (startDate) {
        end = startDate.add(8, 'w').endOf('isoWeek');
      }
    }
  } else {
    if (membershipSubType === MembershipSubType.SEASONAL_BASKET) {
      let christmas = startYearForSeason(season).month(11).day(23);
      if (christmas.isoWeekday() === 1) {
        end = christmas.subtract(1, "w").startOf('isoWeek');
      } else {
        end = christmas.startOf('isoWeek');
      }
    } else if (membershipSubType === MembershipSubType.WINTER_BASKET) {
      end = moment(endYearForSeason(season).year() + '-16 1', 'YYYY-W E');
    } else {
      end = endYearForSeason(season).month(3).day(30).startOf('isoWeek');
    }
  }

  if (trial) {
    let startDate = calculateMembershipStartDate(today, season, membershipType, membershipSubType);
    if (startDate) {
      if (membershipType === MembershipType.BASKETS) {
        if (biWeekly) {
          end = startDate.add(14, 'w');
        } else {
          end = startDate.add(7, 'w');
        }
      } else {
        end = startDate.add(7 * 8, 'd');
      }
    }
  }

  return end;
};

export const toDeliveryWeekDates = (weeks: number[], season: string, membershipSubType: SubscriptionSubType) => {
  let seasonStartYear = season.substring(0, 4);
  let seasonEndYear = season.substring(5, 9);

  return weeks.reduce((acc: Moment[], week: number) => {
      let deliveryDay = moment(seasonStartYear + '-' + week + ' 1', 'YYYY-W E');
      let lastDeliveryDay = last(acc);
      if (membershipSubType === SubscriptionSubType.WINTER_BASKET
        || (lastDeliveryDay && (lastDeliveryDay.isoWeek() > week || lastDeliveryDay.year().toString() === seasonEndYear))
      ) {
        deliveryDay = moment(seasonEndYear + '-' + week + ' 1', 'YYYY-W E');
      }

      return [...acc, deliveryDay];
    },
    [])
};

/**
 * @deprecated use calculateDeliveryWeeksForMembership()
 */
export const calculateDeliveryWeeksForMembershipFromStartDate = (
  subscriptionStartDate: Moment | undefined,
  membershipSubType: MembershipSubType,
  season: '2022-2023',
  biWeekly: boolean,
  frequency: Frequency,
  trial: boolean,
) => {
  if (!subscriptionStartDate) {
    return [];
  }
  let formula = formulas[membershipSubType];
  let isoWeeks = formula.weeks[season];

  let startIsoWeek = subscriptionStartDate.isoWeek();
  if (membershipSubType !== MembershipSubType.SELF_HARVEST && subscriptionStartDate.isoWeekday() > 1) {
    startIsoWeek += 1;
  }

  let seasonStartYear = season.substring(0, 4);
  let seasonEndYear = season.substring(5, 9);
  let startOfSeason = moment(seasonStartYear + '-' + isoWeeks[0] + ' 1', 'YYYY-W E');
  let endOfSeason = moment(seasonEndYear + '-' + isoWeeks[isoWeeks.length - 1] + ' 1', 'YYYY-W E');
  if (formula.membershipSubType === SubscriptionSubType.WINTER_BASKET) {
    startOfSeason = moment(seasonEndYear + '-' + isoWeeks[0] + ' 1', 'YYYY-W E');
  }

  let remainingWeeks = [];
  if (subscriptionStartDate.isSameOrBefore(endOfSeason, 'd')) {
    if (subscriptionStartDate.isSameOrBefore(startOfSeason, 'd')) {
      remainingWeeks = isoWeeks;
    } else {
      if (membershipSubType === MembershipSubType.SELF_HARVEST) {
        remainingWeeks = elementsFrom(isoWeeks, startIsoWeek);
      } else {
        remainingWeeks = elementsFrom(isoWeeks, startIsoWeek);
      }
    }
  }

  let firstWeekIsOdd = remainingWeeks[0] % 2 === 1;
  if (remainingWeeks.length > 0) {
    if (biWeekly || frequency === Frequency.BI_WEEKLY) {
      let firstYearInSeasonIsPassed = false;
      remainingWeeks = remainingWeeks.reduce((acc: number[], week: number) => {
        let lastWeek = last(acc);
        if (firstYearInSeasonIsPassed || (lastWeek && lastWeek > week)) {
          firstYearInSeasonIsPassed = true;
          return [...acc, week];
        }
        // if (idx % 2 === 0) {
        //   return [...acc, week];
        // }
        if ((firstWeekIsOdd && week % 2 === 1) || (!firstWeekIsOdd && week % 2 === 0)) {
          return [...acc, week];
        }
        return acc;
      }, []);
    }

    if (trial) {
      remainingWeeks = remainingWeeks.slice(0, 8);
    }

    if (frequency === Frequency.MONTHLY) {
      let monthsWithWeeksForFullSeason = isoWeeks.reduce((acc: { [key: number]: number[] }, week: number) => {
        let month = startOfSeason.isoWeek(week).month();

        return {
          ...acc,
          [month]: [...(acc[month] || []), week]
        }
      }, {});

      remainingWeeks = remainingWeeks.reduce((acc: number[], week: number) => {
        const lastWeek = last(acc);

        // skip this week if the subscriptionDate is after the first eligible (biWeekly) basket for the month of this week
        // KNOWN BUG: if a winterbasket starts after the first delivery this doesn't work
        if (formula.membershipSubType !== SubscriptionSubType.WINTER_BASKET) {
          const monthForCurrentWeek = startOfSeason.isoWeek(week).month();

          let firstBasketWeekForCurrentMonth;
          if (firstWeekIsOdd && biWeekly) {
            firstBasketWeekForCurrentMonth = monthsWithWeeksForFullSeason[monthForCurrentWeek].find((week: number) => week % 2 === 1);
          } else {
            firstBasketWeekForCurrentMonth = monthsWithWeeksForFullSeason[monthForCurrentWeek].find((week: number) => week % 2 === 0);
          }

          if (!lastWeek && firstBasketWeekForCurrentMonth && subscriptionStartDate.isoWeek() > firstBasketWeekForCurrentMonth) {
            return acc;
          }
        }

        if (lastWeek && startOfSeason.isoWeek(lastWeek).month() === startOfSeason.isoWeek(week).month()) {
          return acc;
        } else {
          return [...acc, week];
        }
      }, []);
    }
  }

  return remainingWeeks;
}

export const calculateDeliveryWeeksForMembership = (
  subscriptionStartDate: Moment | undefined,
  subscriptionEndDate: Moment | undefined,
  membershipSubType: MembershipSubType,
  season: '2022-2023',
  biWeekly: boolean,
  frequency: Frequency,
  // trial: boolean,
) => {
  if (!subscriptionStartDate || !subscriptionEndDate) {
    return [];
  }
  let formula = formulas[membershipSubType];
  let isoWeeks = formula.weeks[season];

  let startIsoWeek = subscriptionStartDate.isoWeek();
  if (membershipSubType !== MembershipSubType.SELF_HARVEST && subscriptionStartDate.isoWeekday() > 1) {
    startIsoWeek += 1;
  }

  let seasonStartYear = season.substring(0, 4);
  let seasonEndYear = season.substring(5, 9);
  let startOfSeason = moment(seasonStartYear + '-' + isoWeeks[0] + ' 1', 'YYYY-W E');
  let endOfSeason = moment(seasonEndYear + '-' + isoWeeks[isoWeeks.length - 1] + ' 1', 'YYYY-W E');
  if (formula.membershipSubType === SubscriptionSubType.WINTER_BASKET) {
    startOfSeason = moment(seasonEndYear + '-' + isoWeeks[0] + ' 1', 'YYYY-W E');
  }

  let remainingWeeks = [];
  if (subscriptionStartDate.isSameOrBefore(endOfSeason, 'd')) {
    if (subscriptionStartDate.isSameOrBefore(startOfSeason, 'd')) {
      remainingWeeks = isoWeeks;
    } else {
      if (membershipSubType === MembershipSubType.SELF_HARVEST) {
        remainingWeeks = elementsFrom(isoWeeks, startIsoWeek);
      } else {
        remainingWeeks = elementsFrom(isoWeeks, startIsoWeek);
      }
    }
  }

  remainingWeeks = elementsBeforeIncluding(remainingWeeks, subscriptionEndDate.isoWeek());

  let firstWeekIsOdd = remainingWeeks[0] % 2 === 1;
  if (remainingWeeks.length > 0) {
    if (biWeekly || frequency === Frequency.BI_WEEKLY) {
      let firstYearInSeasonIsPassed = false;
      remainingWeeks = remainingWeeks.reduce((acc: number[], week: number) => {
        let lastWeek = last(acc);
        if (firstYearInSeasonIsPassed || (lastWeek && lastWeek > week)) {
          firstYearInSeasonIsPassed = true;
          return [...acc, week];
        }
        // if (idx % 2 === 0) {
        //   return [...acc, week];
        // }
        if ((firstWeekIsOdd && week % 2 === 1) || (!firstWeekIsOdd && week % 2 === 0)) {
          return [...acc, week];
        }
        return acc;
      }, []);
    }

    if (frequency === Frequency.MONTHLY) {
      let monthsWithWeeksForFullSeason = isoWeeks.reduce((acc: { [key: number]: number[] }, week: number) => {
        let month = startOfSeason.isoWeek(week).month();

        return {
          ...acc,
          [month]: [...(acc[month] || []), week]
        }
      }, {});

      remainingWeeks = remainingWeeks.reduce((acc: number[], week: number) => {
        const lastWeek = last(acc);

        // skip this week if the subscriptionDate is after the first eligible (biWeekly) basket for the month of this week
        // KNOWN BUG: if a winterbasket starts after the first delivery this doesn't work
        if (formula.membershipSubType !== SubscriptionSubType.WINTER_BASKET) {
          const monthForCurrentWeek = startOfSeason.isoWeek(week).month();

          let firstBasketWeekForCurrentMonth;
          if (firstWeekIsOdd && biWeekly) {
            firstBasketWeekForCurrentMonth = monthsWithWeeksForFullSeason[monthForCurrentWeek].find((week: number) => week % 2 === 1);
          } else {
            firstBasketWeekForCurrentMonth = monthsWithWeeksForFullSeason[monthForCurrentWeek].find((week: number) => week % 2 === 0);
          }

          if (!lastWeek && firstBasketWeekForCurrentMonth && subscriptionStartDate.isoWeek() > firstBasketWeekForCurrentMonth) {
            return acc;
          }
        }

        if (lastWeek && startOfSeason.isoWeek(lastWeek).month() === startOfSeason.isoWeek(week).month()) {
          return acc;
        } else {
          return [...acc, week];
        }
      }, []);
    }
  }

  return remainingWeeks;
}

export const elementsAfter = (arr: any[], element: any) => {
  const diffArr = arr.map(x => Math.abs(element - x));
  const minNumber = Math.min(...diffArr);
  const idx = diffArr.findIndex(x => x === minNumber);

  return arr.slice(idx + 1, arr.length);
};

export const elementsFrom = (haystack: any[], needle: any) => {
  const tmp = haystack.filter(i => i >= needle).sort((a, b) => a - b).at(0);
  const idx = haystack.indexOf(tmp);
  return (idx === -1) ? [] : haystack.slice(idx);
};

export const elementsBeforeIncluding = (haystack: any[], needle: any) => {
  return slice(haystack, 0, indexOf(haystack, needle) + 1);

  //return dropRightWhile(haystack, (element) => element > needle);
};

export const priceForMember = (price: number | undefined,
                               age: number,
                               percentage: number,
) => {
  return price ? ageToFullEquivalent(age) * price * percentage : 0;
};

export const ageToFullEquivalent = (age: number) => {
  if (age > 3) {
    let adultEquivalent = age / 18;
    return (adultEquivalent > 1 ? 1 : adultEquivalent);
  }
  return 0;
};

export const calculateSelfHarvestTotalPrice = (price: number | undefined,
                                               members: { dateOfBirth: string | undefined, child?: boolean | undefined, deleted?: boolean | undefined }[] | undefined,
                                               trial: boolean | undefined,
                                               season: string,
                                               percentage: number) => {
  if (!price || !members) {
    return NaN;
  }
  return members
    .filter(m => !m.deleted)
    .reduce((total: number, member: { dateOfBirth: string | undefined, child?: boolean | undefined }) => {
      const age = member.dateOfBirth
        ? calculateAgeAtStartOfSeason(member.dateOfBirth, startOfSeason(season))
        : NaN;
      return !isNaN(age) ? total + priceForMember(price, age, percentage) : NaN;
    }, 0);
};

export const isSelfHarvest = (type?: MembershipType) => type === MembershipType.SELF_HARVEST;
export const isBaskets = (type?: MembershipType) => type === MembershipType.BASKETS;
export const isBasketSize = (type: MembershipType | undefined, basketType: BasketType | undefined | null, expectedSize: BasketType) => {
  return isBaskets(type) && basketType === expectedSize;
};

export const targetOrTrialPriceFor = (options: ClientSettings, trial: boolean | undefined, type: MembershipType | undefined, basketType: BasketType | undefined | null) => {
  let {basketOptions, selfHarvestOptions} = CLIENT_SETTINGS[0];
  let basketOptionsSmall = basketOptions && basketOptions.sizes[0];
  let basketOptionsLarge = basketOptions && basketOptions.sizes[1];

  let priceRange: undefined | { prices: { min?: number, max?: number, target?: number, trial?: number } } = {prices: {}};

  if (isSelfHarvest(type)) {
    priceRange = selfHarvestOptions;
  } else if (isBasketSize(type, basketType, BasketType.LARGE)) {
    priceRange = basketOptionsLarge;
  } else if (isBasketSize(type, basketType, BasketType.STANDARD)) {
    priceRange = basketOptionsSmall;
  }

  if (priceRange && trial) {
    return priceRange.prices.trial || 0;
  } else if (priceRange) {
    return priceRange.prices.target || 0;
  }
  return NaN;
};

export const maxPriceFor = (options: ClientSettings, trial: boolean | undefined, type: MembershipType | undefined, basketType: BasketType | undefined | null) => {
  let {basketOptions, selfHarvestOptions} = CLIENT_SETTINGS[0];
  let basketOptionsSmall = basketOptions && basketOptions.sizes[0];
  let basketOptionsLarge = basketOptions && basketOptions.sizes[1];

  let priceRange: undefined | { prices: { min?: number, max?: number, target?: number, trial?: number } } = {prices: {}};

  if (isSelfHarvest(type)) {
    priceRange = selfHarvestOptions;
  } else if (isBasketSize(type, basketType, BasketType.LARGE)) {
    priceRange = basketOptionsLarge;
  } else if (isBasketSize(type, basketType, BasketType.STANDARD)) {
    priceRange = basketOptionsSmall;
  }

  if (priceRange && trial) {
    return priceRange.prices.trial || 0;
  } else if (priceRange) {
    return priceRange.prices.max || 0;
  }
  return NaN;
};

export const maxPreviousPriceFor = (options: ClientSettings, trial: boolean | undefined, type: MembershipType | undefined, basketType: BasketType | undefined | null) => {
  let {basketOptions, selfHarvestOptions} = CLIENT_SETTINGS[0];
  let basketOptionsSmall = basketOptions && basketOptions.sizes[0];
  let basketOptionsLarge = basketOptions && basketOptions.sizes[1];

  let priceRange: undefined | { previousPrices: { min?: number, max?: number, target?: number, trial?: number } } = {previousPrices: {}};

  if (isSelfHarvest(type)) {
    priceRange = selfHarvestOptions;
  } else if (isBasketSize(type, basketType, BasketType.LARGE)) {
    priceRange = basketOptionsLarge;
  } else if (isBasketSize(type, basketType, BasketType.STANDARD)) {
    priceRange = basketOptionsSmall;
  }

  if (priceRange && trial) {
    return priceRange.previousPrices.trial || 0;
  } else if (priceRange) {
    return priceRange.previousPrices.max || 0;
  }
  return NaN;
};

export const typeToHumanReadable = (
  type: SubscriptionType | MembershipType,
  subType?: SubscriptionSubType | MembershipSubType | null,
  biWeekly?: boolean
) => {
  if (type === SubscriptionType.SELF_HARVEST) {
    return 'Zelfoogst';
  } else {
    let basketType;
    if (subType === SubscriptionSubType.WINTER_BASKET) {
      basketType = 'Winterpakket';
    } else if (subType === SubscriptionSubType.YEAR_BASKET) {
      basketType = 'Jaarpakket';
    } else if (subType === SubscriptionSubType.SEASONAL_BASKET) {
      basketType = 'Seizoenspakket';
    } else if (subType === SubscriptionSubType.BASKETS_FLEX) {
      basketType = 'Flexibel';
    } else if (subType === SubscriptionSubType.BASKETS_FIXED) {
      basketType = 'Vast';
    } else if (type === SubscriptionType.BASKETS || SubscriptionType.BASKETS_FLEX) {
      basketType = 'Pakket';
    }
    if (biWeekly) {
      basketType += ' (Tweewekelijks)';
    }
    return basketType;
  }
};

export const membershipBasketsSubTypeToTag = (
  type: MembershipType,
  subType?: MembershipSubType | null,
) => {
  let basketType;
  if (subType === MembershipSubType.WINTER_BASKET) {
    basketType = 'winterpakket';
  } else if (subType === MembershipSubType.YEAR_BASKET) {
    basketType = 'winterpakket';
  } else if (subType === MembershipSubType.SEASONAL_BASKET) {
    basketType = 'seizoenspakket';
  }

  return basketType;
};
