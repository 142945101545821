import React, {ReactNode, useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {
  AddPaymentToInvoice,
  ClientOrderStatus,
  Clients_clients,
  CreateClientOrderInvoice,
  CreateClientOrderInvoiceVariables,
  GetClientOrdersToProcess_clientOrders,
  GetClientOrdersToProcess_clientOrders_client,
  InvoiceCreateInput,
  MarkClientOrdersForInvoiceAsSent,
  SalesData,
  SalesData_clientGroups,
  SalesData_products,
  SalesData_sequences,
  SalesDataVariables,
  Sequences_sequences,
  UpsertClientOrder,
  UpsertClientOrderVariables
} from "../../__generated__/types";
import {
  AddPaymentToInvoiceMutation,
  MarkClientOrdersForInvoiceAsSentMutation,
  SalesQuery,
  UpsertClientOrderMutation
} from "./queries.gql";
import {notEmpty} from "../../shared/utils/stream.utils";
import {compact, difference, first, get, keyBy, map, mapValues, omit, orderBy} from 'lodash';
import {calculatePayments, createClientOrderInvoiceData} from './utils/invoice.utils';
import {CreateClientOrderInvoiceMutation} from "../../shared/queries/clientOrder.gql";
import {nextInvoiceNumber} from "../../shared/utils/sequence.utils";
import {asIso8601} from "../../shared/utils/date.utils";
import {productOrdersTotalIncl} from "../../utils/productOrder.utils";
import {TODAY} from "../../shared/config";
import {useUserObject} from "../../shared/context/UserContext";

interface SalesContextValue {
  sequences: {
    [key: string]: SalesData_sequences,
  };
  clientGroups: {
    [key: string]: SalesData_clientGroups,
  };
  products: {
    [key: string]: SalesData_products,
  };
  clientOrders: {
    [key: string]: GetClientOrdersToProcess_clientOrders,
  };
  upsertClientOrder: (clientOrderPartial: Partial<GetClientOrdersToProcess_clientOrders>) => void;
  createClientOrderInvoice: (client: GetClientOrdersToProcess_clientOrders_client,
                             clientOrders: GetClientOrdersToProcess_clientOrders[],
                             sequence: Sequences_sequences) => void;
  upsertProduct: (productPartial: Partial<SalesData_products>, image: any) => void;
  deleteProduct: (productId: string) => void;
  addPaymentToInvoice: (invoiceId: string, payment: { paymentDate: string, amount: number }) => void;
  updateProductPriceForClientGroup: (update: {
    clientGroupId: string,
    productId: string,
    price: number
  }) => void;
  markClientOrdersForInvoiceAsSent: (invoiceId: string) => void;
}

const DEFAULT_CONTEXT = {
  clientOrders: {},
  products: {},
  sequences: {},
  clientGroups: {},
  clientGroupDiscounts: {},
  clientGroupDiscountsPerProduct: {},
  addPaymentToInvoice: () => {
  },
  upsertClientOrder: () => {
  },
  createClientOrderInvoice: () => {
  },
  upsertProduct: () => {
  },
  deleteProduct: () => {
  },
  updateProductPriceForClientGroup: () => {
  },
  markClientOrdersForInvoiceAsSent: () => {
  }
};
const SalesContext = React.createContext<SalesContextValue>(DEFAULT_CONTEXT);

export type Props = {
  children: ReactNode;
  salesContextValue?: SalesContextValue;
};

function SalesProvider(props: Props) {
  const {activeFarm} = useUserObject();
  const {children, salesContextValue} = props;
  const variables: SalesDataVariables = {farmId: activeFarm?.id || '',};
  const {data} = useQuery<SalesData>(SalesQuery, {variables});
  const [value, setValue] = useState<SalesContextValue>(salesContextValue || DEFAULT_CONTEXT);
  const [upsertClientOrderRemote] = useMutation<UpsertClientOrder>(UpsertClientOrderMutation);
  const [markClientOrdersForInvoiceAsSentRemote] = useMutation<MarkClientOrdersForInvoiceAsSent>(MarkClientOrdersForInvoiceAsSentMutation);
  const [addPaymentToInvoiceRemote] = useMutation<AddPaymentToInvoice>(AddPaymentToInvoiceMutation);
  const [createClientOrderInvoiceRemote] = useMutation<CreateClientOrderInvoice>(CreateClientOrderInvoiceMutation);

  const createClientOrderInvoice = (client: GetClientOrdersToProcess_clientOrders_client,
                                    clientOrdersUnsorted: GetClientOrdersToProcess_clientOrders[],
                                    sequence: Sequences_sequences
  ) => {
    const clientOrdersForInvoice = orderBy(clientOrdersUnsorted, 'deliveryDate');

    if (sequence) {
      const invoiceNumber = nextInvoiceNumber(sequence);

      const firstClientOrder = first(clientOrdersForInvoice);
      if (firstClientOrder) {

        let totalIncl = productOrdersTotalIncl(clientOrdersForInvoice);
        let payments = calculatePayments(1, totalIncl, 0, asIso8601(TODAY), TODAY);

        const clientOrderInvoicePdfData = createClientOrderInvoiceData(clientOrdersForInvoice, client, invoiceNumber, false, TODAY, payments);

        const invoiceData: InvoiceCreateInput = {
          ...omit(clientOrderInvoicePdfData, "paymentCreates"),
          payments: clientOrderInvoicePdfData.paymentCreates,
          clientOrders: {
            connect: clientOrdersForInvoice.map(clientOrder => ({id: clientOrder.id}))
          }
        };

        const variables: CreateClientOrderInvoiceVariables = {
          clientOrders: clientOrdersForInvoice.map(clientOrder => clientOrder.id),
          nextSequenceValue: sequence.nextValue + 1,
          sequenceId: sequence.id,
          invoiceData,
        };

        createClientOrderInvoiceRemote({variables, refetchQueries: "active"}).then(r => {
          // if (r.data && r.data.createInvoice) {
          //   const updatedClientOrders = reduce(r.data.createInvoice.clientOrders, (acc, el) => {
          //     return {...acc, [el.id]: el};
          //   }, value.clientOrders);
          //
          //   let updatedSequence = value.sequences[sequence.id];
          //   if (r.data && r.data.updateSequence) {
          //     updatedSequence = r.data.updateSequence;
          //   }
          //
          //   setValue(state => {
          //     return {
          //       ...state,
          //       clientOrders: updatedClientOrders,
          //       sequences: {...state.sequences, [updatedSequence.id]: updatedSequence},
          //     }
          //   });
          // }

        });
      }
    } else {
      console.error('No sequence found');
    }
  };

  const upsertClientOrder = (upsertData: Partial<GetClientOrdersToProcess_clientOrders>) => {
    let deletedProductOrderIds: string[] = [];
    if (upsertData.id) {
      const clientOrder = value.clientOrders[upsertData.id];
      deletedProductOrderIds = difference(map(clientOrder.productOrders || [], 'id'), map(upsertData.productOrders, 'id'));
    }

    const variables: UpsertClientOrderVariables = {
      id: upsertData.id || '',
      createData: {
        ...omit(upsertData, ['__typename', 'id', 'createdAt', 'updatedAt']) as GetClientOrdersToProcess_clientOrders,
        productOrders: {
          create: (upsertData.productOrders || []).map(productOrder => ({
            priceExcl: productOrder.priceExcl,
            quantity: productOrder.quantity,
            product: productOrder.product ? {connect: {id: productOrder.product?.id}} : null
          }))
        },
        invoice: null,
        client: {
          connect: {
            id: get(upsertData, 'client.id')
          }
        },
      },
      updateData: {
        ...omit(upsertData, ['__typename', 'id', 'invoice', 'createdAt', 'updatedAt', 'productOrders']) as Clients_clients,
        client: {
          connect: {
            id: get(upsertData, 'client.id')
          }
        },
        productOrders: {
          delete: deletedProductOrderIds.map(id => ({id})),
          create: (upsertData.productOrders || []).filter(po => !po.id || po.id.startsWith('new_')).map(productOrder => ({
            priceExcl: productOrder.priceExcl,
            quantity: productOrder.quantity,
            product: productOrder.product ? {connect: {id: productOrder.product?.id}} : null
          })),
          update: (upsertData.productOrders || []).filter(po => po.id && !po.id.startsWith('new_')).map(productOrder => ({
            where: {
              id: productOrder.id
            },
            data: {
              priceExcl: productOrder.priceExcl,
              quantity: productOrder.quantity,
              product: productOrder.product ? {connect: {id: productOrder.product?.id}} : null
            }
          }))
        },
      }
    };
    upsertClientOrderRemote({
      variables: variables,
      refetchQueries: "active"
    }).then(r => {
      // if (r.data && r.data.upsertClientOrder) {
      //   setValue({
      //     ...value,
      //     clientOrders: {...value.clientOrders, [r.data.upsertClientOrder.id]: r.data.upsertClientOrder}
      //   })
      // }
    });

  };

  const addPaymentToInvoice = (invoiceId: string, payment: { paymentDate: string, amount: number }) => {
    addPaymentToInvoiceRemote({variables: {...payment, id: invoiceId}})
      .then(r => {
        if (r.data && r.data.updateManyClientOrders.count > 0) {
          const updateInvoice = r.data.updateInvoice;
          setValue(state => ({
            ...state,
            clientOrders: {
              ...mapValues(state.clientOrders, clientOrder => {
                if (clientOrder.invoice && clientOrder.invoice.id === invoiceId) {
                  return {
                    ...clientOrder, invoice: {
                      ...updateInvoice || clientOrder.invoice,
                      status: ClientOrderStatus.PAID
                    }
                  }
                }
                return clientOrder;
              }),
            }
          }))
        }
      })
  };

  const markClientOrdersForInvoiceAsSent = (invoiceId: string) => {
    markClientOrdersForInvoiceAsSentRemote({
      variables: {id: invoiceId},
      refetchQueries: "active",
    }).then(r => {
      if (r.data && r.data.updateManyClientOrders.count > 0) {
        setValue(state => ({
          ...state,
          clientOrders: {
            ...mapValues(state.clientOrders, clientOrder => {
              if (clientOrder.invoice && clientOrder.invoice.id === invoiceId) {
                return {...clientOrder, invoice: {...clientOrder.invoice, status: ClientOrderStatus.INVOICE_SENT}}
              }
              return clientOrder;
            }),
          }
        }))
      }
    });
  };

  useEffect(() => {
    if (data) {
      const {products, clientOrders, sequences} = data;
      const clientGroups = data.clientGroups.filter(notEmpty);
      setValue((state) => ({
        ...state,
        sequences: keyBy(compact(sequences), 'id'),
        clientOrders: keyBy(compact(clientOrders), 'id'),
        clientGroups: keyBy(clientGroups, 'id'),
        products: keyBy(compact(products), 'id'),
      }));
    }
  }, [data]);

  return (
    <SalesContext.Provider value={{
      ...value,
      upsertClientOrder,
      createClientOrderInvoice,
      addPaymentToInvoice,
      markClientOrdersForInvoiceAsSent,
    }}>
      {children}
    </SalesContext.Provider>
  );
}

const useSales = () => React.useContext(SalesContext);

export {SalesProvider, useSales};
