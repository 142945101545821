import React from 'react';

interface UnauthorizedAccessPageProps {

}

const UnauthorizedAccessPage = (props: UnauthorizedAccessPageProps) => {
  return <div>
    Je hebt geen toegang tot deze pagina
  </div>;
};

export default UnauthorizedAccessPage;
