import React, {useEffect, useRef, useState} from 'react';
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {Dropdown} from "primereact/dropdown";
import {compact, keyBy, values} from "lodash";
import {
  ClientOrderStatus,
  Clients, ClientsVariables, GetClientOrdersToProcess_clientOrders,
  SalesData_clientOrders_productOrders
} from "../../../../__generated__/types";
import {useSales} from "../../sales-context";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import styled from "styled-components";
import ProductOrderList from "./ProductOrderList";
import {momentFromIso8601} from "../../../../shared/utils/date.utils";
import {ToggleButton} from "primereact/togglebutton";
import {useQuery} from "@apollo/client";
import {ClientsQuery} from "../../../../shared/queries/client.gql";
import {InputNumber} from "primereact/inputnumber";
import {useUserObject} from "../../../../shared/context/UserContext";

interface ClientOrderUpsertFormProps {
  clientOrder: Partial<GetClientOrdersToProcess_clientOrders>;
  onHide: () => void;
}

const Form = styled.div`
  display: grid;
  grid-template-areas: 'client orderDate deliveryDate delivery' 'products products products products';
  grid-gap: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 1rem;
`;

const ClientOrderUpsertForm = (props: ClientOrderUpsertFormProps) => {
  const {activeFarm} = useUserObject();
  const variables: ClientsVariables = {
    farmId: activeFarm?.id ||''
  };
  const {data: clientsData} = useQuery<Clients>(ClientsQuery, {variables});

  const clients = compact(clientsData?.clients);

  const {upsertClientOrder, clientGroups} = useSales();

  const [clientOrder, setClientOrder] = useState<Partial<GetClientOrdersToProcess_clientOrders>>({});
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setClientOrder(props.clientOrder);
  }, [props.clientOrder]);

  const saveClientOrder = () => {
    setSubmitted(true);

    if (clientOrder.client) {
      let _clientOrder = {...clientOrder};
      if (clientOrder.id) {
        upsertClientOrder(_clientOrder);

        toast.current && toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'ClientOrder Updated',
          life: 3000,
        });
      } else {
        upsertClientOrder(_clientOrder);

        toast.current && toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Client Created',
          life: 3000
        });
      }

      setClientOrder({orderDate: moment()});
      props.onHide();
    }
  };

  let identifiedClients = keyBy(clients, 'id');
  const clientGroup = clientOrder && clientOrder.client && clientGroups ? clientGroups[identifiedClients[clientOrder.client.id]?.group.id] : undefined;

  const hideDialog = () => {
    setSubmitted(false);
    props.onHide();
  };

  return <div>
    <div>
      <Toast ref={toast}/>
      <Form className="p-fluid">
        <div style={{gridArea: 'client'}} className="p-field">
          <label htmlFor="name">Klant</label>
          <Dropdown
            filter showClear filterBy="fullName"
            options={values(clients)
              .map(client => ({...client, fullName: `${client.companyName||''} ${client.name||''} ${client.firstName||''}`}))
          }
            disabled={clientOrder.status !== ClientOrderStatus.CREATED}
            optionLabel={'fullName'}
            optionValue={'id'}
            value={clientOrder && clientOrder.client ? clientOrder.client.id : undefined}
            onChange={(e: any) => {
              const clientId = e.value;
              setClientOrder((state) => {
                return {
                  ...state,
                  client: clients.find(client => client.id === clientId),
                };
              });
            }}
          />
          {submitted && !clientOrder.client && <small className="p-error">Klant is verplicht.</small>}
        </div>
        <div style={{gridArea: 'orderDate'}} className="p-field">
          <label htmlFor="name">Orderdatum</label>
          <Calendar
            disabled={clientOrder.status !== ClientOrderStatus.CREATED}
            locale={'nl'}
            dateFormat={"D dd-mm-yy"}
            value={clientOrder && clientOrder.orderDate && momentFromIso8601(clientOrder.orderDate).toDate()}
            onChange={(e) => {
              const orderDate = moment((e.value as Date));
              setClientOrder(state => ({
                ...state,
                orderDate,
                deliveryDate: state.deliveryDate ? state.deliveryDate : orderDate,
              }));
            }}/>
          {submitted && !clientOrder.orderDate && <small className="p-error">Datum is verplicht.</small>}
        </div>

        <div style={{gridArea: 'deliveryDate'}} className="p-field">
          <label htmlFor="name">Leverdatum</label>
          <Calendar
            disabled={clientOrder.status !== ClientOrderStatus.CREATED}
            locale={'nl'}
            dateFormat={"D dd-mm-yy"}
            value={clientOrder && clientOrder.deliveryDate && momentFromIso8601(clientOrder.deliveryDate).toDate()}
            onChange={(e) => setClientOrder(state => ({...state, deliveryDate: moment((e.value as Date))}))}/>
          {submitted && !clientOrder.deliveryDate && <small className="p-error">Datum is verplicht.</small>}
        </div>

        <div style={{gridArea: 'delivery'}} className="p-field">
          <label htmlFor="name">Levering</label>
          <ToggleButton
            onLabel={'Ja'}
            offLabel={'Nee'}
            checked={clientOrder.delivery} onChange={(e) => setClientOrder(state => ({...state, delivery: e.value}))}/>
        </div>
      </Form>
      <div style={{gridArea: 'products'}}>
        <h3>Producten</h3>
        <ProductOrderList
          orderDiscount={clientOrder.orderDiscount}
          disabled={false}
          productOrders={clientOrder && compact(clientOrder.productOrders)}
          clientGroup={clientGroup}
          onChange={(productOrders) => {
            setClientOrder(state => ({
              ...state,
              productOrders: productOrders as SalesData_clientOrders_productOrders[]
            }))
          }}
        />
      </div>
      <div className="flex justify-content-start align-items-center mt-3">
        <label htmlFor="orderDiscount" className="pr-2">Korting</label>
        <InputNumber
          id="orderDiscount"
        value={clientOrder.orderDiscount}
        locale={'nl-NL'}
        minFractionDigits={1}
        maxFractionDigits={3}
        max={0.95}
        onValueChange={(e: any) => {
          setClientOrder({
            ...clientOrder,
            orderDiscount: e.value || 0,
          });
        }}/>
      </div>

    </div>
    <Actions>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text p-button-plain" onClick={hideDialog}/>
      <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveClientOrder}/>
    </Actions>
  </div>;
};

export default ClientOrderUpsertForm;
