import {
  ClientOrder_clientOrder_invoice,
  ClientOrderStatus,
  ClientType,
  GetClientOrdersToProcess_clientOrders_client,
  InvoiceType,
  PaymentCreateWithoutInvoiceInput,
  ProductType,
  ProductUnit,
  Vat
} from "../../../__generated__/types";
import {camelCase, ceil, chain, compact, every, first, range, some, sum, times} from "lodash";
import moment, {Moment} from "moment";
import {
  asDayFullDdMmYyyy,
  asDdMmYyyyNonNull,
  asIso8601,
  momentFromDdMmYyyy,
  momentFromIso8601
} from "../../../shared/utils/date.utils";
import {translatedUnit} from "../../../shared/utils/unit.utils";
import {formatDoubleDigit} from "../../../shared/utils/currency.utils";
import {vat} from "../../../shared/utils/vat.utils";
import {clientToRecipient} from "../../../shared/pdf/pdf-data.utils";
import {ClientOrderInvoicePdfData, ClientOrderInvoicePdfProductLine, PaymentStatus} from "../../../shared/utils/invoice.utils";
import {clientOrderTotals, productOrderPriceWithDiscount, vatForValue} from "../../../utils/clientOrder.utils";
import {productIsAvailableAsAlternativeUnitFor} from "../../../shared/utils/product.utils";
import {TODAY} from "../../../shared/config";

export interface ClientOrderForInvoice {
  id: string;
  orderDate: any;
  orderDiscount: number | null | undefined;
  deliveryDate: any;
  deliveryEndDate: any;
  delivery: boolean;
  status: ClientOrderStatus;
  client: {
    name: string | null
    firstName: string | null
    companyName: string | null
    street: string | null
    streetNumber: string | null
    cityPostalCode: string | null
    city: string | null
    vatNumber: string | null
    type: ClientType | null
    group: { id: string } | null;
  };
  productOrders: {
    id: string;
    priceExcl: number;
    quantity: number;
    ambassadorDiscount: boolean;
    productDescription?: string | null;
    vat?: Vat | null;
    product: {
      name: string;
      unit: ProductUnit | null;
      amount: number | null;
      type: ProductType;
      availableAsAlternativeUnitFor: string[];
      alternativeUnit: ProductUnit | null;
      avgWeight: number | null;
      vat: Vat;
      category?: { code: string | null } | null,
    } | null;
    deliveries: {
      plannedDeliveryDate: string;
    }[] | null;
  }[] | null;
}

let CATEGORY_CODES_FOR_DELIVERIES = ['eieren', 'pakketten', 'aardappelen', 'uien'];
let CATEGORY_CODES_FOR_PERSONS = ['zelfoogst'];

const calculateInvoiceTotals = (
  clientOrders: {
    orderDiscount?: number | null | undefined,
    productOrders: { priceExcl?: number | null | undefined, product: { vat?: Vat | null | undefined } | null }[] | null
  }[] | null
): {
  totalIncl: number,
  totalExcl: number,
  subTotalExcl?: number,
  discountExcl?: number,
  vat6: number,
  vat12: number,
  vat21: number,
} => {
  return compact(clientOrders)
    .reduce((globalTotals, clientOrder) => {
      const totals = clientOrderTotals(clientOrder);

      return {
        ...globalTotals,
        totalIncl: globalTotals.totalIncl + totals.incl.total,
        totalExcl: globalTotals.totalExcl + totals.excl.total,
        vat6: globalTotals.vat6 + sum(compact(clientOrder.productOrders).filter(productOrder => productOrder.product?.vat === Vat.VAT_6)
          .map(productOrder => vatForValue(productOrderPriceWithDiscount(false, clientOrder.orderDiscount)(productOrder), Vat.VAT_6))
        ),
        vat12: globalTotals.vat12 + sum(compact(clientOrder.productOrders).filter(productOrder => productOrder.product?.vat === Vat.VAT_12)
          .map(productOrder => vatForValue(productOrderPriceWithDiscount(false, clientOrder.orderDiscount)(productOrder), Vat.VAT_12))
        ),
        vat21: globalTotals.vat21 + sum(compact(clientOrder.productOrders).filter(productOrder => productOrder.product?.vat === Vat.VAT_21)
          .map(productOrder => vatForValue(productOrderPriceWithDiscount(false, clientOrder.orderDiscount)(productOrder), Vat.VAT_21))
        ),
        subTotalExcl: globalTotals.subTotalExcl + totals.excl.subTotal,
        discountExcl: globalTotals.discountExcl + totals.excl.orderDiscount,
      };
    }, {
      totalIncl: 0,
      totalExcl: 0,
      subTotalExcl: 0,
      discountExcl: 0,
      vat6: 0,
      vat12: 0,
      vat21: 0,
    });
};

const createInvoiceMetaData = (invoiceDate: Moment,
                               invoiceNumber: string,
                               client: {
                                 name: string | null;
                                 firstName: string | null;
                                 street: string | null;
                                 streetNumber: string | null;
                                 cityPostalCode: string | null;
                                 city: string | null;
                                 vatNumber: string | null;
                                 type: ClientType | null
                               }
) => {
  let expirationDate = invoiceDate.clone().add(1, 'month');

  let meta = [
    {
      label: 'Factuurnummer',
      value: invoiceNumber,
    },
    {
      label: 'Factuurdatum',
      value: asDdMmYyyyNonNull(invoiceDate),
    },
    {
      label: 'Vervaldatum',
      value: asDdMmYyyyNonNull(expirationDate),
    },
  ];
  if (client.type === ClientType.BUSINESS || client.vatNumber) {
    meta.push({
      label: "Btw nummer klant", value: client.vatNumber ? `BTW ${client.vatNumber}` : '',
    });
  }

  return {expirationDate, meta};
};

const productUnitForProductOrder = (productOrder:
                                      {
                                        quantity: number
                                        deliveries: {}[] | null
                                      },
                                    product: {
                                      unit: ProductUnit | null;
                                      amount: number | null;
                                      type: ProductType;
                                      avgWeight: number | null;
                                      availableAsAlternativeUnitFor: string[];
                                      alternativeUnit: ProductUnit | null;
                                      category?: { code: string | null } | null;
                                    } | null,
                                    clientGroupId: string | undefined) => {
  if (product) {
    let quantity = productOrder.quantity;
    let unit = product.unit;
    if (product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, clientGroupId)) {
      quantity = quantity / product.avgWeight;
      unit = product.alternativeUnit;
    }

    let productUnit = `${formatDoubleDigit(quantity * (product.amount || 0))} ${translatedUnit(unit)}`;

    if (product.type === ProductType.SUBSCRIPTION && product.category?.code && CATEGORY_CODES_FOR_DELIVERIES.includes(product.category.code) && productOrder.deliveries) {
      productUnit = `${productOrder.deliveries.length} leveringen`;
    }
    if (product.category?.code && CATEGORY_CODES_FOR_PERSONS.includes(product.category.code)) {
      productUnit = `${formatDoubleDigit(productOrder.quantity * (product.amount || 0))} personen`;
    }
    return productUnit;
  } else {
    return "-";
  }
};

export const createInvoiceData = (
  clientOrder: ClientOrderForInvoice,
  invoiceNumber: string,
  sponsoring: boolean,
  payments?: PaymentCreateWithoutInvoiceInput[],
  invoice?: ClientOrder_clientOrder_invoice,
): ClientOrderInvoicePdfData => {
  const {client} = clientOrder;
  let productOrders = compact(clientOrder.productOrders);
  let productLines: ClientOrderInvoicePdfProductLine[] = chain(productOrders)
    .map(productOrder => {
      const product = productOrder.product;
      if (product) {
        let productUnit = productUnitForProductOrder(productOrder, product, client.group?.id);

        let description = productOrder.ambassadorDiscount ? 'Ambassadeurskorting' : product.name;

        let info = undefined;
        if (product.category?.code === 'leeggoed') {
          info = `(De bakken blijven eigendom van Groentegeweld, bij het einde van het abonnement dienen deze proper te worden ingeleverd.)`;
        }

        if (product.category?.code === 'pakketten' && clientOrder.deliveryEndDate) {
          info = `Van: ${asDayFullDdMmYyyy(momentFromIso8601(clientOrder.deliveryDate))} tot: ${asDayFullDdMmYyyy(momentFromIso8601(clientOrder.deliveryEndDate))}`;
        }

        return {
          deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
          description,
          info,
          productUnit: productOrder.ambassadorDiscount || product.category?.code === 'ambassadeur' ? '' : productUnit,
          totalPriceExcl: productOrder.priceExcl,
          unitPriceExcl: productOrder.priceExcl / productOrder.quantity,
          unitPriceIncl: (productOrder.priceExcl / productOrder.quantity) * (1 + vat(product.vat)),
          vat: product.vat,
          productOrderId: productOrder.id,
          discount: productOrder.ambassadorDiscount,
        }
      } else if (productOrder.productDescription) {
        let description = productOrder.productDescription;

        let vatValue = productOrder.vat || Vat.VAT_0;
        return {
          deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
          description,
          productUnit: '',
          totalPriceExcl: productOrder.priceExcl,
          unitPriceExcl: productOrder.priceExcl / productOrder.quantity,
          unitPriceIncl: (productOrder.priceExcl / productOrder.quantity) * (1 + vat(vatValue)),
          vat: vatValue,
          productOrderId: productOrder.id,
          discount: productOrder.ambassadorDiscount,
        }
      }
    })
    .compact()
    .value();

  let invoiceData = calculateInvoiceTotals([clientOrder]);

  if (sponsoring) {
    productLines = [{
      productUnit: '1',
      unitPriceIncl: invoiceData.totalIncl,
      totalPriceExcl: invoiceData.totalIncl / 1.21,
      vat: Vat.VAT_21,
      unitPriceExcl: invoiceData.totalExcl,
      deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder?.deliveryDate)),
      description: "Sponsoring"
    }];

    invoiceData = {
      totalIncl: invoiceData.totalIncl,
      totalExcl: 0,
      vat6: 0,
      vat12: 0,
      vat21: 0,
    }
  }

  if (clientOrder.orderDiscount && clientOrder.orderDiscount > 0) {
    productLines = [
      ...productLines,
      {
        deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
        description: `Korting`,
        productUnit: '',
        discount: true,
        totalPriceExcl: -clientOrderTotals(clientOrder).excl.orderDiscount,
        unitPriceExcl: -clientOrderTotals(clientOrder).excl.orderDiscount,
        vat: Vat.VAT_6,
      }
    ];
  }
  let invoiceDate = invoice ? momentFromDdMmYyyy(invoice.data.meta.find((m: { label: string }) => m.label === 'Factuurdatum').value) : TODAY;
  let {expirationDate, meta} = createInvoiceMetaData(invoiceDate, invoiceNumber, client);

  let paymentsCreate: PaymentCreateWithoutInvoiceInput[];

  if (payments && payments.length > 0) {
    paymentsCreate = payments
  } else {
    paymentsCreate = [{
      amount: invoiceData.totalIncl,
      dueDate: asIso8601(expirationDate),
    }];
  }
  return {
    type: InvoiceType.CLIENT,
    filename: `${invoiceNumber}-${camelCase(client.companyName || client.name || '')}.pdf`,
    number: invoiceNumber,
    paymentCreates: {
      create: paymentsCreate,
    },
    data: {
      ...invoiceData,
      payments: paymentsCreate,
      clientType: client.type,
      includeTermsFor: productOrders
          .find(po => po.product?.category?.code && ['zelfoogst', 'pakketten'].includes(po.product?.category.code))?.product?.category?.code as ('zelfoogst' | 'pakketten'),
      recipient: clientToRecipient(client),
      meta,
      productLines: productLines,
    }
  };
};


export const createClientOrderInvoiceData = (
  clientOrders: {
    deliveryEndDate: string | null,
    deliveryDate: string,
    orderDiscount: number | null,
    delivery: boolean,
    productOrders: {
      id: string,
      productDescription: string | null,
      priceExcl: number,
      product: {
        name: string,
        unit: ProductUnit | null,
        amount: number | null,
        type: ProductType,
        avgWeight: number | null,
        availableAsAlternativeUnitFor: string[],
        alternativeUnit: ProductUnit | null,
        category?: { code: string | null } | null,
        vat: Vat,
      } | null,
      ambassadorDiscount: boolean,
      quantity: number
      deliveries: {}[] | null
    }[] | null,
  }[],
  client: GetClientOrdersToProcess_clientOrders_client,
  invoiceNumber: string,
  sponsoring: boolean,
  invoiceDate: Moment,
  payments?: PaymentCreateWithoutInvoiceInput[],
): ClientOrderInvoicePdfData => {

  let productOrders = chain(clientOrders)
    .map("productOrders")
    .compact()
    .flatten()
    .value();

  let clientOrder = first(clientOrders);
  let productLines = clientOrders.reduce((productLines: ClientOrderInvoicePdfProductLine[], clientOrder) => {
    let productLinesForClientOrder: ClientOrderInvoicePdfProductLine[] = (clientOrder.productOrders || []).map(productOrder => {
      const product = productOrder.product;
      let productUnit = productUnitForProductOrder(productOrder, product, client.group?.id);

      let description = productOrder.ambassadorDiscount
        ? 'Ambassadeurskorting'
        : product?.name
          ? product.name
          : (productOrder.productDescription || "-");

      let info = undefined;
      if (productOrder.product?.category?.code === 'leeggoed') {
        info = `(De bakken blijven eigendom van Groentegeweld, bij het einde van het abonnement dienen deze proper te worden ingeleverd.)`;
      }

      if (productOrder.product?.category?.code === 'pakketten' && clientOrder.deliveryEndDate) {
        info = `Van: ${asDayFullDdMmYyyy(momentFromIso8601(clientOrder.deliveryDate))} tot: ${asDayFullDdMmYyyy(momentFromIso8601(clientOrder.deliveryEndDate))}`;
      }

      return {
        deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
        description,
        info,
        productUnit: productOrder.ambassadorDiscount || productOrder.product?.category?.code === 'ambassadeur' ? '' : productUnit,
        totalPriceExcl: productOrder.priceExcl,
        unitPriceExcl: productOrder.priceExcl / productOrder.quantity,
        vat: product?.vat || Vat.VAT_0,
        productOrderId: productOrder.id,
      }
    });

    if (clientOrder.orderDiscount && clientOrder.orderDiscount > 0) {
      productLinesForClientOrder = [
        ...productLinesForClientOrder,
        {
          deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
          description: `Korting -${clientOrder.orderDiscount * 100}%`,
          productUnit: '',
          discount: true,
          totalPriceExcl: -clientOrderTotals(clientOrder).excl.orderDiscount,
          unitPriceExcl: -clientOrderTotals(clientOrder).excl.orderDiscount,
          vat: Vat.VAT_0,
        }
      ];
    }


    let updatedProductLines = [...productLines, ...productLinesForClientOrder];
    if (clientOrder.delivery) {
      updatedProductLines = [...updatedProductLines, {
        deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
        description: `Levering`,
        totalPriceExcl: 10,
        unitPriceExcl: 10,
        vat: Vat.VAT_6,
      }];
    }

    return updatedProductLines;
  }, []);

  let invoiceData = calculateInvoiceTotals(clientOrders);

  if (sponsoring && clientOrder) {
    productLines = [{
      productUnit: '1',
      unitPriceIncl: invoiceData.totalIncl,
      totalPriceExcl: invoiceData.totalIncl / 1.21,
      vat: Vat.VAT_21,
      unitPriceExcl: invoiceData.totalExcl,
      deliveryDate: asDdMmYyyyNonNull(momentFromIso8601(clientOrder.deliveryDate)),
      description: "Sponsoring"
    }];

    invoiceData = {
      totalIncl: invoiceData.totalIncl,
      totalExcl: 0,
      vat6: 0,
      vat12: 0,
      vat21: 0,
    }
  }

  let {expirationDate, meta} = createInvoiceMetaData(invoiceDate, invoiceNumber, client);

  let paymentsCreate: PaymentCreateWithoutInvoiceInput[];
  if (payments && payments.length > 0) {
    paymentsCreate = payments
  } else {
    paymentsCreate = [{
      amount: invoiceData.totalIncl,
      dueDate: asIso8601(expirationDate),
    }];
  }
  return {
    type: InvoiceType.CLIENT,
    filename: `${invoiceNumber}-${camelCase(client.companyName || client.name || '')}.pdf`,
    number: invoiceNumber,
    paymentCreates: {
      create: paymentsCreate,
    },
    data: {
      ...invoiceData,
      payments: paymentsCreate,
      clientType: client.type,
      includeTermsFor: productOrders.find(po => po.product?.category?.code && ['zelfoogst', 'pakketten'].includes(po.product?.category.code))?.product?.category?.code as ('zelfoogst' | 'pakketten'),
      recipient: clientToRecipient(client),
      meta,
      productLines: productLines,
    }
  };
};

const paymentDate = (firstPaymentDate: Moment, daysBetweenPayments: number, nrOfPayment: number): Moment => {
  return firstPaymentDate.clone().add(daysBetweenPayments * nrOfPayment, 'days');
}

const paymentCreateInput = (amount: number, firstPaymentDate: Moment, daysBetweenPayments: number, t: number): PaymentCreateWithoutInvoiceInput => {
  return {
    amount,
    dueDate: asIso8601(paymentDate(firstPaymentDate, daysBetweenPayments, t)),
  };
}

export const calculatePayments = (
  nrOfPayments: number,
  totalIncl: number,
  prePayment: number,
  firstDeliveryDate: string,
  firstPaymentDate: Moment,
): PaymentCreateWithoutInvoiceInput[] => {
  if (nrOfPayments === 1) {
    return [{
      amount: totalIncl,
      dueDate: asIso8601(moment().add(1, 'month')),
    }];
  }

  const lastAllowedPaymentDate = momentFromIso8601(firstDeliveryDate).add(4, 'M');
  const daysBetweenPayments = ceil(lastAllowedPaymentDate.diff(firstPaymentDate, 'day') / nrOfPayments);
  if (prePayment > 0) {
    const payments = [{
      amount: prePayment,
      dueDate: asIso8601(firstPaymentDate),
    }];
    const remainingAmount = totalIncl - prePayment;
    return [
      ...payments,
      ...range(1, nrOfPayments).map(t => paymentCreateInput(remainingAmount / (nrOfPayments - 1), firstPaymentDate, daysBetweenPayments, t))
    ];
  } else {
    return times(nrOfPayments).map(t => paymentCreateInput(totalIncl / nrOfPayments, firstPaymentDate, daysBetweenPayments, t));
  }
};

export const invoicePaymentStatus = (invoice: {
  payments: {
    paid: boolean | null,
  }[] | null,
}): PaymentStatus => {
  let status: PaymentStatus;
  const paymentStatuses = compact(invoice.payments).map(p => p.paid);
  if (every(paymentStatuses, x => x)) {
    status = 'paid';
  } else if (some(paymentStatuses, x => x)) {
    status = 'paidPartially';
  } else {
    status = 'unpaid';
  }

  return status;
};
