import React from 'react';
import {useUserObject} from "../../../shared/context/UserContext";
import {useMutation, useQuery} from "@apollo/client";
import {
  FarmConfiguration,
  UpsertFarmConfiguration,
  UpsertFarmConfigurationVariables
} from "../../../__generated__/types";
import {FarmConfigurationQuery, FarmConfigurationUpsertMutation} from "../farm.gql";
import {InputSwitch} from "primereact/inputswitch";
import {FarmConfigCodes, getFarmConfig, isEnabled} from "../../../shared/utils/farmConfig.utils";
import {compact} from "lodash";
import FarmConfigViewer from "../FarmConfigViewer/FarmConfigViewer";


const Baskets = () => {
  const {activeFarm} = useUserObject();

  const {data} = useQuery<FarmConfiguration>(FarmConfigurationQuery, {variables: {id: activeFarm?.id || ''}});
  const [updateFarmConfiguration] = useMutation<UpsertFarmConfiguration>(FarmConfigurationUpsertMutation);

  return <div>
    <div>
      <InputSwitch checked={isEnabled(data?.farm?.configuration, FarmConfigCodes.baskets)}
                   onChange={(e) => {
                     if (activeFarm) {
                       let farmConfig = getFarmConfig(data?.farm?.configuration, FarmConfigCodes.baskets);
                       let variables: UpsertFarmConfigurationVariables = {
                         id: farmConfig?.id || '',
                         createData: {
                           code: FarmConfigCodes.baskets.toString(),
                           value: {value: e.value},
                           farm: {
                             connect: {id: activeFarm.id}
                           }
                         },
                         updateData: {
                           value: {value: e.value},
                         }
                       };
                       updateFarmConfiguration({
                         variables: variables,
                         refetchQueries: "active"
                       });
                     }
                   }}
      />
    </div>

    <div className="font-bold text-xl">Instellingen</div>
    {activeFarm && <FarmConfigViewer farmId={activeFarm.id}/>}
  </div>;
};

export default Baskets;
