import { BasketType } from "../../__generated__/types";


export type Props<P> = P & { className?: string };

export type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T; };

export type FlexCodes = {
  [BasketType.STANDARD]: {
    codes: string[]
  }, [BasketType.LARGE]: {
    codes: string[]
  }
};

export type FormulaPricing = {
  target: number,
  trial?: number,
}

export interface FormulaPeriod {
  label: string,
  hidden?: boolean,
  description: string,
  codeSmall?: string,
  codeLarge?: string,
  biWeekly?: boolean,
  code?: string,
  nrOfWeeks: number,
  nrOfPossibleWeeks: number,
  order: number;
  showTimeline: boolean,
  portionsSmall?: number;
  portionsLarge?: number;
  deliveryWeeks?: string[];
}

export enum SubscriptionSubType {
  SEASONAL_BASKET = "SEASONAL_BASKET",
  SELF_HARVEST = "SELF_HARVEST",
  WINTER_BASKET = "WINTER_BASKET",
  YEAR_BASKET = "YEAR_BASKET",
  BASKETS_FIXED = "BASKETS_FIXED",
  BASKETS_FLEX = "BASKETS_FLEX",
}

export enum SubscriptionType {
  BASKETS = "BASKETS",
  SELF_HARVEST = "SELF_HARVEST",
  BASKETS_FLEX = "BASKETS_FLEX",
}

export type FormulaType = {
  productCode?: string;
  productCodeTrial?: string;
  formulaType: SubscriptionType,
  membershipSubType: SubscriptionSubType,
  disabled: boolean,

  /**
   * @deprecated Use deliveryWeeks
   * */
  weeks: {
    [key in '2022-2023']: number[]
  },
  name: string,
  shortDescription: string,
  description?: string[],
  allowTrial: boolean;
  pricing: {
    title: string,
    type?: BasketType,
    price: FormulaPricing
  }[];
  active: boolean,
  periods?: FormulaPeriod[],
  deliveryWeeks?: string[],
};
