import React from 'react';
import classNames from "classnames";

interface ProgressBarProps {
  value: number;
  label?: string;
  labelValue?: number;
  max: number | undefined | null;
  suffix?: string;
  inline?: boolean
}

const ProgressBar = (props: ProgressBarProps) => {
  let width = props.max ?
    (props.value / props.max) * 100
    : props.value;
  return <div className="flex justify-content-between">
    {props.label && <div>{props.label}</div>}
    <div className={classNames("w-15rem mb-1", {
      "grid grid-nogutter align-items-center": props.inline,
    })}>
      <div
        className={
          classNames(
            "relative surface-200 border-round overflow-hidden flex align-items-stretch justify-content-center",
            {
              "col-8": props.inline,
              "w-full ": !props.inline,
            }
          )
        }>
        <div
          className="h-full bg-orange-80 absolute h-full text-center top-0 bottom-0 left-0"
          style={{width: `${width}%`}}/>
        <div className="relative text-base text-700 font-medium line-height-1 p-1 z-1">
          {props.labelValue || props.value} van {props.max}
        </div>
      </div>

      <div className={
        classNames("text-center text-500 text-sm white-space-nowrap",
          {
            "pl-2 col-4": props.inline,
          }
        )
      }>
        {props.suffix}</div>
    </div>
  </div>;
};

export default ProgressBar;
