import React, {useState} from 'react';
import AddNewBasketCompositionSetForm from "./components/AddNewBasketCompositionSetForm";
import BasketCompositionSetViewer from './components/BasketCompositionSetViewer/BasketCompositionSetViewer';
import {useQuery} from "@apollo/client";
import {compact} from "lodash";
import { BasketCompositionSetsQuery } from 'shared/queries/basketComposition.gql';
import {BasketCompositionSets, BasketSize} from "../../../__generated__/types";
import classNames from "classnames";
import {momentFromIso8601} from "../../../shared/utils/date.utils";
import {TODAY} from "../../../shared/config";

interface BasketComposerPage2Props {

}

export const BASKET_SIZES = [BasketSize.LARGE, BasketSize.SMALL];

const BasketComposerPage2 = (props: BasketComposerPage2Props) => {
  const {data} = useQuery<BasketCompositionSets>(BasketCompositionSetsQuery);

  const [expandedIds, setExpandedIds] = useState<string[]>(["cloyw00wq00780775uv5p8adq"]);

  return <div>
    <AddNewBasketCompositionSetForm/>
    {compact(data?.basketCompositionSets).map(set =>
    <BasketCompositionSetViewer
      set={set}
      expanded={expandedIds.includes(set.id)}
      onExpand={() => {
        setExpandedIds(value => {
          if (value.indexOf(set.id) != -1) {
            value.splice(value.indexOf(set.id), 1)
          } else {
            value.push(set.id);
          }
          return [...value];
        });
      }}
      className={classNames({
        'border-2 border-teal-700 bg-teal-100': momentFromIso8601(set.deliveryWeek).isSame(TODAY, "isoWeek")
      })
      }
    />
    )}
  </div>;
};

export default BasketComposerPage2;
