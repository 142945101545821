import React, {useState} from 'react';
import {Button} from "primereact/button";
import emailjs from 'emailjs-com';
import {Dialog} from "primereact/dialog";
import {useMutation, useQuery} from "@apollo/client";
import {
  ClientOrder,
  ClientOrder_clientOrder_productOrders,
  MarkClientOrdersForInvoiceAsSent
} from "../../../__generated__/types";
import {ClientOrderQuery} from "../../../shared/queries/clientOrder.gql";
import {MarkClientOrdersForInvoiceAsSentMutation} from "../../sales/queries.gql";
import {asDayFullDdMmYyyy, momentFromIso8601} from "../../../shared/utils/date.utils";
import {first} from "lodash";
import {toCity} from "../../../shared/utils/deliveryLocation.utils";
import {formatCurrency} from "../../../shared/utils/currency.utils";

interface MailButtonProps {
  clientOrderId: string;
}

export function sendInvoiceToClient(
  clientOrder: {
    id: string;
    client: {
      firstName: string | null;
      id: string;
      billingEmail?: string | null | undefined;
      email: string | null;
    };
    invoice: {
      id: string;
      number: string;
      data: any | null;
    } | null;
    deliveryDate: string;
    deliveryEndDate?: string | null;
    productOrders: ClientOrder_clientOrder_productOrders[] | null;
  },
  reminder: boolean,
  dryRun: boolean,
  onSucces: (invoice: { id: string }) => void,
  onError: ((error: any) => void) | undefined,
  onComplete: (() => void) | undefined,
) {
  const client = clientOrder.client;
  const invoice = clientOrder.invoice;
  let recipient: string | null = null;
  if (invoice && client) {
    recipient = client.billingEmail || client.email;

    let selfharvestProductOrder = clientOrder?.productOrders?.find(po => po.product?.category?.code === 'zelfoogst');
    let basketProductOrder = clientOrder?.productOrders?.find(po => po.product?.category?.code === 'pakketten');
    let deliveryStartDate = asDayFullDdMmYyyy(momentFromIso8601(clientOrder?.deliveryDate));
    const emailIntro = selfharvestProductOrder && clientOrder?.deliveryEndDate
      ? `Welkom bij Groentegeweld. Je schreef je in voor een zelfoogstabonnement. Dit wil zeggen dat je vanaf ${deliveryStartDate} tot ${asDayFullDdMmYyyy(momentFromIso8601(clientOrder.deliveryEndDate))} je groenten zelf mag komen kiezen, snijden, plukken etc.
`
      : `Welkom bij Groentegeweld. Je schreef je in voor een ${basketProductOrder?.product?.name} in ${toCity(first(basketProductOrder?.deliveries)?.deliveryLocation)}. Dit wil zeggen dat je vanaf ${deliveryStartDate} je eerste groentepakket kan afhalen! `;

    emailjs.send('service_8ch8mgd', 'invoice_membership', {
      invoice_number: invoice.number,
      subject: (reminder ? '[Herinnering] ': '') + 'Betalingsuitnodiging voor je ' + (selfharvestProductOrder ? 'zelfoogstabonnement' : 'groentepakket'),
      toEmail: dryRun ? 'willem.vanpelt@me.com' : recipient,
      //toEmail: 'info@groentegeweld.be',
      //invoice: data,
      invoiceUrl: `https://shop.groentegeweld.be/clientsBilling?client=${client?.id}`,
      //invoiceFilename: fileName,
      toName: client?.firstName,
      //http://www.groentegeweld.be/facturatie?id={{invoiceId}}
      //invoiceId: invoice?.id,
      membershipOption: selfharvestProductOrder ? 'zelfoogstabonnement' : 'groentepakket',
      emailIntro,
      totalInvoiceAmount: formatCurrency(invoice.data.totalIncl),

    })
      .then(() => {
        onComplete && onComplete();
        if (invoice) {
          onSucces(invoice);
        }
      }, (error) => {
        console.log(error.text);
        onError && onError(error);
        onComplete && onComplete();
      });
  }
}

const MailButton = (props: MailButtonProps) => {
  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});
  const [markClientOrdersForInvoiceAsSentRemote] = useMutation<MarkClientOrdersForInvoiceAsSent>(MarkClientOrdersForInvoiceAsSentMutation);

  const [showMailDialog, setShowMailDialog] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  let clientOrder = data?.clientOrder;
  let invoice = clientOrder?.invoice;
  let client = clientOrder?.client;

  const onClick = (dryRun: boolean) => async () => {
    console.log('pipe', invoice, clientOrder);
    if (invoice && clientOrder) {
      setSending(true);
      sendInvoiceToClient(
        clientOrder,
        false,
        dryRun,
        (invoice: { id: string }) => markClientOrdersForInvoiceAsSentRemote({
          variables: {id: invoice.id}
        }).then(() => {
        }),
        undefined,
        () => {
          setSending(false);
        }
      );
    }
  };
  return <>
    <Dialog visible={showMailDialog} onHide={() => setShowMailDialog(false)}>
      <div>
        <p>De factuur wordt verzonden naar <strong>{client?.billingEmail || client?.email}</strong></p>
        <Button
          disabled={sending}
          label={'Versturen'}
          loading={sending}
          onClick={onClick(false)}
        />
        <Button
          disabled={sending}
          className={'p-button-text'}
          label={'Versturen als test (info@groentegeweld.be)'}
          loading={sending}
          onClick={onClick(true)}
        />
      </div>
    </Dialog>
    <Button
      className={'p-button-link'}
      label={'Verstuur de factuur'}
      onClick={() => {
        setShowMailDialog(true);
      }}
    />
  </>;
};

export default MailButton;
