import React, {useState} from 'react';
import {Button} from "primereact/button";
import {useClientUpdateMutation} from "../../../mutations/client.mutations";
import {findBasketProductOrder, findSelfHarvestProductOrder} from "../../../shared/utils/productOrder.utils";
import {CreateUser, CreateUserVariables, Permission} from "../../../__generated__/types";
import {useMutation} from "@apollo/client";
import {CreateUserMutation} from "../../../shared/queries/user.gql";
import {SubscriptionClientOrders_productOrders} from "./SubscriptionTable/SubscriptionTable";

type Client = {
  id: string,
  email: string | undefined | null,
  firstName: string | undefined | null,
  name: string | undefined | null,
  farm: {id: string},
}

interface CreateAccountButtonProps {
  client: Client;
  productOrders: SubscriptionClientOrders_productOrders[];
}


async function sendCreateUserRequest(client: Client) {
  const tokenResponse = await fetch('https://dev-syqz0u3n.eu.auth0.com/oauth/token', {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    body: '{"client_id":"qBTfsct2hygMWSHOXZHbfVHdw8B5jewv","client_secret":"leqGNu0qYyFZBBDBF6DsnaQ1hwcINzLTwgv_XWp9gNEy8IW6eUPqSR7ejHwVNw4u","audience":"https://auth.softfarms.be/","grant_type":"client_credentials"}'
  });

  const tokenBody = await tokenResponse.json();
  const metadataResponse = await fetch(`https://api.groentegeweld.be/api/user`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tokenBody['access_token']}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: client.email,
      user_metadata: {},
      email_verified: false,
      verify_email: true,
      given_name: client.firstName,
      family_name: client.name,
      name: `${client.firstName} ${client.name}`,
      nickname: client.firstName,
      connection: "Username-Password-Authentication",
      password: "Secret123",
    })
  });

  return await metadataResponse.json();
}

export const createUserWithPermissions = async (productOrders: SubscriptionClientOrders_productOrders[],
                                         auth0UserId: string,
                                         client: Client,
                                         createUser: any) => {
  let hasBasket = findBasketProductOrder(productOrders) !== undefined;
  let hasSelfHarvest = findSelfHarvestProductOrder(productOrders) !== undefined;

  let permissions = [];
  if (hasBasket) {
    permissions.push(Permission.CLIENT_BASKET);
  }
  if (hasSelfHarvest) {
    permissions.push(Permission.CLIENT_SELF_HARVEST);
  }

  if (client.email && client.name && client.firstName) {
    const variables: CreateUserVariables = {
      data: {
        auth0userId: auth0UserId,
        email: client.email.toLowerCase(),
        name: client.name,
        firstName: client.firstName,
        permissions: {set: permissions},
        farms: {
          connect: [{
            id: client.farm.id
          }]
        }
      }
    };
    const result = await createUser({variables});
    console.log(result);
  }
};

const CreateAccountButton = (props: CreateAccountButtonProps) => {
  const [createUser] = useMutation<CreateUser>(CreateUserMutation, {
    refetchQueries: 'active'
  });
  const [updateClient] = useClientUpdateMutation();
  const [connectingWithAuth0, setConnectingWithAuth0] = useState<boolean>(false);
  const addAuth0userIdToClient = async (client: Client) => {
    if (client.email && client.name && client.firstName) {
      try {
        const response = await sendCreateUserRequest(client);
        if (response.create.user_id) {
          await createUserWithPermissions(props.productOrders, response.create.user_id, client, createUser);

          updateClient({
            variables: {
              data: {
                auth0userId: response.create.user_id,
              },
              id: client.id,
            },
            refetchQueries: "active",
          })
            .then(r => console.log(r));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };


  return <Button
    disabled={connectingWithAuth0}
    loading={connectingWithAuth0}
    link
    label='Maak een Auth0 gebruiker aan'
    onClick={async () => {
      setConnectingWithAuth0(true);
      if (props.client) {
        await addAuth0userIdToClient(props.client);
        setConnectingWithAuth0(false);
      }
    }}
  />;
};

export default CreateAccountButton;
