import React from 'react';
import {Client, Client_client, CreateUser, User, UserVariables} from "../../../../__generated__/types";
import {useMutation, useQuery} from "@apollo/client";
import {ClientQuery} from "../../../../shared/queries/client.gql";
import CreateAccountButton, {createUserWithPermissions} from "../CreateAccountButton";
import {SubscriptionClientOrders_productOrders} from "../SubscriptionTable/SubscriptionTable";
import EditableDescriptionItem from "../../../../shared/components/DescriptionList/EditableDescriptionItem";
import {UserQuery} from "../../../../shared/context/UserContext.gql";
import {Button} from "primereact/button";
import {CreateUserMutation} from "../../../../shared/queries/user.gql";

interface AccountInformationProps {
  client: { id: string, email: string | null };
  productOrders: SubscriptionClientOrders_productOrders[];
}


const AccountInformation = (props: AccountInformationProps) => {
  const [createUser] = useMutation<CreateUser>(CreateUserMutation, {
    refetchQueries: 'active'
  });
  const {data, loading} = useQuery<Client>(ClientQuery, {variables: {id: props.client.id}});
  let userVariables: UserVariables = {where: {email: props.client.email}};
  const {data: userData} = useQuery<User>(UserQuery, {variables: userVariables});

  let client = data?.client;

  if (loading) {
    return <div>loading</div>;
  }

  if (client) {
    return <div>

      <ul className="list-none p-0 m-3">
        <li className="flex align-items-start py-1 px-2 border-top-1 border-300 flex-wrap">
          <div className=" w-7 md:w-3 font-medium text-right px-2 color-green">Auth0 gebruiker</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 flex flex-wrap gap-1">
            {client.auth0userId}
            {!client.auth0userId &&
              <CreateAccountButton
                client={client}
                productOrders={props.productOrders}
              />}

          </div>
        </li>
        <li className="flex align-items-start py-1 px-2 border-top-1 border-300 flex-wrap">
          <div className=" w-7 md:w-3 font-medium text-right px-2 color-green">Mijn Boer gebruiker</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 flex flex-wrap gap-1">
            {userData?.user?.id}
            {!userData?.user?.id &&
              <Button
                link
                label="Maak Mijn Boer gebruiker aan"
                onClick={async () => {
                  if (client?.auth0userId) {
                    await createUserWithPermissions(props.productOrders, client?.auth0userId, client, createUser);
                  }
                }}
              />
            }
          </div>
        </li>
        <li className="flex align-items-start py-1 px-2 border-top-1 border-300 flex-wrap">
          <div className=" w-7 md:w-3 font-medium text-right px-2 color-green">Rechten</div>
          <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 flex flex-wrap gap-1">
            <ul>

              {userData?.user?.permissions
                .map(permission => <li key={`permission-${permission}`}>
                  {permission}
                </li>)}
            </ul>
          </div>
        </li>
      </ul>
    </div>;
  }
  return null;
};

export default AccountInformation;
