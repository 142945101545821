import React, {useState} from 'react';
import {
  PricelistData_products,
  ProductCategories,
  UpdateProduct,
  UpdateProductVariables
} from "../../../../__generated__/types";
import {Button} from "primereact/button";
import {useMutation, useQuery} from "@apollo/client";
import {UpdateProductMutation} from "../../queries.gql";
import {Dialog} from "primereact/dialog";
import {TreeSelect} from "primereact/treeselect";
import {ProductCategoriesQuery} from "../../../../shared/queries/product.gql";
import {productCategoriesTree} from "../../../../shared/utils/productCategory.utils";

interface SetCategoryButtonProps {
  products: PricelistData_products[];
  onFinished: () => void;
}

const SetProductCategoryButton = (props: SetCategoryButtonProps) => {
  const {data} = useQuery<ProductCategories>(ProductCategoriesQuery);

  const [executing, setExecuting] = useState<boolean>();
  const [categoryId, setCategoryId] = useState<string | null>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [updateProduct] = useMutation<UpdateProduct>(UpdateProductMutation, {
    refetchQueries: "active"
  });

  let options = productCategoriesTree(data?.productCategories);

  const saveProduct = () => {
    setExecuting(true);
    let productUpdates = props.products
      .map(product => {
        const variables: UpdateProductVariables = {
          id: product.id,
          data: {
            category: {
              connect: {id: categoryId}
            }
          }
        };
        return variables;
      });
    productUpdates.forEach(async (productUpdate) => {
      await updateProduct({variables: productUpdate});
    });
    setExecuting(false);
    props.onFinished();
  };

  const hideDialog = () => {
    setShowDialog(false);
    props.onFinished();
  };

  return <div>
    <Dialog visible={showDialog} style={{width: '450px'}} header="Productcategorie Details" modal
            className="p-fluid"
            footer={(
              <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct}/>
              </React.Fragment>
            )} onHide={hideDialog}>
      <label className="mb-3">Kies je categorie</label>
      <div>
        <TreeSelect
          value={categoryId}
          onChange={(e) => setCategoryId(e.value as string)}
          options={[{key: undefined, label: 'Geen hoofdcategorie'}, ...options]}
          className="md:w-20rem w-full"
          placeholder="Selecteer een categorie"
          filter
        />
      </div>
    </Dialog>

    <Button
      loading={executing}
      disabled={executing}
      label={`Stel de categorie in voor ${props.products.length} geselecteerde producten`}
      onClick={() => {
        setShowDialog(true);
      }}
    />
  </div>;
};

export default SetProductCategoryButton;
