import React, {useEffect, useRef, useState} from 'react';
import {
  ClientOrderStatus,
  GetClientOrdersToProcess_clientOrders,
  SalesData_clientOrders, Sequences, SequencesVariables, SequenceType
} from "../../../../../__generated__/types";
import ClientOrder from "../ClientOrder";
import {Column} from "primereact/column";
import {chain, compact, first, get, isEmpty, keys, map, omit, orderBy, sum, uniqBy, values} from "lodash";
import {asDdMmYyyy, momentFromIso8601} from "../../../../../shared/utils/date.utils";
import {formatCurrency} from "../../../../../shared/utils/currency.utils";
import {DataTable, DataTableExpandedRows} from "primereact/datatable";
import {Badge, statusOptions} from '../../OrderManagement';
import ClientHeaderRow from "./ClientHeaderRow";
import {clientOrderTotals} from "../../../../../utils/clientOrder.utils";
import {clientName} from "../../../../../shared/utils/client.utils";
import {Button} from "primereact/button";
import {useSales} from "../../../sales-context";
import {useLazyQuery} from "@apollo/client";
import {SequencesQuery} from "../../../../../shared/queries/sequence.gql";
import {accountColumn} from "../../../../SubscriptionsPage/components/columns";
import {useUserObject} from "../../../../../shared/context/UserContext";

interface OrderTableProps {
  clientOrders: any;
}

const orderedByOrderDate = (clientOrdersToProcess: GetClientOrdersToProcess_clientOrders[]) => {
  return orderBy(clientOrdersToProcess, (clientOrder) => {
    return momentFromIso8601(clientOrder.orderDate).unix();
  }, ['desc']);
};


const OrderTable = (props: OrderTableProps) => {
  const {createClientOrderInvoice} = useSales();
  const {activeFarm} = useUserObject();
  const [selection, setSelection] = useState<GetClientOrdersToProcess_clientOrders[]>([]);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const [clientOrders, setClientOrders] = useState<GetClientOrdersToProcess_clientOrders[]>([]);

  useEffect(() => {
    let getClientOrdersToProcessClientOrders = orderedByOrderDate(props.clientOrders);
    //setExpandedRows({[first(getClientOrdersToProcessClientOrders)?.id||'a']: true});
    setClientOrders(getClientOrdersToProcessClientOrders);
  }, [props.clientOrders]);

  const orderTableRef = useRef<DataTable<GetClientOrdersToProcess_clientOrders[]>>(null);
  let clientOrdersInTable = clientOrders.map(clientOrder => ({
    ...clientOrder,
    client: {...clientOrder.client, sortableName: clientName(clientOrder.client)}
  }));

  const variables: SequencesVariables= {
    farmId: activeFarm?.id ||'',
    where: {},
  };
  let [getSequences, {data}] = useLazyQuery<Sequences>(SequencesQuery, {variables});
  const [creatingInvoice, setCreatingInvoice] = useState<boolean>(false);
  useEffect(() => {
    if (data?.sequences) {
      const sequence = compact(data.sequences)
        .find(sequence =>
          sequence.sequenceType === SequenceType.INVOICE_CLIENT
          && sequence.season === null
        );
      if (!sequence) {
        setSelection([]);
        setCreatingInvoice(false);
      } else {
        let client = first(selection)?.client;
        if (client && uniqBy(map(selection, 'client'), 'id').length === 1) {
          createClientOrderInvoice(client, selection, sequence);
          setSelection([]);
        }
        setCreatingInvoice(false);
      }
    }
  }, [data]);

  return <div>
    <div>
      <Button
        className={'p-button-text'}
        loading={creatingInvoice}
        disabled={creatingInvoice || isEmpty(selection) || uniqBy(map(selection, 'client'), 'id').length !== 1}
        label={'Factureer geselecteerde bestellingen'}
        onClick={() => {
          setCreatingInvoice(true);
          getSequences({fetchPolicy: "no-cache"});
        }}
      />
    </div>
    <DataTable<GetClientOrdersToProcess_clientOrders[]>
      className="sf-table"
      // rowGroupMode="subheader"
      // groupRowsBy="client.id"
      sortOrder={-1}
      sortMode="single"
      sortField="createdAt"
      selectAll={false}
      rowClassName={(clientOrder) => {
        if (keys(expandedRows).length > 0) {
          if (expandedRows[clientOrder.id]) {
            return 'font-bold';
          } else {
            return 'opacity-60';
          }
        }
        return '';
      }}
      rowGroupHeaderTemplate={(data: GetClientOrdersToProcess_clientOrders) => {
        return <ClientHeaderRow
          client={data.client}
          selectedClientOrders={selection}
          allClientOrders={compact(clientOrders).filter(clientOrder => clientOrder.client?.id === data.client.id)}
          onInvoiceCreated={() => setSelection([])}
          onSelectAllClientOrders={(client) => {
            if (client) {
              setSelection(clientOrders.filter(clientOrder => clientOrder.client.id === client.id))
            } else {
              setSelection([]);
            }
          }}
        />;
      }}
      rowGroupFooterTemplate={(data: GetClientOrdersToProcess_clientOrders) => <>
        <td colSpan={5}/>
        <td>
          {formatCurrency(sum(clientOrders
            .filter(co => co.client.id === data.client.id)
            .map(co => clientOrderTotals(co).incl.total)
          ))}
        </td>
      </>}
      ref={orderTableRef}
      value={clientOrdersInTable}
      //autoLayout
      selection={selection}
      selectionMode={'multiple'}
      onSelectionChange={(e) => {
        setSelection((e.value as GetClientOrdersToProcess_clientOrders[])
          .filter((clientOrder: {
            status: ClientOrderStatus
          }) => [ClientOrderStatus.DELIVERED, ClientOrderStatus.CREATED].includes(clientOrder.status)));
      }}

      expandedRows={expandedRows}
      dataKey={"id"}
      onRowToggle={(e) => {
        setExpandedRows(e.data as DataTableExpandedRows);
      }}
      rowExpansionTemplate={(clientOrder: GetClientOrdersToProcess_clientOrders) => {
        return <div className="pb-4"><ClientOrder clientOrder={clientOrder}
                                                  onHide={() => setExpandedRows(omit(expandedRows, clientOrder.id))}/>
        </div>;
      }}
    >
      <Column expander style={{width: '3em'}}/>
      <Column selectionMode="multiple" headerStyle={{width: '3rem'}}/>
      <Column
        header={'Status'}
        field={'status'}
        body={(clientOrder: SalesData_clientOrders) => <Badge
          className={`status-${clientOrder.status}`}>{get(statusOptions, clientOrder.status)}</Badge>}
      />
      <Column
        header={'Klant'}
        field={'client.sortableName'}
        sortable
        body={(clientOrder: SalesData_clientOrders) => clientName(clientOrder.client)}
      />
      <Column
        header={'Orderdatum'}
        field={'orderDate'}
        body={(clientOrder: SalesData_clientOrders) => asDdMmYyyy(momentFromIso8601(clientOrder.orderDate))}
      />
      <Column
        header={'Levering'}
        field={'delivery'}
        body={(clientOrder: SalesData_clientOrders) => {
          return <div>
            {clientOrder.delivery && 'Ja'}
          </div>;
        }
        }
      />
      <Column
        header={'Totaal incl. btw'}
        body={(clientOrder: SalesData_clientOrders) => {
          return formatCurrency(clientOrderTotals(clientOrder).incl.total);
        }}
        footer={() => <div>
          {formatCurrency(chain(clientOrdersInTable)
            .map((clientOrder) => clientOrderTotals(clientOrder).incl.total)
            .sum()
            .value())}
        </div>}
      />
    </DataTable>
  </div>;
};

export default OrderTable;
