import React from 'react';
import { compact } from "lodash";
import { momentFromIso8601 } from "../../../../../shared/utils/date.utils";
import moment from "moment";
import styled from "styled-components";

interface PromoCellProps {
  product: { id: string, productPromos: { endDate: any | null }[] | null };
  onPromoClick: (productId: string) => void;
}

const PromoIcon = styled.i`
  cursor: pointer;
  color: var(--orange);
`;

const PromoCell = (props: PromoCellProps) => {
  const {product} = props;
  const hasActivePromo = compact(product.productPromos)
    .filter(promo => (!promo.endDate || momentFromIso8601(promo.endDate).isSameOrAfter(moment(), 'd')))
    .length > 0;

  return <><PromoIcon
    className={`pi pi-star${hasActivePromo ? '-fill' : ''}`}
    onClick={() => props.onPromoClick(product.id)}
  /></>;
};

export default PromoCell;
