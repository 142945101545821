import React from 'react';
import {ClientOrder_clientOrder} from "../../../../../../__generated__/types";
import {iso8601_DayFullDdMmm} from "../../../../../../shared/utils/date.utils";

interface ClientOrderDetailProps {
  clientOrder: ClientOrder_clientOrder;
}

const ClientOrderDetail = (props: ClientOrderDetailProps) => {
  return <div>
    <div>Startdaum: {iso8601_DayFullDdMmm(props.clientOrder.deliveryDate)}</div>
    <div>Einddatum: {iso8601_DayFullDdMmm(props.clientOrder.deliveryEndDate)}</div>
  </div>;
};

export default ClientOrderDetail;
