import {useQuery} from "@apollo/client";
import {BasketCompositions, BasketCompositionsVariables, BasketCompositionType} from "../__generated__/types";
import {Moment} from "moment";
import {BasketCompositionsQuery} from "../shared/queries/basketComposition.gql";

export const useBasketCompositions_comparableForPreviousYear_Query = (farmId: string, deliveryWeek: Moment | null) => {
  let deliveryWeekLastYear = deliveryWeek?.clone().startOf('isoWeek').subtract(1, 'year');
  const variables: BasketCompositionsVariables = {
    farmId: farmId,
    where: {
      OR:[{
        deliveryWeek_lte: deliveryWeekLastYear?.clone().add(2, 'w').startOf('isoWeek') || null,
        deliveryWeek_gte: deliveryWeekLastYear?.clone().subtract(2, 'w').startOf('isoWeek') || null,
        type: BasketCompositionType.FIXED,
      },
        {
          deliveryWeek_lte: deliveryWeekLastYear?.clone().subtract(1,'year').add(2, 'w').startOf('isoWeek') || null,
          deliveryWeek_gte: deliveryWeekLastYear?.clone().subtract(1,'year').subtract(2, 'w').startOf('isoWeek') || null,
          type: BasketCompositionType.FIXED,
        }],

    }
  };

  return useQuery<BasketCompositions>(BasketCompositionsQuery, {variables});
};
