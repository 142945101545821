import React from 'react';
import {ClientOrderStatus, ClientOrderUpdatePaymentStatus} from "../../../__generated__/types";
import {asDdMmYyyy, asIso8601, momentFromIso8601} from "../../../shared/utils/date.utils";
import {formatCurrency} from "../../../shared/utils/currency.utils";
import {compact} from "lodash";
import styled from "styled-components";
import {useMutation} from "@apollo/client";
import {ClientOrderUpdatePaymentStatusMutation} from "../../../shared/queries/clientOrder.gql";
import classNames from "classnames";
import {paymentIsOverdue} from "./Dashboard/DashboardItemRevenue";
import {TODAY} from "../../../shared/config";

interface InvoicePaymentsProps {
  invoice: { payments: { id: string, paid: boolean | null, dueDate: string, amount: number }[] | null };
  clientOrder: { id: string, status: ClientOrderStatus };
}

const Payment = styled.div`
  display: grid;
  grid-gap: 4px;
  align-items: center;
  grid-template-columns: max-content max-content 1fr;
`;
const InvoicePayments = (props: InvoicePaymentsProps) => {
  const [updatePaymentStatus] = useMutation<ClientOrderUpdatePaymentStatus>(ClientOrderUpdatePaymentStatusMutation, {
    refetchQueries: "active",
  });
  const {invoice} = props;
  return <div
    className={classNames(
    "flex flex-wrap"
  )}>
    {compact(invoice.payments).map(p => <Payment
      className={classNames("p-1", {"text-red-500" : paymentIsOverdue(p)})}
      key={p.id}>
      <i style={{fontSize: '22px'}}
         className={`cursor-pointer pi ${p.paid ? 'pi-check-circle pi-green' : 'pi-circle-off'}`}
         onClick={() => {
           let payments = compact(invoice.payments);
           let paidPayments = payments.filter(payment => payment.paid);

           let clientOrderStatus = props.clientOrder.status;
           let nrOfPaidPayments = paidPayments.length + (!p.paid ? 1 : -1);
           if (nrOfPaidPayments === invoice.payments?.length) {
             clientOrderStatus = ClientOrderStatus.PAID;
           } else if (nrOfPaidPayments < (invoice.payments?.length || 0)) {
             clientOrderStatus = ClientOrderStatus.PAID_PARTIALLY;
           }

           updatePaymentStatus({
             variables: {
               clientOrderId: props.clientOrder.id,
               clientOrderStatus,
               paymentId: p.id,
               date: asIso8601(TODAY),
               paid: !p.paid,
             }
           }).then(r => console.log(r));
         }}/>
      <span className={'label'}>{asDdMmYyyy(momentFromIso8601(p.dueDate))}</span>
      <span>{formatCurrency(p.amount)}</span>
    </Payment>)}
  </div>;
};

export default InvoicePayments;
