import React from 'react';
import {BasketCompositions_basketCompositions} from "../../../../../__generated__/types";
import BasketCompositionStatusBadge from "./BasketCompositionStatusBadge";
import BasketCompositionPublishControl from "./BasketCompositionPublishControl";

interface BasketCompositionStatusControlProps {
  basketComposition: BasketCompositions_basketCompositions;
  expanded?: boolean | undefined;
}

const BasketCompositionStatusControl = (props: BasketCompositionStatusControlProps) => {

  return <div className={"h-full flex flex-column justify-content-end"}>
    <BasketCompositionStatusBadge status={props.basketComposition?.status}/>
    {props.expanded && <BasketCompositionPublishControl basketComposition={props.basketComposition}/>}
  </div>;
};

export default BasketCompositionStatusControl;
