import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {PointsQuery} from './queries.gql';
import {
  BasketCompositions,
  BasketCompositionsVariables,
  BasketCompositionType, ClientOrderCompletionStatus,
  PointsData,
  PointsData_clients,
  PointsData_clients_clientOrders,
  PointsData_clients_clientOrders_productOrders,
  PointsData_clients_clientOrders_productOrders_deliveries,
  ProductOrderUpdate,
  ProductOrderUpdateVariables
} from '__generated__/types';
import {DataTable} from "primereact/datatable";
import {chain, compact, first, last, map, sum, uniq} from "lodash";
import {Column} from "primereact/column";
import {clientName} from "../../shared/utils/client.utils";
import ProgressBar from "../../shared/components/ProgressBar/ProgressBar";
import {findBasketProductOrder} from "../../shared/utils/productOrder.utils";
import {ProductOrderUpdateMutation} from "../../shared/queries/productOrder.gql";
import {periodBySubscriptionProductCode} from "../../shared/utils/formula.utils";
import {Checkbox} from "primereact/checkbox";
import classNames from "classnames";
import {roundHalf} from "../../shared/utils/number.utils";
import {totalPortions} from "../../shared/utils/basketComposition.utils";
import {getBasketSize} from "../../shared/utils/product.utils";
import {asDdMmYyyy, asIso8601, momentFromIso8601} from "../../shared/utils/date.utils";
import {TODAY} from "../../shared/config";
import moment from "moment";
import FlexNotifications, {
  FlexNotification,
  highestSeverity,
  highestSeverityIs,
  notificationsFor
} from "../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";
import {BasketCompositionsQuery} from "../../shared/queries/basketComposition.gql";
import {nextDelivery} from "../../shared/utils/clientOrder.utils";
import {useUserObject} from "../../shared/context/UserContext";

interface ProductOrderPointsPageProps {

}

interface PointsTableRow extends PointsData_clients {
  clientOrder: PointsData_clients_clientOrders;
  nextDelivery: PointsData_clients_clientOrders_productOrders_deliveries | undefined;
  lastDelivery: PointsData_clients_clientOrders_productOrders_deliveries | undefined;
  clientName: string;
  productName: string;
  productCode: string;
  portions: number;
  calculatedUsedPortions: number;
  remainingPortions: number;
  remainingPortionsPerDelivery: number;
  avgPortionsPerDelivery: number;
  portionsPerDeliveryOffset: number;
  flexFixed: string;
  isFlex: boolean;
  productOrderId: string;
  productOrder: PointsData_clients_clientOrders_productOrders | undefined;
  trial: string;
  notifications: FlexNotification[];
  notificationSeverity: number;
  deliveries: PointsData_clients_clientOrders_productOrders_deliveries[];
}

const ProductOrderPointsPage = (props: ProductOrderPointsPageProps) => {
  const {activeFarm} = useUserObject();
  const {data, loading} = useQuery<PointsData>(PointsQuery, {
    variables: {afterDeliveryEndDate: asIso8601(TODAY.clone().subtract(2, 'month'))}
  });

  const deliveryWeekStart = TODAY.clone().startOf('isoWeek');
  let deliveryWeekEnd = TODAY.clone().endOf('isoWeek');
  const variables: BasketCompositionsVariables = {
    farmId: activeFarm?.id || '',
    where: {
      type: BasketCompositionType.FIXED,
      deliveryWeek_gte: deliveryWeekStart,
      deliveryWeek_lte: deliveryWeekEnd,
    }
  };
  const {data: fixedBasketCompositionData} = useQuery<BasketCompositions>(BasketCompositionsQuery, {variables});

  const [updateProductOrder] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation);
  const [tableData, setTableData] = useState<PointsTableRow[]>([]);
  const [showFixedBaskets, setShowFixedBaskets] = useState<boolean>(true);

  useEffect(() => {
    const value = chain(data?.clients)
      .compact()
      .map(client => {
        return compact(client.clientOrders)
          .map(clientOrder => {
            let basketProductOrder = findBasketProductOrder(clientOrder.productOrders);
            let product = basketProductOrder?.product;
            let isFlex = product?.code && product.code.indexOf('flex') > -1;
            let isTrial = product?.code && product.code.indexOf('trial') > -1;
            let isLarge = product?.code && product.code.indexOf('large') > -1;

            if (!showFixedBaskets && !isFlex) {
              return null
            }

            let deliveries = compact(basketProductOrder?.deliveries);
            const firstUndelivered = nextDelivery(deliveries);
            const lastDelivery = last(deliveries);

            let productCode = product?.code || 'Onbekend';
            let period = periodBySubscriptionProductCode(productCode);
            let portionsByPeriod = isLarge ? period?.portionsLarge : period?.portionsSmall;

            const avgPortionsPerDelivery = portionsByPeriod ? portionsByPeriod / (period?.nrOfWeeks || NaN) : NaN;

            let deliveredDeliveries = deliveries.filter(delivery => delivery.deliveryDate !== null);

            let usedPortions = sum(deliveredDeliveries.map(delivery => {
                let deliveredBasketItemRows = compact(delivery.basketComposition?.items).filter(item => delivery.deliveredProductIds.includes(item.product.id));
                return totalPortions(deliveredBasketItemRows, getBasketSize(basketProductOrder?.product), first(deliveries)?.deliveryLocation);
              })
            );

            let portions = basketProductOrder?.portions || NaN;
            const remainingPortions = portions - usedPortions;

            const remainingPortionsPerDelivery = remainingPortions / deliveries.filter(d => d.deliveryDate === null).length;

            let fixedBasketComposition = first(fixedBasketCompositionData?.basketCompositions);

            // let additionalPortions = 0;
            // // basket composition is NOT assigned to next delivery because there where insufficient points to receive the basket composition
            // if (basketProductOrder
            //   && firstUndelivered
            //   && firstUndelivered.basketComposition === null
            //   && fixedBasketComposition
            //   && momentFromIso8601(firstUndelivered.plannedDeliveryDate).isBetween(deliveryWeekStart, deliveryWeekEnd)
            // ) {
            //   additionalPortions = totalPortions(fixedBasketComposition?.items, getBasketSize(basketProductOrder.product), firstUndelivered.deliveryLocation);
            //   // basket composition is assigned to next delivery but not yet delivered
            // } else if (basketProductOrder
            //   && firstUndelivered
            //   && firstUndelivered.basketComposition !== null
            // ) {
            //   additionalPortions = totalPortions(firstUndelivered.basketComposition?.items, getBasketSize(basketProductOrder.product), firstUndelivered.deliveryLocation);
            // }

            let notifications = notificationsFor(basketProductOrder);
            let severities = uniq(map(notifications, "severity"));
            let severity = highestSeverity(notifications);

            if (clientOrder.client.email === 'michelle.turelinckx@hotmail.com') {
              debugger;
            }
            if(!firstUndelivered && clientOrder.completionStatus) {
              return null;
            }

            return ({
              ...client,
              clientOrder: {...clientOrder, client},
              clientOrderId: clientOrder.id,
              productOrderId: basketProductOrder?.id || '',
              productOrder: basketProductOrder,
              clientName: clientName(client),
              productName: product?.name || 'Onbekend',
              productCode,
              portions,
              flexFixed: isFlex ? 'Flex' : 'Vast',
              trial: isTrial ? 'Ja' : 'Nee',
              calculatedUsedPortions: usedPortions,
              deliveries,
              remainingPortions,
              isFlex: !!isFlex,
              // additionalPortions,
              notifications,
              notificationSeverity: severity === "error" ? 3 : severity === "warn" ? 2 : severity === "info" ? 1 : 0,
              nextDelivery: firstUndelivered,
              lastDelivery,
              avgPortionsPerDelivery: roundHalf(avgPortionsPerDelivery, 0.25),
              remainingPortionsPerDelivery: roundHalf(remainingPortionsPerDelivery, 0.25),
              portionsPerDeliveryOffset: remainingPortionsPerDelivery / avgPortionsPerDelivery,
            });
          });
      })
      .flatten()
      .compact()
      .value();
    setTableData(value);
  }, [data, fixedBasketCompositionData, showFixedBaskets]);

  if (loading) {
    return <div>Loading... </div>
  }

  return <div>
    <div className="mx-1 text-xs flex align-items-center">
      <Checkbox checked={showFixedBaskets} onChange={(event) => setShowFixedBaskets(event.checked || false)}/>
      <div className="pl-2">Toon vaste pakketten</div>
    </div>
    <DataTable<PointsTableRow[]>
      dataKey={'productOrderId'}
      sortMode={'multiple'}
      multiSortMeta={[
        {field: 'notificationSeverity', order: -1},
        {field: 'lastDelivery.plannedDeliveryDate', order: 1},
        {field: 'portionsPerDeliveryOffset', order: 1},
      ]}
      sortField={'membershipSubType'}
      sortOrder={1}
      className="sf-table"
      rowClassName={(row: PointsTableRow) =>
        classNames(
          {"bg-orange-50": highestSeverityIs(row.notifications, "warn")},
          {"bg-blue-50": highestSeverityIs(row.notifications, "info")},
          {"bg-red-50": highestSeverityIs(row.notifications, "error")},
        )
      }
      value={showFixedBaskets ? tableData : tableData.filter(x => x.isFlex)}
    >
      <Column header=""
              field="notificationSeverity"
              sortable
              className="pl-2"
              body={(row: PointsTableRow) => <FlexNotifications flag
                                                                basketProductOrder={findBasketProductOrder(row.clientOrder.productOrders)}
                                                                clientEmail={row.clientOrder.client.email}
                                                                clientOrderId={row.clientOrder.id}
                                                                nextDelivery={row.nextDelivery}
              />}/>
      <Column header="Naam" field="clientName" sortable
              body={row => <div className="">
                <div>
                  {row.clientName}
                </div>
                <div className="text-gray-400 text-xs">
                  co: {row.clientOrderId} <br/>
                  po:{row.productOrderId}
                </div>
              </div>}/>

      <Column header="Pakkettype" field="productName" sortable/>
      {/*<Column header="Flex/Vast" field="flexFixed" sortable/>*/}
      <Column header="Volgende pakket" field="nextDelivery.plannedDeliveryDate" sortable
              body={(row: PointsTableRow) => {
                let nextDelivery = row.nextDelivery;

                if(row.clientOrder.completionStatus === ClientOrderCompletionStatus.RESUBSCRIBED) {
                  return <div className="text-green-500">Nieuw abonnement afgesloten</div>;
                } else if(row.clientOrder.completionStatus && [ClientOrderCompletionStatus.UNSUBSCRIBED, ClientOrderCompletionStatus.TERMINATED].includes(row.clientOrder.completionStatus)) {
                  return <div className="text-orange-500">Deelnemer zet niet verder</div>;
                }
                if (!nextDelivery) {
                  // return null;
                  return <div>
                    <a
                      href={`mailto:${row.email}?body=https://www.mijnboer.be/groentegeweld/inschrijven?email=` + row.email}>Mail</a><br/>
                    {row.phone}
                  </div>
                }
                let nextDeliveryDate = momentFromIso8601(nextDelivery?.plannedDeliveryDate);
                let basketComposition = nextDelivery?.basketComposition;
                return <div
                  className={classNames(
                    {"font-bold": nextDeliveryDate.isSame(TODAY, 'isoWeek')}
                  )}>
                  {asDdMmYyyy(nextDeliveryDate)}
                  {basketComposition && <div className="text-xs text-color-secondary">
                    <div>{basketComposition.type}</div>
                    <div>{totalPortions(basketComposition.items, getBasketSize(row.productOrder?.product), nextDelivery?.deliveryLocation)} p.</div>
                  </div>}
                </div>;
              }}/>
      <Column header="Laatste pakket" field="lastDelivery.plannedDeliveryDate" sortable
              body={(row: PointsTableRow) => {
                let lastDelivery = row.lastDelivery;
                let lastDeliveryDate = momentFromIso8601(lastDelivery?.plannedDeliveryDate);
                let basketComposition = lastDelivery?.basketComposition;
                return <div
                  className={classNames(
                    {"font-bold": lastDeliveryDate.isSame(TODAY, 'isoWeek')}
                  )}>
                  {asDdMmYyyy(lastDeliveryDate)}
                  {basketComposition && <div className="text-xs text-color-secondary">
                    <div>{basketComposition.type}</div>
                    <div>{totalPortions(basketComposition.items, getBasketSize(row.productOrder?.product), lastDelivery?.deliveryLocation)} p.</div>
                  </div>}
                </div>;
              }}/>
      {/*<Column header="Proef" field="trial" sortable/>*/}
      <Column header="Verbruikte porties" field={"usedPortions"} sortable
              body={(row: PointsTableRow) => {
                let period = periodBySubscriptionProductCode(row.productCode);
                let isLarge = row.productCode.indexOf('large') > -1;
                let portionsByPeriod = isLarge ? period?.portionsLarge : period?.portionsSmall;

                let inError = row.productOrder?.usedPortions !== row.calculatedUsedPortions;

                return <div>
                  <div className={classNames({
                    "font-bold text-red-800 bg-red-100": row.calculatedUsedPortions > row.portions
                  })}>
                    <span>{row.calculatedUsedPortions}</span> / <span>{row.portions}</span>
                    {/*{row.portions !== portionsByPeriod &&*/}
                    {/*  <Button*/}
                    {/*    icon="pi pi-plus-circle"*/}
                    {/*    onClick={() => {*/}

                    {/*      const variables: ProductOrderUpdateVariables = {*/}
                    {/*        where: {*/}
                    {/*          id: row.productOrderId*/}
                    {/*        },*/}
                    {/*        data: {*/}
                    {/*          portions: portionsByPeriod,*/}
                    {/*        },*/}
                    {/*      };*/}
                    {/*      updateProductOrder({variables});*/}
                    {/*    }}/>*/}
                    {/*}*/}
                  </div>
                  <div className={classNames("text-xs", {
                    "font-bold text-red-800 bg-red-100": row.remainingPortions <= 0
                  })}>Resterend: {row.remainingPortions} p.</div>

                  {inError && <div className="pt-2 underline cursor-pointer text-blue-500" onClick={() => {
                    const variables: ProductOrderUpdateVariables = {
                      where: {id: row.productOrderId},
                      data: {
                        usedPortions: row.calculatedUsedPortions
                      }
                    };
                    updateProductOrder({variables});
                  }}>Corrigeer</div>}
                  {inError && <div className="text-xs">{row.productOrder?.usedPortions}</div>}

                </div>;
              }}
      />
      <Column header={"Resterende punten per pakket "} field={"portionsPerDeliveryOffset"} sortable
              body={row => {
                if (!row.nextDelivery) {
                  return null;
                }
                let colorStep = roundHalf(row.portionsPerDeliveryOffset * 10, 1);
                colorStep = colorStep < 1 ? 0 : colorStep;
                colorStep = colorStep > 9 ? 9 : colorStep;
                return <div>
                  <div className="py-2">
                    Resterend:
                    <span
                      className={`white-space-nowrap px-2 font-bold text-${colorStep <= 5 ? 'white' : 'black'} bg-red-${9 - colorStep}00`}>
                    {row.remainingPortionsPerDelivery} p. per pakket
                  </span>
                  </div>
                  <div className="text-xs text-gray-600">Standaard: {row.avgPortionsPerDelivery} p. per pakket</div>
                </div>;
              }}/>
      <Column
        style={{minWidth: '200px'}}
        header="Totaal # porties"
        body={(client: PointsTableRow) => {
          let deliveredDeliveries = client.deliveries.filter(delivery => delivery.deliveryDate !== null);
          return <div>
            <ProgressBar inline max={client.deliveries.length}
                         labelValue={deliveredDeliveries.length}
                         value={deliveredDeliveries.length} suffix="pakketten ontvangen"/>

            <ProgressBar inline max={client.portions} value={client.calculatedUsedPortions}
                         labelValue={client.remainingPortions} suffix="punten resterend"/>

          </div>;
        }}
      />
    </DataTable>


  </div>;
};

export default ProductOrderPointsPage;
